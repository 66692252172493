import React from 'react';

const PrivacyPolicyEs = () => (
  <div className='privacy-notice'>
    <h1>Aviso de Confidencialidad de Propeller Health</h1>
    <p>
      La confidencialidad de sus datos es importante para nosotros. Reciprocal
      Labs Corporation (cuyo nombre comercial es Propeller Health) (al cual se
      hace referencia en todo el presente aviso como "Propeller Health",
      "nosotros", "nos", "nuestro") tiene el compromiso de proteger la
      confidencialidad de sus datos. Como parte de nuestro compromiso de cumplir
      con dicha tarea, el presente Aviso de Confidencialidad explica cómo
      abordamos la protección y el uso de los datos que recabamos sobre usted.
      Sus datos personales se procesan de conformidad con todas las normas
      federales, estatales y locales aplicables, incluida la Ley de Privacidad
      del Consumidor de California (CCPA, por sus siglas en inglés) de 2018 y
      las Normas del Procurador General propuestas en 2019
    </p>
    <p>
      Nuestro Aviso de Confidencialidad explica cómo procesamos sus datos
      personales cuando usted utiliza nuestros servicios. Dichos servicios son:
      los sensores, los hubs, el sitio web, y la aplicación para dispositivos
      móviles (en conjunto, "el sistema de Propeller"), que recopilan, almacenan
      y procesan datos para brindarle información que le ayude a respirar mejor.
    </p>
    <h2>Quién está a cargo:</h2>
    <p>
      En referencia al procesamiento de datos personales en el contexto de los
      Servicios, se considera "responsable de los datos" a Propeller Health, con
      sede en 1 South Pinckney Street, Suite 610, Madison, WI 53703, y cuya
      dirección de correo electrónico es{' '}
      <a href='mailto:help@propellerhealth.com'>help@propellerhealth.com</a>.
      Propeller Health ha designado a una Directora de Protección de
      Confidencialidad de la Información, Stacy Tyman, con despacho en la
      dirección mencionada y a quien se le puede escribir a{' '}
      <a href='mailto:dpo@propellerhealth.com'>dpo@propellerhealth.com</a>.
    </p>
    <h2>Tipos de información que recopilamos</h2>
    <h3>Información que proporciona el usuario:</h3>
    <p>
      Recopilamos datos que usted nos da a conocer cuando ingresa su información
      personal para abrir una cuenta con nosotros, comunicarse con nuestro
      equipo de atención al cliente y utilizar el sistema de Propeller.
    </p>
    <p>
      Dichos datos podrían incluir información personal, información protegida,
      información médica e información biométrica conforme se describe a
      continuación:
    </p>
    <ul>
      <li>Nombre y apellido</li>
      <li>Fecha de nacimiento Dirección de correo electrónico</li>
      <li>Dirección postal</li>
      <li>Número de teléfono</li>
      <li>Dirección IP/identificador de dispositivio</li>
      <li>Datos de geoubicación (pueden desactivarse desde su dispositivo)</li>
      <li>Si sufre de asma o de EPOC</li>
      <li>
        Información sobre sus medicamentos, tal como el nombre del medicamento,
        las dosis y el calendario
      </li>
      <li>
        Información sobre su asma o su EPOC, incluidos sus síntomas y sus
        desencadenantes
      </li>
      <li>
        Información de uso proveniente de su sensor o de un hub, o que ingrese a
        mano en la aplicación para dispositivos móviles
        <ul>
          <li>
            La información de uso incluirá la fecha y la hora de uso de su
            medicamento y, en algunos casos, el lugar en el cual lo tomó.
          </li>
        </ul>
      </li>
    </ul>
    <h3>Información comercial:</h3>
    <p>
      Propeller Health conserva la información sobre los productos que usted
      recibe de nosotros, para brindarle ayuda con el uso del sistema de
      Propeller.
    </p>
    <h3>Cookies:</h3>
    <p>
      Las cookies son pequeños archivos de texto que los sitios web pueden
      utilizar para personalizar más la interacción con el usuario. Propeller
      Health utiliza cookies para personalizar el contenido y los anuncios, y
      para analizar el tráfico del sitio. Eso nos ayuda a brindarle la
      información más relevante en lo que a su experiencia con Propeller
      respecta.
    </p>
    <p>
      Propeller Health utiliza Google Analytics, un servicio de análisis web que
      brinda Google, con la finalidad de entender mejor cómo el público utiliza
      el sitio web y hacer cambios para mejorar su experiencia.
    </p>
    <p>
      Los datos que recopile Google se guardarán en cookies en su navegador y se
      transmitirán y guardarán en un servidor de Google. La dirección IP (o
      dirección IP reducida) transmitida desde su navegador durante el uso de
      Google Analytics no se asociará con ningún otro dato que guarde Google.
      Para evitar que se guarden cookies de Google Analytics, haga los ajustes
      correspondientes en la configuración de su navegador.
    </p>
    <p>
      En el caso de los usuarios que acceden al sistema de Propeller Health
      mediante anuncios de Facebook, Propeller Health utiliza herramientas de
      rastreo de conversión de Facebook para evaluar en qué medida es eficaz la
      publicidad que hacemos para usuarios como usted. Cuando crea una cuenta en
      Propeller, Facebook guarda su nombre y su posible afección respiratoria
      para medir la eficacia de nuestras campañas publicitarias actuales y como
      ayuda para hacer llegar nuestros anuncios a otros potenciales usuarios que
      puedan sacar provecho de Propeller.
    </p>
    <h3>Integraciones:</h3>
    <p>
      Si usan un dispositivo de Apple, los pacientes pueden tener la opción de
      activar la integración con la aplicación HealthKit. Los usuarios que
      activen esa función permitirán a Propeller Health acceder a los datos de
      salud y ejercicio que la aplicación HealthKit recopile sobre ellos. El
      sistema de Propeller recopilará y almacenará los datos de HealthKit. Los
      usuarios pueden desactivar esa integración en cualquier momento desde los
      ajustes de su dispositivo.
    </p>
    <h3>Información para padres de usuarios menores de 13 años de edad:</h3>
    <p>
      Para que los padres inscriban a sus hijos en el Servicio, necesitamos que
      nos brinden consentimiento verificado. Para verificar su información,
      utilizamos un servicio de terceros. Los padres enviarán su información a
      dicho tercero. Esos datos pueden incluir el nombre, la dirección postal y
      la fecha de nacimiento. Dicha información se utiliza solo para verificar
      su identidad y no se dará a conocer a ningún otro tercero.
    </p>
    <p>
      El sistema de Propeller no se concibió para que los niños menores de 13
      años lo utilicen sin el consentimiento de uno de sus padres o de un tutor.
      Si es padre, madre o tutor de un niño de menos de 13 años de edad y cree
      que el niño nos ha brindado información sin su consentimiento, sírvase
      escribir a 
      <a href='mailto:dpo@propellerhealth.com'>dpo@propellerhealth.com</a> para
      que podamos eliminar dicha información a la brevedad.
    </p>
    <h2>Uso y almacenamiento de su información personal:</h2>
    <p>
      Podemos utilizar la información personal recopilada mediante el sistema de
      Propeller con los siguientes fines:
    </p>
    <h3>Afiliados de Propeller Health:</h3>
    <p>
      Propeller Health puede compartir su información personal con cualquiera de
      nuestras afiliadas, con ResMed (nuestra casa matriz), con cualquier
      afiliada o subsidiaria de ResMed, o con cualquier otra entidad que
      comparta el control o la propiedad común con nosotros.
    </p>
    <h3>Atención al cliente y comunicación:</h3>
    <p>
      Propeller Health puede usar la información que nos brinde mediante su uso
      del sistema de Propeller para comunicarse con usted con el fin de ayudarle
      a utilizar el sistema de Propeller. Podemos comunicarnos con usted para
      conocer novedades sobre su estado de salud, enviarle anuncios importantes
      sobre el Servicio e información sobre servicios que, a nuestro criterio,
      puedan ser de su interés o pedirle su opinión sobre el sistema de
      Propeller. Puede recibir dicha información por correo electrónico,
      notificaciones automáticas de la aplicación, teléfono o mensajes de texto.
      Para no recibir dichas notificaciones, modifique los ajustes desde su
      dispositivo móvil o el portal web.
    </p>
    <h3>Análisis:</h3>
    <p>
      Propeller Health puede utilizar su información para brindarle información
      sobre el nivel de eficacia del control de su enfermedad. Podemos utilizar
      información sobre su ubicación para brindarle datos sobre el tiempo y el
      ambiente que puedan influir en el control de su enfermedad.
    </p>
    <h3>Investigación</h3>
    <p>
      A Propeller Health le interesa entender el control y los efectos de las
      enfermedades respiratorias, y encontrar y promover mejores métodos para
      tratarlas. Podemos hacer anónimos sus datos y combinarlos con los datos de
      otros usuarios (datos conjuntos) para llevar a cabo investigaciones sobre
      enfermedades respiratorias crónicas con el objeto de mejorar el
      diagnóstico y el tratamiento, reducir los efectos de factores ambientales
      tales como la contaminación y seguir mejorando Propeller para prestarle el
      mejor servicio posible.
    </p>
    <h3>Leyes, normas y procesos legales</h3>
    <p>
      Es posible que se le exija a Propeller Health utilizar información
      recopilada sobre usted para cumplir con diferentes leyes y normas en caso
      de una investigación o de actuaciones judiciales, en caso de fusión, venta
      u otra transferencia de activos y para proteger los derechos y la
      integridad de Propeller Health, sus empleados, sus clientes o el público.
    </p>
    <h2>Divulgación de su información personal</h2>
    <p>
      Podemos dar a conocer su información personal a los siguientes grupos:
    </p>
    <h3>Terceros que presten servicios en representación de Propeller</h3>
    <p>
      Podemos dar a conocer su información a terceros proveedores de servicios
      que nos ayuden con el funcionamiento de los servicios que prestamos
      mediante el sistema de Propeller. Limitamos la información que damos a
      conocer a dichos proveedores solo a los datos necesarios para los fines
      descritos en nuestros contratos.
    </p>
    <h3>Terceros asociados o instituciones de investigación/salud pública</h3>
    <p>
      Además de satisfacer sus necesidades individuales y las necesidades de
      nuestros clientes, brindamos información a los Socios de Propeller que
      puedan estar contribuyendo con los servicios que Propeller le preste a
      usted, y a otros terceros tales como instituciones de salud pública. Dicha
      información incluye datos que emplean en sus actividades de investigación
      científica relacionadas con temas de salud. Antes de dar a conocer dicha
      información, eliminamos la información de identificación personal que
      pueda utilizarse para identificarlo.
    </p>
    <h3>Venta de Propeller Health:</h3>
    <p>
      En caso de una transacción comercial tal como la venta de la totalidad o
      parte de nuestra empresa (por ejemplo: una fusión, liquidación,
      reorganización, transferencia u otra transacción o negociación comercial),
      nos reservamos el derecho de transferir su información a un tercero que
      adquiera nuestros activos. Recibirá un comunicado en referencia a dicho
      tema.
    </p>
    <h3>Cuidadores, proveedores de servicios de salud, otras personas</h3>
    <p>
      Como usuario de Propeller Health, tiene la opción de dar a conocer su
      información a cuidadores, proveedores de servicios de salud y otras
      personas. Puede otorgar o quitar dichos permisos en cualquier momento
      desde su perfil.
    </p>
    <h2>Sus opciones</h2>
    <p>
      Propeller Health le ofrece ciertas opciones sobre la forma de uso de sus
      datos personales y la interacción entre la empresa y usted. En cualquier
      momento puede dejar de prestar su consentimiento para lo siguiente:
    </p>
    <ul>
      <li>
        Puede optar por no recibir materiales o información de mercadotecnia
        proveniente de nosotros. Para hacerlo, modifique los ajustes de
        notificaciones desde la aplicación para dispositivos móviles o el portal
        web, o mediante los comunicados que le enviamos.
      </li>
      <li>
        Puede cambiar sus ajustes desde el portal web para usuarios si desea
        dejar de recibir mensajes de texto.
      </li>
      <li>
        Puede cambiar los ajustes de su dispositivo móvil para no recibir
        notificaciones automáticas.
      </li>
      <li>
        Puede administrar y actualizar desde los ajustes de su cuenta la
        información personal que nos brinde.
      </li>
      <li>
        Después de recibir un mensaje de texto, puede responder con la palabra
        STOP para que lo eliminemos de la lista de destinatarios de mensajes de
        texto.
      </li>
      <li>
        Si en algún momento decide no seguir utilizando el sistema de Propeller,
        puede escribirle a nuestro equipo de atención al cliente a{' '}
        <a href='mailto:help@propellerhealth.com'>help@propellerhealth.com</a> o
        llamar al 877-251-5451 para eliminar su cuenta.
      </li>
    </ul>
    <p>
      Propeller Health no recopilará más información personal ni utilizará la
      información personal recopilada con fines diferentes, no relacionados o
      incompatibles sin notificárselo.
    </p>
    <h2>Información adicional para residentes de California</h2>
    <h3>Venta de información personal:</h3>
    <p>
      En los últimos doce (12) meses, no hemos vendido categoría alguna de
      información personal. Propeller Health no vende información personal a
      terceros.
    </p>
    <h3>Sus derechos y opciones:</h3>
    <p>
      La CCPA otorga a los clientes (residentes de California) derechos
      específicos en referencia a su información personal. Usted tiene los
      siguientes derechos:
    </p>
    <h3>Derechos de divulgación:</h3>
    <p>
      Tiene el derecho de solicitar que le demos a conocer las categorías de
      información personal que recopilemos en el período de 12 meses anterior a
      la fecha de solicitud. Los consumidores pueden hacer un máximo de dos
      solicitudes de este tipo en un período de 12 meses. Las categorías de
      información personal son las siguientes (nota: las áreas en negrita son
      categorías de las cuales Propeller recopila datos):
    </p>
    <ul>
      <li className='font-bold'>Categoría A: Identificadores </li>
      <li className='font-bold'>
        Categoría B: Categorías de información personal de registros de clientes
        de California
      </li>
      <li className='font-bold'>
        Categoría C: Características de clasificación protegidas de conformidad
        con las leyes de California o las federales
      </li>
      <li className='font-bold'>Categoría D: Información comercial </li>
      <li className='font-bold'>Categoría E: Información biométrica </li>
      <li className='font-bold'>
        Categoría F: Actividad en Internet u otra red similar{' '}
      </li>
      <li className='font-bold'>Categoría G: Datos de geoubicación </li>
      <li className='font-bold'>Categoría H: Datos sensoriales</li>
      <li className='font-bold'>
        Categoría I: Información profesional o relacionada con el trabajo
      </li>
      <li className='font-bold'>
        Categoría J: Información sobre educación no pública{' '}
      </li>
      <li className='font-bold'>
        Categoría K: Deducciones hechas a partir de otra información personal
      </li>
    </ul>
    <h3>Solicitud de acceso a los datos:</h3>
    <p>
      Tiene derecho a solicitar a Propeller Health que le dé a conocer cierta
      información sobre la recopilación y el uso de su información personal en
      los 12 meses anteriores. Después de haber recibido su solicitud
      y confirmarla (consulte: "Ejercicio de sus derechos sobre los datos"), le
      daremos a conocer la siguiente información:
    </p>
    <ul>
      <li>
        Las categorías de información personal que recopilamos sobre usted.
      </li>
      <li>
        Las categorías de las fuentes de la información personal que recopilamos
        sobre usted.
      </li>
      <li>
        Las razones empresariales o comerciales por las cuales recopilamos o
        vendemos esa información personal.
      </li>
      <li>Los datos personales específicos que recopilamos sobre usted.</li>
      <li>
        Si divulgamos información personal a terceros, le daremos a conocer las
        categorías de dichos terceros.
      </li>
      <li>
        Si divulgamos su información personal con fines empresariales, le
        proporcionaremos una lista donde se enumeren las categorías de
        información personal que obtuvo cada categoría de destinatario.
      </li>
    </ul>
    <h3>Solicitudes de eliminación:</h3>
    <p>
      Tiene derecho a solicitar que Propeller Health elimine cualquiera de sus
      datos personales recibidos de usted y conservados, salvo ciertas
      excepciones establecidas por las leyes aplicables. Después de que hayamos
      recibido y confirmado su solicitud de consumidor (consulte: "Ejercicio de
      sus derechos sobre los datos"), eliminaremos (e indicaremos a nuestros
      proveedores de servicio que eliminen) su información personal de nuestros
      registros, salvo que rija una excepción.
    </p>
    <p>
      Podemos rechazar la solicitud de eliminación si es necesario conservar la
      información para permitirnos a nosotros o a nuestros proveedores de
      servicio:
    </p>
    <ul>
      <li>
        Completar la transacción para la cual recopilamos la información
        personal, proporcionar un bien o prestar un servicio que usted haya
        solicitado, tomar medidas esperadas dentro de lo razonable y en el
        contexto de nuestra relación comercial en curso con usted o emprender
        acciones contempladas en el contrato que tengamos con usted.
      </li>
      <li>
        Detectar incidentes de seguridad, brindar protección contra actividades
        maliciosas, engañosas, fraudulentas o ilegales, o interponer acciones
        judiciales contra los responsables de dichas actividades.
      </li>
      <li>
        Identificar y enmendar errores que dificulten el funcionamiento del
        producto tal como se lo concibió. Ejercer la libertad de expresión,
        garantizar el derecho de otro consumidor a ejercer sus derechos de
        libertad de expresión o ejercer otro derecho que establezca la ley.
      </li>
      <li>
        Participar en investigaciones históricas, estadísticas, científicas
        revisadas por expertos o públicas que sean de interés público y cumplan
        con todas las otras leyes de ética y confidencialidad aplicables cuando
        sea probable que la eliminación de la información haga imposible o
        perjudique en gran medida el cumplimiento de los objetivos de la
        investigación, siempre y cuando usted haya brindado consentimiento
        informado.
      </li>
      <li>
        Permitir usos solo internos que estén, dentro de una medida razonable,
        en línea con las expectativas del consumidor sobre la base de la
        relación de usted con nosotros.
      </li>
      <li>
        Cumplir con una obligación legal. Dar otros usos internos y legítimos a
        esa información que sean compatibles con el contexto en el cual usted la
        proporcionó.
      </li>
    </ul>
    <h3>Ejercicio de sus derechos sobre los datos:</h3>
    <p>
      Para ejercer sus derechos de divulgación, acceso, portabilidad y
      eliminación de datos descritos arriba, envíenos una solicitud por uno de
      los siguientes medios:
    </p>
    <ul>
      <li>Llámenos al 877-251-5451 </li>
      <li>Escríbanos a datarequests@propellerhealth.com</li>
      <li>
        Complete este{' '}
        <a href='https://propellerhealth.force.com/helpcenter/s/contactsupport?language=es_US'>
          formulario web
        </a>
      </li>
    </ul>
    <p>
      Solo usted o una persona inscrita en la Secretaría de Estado de California
      a quien usted haya autorizado para representarlo pueden hacer una
      solicitud de consumidor en relación con su información personal. También
      puede hacer una solicitud de consumidor en representación de un menor.
      Solo puede hacer ese tipo de solicitudes de acceso o portabilidad de datos
      dos veces en un período de 12 meses. La solicitud de consumidor debe:
    </p>
    <ul>
      <li>
        Brindar suficiente información que nos permita verificar en una medida
        razonable que usted es la persona sobre la cual recopilamos información
        personal o demuestre que es un representante autorizado de dicha persona
      </li>
      <li>
        Describir su solicitud con suficiente detalle como para permitirnos
        entenderla, evaluarla y responder a ella como corresponde.
      </li>
    </ul>
    <p>
      Si no podemos verificar su identidad o autoridad para hacer la solicitud y
      confirmar que la información personal se relaciona con usted, no podemos
      responder a su solicitud o brindarle información personal. Solo
      utilizaremos la información personal que se nos brinde en solicitudes de
      consumidor para verificar la identidad del solicitante o la autoridad para
      hacer la solicitud.
    </p>
    <h3>Tiempos y formato de respuesta</h3>
    <p>
      Tenemos la intención de responder a toda solicitud de consumidor dentro de
      los 45 días posteriores a su recepción. Si necesitamos más tiempo (hasta
      90 días), le comunicaremos por escrito el motivo y el tiempo de la demora.
      Enviaremos nuestra respuesta escrita por medios electrónicos. La
      información divulgada que brindemos solo cubrirá el período de 12 meses
      anterior a la recepción de la solicitud de consumidor. Si corresponde, la
      respuesta que brindemos también explicará los motivos por los cuales no
      podemos cumplir con la solicitud. Entregaremos los datos pedidos en las
      solicitudes de acceso a datos en un formato fácil de usar.
    </p>
    <h3>No discriminación</h3>
    <p>
      No lo discriminaremos por ejercer los derechos que le confiere la CCPA.
    </p>
    <h3>Otros derechos de confidencialidad de California</h3>
    <p>
      La ley "Shine the Light" (Brillo de luz) (apartado 1798.83 del Código
      Civil) de California permite a los usuarios de nuestro sitio web que sean
      residentes de dicho estado solicitar cierta información sobre nuestra
      divulgación de información personal a terceros para sus fines de
      mercadotecnia directa.
    </p>
    <p>
      No damos a conocer información personal a terceros para sus fines de
      mercadotecnia directa.
    </p>
    <h2>Revisiones</h2>
    <p>
      Este Aviso de Confidencialidad está sujeto a cambios a nuestra discreción.
      Para indicar los cambios, incluidos los de carácter sustancial, al Aviso
      de Confidencialidad, modificaremos la "Fecha de entrada en vigencia". Usar
      el Servicio después de cualquier modificación del presente Aviso de
      Confidencialidad constituirá su aceptación de las modificaciones.
    </p>

    <h2>Información de contacto</h2>
    <p>
      Si tiene alguna duda o inquietud acerca del presente Aviso de
      Confidencialidad, sírvase escribirnos a{' '}
      <a href='mailto:dpo@propellerhealth.com'>dpo@propellerhealth.com</a>.
    </p>
    <p className='version'>Fecha de entrada en vigencia: 05/26/2020</p>
  </div>
);

export default PrivacyPolicyEs;
