import React, { Component } from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import PropTypes from 'prop-types';
import container from '../container';
import PageHeading from '../../../components/PageHeading/PageHeading';
import Loading from '../../../components/Loading';

import { verifyId, verifyAnswers } from '../../../api/user';
import { logPage } from '../../../lib/analytics';
import { cleanEmptyStrings, isNotEmpty } from '../../../lib/utilities';
import CoppaQuestion from './CoppaQuestion';
import CoppaResult from './CoppaResult';

import EnrollmentButtons from '../Buttons';
export class CoppaQuestions extends Component {
  state = {
    questionIdx: 0,
    coppaData: [],
    error: false,
    errorMessage: false,
    success: false,
    loading: true,
    answers: [],
  };

  componentDidMount() {
    if (!this.props.enrollmentData.caregiver) {
      this.props.pushHistory('/enrollment');
    } else {
      this.loadQuestions();
    }
    logPage('Coppa-Questions');
  }

  loadQuestions() {
    const { caregiver, mailingAddress } = this.props.enrollmentData;
    if (caregiver) {
      const data = {
        givenName: caregiver.givenName,
        familyName: caregiver.familyName,
        birthDate: caregiver.birthDate,
        address: cleanEmptyStrings(mailingAddress),
      };

      verifyId(data)
        .then((response) => {
          this.setState(() => {
            return {
              coppaData: response,
              loading: false,
              error: false,
            };
          });
        })
        .catch(() => {
          this.setError();
        });
    }
  }

  setError = () => {
    this.setState({
      loading: false,
      error: true,
    });
  };

  validateAnswers() {
    const { coppaData, answers } = this.state;
    const { id, questions } = coppaData;

    const data = answers.reduce((str1, answer, idx1) => {
      const answerIdx = parseInt(answer, 10);
      return (
        str1 +
        questions[idx1].answers.reduce(
          (str2, q, idx2) => str2 + (answerIdx === idx2 ? 1 : 0),
          ''
        )
      );
    }, '');

    this.setState({ loading: true });

    verifyAnswers({ id: id, answers: data })
      .then((json) => {
        this.props.updateEnrollmentData(json);
        if (json.verified) {
          this.setState({ loading: false, success: true });
        } else {
          this.setError();
        }
      })
      .catch(() => {
        this.setError();
      });
  }

  handleChange = (e) => {
    const { questionIdx } = this.state;
    const answer = { [questionIdx]: e.target.value };
    const answers = Object.assign([], this.state.answers, answer);
    this.setState({ answers: answers, errorMessage: false });
  };

  handleBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  handleSubmit = () => {
    if (this.state.answers[this.state.questionIdx]) {
      const nextIdx = this.state.questionIdx + 1;
      if (nextIdx >= this.state.coppaData.questions.length) {
        this.validateAnswers();
      } else {
        this.setState({
          questionIdx: nextIdx,
        });
      }
    } else {
      this.setState({ errorMessage: true });
    }
  };

  render() {
    const { groupConfig, groupConfig4x, t } = this.props;
    const { questionIdx, coppaData } = this.state;
    const question = coppaData.questions && coppaData.questions[questionIdx];

    if (this.state.error || this.state.success) {
      return (
        <CoppaResult
          groupConfig4x={groupConfig4x}
          groupConfig={groupConfig}
          t={t}
          verified={this.state.success}
        />
      );
    }

    return (
      <Container className='coppa-questions'>
        <PageHeading pageTitle={t('coppa:PAGE_TITLE_COPPA')}>
          {isNotEmpty(coppaData) ? (
            <>
              <CoppaQuestion
                questionIdx={questionIdx}
                onChange={this.handleChange}
                question={question}
              />
              {this.state.errorMessage ? <div
                role='alert'
                aria-live='assertive'
                className='error-message-center'
              >
                {t('SELECT_OPTION')}
              </div> : null}
              <EnrollmentButtons
                handleBack={this.handleBack}
                handleSubmit={this.handleSubmit}
                t={t}
              />
            </>
          ) : (
            <Loading />
          )}
        </PageHeading>
      </Container>
    );
  }
}

CoppaQuestions.propTypes = {
  enrollmentData: PropTypes.object.isRequired,
  groupConfig: PropTypes.object.isRequired,
  groupConfig4x: PropTypes.object,
  t: PropTypes.func.isRequired,
  updateEnrollmentData: PropTypes.func.isRequired,
};

export default compose(
  withTranslation(['enrollment', 'coppa']),
  container
)(CoppaQuestions);
