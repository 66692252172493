import React, { Component } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import StandardCodeInput from '../StandardCodeInput';
import EditableCodeInput from '../EditableCodeInput';
import GreenButton from '../../../components/Button/GreenButton';

const SUCCESS_MESSAGE = 'LOOKS_GOOD_EXCLAMATION';

class AccessCodeEntry extends Component {
  state = {
    accessCode: this.props.accessCode || '',
    error: { type: null, msg: null },
    submitting: false,
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.updateCode = this.updateCode.bind(this);
  }

  onChange(e) {
    const { value } = e.target;
    let _state = { accessCode: value };

    if ('____-____' === value) {
      _state.error = { type: null, msg: null };
    }

    this.setState(_state);
  }

  updateCode(code) {
    this.setState({ accessCode: code });
    return this.props.onSuccess({
      accessCode: code,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const { validate, onSuccess, errorMessages = {} } = this.props;
    const { accessCode: _accessCode } = this.state;

    const accessCode = _accessCode.replace(/[^0-9-]/g, '');

    if (accessCode.length < 9) {
      return this.setState({
        error: { type: 'warning', msg: errorMessages.tooShort },
      });
    }

    this.setState({ submitting: true });

    validate(accessCode)
      .then(({ eligible }) => {
        this.setState({
          submitting: false,
          error: { type: null, msg: null },
        });

        if (eligible) {
          return onSuccess({ eligible, accessCode });
        } else {
          return this.setState({
            error: { type: 'error', msg: errorMessages.invalid },
          });
        }
      })
      .catch(() =>
        this.setState({
          error: { type: 'error', msg: errorMessages.serverError },
          submitting: false,
        })
      );
  }

  getValidationState() {
    const { error, accessCode } = this.state;

    if (error.type === 'error') return error.type;

    if (accessCode.replace(/[^0-9-]/g, '').length === 9) {
      return 'success';
    }

    return error.type;
  }

  render() {
    const { accessCode, error, submitting } = this.state;
    const { subheading, eligible, phoneNumber, validate, t } = this.props;

    const validationState = this.getValidationState();
    const helpMessage =
      validationState === 'success'
        ? SUCCESS_MESSAGE
        : error.msg
        ? error.msg
        : null;

    return (
      <React.Fragment>
        <Collapse in={!eligible}>
          <div>
            <h1>{t('ENTER_YOUR_ACCESS_CODE')}</h1>
            <div
              className='subheading'
              style={eligible ? { visibility: 'hidden' } : null}
            >
              {t(subheading)}
            </div>
          </div>
        </Collapse>
        {eligible ? (
          <EditableCodeInput
            value={accessCode}
            onUpdate={this.updateCode}
            validate={validate}
            t={t}
          />
        ) : (
          <form onSubmit={this.onSubmit}>
            <StandardCodeInput
              onChange={this.onChange}
              value={accessCode}
              validationState={validationState}
              helpMessage={t(helpMessage, { phoneNumber })}
              t={t}
            />
            <GreenButton
              type='submit'
              block
              disabled={submitting}
              style={{
                maxWidth: '250px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              {t('common:SUBMIT')}
            </GreenButton>
          </form>
        )}
      </React.Fragment>
    );
  }
}

export default AccessCodeEntry;
