import { makeActionCreator } from './utilities';

export const SHOW_VIDEO = 'SHOW_VIDEO';
export const VIDEO_VIEWED = 'VIDEO_VIEWED';
export const SET_WALGREENS_PRODUCTS = 'SET_WALGREENS_PRODUCTS';
export const RESET_OBJECT_ID = 'RESET_OBJECT_ID';
export const SET_DISEASE = 'SET_DISEASE';
export const ACCESS_VALIDATED = 'ACCESS_VALIDATED';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_DISABLED_INPUTS = 'SET_DISABLED_INPUTS';

export const setDisease = makeActionCreator(SET_DISEASE, 'disease');
export const showVideo = makeActionCreator(SHOW_VIDEO, 'videoInfo');
export const videoViewed = makeActionCreator(VIDEO_VIEWED);
export const _setError = makeActionCreator(SET_ERROR, 'error');
export const clearErrors = makeActionCreator(CLEAR_ERRORS);
export const setWalgreensProducts = makeActionCreator(
  SET_WALGREENS_PRODUCTS,
  'walgreensProducts'
);
export const accessValidated = makeActionCreator(ACCESS_VALIDATED, 'validated');
export const resetObjectId = makeActionCreator(RESET_OBJECT_ID);
export const setDisabledInputs = makeActionCreator(
  SET_DISABLED_INPUTS,
  'inputs'
);

export function setError(err) {
  return function (dispatch) {
    if (err.text && typeof err.text === 'function') {
      return err.text().then((text) => {
        return dispatch(
          _setError({
            code: err.code,
            statusText: err.statusText,
            response: text,
          })
        );
      });
    } else {
      return dispatch(_setError(err));
    }
  };
}

export * from './enrollment';
export * from './groupConfig';
export * from './quizes';
export * from './medSelection';
export * from './session';
export * from './pharmaPrograms';
