import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Flag from '../Flag';
import Dropdown from 'react-bootstrap/Dropdown';
import container from './container';
import { logEvent } from '../../lib/analytics';
import { getLanguageName } from '../../lib/utilities/languageMap';
import { chatInit } from '../../lib/sfChat';
import './styles.scss';
import caret from '../../assets/images/icons/icon-caret.svg';

export class LanguageSelection extends React.Component {
  menuSelection = (locale) => {
    const { i18n } = this.props;
    const label = `click-setlocale-${locale}`;
    logEvent('click', {
      action: label,
      label: label,
    });

    // percolate language change: changeLanguage method from i18next; & update sfChat w/new locale
    i18n.changeLanguage(locale, () => {
      chatInit(locale);
    });

    this.setState(() => ({
      dropDownSelected: false,
    }));
  };

  toggleMenu = () => {
    this.setState((prevState) => ({
      dropDownSelected: !prevState.dropDownSelected,
    }));
  };

  render() {
    const { locales, language = '', country } = this.props;
    return (
      <Dropdown id='language-toggle'>
        <Dropdown.Toggle variant='outline-dark'>
          <Flag
            className='selected-locale-flag'
            country={country}
          />
          <label className='selected-locale' value={language}>
            {getLanguageName(language)}
          </label>
          <img src={caret} alt='' width='24' height='24' />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {locales.map((locale) => {
            return (
              <Dropdown.Item
                key={'list-item-' + locale}
                className='locale-list-item'
                onClick={() => this.menuSelection(locale)}
              >
                <Flag country={country} />
                <label value={locale}>{getLanguageName(locale)}</label>
                {locale === language ? (
                  <span className='glyphicon glyphicon-ok'></span>
                ) : null}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default compose(withTranslation(), container)(LanguageSelection);
