import { connect } from 'react-redux';
import { push as pushHistory } from 'connected-react-router';
import { setEnrollmentMeds, setWalgreensProducts } from '../../actions';
import { enrollmentDisease, isPaid } from '../../lib/utilities';
import { SELF_PAID } from '../../lib/pharmaProgram';

const mapStateToProps = (state) => {
  const {
    enrollmentMeds,
    groupConfig,
    enrollmentData,
    pharmaProgram,
    subdomain,
    unsensoredMeds,
  } = state;

  const requiresPayment =
    isPaid(subdomain) && pharmaProgram.confirmedProgram === SELF_PAID;

  return {
    disease: enrollmentDisease(enrollmentData),
    pharmaProgram,
    groupConfig,
    enrollmentMeds,
    requiresPayment,
    unsensoredMeds,
  };
};

const mapDispatchToProps = {
  pushHistory,
  setEnrollmentMeds,
  setWalgreensProducts,
};

export default connect(mapStateToProps, mapDispatchToProps);
