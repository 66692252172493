const IMG_LOCALE = '{{LOCALE}}';
const IMG_LOCALE_RX = new RegExp(IMG_LOCALE, 'g');

/**
 * To be used in an [].map and takes a new array.
 * Each entry in the new array is applied back to the initial array
 * in order to return a new array matching the initial one in structure
 * but with updated values.
 *
 * eg. DEFAULT_CONTENT.map(interlaceContent(ASTHMA_CONTENT));
 * @param {Array} toReplace: an array of content objects
 *
 */
export const interlaceContent = (toReplace) => (item, idx) => ({
  ...item,
  ...toReplace[idx],
});

export const publicImg = (filepath) =>
  `${process.env.PUBLIC_URL}/images/${filepath}`;
export const i18nImg = (filepath) =>
  publicImg(`i18n/${IMG_LOCALE}/${filepath}`);
export const i18nImgSrc = (path, locale = 'en-US') => {
  if (!path) return null;
  return path.replace ? path.replace(IMG_LOCALE_RX, locale) : null;
};

export const privacyPolicyURI = function privacyPolicyURI(locale = 'en-US') {
  // in case we get other locales

  switch (locale) {
    case 'es-US':
    case 'en-US':
      return `https://s3-us-west-2.amazonaws.com/ph-legal/privacyPolicy/${locale}/coppa_privacy_policy.html`;

    default:
      return 'https://s3-us-west-2.amazonaws.com/ph-legal/privacyPolicy/en-US/coppa_privacy_policy.html';
  }
};
