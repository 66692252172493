import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Route } from 'react-router-dom';
import Button from '../Button/Button';

class ModalRoute extends Component {
  constructor(props) {
    super(props);
    this.state = { modalVisible: true };
    this.hide = this.hide.bind(this);
    this.back = this.back.bind(this);
  }

  hide() {
    this.setState({ modalVisible: false });
  }

  back(history) {
    const { returnIdx, returnTo } = this.props;
    const _back = returnIdx - history.length;

    if (returnIdx !== 0 && _back < 0) {
      history.go(_back);
    } else {
      history.replace(returnTo);
    }
  }

  render() {
    const {
      component: Component,
      modalSize,
      className,
      animation = true,
      autoFocus,
      ...rest
    } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => {
          const { history } = props;

          return (
            <Modal
              show={this.state.modalVisible}
              onHide={this.hide}
              onExited={() => this.back(history)}
              size={modalSize}
              dialogClassName={className}
              animation={animation}
              autoFocus={autoFocus}
            >
              <Button
                className='close-video'
                onClick={this.hide}
                aria-label='CLOSE'
                tabIndex={0}
              >
                <span>×</span>
              </Button>
              <Component {...props} />
            </Modal>
          );
        }}
      />
    );
  }
}

export default ModalRoute;
