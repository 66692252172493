import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import container from './container';
import {
  isExpressScripts,
  isNonCoveredEntity,
  isBCBS,
} from '../../lib/utilities';
import { openTime, closeTime } from '../../lib/utilities/locales.js';
import pkg from '../../../package.json';

import './styles.scss';
import logo from '../../assets/images/logo_blue.svg';

const VERSION = ['test', 'ci'].includes(process.env.NODE_ENV)
  ? 'some.version'
  : pkg.version;

const NonCoveredEntityLinks = ({ t }) => (
  <>
    <li>
      <a href='/privacy-policy' target='_blank'>
        {t('PRIVACY_NOTICE')}
      </a>
    </li>
  </>
);

const CoveredEntityLinks = ({ t }) => (
  <>
    <li>
      <a
        href='https://www.propellerhealth.com/privacy-policy/'
        target='_blank'
        rel='noopener noreferrer'
      >
        {t('PRIVACY_POLICY')}
      </a>
    </li>
    <li className='hidden-xs'>•</li>
    <li>
      <a
        className='break-small'
        href='https://s3-us-west-2.amazonaws.com/ph-legal/terms_and_conditions.html'
        target='_blank'
        rel='noopener noreferrer'
      >
        {t('TERMS_OF_SERVICE')}
      </a>
    </li>
  </>
);

export const Footer = ({ groupConfig = {}, subdomain, t }) => {
  return (
    <footer className='content-info'>
      <Container>
        <Row>
          <Col sm={12}>
            <img
              className='logo'
              src={logo}
              alt={t('PROPELLER_HEALTH_LOGO')}
              style={{ backgroundImage: 'none' }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} className='foot-section'>
            {t('QUESTIONS_WE_ARE_HERE_TO_HELP')}
            <br />
            <a href='tel:8772515451' aria-label='8 7 7. 2 5 1. 5 4 5 1'>
              +1 (877) 251-5451
            </a>
            <br />
            {t('MONDAY_FRIDAY')} {openTime(groupConfig.language)} -{' '}
            {closeTime(groupConfig.language)} {t(`faq:TIMEZONE_EST`)}
            <br />
            <a href='mailto:help@propellerhealth.com'>
              help@propellerhealth.com
            </a>
            <br />
            <a
              href='https://support.propellerhealth.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              {t('SUPPORT_CENTER')}
            </a>
          </Col>
        </Row>
        {isNonCoveredEntity() ? (
          <Row className='privacy-row'>
            <Col xs={1}></Col>
            <Col xs={10}>{t('PRIVACY_STATEMENT')}</Col>
            <Col xs={1}></Col>
          </Row>
        ) : null}
        <Row>
          <Col>
            <ul className='copyright'>
              <li>© {new Date().getFullYear()} Propeller Health.</li>
              <li className='hidden-xs'>•</li>
              {isNonCoveredEntity(subdomain) ? (
                <NonCoveredEntityLinks t={t} />
              ) : (
                <CoveredEntityLinks t={t} />
              )}
              <li className='hidden-xs'>•</li>
              <li>v{VERSION}</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            {isExpressScripts(subdomain) && (
              <div className='express-scripts-footer inherit-position'>
                {t('ES_COPYRIGHT')}
              </div>
            )}
            {isBCBS(subdomain) && (
              <div className='bcbsmn-footer'>
                Blue Cross® and Blue Shield® of Minnesota and Blue Plus® are
                nonprofit independent licensees of the Blue Cross and Blue
                Shield Association.
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default compose(container, withTranslation(['common', 'faq']))(Footer);
