export const testimonials = {
  header: 'WHAT_PEOPLE_ARE_SAYING',
  asthma: [
    {
      quote: 'TESTIMONIAL_KATHERINE',
      name: 'Katherine',
      title: 'PROPELLER_MEMBER',
    },
    {
      quote: 'TESTIMONIAL_DR_MERCHANT',
      name: 'Dr. Merchant',
      title: 'ALLERGIST_DIGNITY_HEALTH',
    },
    {
      quote: 'TESTIMONIAL_YVETTE',
      name: 'Yvette',
      title: 'PROPELLER_MEMBER_CAREGIVER',
    },
  ],
  copd: [
    {
      quote: 'TESTIMONIAL_HELEN',
      name: 'Helen',
      title: 'PROPELLER_MEMBER',
    },
    {
      quote: 'TESTIMONIAL_STEPHEN',
      name: 'Stephen',
      title: 'PROPELLER_MEMBER',
    },
    {
      quote: 'TESTIMONIAL_LINDA',
      name: 'Linda',
      title: 'PROPELLER_MEMBER',
    },
  ],
};
