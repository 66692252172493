import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import container from './container';

export const AccessControlRoute = ({
  component: Component,
  requiresValidation,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      !requiresValidation ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/access-code',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default container(AccessControlRoute);
