import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GreenButton from '../Button/GreenButton';
import GreyButton from '../Button/GreyButton';

const BackNextButtons = ({
  backClick,
  nextClick,
  disabled = false,
  backText = 'BACK',
  nextText = 'NEXT',
  nextType = 'button',
  rowStyle = {},
  t,
}) => {
  return (
    <Row className='back-next-buttons' style={rowStyle}>
      <Col xs={{ span: 7, order: 'last' }}>
        <GreenButton
          data-button='next'
          onClick={nextClick}
          disabled={disabled}
          type={nextType}
          size='lg'
        >
          {t(nextText)}
        </GreenButton>
      </Col>
      <Col xs={5}>
        <GreyButton
          size='lg'
          onClick={backClick}
          type='button'
          data-button='back'
        >
          {t(backText)}
        </GreyButton>
      </Col>
    </Row>
  );
};

BackNextButtons.propTypes = {
  backClick: PropTypes.func.isRequired,
  nextClick: PropTypes.func.isRequired,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  backText: PropTypes.string,
  nextText: PropTypes.string,
  nextType: PropTypes.oneOf(['button', 'submit']),
  rowStyle: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(BackNextButtons);
