// AU and KR use US support phone info; IE uses UK support phone info

const supportPhone = {
  AR: {
    phone: '+54 0800 222 0393',
    timezone: 'TIMEZONE_ART',
  },
  AU: {
    phone: '+1 (877) 251-5451',
    timezone: 'TIMEZONE_EST',
  },
  CA: {
    phone: '+1 877-251-5451',
    timezone: 'TIMEZONE_EST',
  },
  FR: {
    phone: '+33 1 77 51 33 83',
    timezone: 'TIMEZONE_CET',
  },
  DE: {
    phone: '+49 89 41207201',
    timezone: 'TIMEZONE_CET',
  },
  ES: {
    phone: '+34 902 757 367',
    timezone: 'TIMEZONE_CET',
  },
  GB: {
    phone: '+44 1557 520409',
    timezone: 'TIMEZONE_WET',
  },
  IE: {
    phone: '+44 1557 520409',
    timezone: 'TIMEZONE_WET',
  },
  IT: {
    phone: '+39 800797077',
    timezone: 'TIMEZONE_CET',
  },
  KR: {
    phone: '+1 (877) 251-5451',
    timezone: 'TIMEZONE_EST',
  },
  MX: {
    phone: '+52 55 5350 9447',
    timezone: 'TIMEZONE_MCT',
  },
  NL: {
    phone: '+31 850014667',
    timezone: 'TIMEZONE_CET',
  },
  PT: {
    phone: '+351 308802362',
    timezone: 'TIMEZONE_WET',
  },
  RU: {
    phone: '8 (800) 301-74-15',
    timezone: 'TIMEZONE_EST',
  },
  US: {
    phone: '+1 (877) 251-5451',
    timezone: 'TIMEZONE_EST',
  },
};

export const getSupportPhone = (countryCode) => {
  return supportPhone[countryCode] || supportPhone['US'];
};
