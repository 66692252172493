import React from 'react';
import propTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import ImageLoader from '../../../components/ImageLoader/ImageLoader';
import azEnterMeds from '../../../assets/images/az_reexpansion/az_enter_meds.svg';
import azSignIntoApp from '../../../assets/images/az_reexpansion/az_sign_into_app.svg';
import azStartTracking from '../../../assets/images/az_reexpansion/az_start_tracking.svg';
import './styles.scss';

const introSteps = [
  {
    stepName: 'enterMeds',
    image: azEnterMeds,
    text: 'ENTER_MEDS_FIND_SENSOR',
    alt: 'PROPELLER_SENSOR',
    width: 89,
    height: 90,
  },
  {
    stepName: 'signIntoApp',
    image: azSignIntoApp,
    text: 'SIGN_INTO_APP',
    alt: 'PROPELLER_APP',
    width: 92,
    height: 93,
  },
  {
    stepName: 'start',
    image: azStartTracking,
    text: 'ATTACH_SENSOR_START_TRACKING',
    alt: 'SENSOR_WITH_INHALER',
    width: 101,
    height: 103,
  },
];

export const IntroSteps = ({ t }) => {
  return introSteps.map((step) => (
    <li xs={12} key={step.stepName} className='get-started-img'>
      <LazyLoad height={96}>
        <div className='icon-wrapper'>
          <ImageLoader
            src={step.image}
            alt={t(step.alt)}
            width={step.width}
            height={step.height}
          />
        </div>
      </LazyLoad>
      <p className='get-started-text'>{t(step.text)}</p>
    </li>
  ));
};

IntroSteps.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation('medSelection')(IntroSteps);
