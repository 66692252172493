import { makeActionCreator } from '../utilities';
import { requiresCoppa } from '../../lib/utilities';

export const UPDATE_ENROLLMENT_DATA = 'UPDATE_ENROLLMENT_DATA';
export const INITIALIZE_ENROLLMENT = 'INITIALIZE_ENROLLMENT';
export const RESET_PATIENT_DATA = 'RESET_PATIENT_DATA';
export const RESET_ENROLLMENT_DATA = 'RESET_ENROLLMENT_DATA';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const SET_ACCEPTED_USER_AGREEMENTS = 'SET_ACCEPTED_USER_AGREEMENTS';
export const CONFIGURE_CAREGIVER = 'CONFIGURE_CAREGIVER';
export const CONFIGURE_COPPA = 'CONFIGURE_COPPA';
export const SHARE_ACCOUNT_ACCESS = 'SHARE_ACCOUNT_ACCESS';
export const CONFIGURE_MED_SCHEDULING = 'CONFIGURE_MED_SCHEDULING';
export const SET_MEDICATION_SCHEDULE = 'SET_MEDICATION_SCHEDULE';
export const SET_ENROLLMENT_MEDS = 'SET_ENROLLMENT_MEDS';
export const SET_DEVICE_TYPE = 'SET_DEVICE_TYPE';
export const SET_USER_ID = 'SET_USER_ID';
export const RESET_ACCESS_VALIDATION = 'RESET_ACCESS_VALIDATION';

const _updateEnrollmentData = makeActionCreator(UPDATE_ENROLLMENT_DATA, 'data');
const _resetEnrollmentData = makeActionCreator(
  RESET_ENROLLMENT_DATA,
  'groupConfig'
);

const _addAcceptedUserAgreements = makeActionCreator(
  SET_ACCEPTED_USER_AGREEMENTS,
  'acceptedUserAgreements'
);
const _configureMedScheduling = makeActionCreator(
  CONFIGURE_MED_SCHEDULING,
  'groupMeds',
  'enrollmentMeds'
);
const _configureCaregiver = makeActionCreator(CONFIGURE_CAREGIVER, 'role');
const _configureCOPPA = makeActionCreator(CONFIGURE_COPPA, 'coppaRequired');
const _shareAccountAccess = makeActionCreator(
  SHARE_ACCOUNT_ACCESS,
  'shareAccess'
);
const _resetAccessValidation = makeActionCreator(RESET_ACCESS_VALIDATION);

export const initEnrollmentForGroup = makeActionCreator(
  INITIALIZE_ENROLLMENT,
  'groupConfig'
);
export const setMedSchedule = makeActionCreator(
  SET_MEDICATION_SCHEDULE,
  'medId',
  'schedule'
);
export const setEnrollmentMeds = makeActionCreator(
  SET_ENROLLMENT_MEDS,
  'medications'
);

export const setDeviceType = makeActionCreator(SET_DEVICE_TYPE, 'device');
export const setUserId = makeActionCreator(SET_USER_ID, 'id');

export function configureEnrollment() {

  return function (dispatch, getState) {
    const state = getState();
    const { enrollmentData, enrollmentMeds, groupConfig } = state;

    dispatch(_configureMedScheduling(groupConfig.medications, enrollmentMeds));

    dispatch(_configureCaregiver(enrollmentData.enrollmentRole));

    dispatch(
      _configureCOPPA(
        requiresCoppa(
          enrollmentData.patient.birthDate,
          enrollmentData.verified,
          groupConfig.country
        )
      )
    );

    dispatch(_shareAccountAccess(enrollmentData.shareAccess));
  };
}

export function resetEnrollmentData() {
  return function (dispatch, getState) {
    const state = getState();
    dispatch(_resetAccessValidation());
    dispatch(_resetEnrollmentData(state.groupConfig));
  };
}

export function updateEnrollmentData(data) {
  return function (dispatch, getState) {
    dispatch(_updateEnrollmentData(data));

    const state = getState();

    if (data.patient && data.patient.birthDate) {
      dispatch(
        _configureCOPPA(
          requiresCoppa(
            data.patient.birthDate,
            state.enrollmentData.verified,
            state.groupConfig.country
          )
        )
      );
    }

    if (data.enrollmentRole) {
      dispatch(_configureCaregiver(data.enrollmentRole));
    }

    if (data.shareAccess) {
      dispatch(_shareAccountAccess(data.shareAccess));
    }

    if (data.acceptedUserAgreements) {
      dispatch(_addAcceptedUserAgreements(data.acceptedUserAgreements));
    }
  };
}
