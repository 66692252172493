import { TLD } from '../lib/utilities';
const env = TLD === 'com' ? 'com' : TLD === 'io' ? 'io' : 'biz';

export const getEligibilitySteps = (group) => {
  return fetch(`/api/eligibility/steps?group=${group}&TLD=${env}`).then((res) =>
    res.json()
  );
};

export const verifyEligibilty = (group, data) => {
  return fetch(`/api/eligibility/verify?group=${group}&TLD=${env}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());
};
