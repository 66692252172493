import { connect } from 'react-redux';
import { setError, resetEnrollmentData } from '../../actions';

const mapStateToProps = (state) => {
  return {
    enrollmentData: state.enrollmentData,
    device: state.device,
  };
};

const mapDispatchToProps = {
  setError,
  resetEnrollmentData,
};

export default connect(mapStateToProps, mapDispatchToProps);
