import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import FormLabel from 'react-bootstrap/FormLabel';
import { withTranslation } from 'react-i18next';
import input_validation from '../../../lib/input_validation';
import { validateEmail } from '../../../api/mailgun';
import './style.scss';

export class EmailInput extends Component {
  VALIDATION_STATES = {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    NULL: null,
  };

  state = {
    valid: this.VALIDATION_STATES.NULL,
    [this.props.name]: '',
    message: '',
    suggestion: '',
  };

  setValidationState = (validationObj) => {
    this.setState(() => {
      return {
        valid: this.VALIDATION_STATES[validationObj.valid],
        message: validationObj.message,
        suggestion: validationObj.suggestion,
      };
    });
  };

  getErrorMessage = (response) => {
    if (response.reason.includes('MX')) {
      return {
        text: 'EMAIL_CHECK',
        suggestion: response.parts.domain,
      };
    }
    if (response.reason.includes('mailbox')) {
      return {
        text: 'EMAIL_CHECK',
        suggestion: response.parts.local_part,
      };
    }
    return { text: 'EMAIL_SPELLING', suggestion: '' };
  };

  parseResponse = (response) => {
    if (response.did_you_mean) {
      this.setValidationState({
        valid: 'WARNING',
        message: 'EMAIL_DID_YOU_MEAN',
        suggestion: response.did_you_mean,
      });
    } else if (!response.is_valid) {
      const message = this.getErrorMessage(response);
      this.setValidationState({
        valid: 'WARNING',
        message: message.text,
        suggestion: message.suggestion,
      });
    } else {
      this.setValidationState({
        valid: 'SUCCESS',
        message: '',
        suggestion: '',
      });
    }
  };

  matchEmails = (verify) => {
    const email = this.props.matchWith;

    if (verify && email) {
      const match =
        verify.toLowerCase() === email.toLowerCase() && email !== '';

      this.setValidationState({
        valid: match ? 'SUCCESS' : 'ERROR',
        message: match ? '' : 'EMAILS_DONT_MATCH',
        suggestion: '',
      });
    }
  };

  checkEmail = async (address) => {
    if (!address) {
      return;
    }

    // if patient (as opposed to caregiver)
    if (this.props.caregiverEmail) {
      if (address.toLowerCase() === this.props.caregiverEmail.toLowerCase()) {
        this.setValidationState({
          valid: 'ERROR',
          message: 'ENTER_UNIQUE_EMAIL',
          suggestion: '',
        });
        this.props.onError(this.props.id);
        return;
      }
    }

    // first check that it's a valid, available email
    let isError = false;
    if (address.includes('@example.com')) {
      this.setValidationState({
        valid: 'SUCCESS',
        message: '',
        suggestion: '',
      });
      return;
    }else {
      try{
        if(this.props.setLoader) this.props.setLoader(true);
        await input_validation.availableEmail(address);
      } catch(err) {
        this.setValidationState({
          valid: 'ERROR',
          message: err,
          suggestion: '',
        });
        this.props.onError(this.props.id);
        isError = true;
      }
      if(this.props.setLoader) this.props.setLoader(false);
    }
    if(isError) return;
    // if it's valid, available, and not example.com, run it through mailgun's validator
    try{
      const response = await validateEmail(address);
      this.parseResponse(response);
    } catch(err) {
      console.warn(err);
    }
  };

  onBlur = (e) => {
    // so we don't fail emails when user enters a space,
    // we'll trim emails before checking & matching them
    const value = !!e.target.value ? e.target.value.trim() : e.target.value;
    const { id, name, onBlur } = this.props;

    this.setState({ [name]: value });

    id === 'verifyEmail' ? this.matchEmails(value) : this.checkEmail(value);

    if (onBlur) {
      onBlur({ [name]: value });
    }
  };

  onChange = (e) => {
    const value = e.target.value;
    const inputLength = value.length;
    const { onChange, maxLength, name, warningText } = this.props;

    this.setState({ [name]: value });

    this.setValidationState({
      valid: null,
      message: '',
      suggestion: '',
    });

    if (inputLength === maxLength) {
      this.setValidationState({
        valid: 'WARNING',
        message: warningText,
        suggestion: '',
      });
    }

    if (onChange) onChange({ [name]: value });
  };

  render() {
    const {
      id,
      error,
      helpText,
      helpTextHidden,
      label,
      maxLength,
      name,
      t,
      value,
    } = this.props;
    const formValidation = error ? this.VALIDATION_STATES.ERROR : null;
    return (
      <FormGroup
        aria-describedby={`${name}-email-input-help-div`}
        className='email-input'
        controlId={id}
        validationState={formValidation ? formValidation : this.state.valid}
      >
        <FormLabel>{label}</FormLabel>
        <FormControl
          autoComplete='email'
          data-attr={id}
          maxLength={maxLength}
          onBlur={this.onBlur}
          onChange={this.onChange}
          type='email'
          value={value}
        />
        <FormControl.Feedback />
        <div role='alert' aria-live='assertive'>
          {id === 'verifyEmail' && error ? (
            <span className='error-message'>{t('EMAILS_DONT_MATCH')}</span>
          ) : this.state.valid === 'error' ? (
            <span className='error-message'>
              {t(this.state.message, { email: this.state.suggestion })}
            </span>
          ) : (
            ''
          )}
        </div>
        <div
          id={`${name}-email-input-help-div`}
          aria-live='polite'
          className={helpTextHidden ? 'visually-hidden' : 'help'}
        >
          {t(helpText, { maxLength: maxLength })}
        </div>
        <div className='has-warning'>
          {this.state.valid === 'warning'
            ? t(this.state.message, { email: this.state.suggestion })
            : ''}
        </div>
      </FormGroup>
    );
  }
}

EmailInput.propTypes = {
  caregiverEmail: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  helpTextHidden: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  matchWith: PropTypes.string,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func,
  placeholder: PropTypes.string,
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
  setLoader: PropTypes.func
};

export default withTranslation('enrollment')(EmailInput);
