import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GetStartedLink from '../../pages/Home/GetStartedLink';
import check from '../../assets/images/icons/icon-check.svg';
import lightbulb from '../../assets/images/icons/lightbulb.png';
import bell from '../../assets/images/icons/bell.png';
import doctor from '../../assets/images/icons/doctor.png';
import { SUBDOMAIN, i18nImg, i18nImgSrc } from '../../lib/utilities';
import './styles.scss';

const CTABanner = () => {
  const { i18n, t } = useTranslation('landing');
  const imgSrc = i18nImg('landing/jellyfish_mobile.png');
  const bccmi = (SUBDOMAIN === 'bccmi');
  return (
    <div className='cta-banner'>
      <div className='banner-container'>
        <Container as='section' className='text-center'>
          <Row>
            <Col xs='12'>
              <h2>{!bccmi ? t('WHY_USE_PROPELLER') : t('WHY_USE_PROPELLER_BCCMI')}</h2>
              { !bccmi ? <>
                <p>
                  <Trans i18nKey={'WHY_TEXT_1'} t={t}>
                    Research shows that people who use Propeller have&nbsp;
                    <a
                      href='https://propellerhealth.com/outcomes/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <strong>more days without symptoms</strong> and&nbsp;
                      <strong>
                        fewer trips to the hospital or emergency room
                      </strong>
                    </a>
                    . That means fewer copays and daily disruptions due to
                    unexpected flare-ups.
                  </Trans>
                </p>
                <p>{t('WHY_TEXT_2')}</p>
              </> : <p>{t('WHY_USE_PROPELLER_CONTENT1_BCCMI')}<sup>1</sup>{t('WHY_USE_PROPELLER_CONTENT2_BCCMI')}<sup>2,3</sup></p> }
            </Col>
          </Row>
        </Container>
      </div>
      <Container className='column-container'>
        <Row>
          <Col xs={12} md={4}>
            <img src={bell} alt='' width='46' height='46' />
            <h3>{t('WHY_TITLE_REMINDERS')}</h3>
            <p>{!bccmi ? t('WHY_TEXT_REMINDERS') : t('WHY_TEXT_REMINDERS_BCCMI')}</p>
          </Col>
          <Col xs={12} md={4}>
            <img src={lightbulb} alt='' width='46' height='46' />
            <h3>{!bccmi ? t('WHY_TITLE_TRIGGERS') : t('WHY_TITLE_TRIGGERS_BCCMI')}</h3>
            <p>{!bccmi ? t('WHY_TEXT_TRIGGERS') : t('WHY_TEXT_TRIGGERS_BCCMI')}</p>
          </Col>
          <Col xs={12} md={4}>
            <img src={doctor} alt='' width='46' height='46' />
            <h3>{!bccmi ? t('WHY_TITLE_CARE') : t('WHY_TITLE_CARE_BCCMI')}</h3>
            <p>{!bccmi ? t('WHY_TEXT_CARE') : t('WHY_TEXT_CARE_BCCMI')}</p>
          </Col>
          <Col xs={12}>
            <br />
            <GetStartedLink type='green' text='SIGN_UP' />
          </Col>
        </Row>
      </Container>
      <div className='banner-container-2'>
        <Container as='section'>
          <Row>
            <Col
              xs={{ span: 10, order: 2, offset: 1 }}
              md={{ span: 6, order: 1, offset: 0 }}
              xl={{ span: 4, order: 1, offset: 2 }}
            >
              <h2>{!bccmi ? t('SIGN_UP_LIST_TITLE') : t('SIGN_UP_LIST_TITLE_BCCMI')}</h2>
              <ul>
                <li>
                  <img src={check} width='32' height='32' alt='' />
                  <span>{!bccmi ? t('SIGN_UP_LIST_ITEM_1') : t('SIGN_UP_LIST_ITEM_1_BCCMI')}</span>
                </li>
                <li>
                  <img src={check} width='32' height='32' alt='' />
                  <span>{!bccmi ? t('SIGN_UP_LIST_ITEM_2') : t('SIGN_UP_LIST_ITEM_2_BCCMI')}</span>
                </li>
                <li>
                  <img src={check} width='32' height='32' alt='' />
                  <span>{!bccmi ? t('SIGN_UP_LIST_ITEM_3') : t('SIGN_UP_LIST_ITEM_3_BCCMI')}</span>
                </li>
              </ul>
            </Col>
            <Col
              className='text-center'
              xs={{ span: 12, order: 1 }}
              md={{ span: 6, order: 2 }}
              xl={{ span: 4 }}
            >
              <img src={i18nImgSrc(imgSrc, i18n.language)} alt='' width='338' />
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 12 }} md={{ span: 6 }} xl={{ span: 4, offset: 2 }}>
              <GetStartedLink type='green' buttonId='2' />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CTABanner;
