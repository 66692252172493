import React from 'react';
import { i18nImgSrc } from '../../lib/utilities';
// import "./styles.css";
// data-responsive-background-image] style moved into
// main style sheet due to behaviors around code splitting / async loading

class ResponsiveBackgroundImg extends React.Component {
  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
    this.state = {
      backgroundImage: i18nImgSrc(props.src, props.locale),
    };
  }

  img = undefined;

  update() {
    let src =
      typeof this.img.currentSrc !== 'undefined'
        ? this.img.currentSrc
        : this.img.src;

    if (this.state.backgroundImage !== src) {
      this.setState({
        backgroundImage: src,
      });
    }
  }

  componentDidMount() {
    if (this.img && this.img.complete) {
      this.update();
    }
  }

  render() {
    const {
      src,
      srcSet,
      sizes,
      alt = '',
      className = '',
      style = {},
      children,
      componentClass: Component = 'div',
      locale = 'en-US',
      ...rest
    } = this.props;
    const { backgroundImage } = this.state;

    const ownStyle = { backgroundImage: `url(${backgroundImage})` };

    return (
      <Component
        data-responsive-background-image
        style={{ ...ownStyle, ...style }}
        className={`responsive-background-image ${className}`}
        {...rest}
      >
        <img
          src={i18nImgSrc(src, locale)}
          srcSet={i18nImgSrc(srcSet, locale)}
          sizes={sizes}
          alt={alt}
          ref={(img) => (this.img = img)}
          onLoad={() => this.update()}
        />
        {children}
      </Component>
    );
  }
}

export default ResponsiveBackgroundImg;
