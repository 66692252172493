import React from 'react';
import Container from 'react-bootstrap/Container';
import PageHeading from '../../components/PageHeading/PageHeading';
import PrivacyPolicyEn from './PrivacyPolicyEn';
import PrivacyPolicyEs from './PrivacyPolicyEs';
import { withTranslation } from 'react-i18next';
import './privacy-policy.scss';

const PrivacyPolicy = (props) => {
  const language = props.i18n.language;
  return (
    <Container>
      <PageHeading pageTitle={props.t('PRIVACY_POLICY')}>
        {language === 'en-US' ? <PrivacyPolicyEn /> : <PrivacyPolicyEs />}
      </PageHeading>
    </Container>
  );
};

export default withTranslation('common')(PrivacyPolicy);
