import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import container from './container';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HowItWorksStep from './HowItWorksStep';
import Title from '../../components/Title/Title';
import {getSteps, getSteps_BCCMI} from '../../data/landingPage/how-it-works-steps';
import './styles.scss';
import GetStartedLink from '../../pages/Home/GetStartedLink';
import { SUBDOMAIN } from '../../lib/utilities';

export class HowPropellerWorks extends React.Component {
  render() {
    const { disease = 'asthma', i18n, t } = this.props;
    const bccmi = (SUBDOMAIN === 'bccmi');
    const steps = bccmi ? getSteps_BCCMI() : getSteps(disease);
    return (
      <section className='how-it-works'>
        <Container>
          <Row className='section-head'>
            <Col xs={12}>
              <Title
                text={t('HOW_PROPELLER_WORKS')}
                level={2}
                className='big-bold-title'
              />
            </Col>
          </Row>
          { bccmi && <Row>
            <Col xs={12}>
              {t('HOW_PROPELLER_WORKS_DESC')}
            </Col>
          </Row> }
          <Row className='how-it-works'>
            {steps.map((step) => {
              return (
                <Col key={step.index} xs={12} lg={12/steps.length}>
                  <HowItWorksStep
                    alt={t(step.alt)}
                    color={step.color}
                    image={step.image}
                    index={step.index}
                    key={step.index}
                    language={i18n.language}
                    t={t}
                    text={step.text}
                    bccmi={bccmi}
                  />
                </Col>
              );
            })}
          </Row>
        </Container>
        <div className='banner'>
          <h2>{bccmi ? t('LETS_FIND_THE_SENSOR_FOR_YOU_BCCMI') : t('LETS_FIND_THE_SENSOR_FOR_YOU')}</h2>
          <GetStartedLink type='outline' buttonId='4' />
        </div>
      </section>
    );
  }
}

HowPropellerWorks.propTypes = {
  alt: PropTypes.string,
  disease: PropTypes.string,
  i18n: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default compose(
  container,
  withTranslation('landing')
)(HowPropellerWorks);
