import { connect } from 'react-redux';
import { error as sendToastError } from 'react-notification-system-redux';

const mapStateToProps = (state) => {
  return {
    walgreensProducts: state.walgreensProducts,
    supportPhone: state.groupConfig.supportPhone || '',
  };
};

const mapDispatchToProps = { sendToastError };

export default connect(mapStateToProps, mapDispatchToProps);
