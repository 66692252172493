import moment from '../include-moment';
import {
  exists,
  fetchJSON,
  displayDateFormat,
  API_DATE_FORMAT,
  TLD,
  queryStringFromObject,
  textOrUndefined,
  upperCaseWords,
} from '../utilities';

const eligibilityURI = (tld = TLD) => {
  switch (tld) {
    case 'localhost':
      return 'https://es-eligibility.propellerhealth.biz';

    default:
      return `https://es-eligibility.propellerhealth.${tld}`;
  }
};

const medForId = (medList) => (medId) =>
  medList.find((med) => med.id === medId);

const medIdsToMedObjects = (medIds = [], medsList) =>
  medIds.map(medForId(medsList)).filter(exists);

// used in this file and on the server
// can move jusst to server once we fully switch to the newer verification procedure
export const EXPRESS_SCRIPTS_MED_MAP = {
  'ADVAIR DISKUS': 'advair_diskus',
  'ADVAIR HFA': 'advair',
  'ANORO ELLIPTA': 'anoro_ellipta',
  'ARNUITY ELLIPTA': 'arnuity_ellipta',
  'ASMANEX HFA': 'asmanex',
  'BREO ELLIPTA': 'breo_ellipta',
  'COMBIVENT RESPIMAT': 'combivent',
  DULERA: 'dulera',
  'FLOVENT DISKUS': 'flovent_diskus',
  'FLOVENT HFA': 'flovent',
  'INCRUSE ELLIPTA': 'incruse_ellipta',
  'PROAIR HFA': 'proair',
  'PROVENTIL HFA': 'proventil',
  'SEREVENT DISKUS': 'serevent',
  'SPIRIVA RESPIMAT': 'spiriva_respimat',
  'STIOLTO RESPIMAT': 'stiolto_respimat',
  'STRIVERDI RESPIMAT': 'striverdi',
  'VENTOLIN HFA': 'ventolin',
};

const esToPhMedIds = (meds = []) =>
  meds.map((m) => EXPRESS_SCRIPTS_MED_MAP[m]).filter(exists);

export const EXPRESS_SCRIPTS_SUPPORT_PHONE = '+18443995542';
const EXPRESS_SCRIPTS_DATE_FORMAT = 'YYYYMMDD';

const VERIFICATION_URL = `${eligibilityURI()}/eligibility/v1`;

// used in this file and on the server
// can move jusst to server once we fully switch to the newer verification procedure
export const adjustESText = (text) =>
  textOrUndefined(upperCaseWords(text.trim()));

const validateCode = (useV1) =>
  function _validateCode(code) {
    code = code.toUpperCase();
    if (useV1) {
      return fetchJSON(`${VERIFICATION_URL}/accessCode?accessCode=${code}`);
    } else {
      // return a promise which resolves
      // the positive condition of the old API call
      return new Promise(function (resolve) {
        return resolve({ eligible: true });
      });
    }
  };

const validatePatient = (useV1) =>
  function _validatePatient(data) {
    const { accessCode, birthDate, postalCode } = data;

    if (useV1) {
      const params = {
        accessCode: accessCode.toUpperCase(),
        dob: moment(birthDate, displayDateFormat()).format(
          EXPRESS_SCRIPTS_DATE_FORMAT
        ),
        zip: postalCode,
      };
      return fetchJSON(
        `${VERIFICATION_URL}/patient?${queryStringFromObject(params)}`
      );
    } else {
      return fetchJSON('/api/express-scripts/patient', {
        method: 'POST',
        body: {
          invitationCode: accessCode.toUpperCase(),
          dob: moment(birthDate, displayDateFormat()).format(API_DATE_FORMAT),
          zipCode: postalCode,
        },
      });
    }
  };

const adaptExpressScriptsData = (useV1) =>
  function _adaptExpressScriptsData(data) {
    if (useV1) {
      // {
      //   batch: "6954",
      //   carrier: "4071",
      //   contract: "PDP04106",
      //   drug1: "ADVAIR DISKUS",
      //   drug2: "PROAIR HFA",
      //   eligible: true,
      //   group: "IN041062",
      //   phone: " ",
      // }

      return {
        email: textOrUndefined(data.email.trim()),
        patient: {
          givenName: adjustESText(data.firstName),
          familyName: adjustESText(data.lastName),
          birthDate: moment(data.dob, EXPRESS_SCRIPTS_DATE_FORMAT).format(
            API_DATE_FORMAT
          ),
          medicalIds: [
            {
              key: 'accessCode',
              value: data.accessCode.trim(),
            },
          ],
        },
        mailingAddress: {
          street: adjustESText(data.addressLine1),
          street2: adjustESText(data.addressLine2),
          city: adjustESText(data.city),
          stateCode: textOrUndefined(data.state.trim()),
          postalCode: data.zip.trim(),
        },
      };
    } else {
      const { medications, ...enrollmentData } = data;
      return enrollmentData;
    }
  };

const adaptExpressScriptsMeds = (useV1) =>
  function _adaptExpressScriptsMeds(data, groupMeds) {
    const medIds = useV1
      ? esToPhMedIds([data.drug1, data.drug2])
      : data.medications;

    const medications = medIdsToMedObjects(medIds, groupMeds);

    return {
      formFactors: medications.map((m) => m.formFactor),
      medications: medications.map((m) => m.id),
    };
  };

export default function expressScriptsConfig({ useV1 } = {}) {
  const codeValidation = validateCode(useV1);
  const patientValidation = validatePatient(useV1);

  return {
    supportPhone: EXPRESS_SCRIPTS_SUPPORT_PHONE,
    displayName: 'Express Scripts - Enterprise',
    successMessage: 'THANKS_VERIFIED_ES',
    firstStep: 'validateCode',
    steps: [
      {
        key: 'validateCode',
        fields: [{ key: 'accessCode' }],
        validation: codeValidation,
        subheadingText: 'PLEASE_ENTER_YOUR_ES_ACCESS_CODE',
        errors: {
          missing: null,
          tooShort: 'ES_CODE_TOO_SHORT',
          invalid: 'ES_CODE_INVALID',
          serverError: 'ES_CODE_SERVER_ERROR',
        },
        nextStep: 'validatePatient',
      },
      {
        key: 'validatePatient',
        fields: [{ key: 'postalCode' }, { key: 'birthDate' }],
        validation: patientValidation,
      },
    ],
    validateCode: codeValidation,
    validatePatient: patientValidation,
    adaptPatient: adaptExpressScriptsData(useV1),
    adaptMedications: adaptExpressScriptsMeds(useV1),
  };
}
