import diskus from '../assets/images/formats/diskus.svg';
import ellipta from '../assets/images/formats/ellipta.svg';
import mdi from '../assets/images/formats/proairHFA.svg';
import nasalSpray from '../assets/images/formats/nasalSpray.svg';
import nebulizer from '../assets/images/formats/nebulizer.svg';
import pills from '../assets/images/formats/pills.svg';
import respimat from '../assets/images/formats/respimat.svg';

const forms = [
  {
    id: 'diskus',
    name: 'FORM_FACTOR_DISKUS',
    image: diskus,
    type: ['controller'],
  },
  {
    id: 'ellipta',
    name: 'FORM_FACTOR_ELLIPTA',
    image: ellipta,
    type: ['controller'],
  },
  {
    id: 'mdi',
    name: 'FORM_FACTOR_HFA_MDI',
    image: mdi,
    type: ['rescue', 'controller'],
  },
  {
    id: 'respimat',
    name: 'FORM_FACTOR_RESPIMAT',
    image: respimat,
    type: ['controller'],
  },
  {
    id: 'nasalSpray',
    name: 'FORM_FACTOR_NASAL_SPRAY',
    image: nasalSpray,
    type: ['rescue', 'controller'],
  },
  {
    id: 'nebulizer',
    name: 'FORM_FACTOR_NEBULIZER',
    image: nebulizer,
    type: ['rescue', 'controller'],
  },
  {
    id: 'pill',
    name: 'FORM_FACTOR_PILLS',
    image: pills,
    type: ['controller'],
  },
  { id: 'misc', name: 'FORM_FACTOR_NONE' }
];

export default forms;
