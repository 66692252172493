import React, { Component } from 'react';
import { compose } from 'redux';
import { withTranslation, Trans } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import container from './container';
import AccessCodeEntry from './AccessCodeEntry';
import ConfirmDetails from './ConfirmDetails';
import AccessCodeExample from './AccessCodeExample';
import PageHeading from '../PageHeading/PageHeading';
import Eligibility from './Eligibility/Eligibility';
import { displayPhone, isExpressScripts } from '../../lib/utilities';
import analytics, { logEvent } from '../../lib/analytics';
import configureVerification from '../../lib/eligibility';

import { isIBX } from '../../lib/utilities/environment';
import './styles.scss';

export class AccessValidation extends Component {
  validation = configureVerification(this.props.subdomain, {
    useV1: this.props.v1ESEligibility,
  });

  isES = isExpressScripts(this.props.subdomain);

  state = {
    accessCode: (this.props.queryParams && this.props.queryParams.c) || '',
    currentStep: this.validation.firstStep,
    eligible: false,
  };

  constructor(props) {
    super(props);
    this.validatePatient = this.validatePatient.bind(this);
    this.codeValidated = this.codeValidated.bind(this);
    this.detailsVerified = this.detailsVerified.bind(this);
  }

  validatePatient({ birthDate, postalCode }) {
    const { accessCode } = this.state;

    return this.validation.validatePatient({
      accessCode,
      birthDate,
      postalCode,
    });
  }

  codeValidated(data) {
    const label = 'validateAccessCode';

    logEvent('User', {
      action: label,
      label,
    });

    analytics.fbq('trackCustom', 'AccessCodeValidated');

    const step = this.validation.steps.find(
      (s) => (s.key = this.state.currentStep)
    );

    if (step.nextStep) {
      this.setState({
        ...data,
        currentStep: step.nextStep,
      });
    } else {
      this.onSuccess(data);
    }
  }

  detailsVerified(data, disease) {
    const label = 'verifyAccessDetails';

    logEvent('User', {
      action: label,
      label: label,
    });

    analytics.fbq('trackCustom', 'AccessDetailsVerified');

    this.onSuccess(data, disease);
  }

  onSuccess(data, disease) {
    const { resolveVerification, notify, medsList, t } = this.props;
    const { adaptPatient, adaptMedications } = this.validation;

    notify(
      {
        title: t('VERIFIED'),
        message: t(this.validation.successMessage),
        autoDismiss: 10,
        position: 'bc',
      },
      'success'
    );
    const patient = adaptPatient ? adaptPatient(data) : {};
    const medications = adaptMedications
      ? adaptMedications(data, medsList)
      : {};

    resolveVerification(patient, medications, disease);
  }

  render() {
    const { subdomain, className = '', t } = this.props;
    const { accessCode, eligible } = this.state;
    const { displayName, supportPhone, steps } = this.validation;
    const phoneNumber = displayPhone(supportPhone);
    const codeStep = steps[0];
    const ibx = isIBX(subdomain) || isExpressScripts(subdomain);
    return (
      <Container
        className={`validation-screen ${className}`}
        style={{ position: 'relative' }}
      >
        <Row>
          <Col xs={12} md={{ span: 10, offset:1 }}>
            <PageHeading pageTitle={t('PAGE_TITLE_ACCESS_CODE')} t={t}>
              {ibx ? (
                <Eligibility t={t} accessCode={accessCode} />
              ) : (
                <Row className='primary-content padding-4'>
                  <Col xs={12} sm={6}>
                    <AccessCodeEntry
                      phoneNumber={phoneNumber}
                      errorMessages={codeStep.errors}
                      subheading={codeStep.subheadingText}
                      accessCode={accessCode}
                      eligible={eligible}
                      validate={this.validation.validateCode}
                      onSuccess={this.codeValidated}
                      t={t}
                    />
                    <ConfirmDetails
                      eligible={eligible}
                      validate={this.validatePatient}
                      onSuccess={this.detailsVerified}
                      t={t}
                    />
                    <div className='content-row' style={{ fontSize: '1.2rem' }}>
                      <Trans
                        i18nKey='verification:QUESTIONS_CALL_US_AT_NUMBER'
                        values={{ displayName, phoneNumber }}
                      >
                        Having trouble with your access code?
                        <br />
                        Call {{ displayName }} at{' '}
                        <a href={`tel:${supportPhone}`}>{{ phoneNumber }}</a>.
                      </Trans>
                    </div>
                  </Col>
                  <AccessCodeExample subdomain={subdomain} t={t} />
                </Row>
              )}
            </PageHeading>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default compose(
  container,
  withTranslation(['verification', 'enrollment', 'common'])
)(AccessValidation);
