import React from 'react';
import loaderImg from '../../assets/images/loader.svg'
const defaultStyle = { height: '400px' };

const Loading = ({ style = defaultStyle }) => {
  return (
    <div style={style}>
      <img
        alt='loading indicator'
        style={{
          width: '80%',
          maxWidth: '150px',
          margin: 'auto',
          display: 'block',
          position: 'relative',
          top: '50%',
          marginTop: '-75px',
        }}
        src={loaderImg}
      />
    </div>
  );
};

export default Loading;
