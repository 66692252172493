import { connect } from 'react-redux';
import { loadSessionOrSignIn as loadSession } from '../../actions';

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = { loadSession };

export default connect(mapStateToProps, mapDispatchToProps);
