import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import container from './container';
import './styles.scss';

export class Testimonials extends React.Component {
  state = {
    slideIndex: 0,
  };

  render() {
    const { t } = this.props;

    return (
      <section className='testimonials' aria-labelledby='testimonial-carousel'>
        <h2 id='testimonial-carousel' className='text-center'>
          {t('TESTIMONIALS_TITLE')}
        </h2>
        <Container>
          <Row>
            <Col xs={12} md={4}>
              {t('TESTIMONIAL_ELLEN')}
              <p className='citation'>
                - Ellen C. <br /> {t('PROPELLER_MEMBER')}
              </p>
            </Col>
            <Col xs={12} md={4}>
              {t('TESTIMONIAL_DOROTHY')}
              <p className='citation'>
                - Dorothy W. <br /> {t('PROPELLER_MEMBER')}
              </p>
            </Col>
            <Col xs={12} md={4}>
              {t('TESTIMONIAL_HELEN')}
              <p className='citation'>
                - Helen S. <br /> {t('PROPELLER_MEMBER')}
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

Testimonials.propTypes = {
  disease: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withTranslation('landing'), container)(Testimonials);
