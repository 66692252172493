import React from 'react';
import Col from 'react-bootstrap/Col';
import { publicImg, isExpressScripts } from '../../../lib/utilities';

const exampleCard = publicImg('es/img_esi_card.png');
const exampleCard2x = publicImg('es/img_esi_card_2x.png');
const exampleCard3x = publicImg('es/img_esi_card_3x.png');

const ExpressScriptsExample = ({ t }) => (
  <img
    src={exampleCard}
    srcSet={`${exampleCard} 1x, ${exampleCard2x} 2x, ${exampleCard3x} 3x`}
    alt={t('EXAMPLE_CARD_ALT')}
    style={{ marginTop: '2rem' }}
  />
);

const DefaultExample = () => <div>No example for current subdomain</div>;

const exampleForSubdomain = (subdomain) => {
  if (isExpressScripts(subdomain)) return ExpressScriptsExample;

  return DefaultExample;
};

const AccessCodeExample = ({ subdomain, t }) => {
  const Example = exampleForSubdomain(subdomain);

  return (
    <Col xs={12} sm={6}>
      <Example t={t} />
    </Col>
  );
};

export default AccessCodeExample;
