import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import container from '../container';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import EligibilityForm from './EligibilityForm';
import Title from '../../Title/Title';
import Loading from '../../Loading';
import { EXPRESS_SCRIPTS_SUPPORT_PHONE } from '../../../lib/express_scripts';
import { exists, SUBDOMAIN, isNotEmpty, displayPhone } from '../../../lib/utilities';

import {
  getEligibilitySteps,
  verifyEligibilty,
} from '../../../api/eligibility';
import configureVerification from '../../../lib/eligibility';
import STATE_REFERENCE from '../../../data/state_reference';

import './eligibility.scss';
import { logPage } from '../../../lib/analytics';

const phoneNumber = displayPhone(EXPRESS_SCRIPTS_SUPPORT_PHONE);
const loadingStyle = {
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 100,
  background: 'rgba(255,255,255,0.7)',
};

export class Eligibility extends React.Component {
  state = {
    error: false,
    errorText: '',
    formData: {},
    steps: {},
    submitting: false,
    usedCode:false,
    statusCode: '',
  };

  codeInputErrorTexts = {};

  componentDidMount() {
    const group = SUBDOMAIN;
    const configuration = configureVerification(group, {
      useV1: this.props.v1ESEligibility,
    });
    this.codeInputErrorTexts = configuration.steps[0].errors;

    logPage('AccessCode');

    getEligibilitySteps(group)
      .then((res) => {
        this.setState(() => {
          return { steps: res };
        });
      })
      .catch((err) => this.handleError(err));

    // this.setState(() => {
    //   return {
    //     steps: {
    //       uniqueId: { name: 'access code', regex: '^[0-9]{1,18}$' },
    //       userInfo: ['birthDate', 'givenName', 'postalCode'],
    //       troubleText:
    //         'Call Customer Service at the telephone number on the back of your ID card or by calling 1-866-681-7373',
    //       imageHeader: 'Some text',
    //       cardImageAltText: 'Alt text',
    //       cardImage:
    //         'https://eligibility-assets.propellerhealth.biz/cards/amerihealth.jpg'
    //     }
    //   };
    // });
  }

  setData = (data) => {
    this.setState((prevState) => {
      return { formData: { ...prevState.formData, ...data } };
    });
  };

  handleError = (err) => {
      if(err.name === 'StatusCodeError'){
        this.setState(() => {
          return { submitting: false, error: true, errorText: err.errorText, usedCode: err.usedCode, statusCode: '503' };
        });
        return;
      }
      this.setState(() => {
        return { submitting: false, error: true, errorText: err.errorText, usedCode: err.usedCode };
      });
  };

  clearError = () => {
    this.setState(() => {
      return { error: false, errorText: '' };
    });
  };

  setEnrollmentData = (allData) => {
    return {
      patient: {
        givenName: allData.givenName || '',
        familyName: allData.familyName || '',
        birthDate: allData.birthDate,
        medicalIds: allData.medicalIds,
      },
      email: allData.email,
      mailingAddress: allData.mailingAddress,
      phone: allData.phone,
    };
  };

  setMedData = (meds) => {
    const { medsList } = this.props;
    const medForId = (list) => (medId) => list.find((med) => med.id === medId);
    const medIdsToMedObjects = (medIds = [], medsList) =>
      medIds.map(medForId(medsList)).filter(exists);

    const medications = medIdsToMedObjects(meds, medsList);

    return {
      formFactors: medications.map((m) => m.formFactor),
      medications: medications.map((m) => m.id),
    };
  };

  checkEligibilty = (data) => {
    this.clearError();
    verifyEligibilty(SUBDOMAIN, data)
      .then((res) => {
        if (res.eligible) {
          this.setState({ submitting: false });
          const allData = { ...res, ...data };
          if (allData.mailingAddress && allData.mailingAddress.stateCode) {
            allData.mailingAddress.stateOrRegion =
              STATE_REFERENCE[allData.mailingAddress.stateCode];
          }
          const enrollmentData = this.setEnrollmentData(allData);
          const medData = allData.medications
            ? this.setMedData(allData.medications)
            : null;

          this.props.resolveVerification(enrollmentData, medData);
        } else {
          this.handleError(res);
        }
      })
      .catch((err) => {
        this.handleError(err);
      });
  };

  handleSuccess = (data) => {
    // to keep patient from double-submitting
    if (this.state.submitting) return;
    this.checkEligibilty({ ...this.state.formData, ...data });
  };

  onSubmit = () => {
    this.setState(() => {
      return { submitting: true };
    });
  };

  getTroubleText = (t) => {
    if(SUBDOMAIN === 'es') {
      return t('verification:ES_PROPELLER_SUPPORT', { phoneNumber });
    }else {
      return t('verification:PROPELLER_SUPPORT', { phoneNumber: '1-877-251-5451' });
    }
  };

  getDisplayName = (displayName) => {
    const host = window.location.host;
    if(host.includes('ibx.')) {
      return 'Independence';
    } else if(host.includes('es.')){
      return 'Express Scripts - Enterprise';
    } else if (host.includes('bltpa.')){
      return 'Blue Link TPA';
    }
    else {
      return displayName;
    }
  }

  render() {
    const { accessCode, displayName, t } = this.props;
    const { steps, submitting } = this.state;

    return (
      <div className='eligibility'>
        {submitting && <Loading style={loadingStyle} />}
        {isNotEmpty(steps) ? (
          <>
            <Row className='primary-content padding-4'>
              <Col xs={12} sm={6}>
                <EligibilityForm
                  accessCode={accessCode}
                  accessCodeErrors={this.codeInputErrorTexts}
                  error={this.state.error}
                  errorText={this.state.errorText}
                  onSubmit={this.onSubmit}
                  onSuccess={this.handleSuccess}
                  steps={steps}
                  t={t}
                  usedCode={this.state.usedCode}
                  statusCode={this.state.statusCode}
                />

                {steps.troubleText ? (
                  <div className='help-text'>
                    <span className='font-bold'>
                      {t('verification:HAVING_TROUBLE', {uniqueId: this.state.steps.uniqueId.name})}
                    </span>
                    <br />
                    {this.getTroubleText(t)}
                  </div>
                ) : null}
              </Col>
              <Col xs={12} sm={6}>
                {steps.cardImage ? (
                  <>
                    <Title text={this.state.steps.imageHeader} level={2} />
                    <img
                      src={steps.cardImage}
                      alt={steps.cardImageAltText}
                      width='360'
                    />
                  </>
                ) : (
                  ''
                )}
              </Col>
            </Row>
            <Row>
              {
                (SUBDOMAIN === 'ibx') ? (
                  <div className='footer lightFooter'>
                    {t('verification:PROPELLER_IBX_NOTICE')}
                  </div>
                ) : (SUBDOMAIN === 'bltpa') ? (
                  <div className='footer lightFooter'>
                    {t('verification:PROPELLER_BLTPA_NOTICE')}
                  </div>
                ) :(
                  <div className='footer'>
                    {t('verification:PROPELLER_NOTICE', {displayName: this.getDisplayName(displayName)} )}
                  </div>
                )
              }
              
            </Row>
          </>
        ) : (
          <div className='loading'>
            {t('common:LOADING')}
            <Loading />
          </div>
        )}
      </div>
    );
  }
}

Eligibility.propTypes = {
  accessCode: PropTypes.string,
  displayName: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default compose(
  container,
  withTranslation(['verification', 'enrollment', 'common'])
)(Eligibility);
