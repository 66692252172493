import { connect } from 'react-redux';
import { enrollmentDisease } from '../../lib/utilities/enrollment';

const mapStateToProps = (state) => {
  const { groupConfig, enrollmentData } = state;
  const disease = enrollmentDisease(enrollmentData);
  const group = groupConfig.name;
  const location = state.router.location;
  return {
    disease,
    group,
    location,
  };
};

export default connect(mapStateToProps);
