import { connect } from 'react-redux';
import { enrollmentDisease } from '../../../lib/utilities';
const mapStateToProps = ({
  enrollmentData,
  enrollmentMeds,
  groupConfig,
  pharmaProgram,
  router,
}) => {
  return {
    enrollmentMeds,
    disease: enrollmentDisease(enrollmentData),
    groupConfig,
    pharmaProgram,
    location: router.location,
  };
};

export default connect(mapStateToProps);
