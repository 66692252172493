import { SET_DISABLED_INPUTS } from '../actions';

export const disabledInputs = function disabledInputs(state = {}, action) {
  switch (action.type) {
    case SET_DISABLED_INPUTS:
      return {
        ...state,
        ...action.inputs,
      };

    default:
      return state;
  }
};

// default to current state
export const v1ESEligibility = function v1ESEligibility(state = true) {
  return state;
};
