import sensorData from '../sensors';
import { exists } from './presence';
import medNameHashMap from '../medNameHashMap';
import md5 from '../md5';
import { TLD } from '../utilities';

const GENERIC_MEDICATION_IDS = ['albuterol', 'albuterolsulfate', 'salbutamol'];

const DISCONTINUED_MEDICATION_IDS = ['pulmicortturbohaler'];

const BAD_IDS = [].concat(GENERIC_MEDICATION_IDS, DISCONTINUED_MEDICATION_IDS);

// we will overwrite pressair from mdi to keep it out of the mdi list
const ADDITIONAL_FORM_FACTORS = ['pressair'];
// change as per the ticket PH-8573
const NON_MISC_FORM_FACTORS = [
  'diskus',
  'ellipta',
  'respimat',
  'nasalSpray',
  'nebulizer',
  'pill',
  'mdi',
  'respiclick',
  'redihaler',
  'symbicort'
];

export const activeIngredientList = (ingredients) => {
  return ingredients.map((ingredient) => ingredient.name).join(',');
};

export const _medTypeFilterFunc = (type) => {
  switch (type) {
    case 'misc':
      return (m) =>
        !NON_MISC_FORM_FACTORS.includes(m.formFactor);

    case 'mdi':
      return (m) =>
        isFormFactor(type)(m) ||
        isFormFactor('respiclick')(m) ||
        isFormFactor('redihaler')(m) ||
        isFormFactor('symbicort')(m);

    /* case 'respiclick':
      return (m) =>
        isFormFactor(type)(m) ||
        m.id === 'proair' ||
        m.id === 'albuterolsulfate_hfa_teva' ||
        m.id.includes('redihaler'); */

    default:
      return (m) => isFormFactor(type)(m);
  }
};

export const _isValidMedication = (m) =>
  //!m.id.includes('_controller') && // remove off-label meds
  //!m.id.includes('_rescue') && // remove off-label meds
  !BAD_IDS.includes(m.id); // remove other assorted meds

export const _formFactorForMed = (med) => {
  for (const ff of ADDITIONAL_FORM_FACTORS) {
    if (med.id.includes(ff)) {
      return ff;
    }
  }

  return med.formFactor;
};

export const _isSymbicortMDI = (med) =>
  isFormFactor('mdi')(med) && med.id.includes('symbicort');

// TODO: interpolate with translate
export const medsForType = (meds, formFactor) => {
  const { id } = formFactor;
  const noMatchText =
    id === 'misc'
      ? 'I_DONT_SEE_MY_MEDICATION'
      : `I_DONT_SEE_MY_NAMED_MEDICATION`;
  return meds.filter(_medTypeFilterFunc(id)).concat({
    id: 'no-match',
    name: noMatchText,
  });
};

export const getMedImageUrl = (med, width) => {
  const env = TLD === 'com' ? 'com' : TLD === 'io' ? 'io' : 'biz';
  const root = `https://images.propellerhealth.${env}/images/medications/form-factor`;
  let formFactor = med.formFactor;
  if (formFactor === 'respiclick') formFactor = 'mdi';
  if (formFactor === 'pressair') formFactor = 'novolizer';
  return `${root}/${formFactor}/${med.id}.png?format=webp&width=${width}&loadDefault=true`;
};

export const modifiedMedList = (medications) =>
  medications.filter(_isValidMedication).map((m) => {
    m.formFactor = _formFactorForMed(m);
    return m;
  });

export const hasSymbicort = (medications) => {
  for (const med of medications) {
    if (_isSymbicortMDI(med)) {
      return true;
    }
  }
  return false;
};

export const isFormFactor = (ff) => (med) => ff === med.formFactor;
export const isMedType = (type) => (med) => type === med.type;
export const hasSensor = (med) =>
  exists(med) && exists(med.sensors) && med.sensors.length > 0;
export const isEllipta = (med) => isFormFactor('ellipta')(med);
export const isRescue = (med) => isMedType('rescue')(med);
export const isController = (med) => isMedType('controller')(med);
export const isHandihaler = (med) => isFormFactor('handihaler')(med);
export const isRespimat = (med) => isFormFactor('respimat')(med);
export const isNotEllipta = (med) => !isEllipta(med);

export const isNonNebPillSprayRescue = (med) => {
  return (
    isRescue(med) &&
    !isFormFactor('nebulizer')(med) &&
    !isFormFactor('nasalSpray')(med) &&
    !isFormFactor('pill')(med)
  );
};

export const getFullMedicationListFromEnrollmentMeds = (
  groupMeds = [],
  enrollmentMeds = []
) => {
  const currentMeds = enrollmentMeds.map(({ medicationId }) => medicationId);
  return groupMeds
    .filter((med) => currentMeds.includes(med.id))
    .map((med) => {
      return {
        ...med,
        sensor: _sensorForMed(med),
        usageList: enrollmentMeds[currentMeds.indexOf(med.id)].usageList,
      };
    });
};

const BLE1 = 'ble1';
const MANTA = 'manta';
export function _sensorFromList(sensors) {
  // primary case, a single item in the list
  if (sensors.length === 1) {
    return sensors[0];
  }

  // ble1 vs jellyfishXX. Prioritize jellyfish.
  if (sensors.includes(BLE1)) {
    return sensors.find((s) => s !== BLE1);
  }

  // if stingray and manta, prioritize manta
  if (sensors.includes(MANTA)) {
    return MANTA;
  }
}

export const _sensorForMed = ({ sensors = [] }) => {
  const sensor = _sensorFromList(sensors);
  return sensorData.find((s) => s.id === sensor);
};

// is md5 good enough?
export const hashMed = (mid) => md5(mid);
export const medFromHash = (hash) => medNameHashMap[hash];

// set the schedule/usageList of any controller meds to null when usageList attribute not present, WSE-824
export const ensureControllerMedsHaveUsageList = (
  enrollmentMeds,
  groupMeds
) => {
  const mappedMeds = enrollmentMeds.map(function (med) {
    const result = groupMeds.filter((gmed) => gmed.id === med.medicationId);
    if (
      result.length > 0 &&
      result[0].type === 'controller' &&
      !med.usageList
    ) {
      med.usageList = [];
    }
    return med;
  });

  return mappedMeds;
};

// returns array of enrollmentMeds (planMeds) which are controllers according to the groupConfig (groupMeds)
export const controllerMedsForPlan = (planMeds, groupMeds) => {
  return planMeds
    .map((med) => {
      return {
        ...med,
        data: groupMeds.find((m) => med.medicationId === m.id),
      };
    })
    .filter((m) => exists(m.data) && isController(m.data));
};
