import React, { Component } from 'react';
import { bool, func, node, number, string } from 'prop-types';
import classNames from 'classnames';

import ErrorMessage from '../ErrorMessage';

import './styles.less';

class FieldSet extends Component {
  constructor(props) {
    super(props);

    this.handleBlur = this.handleBlur.bind(this);
  }

  handleBlur(e) {
    // currentTarget is the node the handler is attched to
    // relatedTarget is the node focus is moving wo
    const { currentTarget } = e;
    const relatedTarget = e.relatedTarget || document.activeElement;
    const { onBlur } = this.props;

    if (onBlur && !currentTarget.contains(relatedTarget)) {
      onBlur(e);
    }
  }

  render() {
    const {
      errorText,
      children,
      className,
      legend,
      helpText,
      helpId,
      id,
      isDisabled,
      name,
      onChange,
      onInvalid,
      tabIndex
    } = this.props;

    return (
      <fieldset
        id={id}
        name={name || id}
        className={classNames('field', { 'has-error': errorText }, className)}
        aria-describedby={helpId}
        tabIndex={tabIndex}
        onBlur={this.handleBlur}
        onChange={(...args) => !isDisabled && onChange && onChange(...args)}
        onInvalid={onInvalid}
      >
        {Boolean(legend) && (
          <legend>
            <span className="control-label field-legend">{legend}</span>
            {helpText && (
              <span className="ph-field-hint" id={helpId}>
                {helpText}
              </span>
            )}
          </legend>
        )}
        {children}
        {errorText && <ErrorMessage>{errorText}</ErrorMessage>}
      </fieldset>
    );
  }
}

FieldSet.propTypes = {
  children: node.isRequired,
  className: string,
  errorText: string,
  helpId: string,
  helpText: string,
  id: string.isRequired,
  isDisabled: bool,
  legend: string,
  name: string,
  onBlur: func,
  onChange: func,
  onInvalid: func,
  tabIndex: number
};

FieldSet.defaultProps = {
  className: null,
  errorText: null,
  helpId: null,
  helpText: null,
  isDisabled: false,
  legend: null,
  name: null,
  onBlur: null,
  onChange: null,
  onInvalid: null,
  tabIndex: null
};

export default FieldSet;
