import { SET_GROUP_CONFIG, UPDATE_GROUP_CONTENT , SET_GROUP_CONFIG4X} from '../actions';
import { init as initMedMap } from '../lib/medNameHashMap';

import FORM_FACTORS from '../lib/form_factors.js';
import {
  modifiedMedList,
  hasSymbicort,
  removeNbspProps,
} from '../lib/utilities';

export const groupConfig = function groupConfig(state = {}, action) {
  switch (action.type) {
    case SET_GROUP_CONFIG:
      let data = action.data;
      if (data) {
        const newMeds = modifiedMedList(data.medications);
        initMedMap(newMeds);

        return {
          ...removeNbspProps(data),
          medications: newMeds,
        };
      } else {
        return state;
      }
    
    case UPDATE_GROUP_CONTENT:
      // let's not rebuild the medlist because it shouldn't have changed
      // we could specific content off, but idk
      return {
        ...removeNbspProps(action.data),
        medications: state.medications,
      };

    default:
      return state;
  }
};

export const groupConfig4x = function groupConfig4x(state = {}, action) {
  switch (action.type) {
    case SET_GROUP_CONFIG4X:
      let data = action.data;
      if (data) {
        return {
          ...removeNbspProps(data)
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};

export const formFactors = function formFactors(state = FORM_FACTORS, action) {
  switch (action.type) {
    case SET_GROUP_CONFIG:
      const medList = modifiedMedList(action.data.medications);
      const ary = hasSymbicort(medList) ? ['symbicort', 'misc'] : ['misc'];

      const medForms = medList.reduce((acc, med) => {
        if (!acc.includes(med.formFactor)) {
          return acc.concat(med.formFactor);
        }
        return acc;
      }, ary);

      return FORM_FACTORS.filter((f) => medForms.includes(f.id));

    default:
      return state;
  }
};
