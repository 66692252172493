import STATE_REFERENCE from '../data/state_reference';
import { isPresent } from './utilities';

const REGION_OPTIONS = {
  US: Object.keys(STATE_REFERENCE),
  CA: [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Nova Scotia',
    'Northwest Territories',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon',
  ],
};

const ADDRESS_INPUT_CONFIG = {
  US: {
    'mailingAddress.street': {
      label: 'STREET_ADDRESS_1_LABEL',
    },
    'mailingAddress.street2': {
      label: 'STREET_ADDRESS_2_LABEL',
    },
    'mailingAddress.city': {
      label: 'CITY_LABEL',
    },
    'mailingAddress.stateCode': {
      label: 'STATE_LABEL',
      present: true,
      options: REGION_OPTIONS.US,
      setState: (val) => STATE_REFERENCE[val],
    },
    'mailingAddress.stateOrRegion': {
      present: false,
    },
    'mailingAddress.postalCode': {
      label: 'POSTAL_CODE_LABEL',
    },
  },
  CA: {
    'mailingAddress.street': {
      placeholder: 'STREET_ADDRESS_1_CA_PLACEHOLDER',
    },
    'mailingAddress.street2': {
      label: 'STREET_ADDRESS_2_LABEL',
    },
    'mailingAddress.city': {
      label: 'CITY_CA_LABEL',
    },
    'mailingAddress.stateCode': {
      present: false,
    },
    'mailingAddress.stateOrRegion': {
      label: 'STATE_CA_LABEL',
      present: true,
      options: REGION_OPTIONS.CA,
      type: 'select',
      includeNull: true,
      placeholder: 'STATE_CA_PLACEHOLDER',
    },
    'mailingAddress.postalCode': {
      label: 'POSTAL_CODE_CA_LABEL',
    },
  },
};

const addressInputConfig = (country) =>
  ADDRESS_INPUT_CONFIG[country] || ADDRESS_INPUT_CONFIG.US;

// const stateOptions = country =>
//   REGION_OPTIONS[country] || REGION_OPTIONS.US;

const inputForCountry = (config, input) => {
  const _config = config[input.key];

  if (input.key === 'mailingAddress.stateOrRegion' && _config.present) {
    if (_config.options && input.type === 'select') {
      return { ...input, ..._config };
    } else if (!_config.options && input.type === 'text') {
      return { ...input, ..._config };
    } else {
      return {
        ...input,
        present: false,
      };
    }
  } else {
    return { ...input, ..._config };
  }
};

const inputsForCountry = (country, inputs) => {
  const config = addressInputConfig(country);
  return inputs.map((i) => inputForCountry(config, i)).filter(isPresent);
};

export default inputsForCountry;
