import React from 'react';
import { compose } from 'redux';
import { Form, Row, Col } from 'react-bootstrap';
import PropTypes, { oneOfType } from 'prop-types';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

import container from '../container';
import Title from '../../../components/Title/Title';
import PageHeading from '../../../components/PageHeading/PageHeading';
import MedicalId from './MedicalId';
import EnrollmentButtons from '../Buttons';
import { logPage } from '../../../lib/analytics';
import { isValidMedId } from '../../../lib/input_validation';
import { getNextPage } from '../enrollment-utils';

/**
 * Data incoming:
 *  groupConfig.medicalIds: [{
 *    descriptorKey: (string),
 *    isRequired: (bool),
 *    label: { en-US: 'Label Text', es-US: 'Label Text', ...etc },
 *    validator: { en-US: '^\d{4}$', es-US: '^\d{4}$', ...etc }
 *  }]
 *
 * Data outgoing:
 *   enrollmentData.patient.medicalIds: [
 *    { key (descriptorKey): value (string) }
 *   ]
 */

export class MedicalIds extends React.Component {
  constructor(props) {
    super(props);
    const medIds = props.enrollmentData.patient.medicalIds;
    this.state = {
      medicalIds: medIds || [],
      errors: [],
      errorMessage: [],
    };
  }

  componentDidMount() {
    if (this.props.enrollmentMeds.length < 1) {
      this.props.pushHistory('/');
    }
    logPage('Enroll-MedicalId');
  }

  getValue = (key) => {
    if (this.state.medicalIds.length) {
      const obj = this.state.medicalIds.filter((id) => id.key === key);
      return obj.length ? obj[0].value : null;
    }
    return null;
  };

  getNextPage = () => {
    const { device, enrollmentData, groupConfig, groupConfig4x } = this.props;
    return getNextPage({
      currentPage: 'medicalId',
      device,
      enrollmentData,
      groupConfig,
      groupConfig4x,
    });
  };

  checkForErrors = () => {
    const config = this.props.groupConfig.medicalIdsRequired;
    const locale = i18n.language;

    return config
      .map((medId) => {
        const { descriptorKey, isRequired, validator } = medId;

        // if there no values return key, if required, otherwise null
        if (!this.state.medicalIds.length) {
          if (isRequired) {
            this.setState({
              [`${descriptorKey}-errorMessage`]: 'PLEASE_ENTER_RESPONSE',
            });
            return descriptorKey;
          }
          return null;
        }

        // get value from state and validate it
        const value = this.getValue(descriptorKey);
        const validation = isValidMedId({
          locale,
          isRequired,
          validator,
          value,
        });

        // if value is valid, return null
        if (validation && validation.valid) {
          return null;
        }

        // this handles the case when users hit return
        // instead of clicking submit
        this.setState({
          [`${descriptorKey}-errorMessage`]: validation.errorMessage,
        });
        return descriptorKey;
      })
      .filter((err) => err !== null);
  };

  setErrors = (errors) => {
    this.setState(() => {
      return { errors: errors };
    });
  };

  setError = (key) => {
    this.setState((prev) => {
      return { errors: [...prev.errors, key] };
    });
  };

  clearError = (key) => {
    this.setState((prev) => {
      const errors = prev.errors.filter((item) => {
        return item !== key;
      });
      return { errors: errors };
    });
  };

  isValid = () => {
    // check that all required fields have content
    // and all fields with validators pass validation
    if (this.state.errors.length > 0) return false;
    const errors = this.checkForErrors();
    if (errors && errors.length) {
      this.setErrors(errors);
      return false;
    }
    return true;
  };

  handleBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  handleChange = (data) => {
    const key = Object.keys(data)[0];
    const value = Object.values(data)[0];
    const field = { key: key, value: value };
    this.setState((prev) => {
      const removed = prev.medicalIds.filter((item) => {
        return item.key !== key;
      });

      // set new value if it isn't empty
      if (field.value.length >= 1) {
        return { medicalIds: [...removed, field], errorMessage: '' };
      }
    });
  };

  handleBlur = (data) => {
    const key = Object.keys(data)[0];
    const value = Object.values(data)[0];
    const field = { key: key, value: value };
    // remove old value
    this.setState((prev) => {
      const removed = prev.medicalIds.filter((item) => {
        return item.key !== key;
      });

      // set new value if it isn't empty
      if (field.value.length >= 1) {
        return { medicalIds: [...removed, field], errorMessage: '' };
      } else {
        return { medicalIds: removed, errorMessage: '' };
      }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const medIds = this.state.medicalIds;

    if (this.isValid()) {
      const next = this.getNextPage();

      this.props.updateEnrollmentData({
        patient: { medicalIds: medIds },
      });

      this.props.pushHistory(next);
    }
  };

  render() {
    const { t, groupConfig } = this.props;
    const medIdsConfig = groupConfig.medicalIdsRequired;

    return (
      <PageHeading pageTitle={t('PAGE_TITLE_MEDICAL_ID')} t={t}>
        <Title
          text={t('MEDICAL_IDS_QUESTION', {
            groupName: groupConfig.displayName,
          })}
          className='sm-left-lg-center-text'
          level={1}
        />
        <Row>
          <Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} >
            <Form
              onSubmit={(e) => this.handleSubmit(e)}
            >
              {medIdsConfig
                ? medIdsConfig.map((item) => {
                    const inputValue = this.getValue(item.descriptorKey);
                    return (
                      <div className='medical-ids' key={item.descriptorKey}>
                        <MedicalId
                          clearError={this.clearError}
                          descriptorKey={item.descriptorKey}
                          error={this.state.errors.includes(item.descriptorKey)}
                          errorMessage={
                            this.state[`${item.descriptorKey}-errorMessage`]
                          }
                          label={item.label}
                          onBlur={this.handleBlur}
                          onChange={this.handleChange}
                          onError={this.setError}
                          isRequired={item.isRequired}
                          t={t}
                          validator={item.validator}
                          value={inputValue}
                        />
                      </div>
                    );
                  })
                : null}

              <EnrollmentButtons
                handleBack={this.handleBack}
                handleSubmit={this.handleSubmit}
                t={t}
              />
            </Form>
          </Col>
        </Row>
      </PageHeading>
    );
  }
}

MedicalIds.propTypes = {
  device: oneOfType([PropTypes.string, PropTypes.bool]),
  enrollmentData: PropTypes.object.isRequired,
  enrollmentMeds: PropTypes.array.isRequired,
  groupConfig: PropTypes.object.isRequired,
  pushHistory: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateEnrollmentData: PropTypes.func.isRequired,
};

export default compose(withTranslation('enrollment'), container)(MedicalIds);
