/**
groupConfig.fbTracking can be 1 of 3 values: 'all', 'view', 'none';
The SPP interface where this configuration is set declares (as of 1/2019)
that setting it to off ('none') disables GA. It appears this GA rule was
added specifically for the native apps.
https://discourse.propellerhealth.com/t/groupconfig-fbtracking-what-does-it-mean-how-is-it-used/224
However, after extended discussion for https://asthmapolis.jira.com/browse/SYNC-1557,
we decided that 'all' will mean use FB and GA, 'view' is GA only, and 'none' is no tracking  .
 */

import {
  GOOGLE_ADS_SUBDOMAINS,
  IS_DEV_OR_TEST,
  // PHARMA_PROGRAM_SUBDOMAINS,
  SUBDOMAIN,
} from '../utilities/environment';

// import initFB from './initFB';
import { initGoogleAds } from './initGoogleAds';
import { initSegment } from './initSegment';

// const FB_ID = '565059946997352';
const MYASTHMA_FB_ID = '441281309806912';
const ALL_TRACKING = 'all';
const PARTIAL_TRACKING = 'view';

let mongoId;
let appVersion;

export function allowTracking(fbTracking) {
  return [ALL_TRACKING, PARTIAL_TRACKING].includes(fbTracking);
}

export function allowGoogleAds(fbTracking) {
  return (
    GOOGLE_ADS_SUBDOMAINS.includes(SUBDOMAIN) &&
    fbTracking === 'all' &&
    !IS_DEV_OR_TEST
  );
}

export function allowFB(fbTracking) {
  return false;
  // PH-9144
  /* return (
    PHARMA_PROGRAM_SUBDOMAINS.includes(SUBDOMAIN) && ALL_TRACKING === fbTracking
  ); */
}

let testVariant;
let fbAllowed = false;

function setTestVariant(variant) {
  return (testVariant = variant);
}

export function setFBallowed(bool) {
  return (fbAllowed = bool);
}

// no longer the default export (change in code)
export function initalizeAnalytics({
  analyticsFlags,
  fbTracking,
  lpVariant,
  uid,
  version,
}) {
  const overrideFbTracking =
    analyticsFlags && analyticsFlags.includes(SUBDOMAIN);
  setTestVariant(lpVariant || '');

  // store these so we can init GA after group config has landed
  // which should only be the case during local development
  mongoId = mongoId || uid;
  appVersion = appVersion || version;

  if (allowGoogleAds(fbTracking)) {
    initGoogleAds();
  }
  if (allowTracking(fbTracking) || overrideFbTracking) {
    initSegment(mongoId, lpVariant);
  }

  if (allowFB(fbTracking)) {
    setFBallowed(true);
    //PH-9144
    // initFB(FB_ID);
    if (SUBDOMAIN === 'myasthma' && window && window.fbq) {
      window.fbq('init', MYASTHMA_FB_ID);
    }
    window.fbq('track', 'PageView');
  }
}

export function fbqLogPageView(location = window.location) {
  window.fbq('track', 'ViewContent', {
    content_name: location.pathname,
    referrer: document.referrer,
    userAgent: navigator.userAgent,
    language: navigator.language,
  });
}

// logs Segment event
export function logEvent(event, properties) {
  const props = { lpVariant: testVariant, subdomain: SUBDOMAIN, ...properties };
  if (window && window.analytics) {
    window.analytics.track(event, props, { context: { ip: '0.0.0.0' } });
  }
}

// logs Segment page load with lpVariant
// also logs FB page view if FB is allowed
export function logPage(pageName, properties = {}, fb = fbAllowed) {
  const props = {
    traits: { lpVariant: testVariant, subdomain: SUBDOMAIN },
    ...properties,
  };
  if (window && window.analytics) {
    window.analytics.page(pageName, props, { context: { ip: '0.0.0.0' } });
  }
  if (fb && window && window.fbq) {
    fbqLogPageView();
  }
}

export function identify(mongoObjectId) {
  if (window && window.analytics) {
    window.analytics.identify(
      mongoObjectId,
      {},
      { context: { ip: '0.0.0.0' } }
    );
  }
}

export default {
  fbq(...args) {
    fbAllowed && window.fbq && window.fbq(...args);
  },

  fbqMyasthma(args) {
    this.fbq('trackSingle', MYASTHMA_FB_ID, args.name, args.properties);
  },
};
