import moment from './include-moment';
import {
  COPPA_AGE,
  API_HOST,
  fetchJSON,
  apiHeaders,
  displayDateFormat,
} from './utilities';
import { getCountryCode } from './utilities/countryMap';

export const ERRORS = {
  AREACODE: 'enrollment:AREACODE_CANT_START_WITH_1',
  EMAIL_NOT_AVAILABLE: 'enrollment:EMAIL_NOT_AVAILABLE',
  EMAIL_TOO_LONG: 'enrollment:EMAIL_TOO_LONG',
  EMAILS_DONT_MATCH: 'enrollment:EMAILS_DONT_MATCH',
  ENTER_UNIQUE_EMAIL: 'enrollment:ENTER_UNIQUE_EMAIL',
  FUTURE_DATE: 'enrollment:FUTURE_DATE',
  INVALID_DATE: 'enrollment:INVALID_DATE',
  INVALID_DAY: 'enrollment:INVALID_DAY',
  INVALID_EMAIL: 'enrollment:EMAIL_INVALID',
  INVALID_MONTH: 'enrollment:INVALID_MONTH',
  INVALID_PASSWORD: 'enrollment:INVALID_PASSWORD',
  INVALID_PHONE: 'enrollment:INVALID_PHONE',
  INVALID_POSTAL_CODE: 'enrollment:INVALID_POSTAL_CODE',
  INVALID_YEAR: 'enrollment:INVALID_YEAR',
  MED_ID_FORMAT_OPTIONAL: 'enrollment:MED_ID_FORMAT_OPTIONAL',
  MED_ID_FORMAT: 'enrollment:MED_ID_FORMAT',
  NEED_CAREGIVER: 'enrollment:NEED_CAREGIVER',
  PLEASE_ENTER_EMAIL: 'enrollment:PLEASE_ENTER_EMAIL',
  PLEASE_ENTER_RESPONSE: 'enrollment:PLEASE_ENTER_RESPONSE',
  PLEASE_ENTER_VALID_PHONE: 'enrollment:PLEASE_ENTER_VALID_PHONE',
  SELECT_OPTION: 'enrollment:SELECT_OPTION',
  TOO_OLD: 'enrollment:TOO_OLD',
  TOO_YOUNG: 'enrollment:TOO_YOUNG',
};

const minAge = 2; //dictated by the FDA
const maxAge = 140; //dictated by Mike
const momentErrors = [
  ERRORS.INVALID_YEAR,
  ERRORS.INVALID_MONTH,
  ERRORS.INVALID_DAY,
  ERRORS.INVALID_DATE,
];

const isString = (string) => {
  return typeof string === 'string' && string.trim().length > 0;
};
// using platform email regex
// eslint-disable-next-line
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// Limited to 14 per E.164 https://en.wikipedia.org/wiki/E.164
// the API has a limit of 17 (14 + up to three digit country code)
const phoneRegex = /^\d{10,14}$/;

// takes a phone number (without country code), returns a boolean
export function isValidPhoneNumber(num) {
  const phoneNumber = num.replace(/\D+/g, '').trim();
  if (!phoneRegex.test(phoneNumber)) return false;
  return true;
}
// checks if US/Canada area code incorrectly starts with 1
// takes a phone number and a country code, returns a boolean
export function isValidAreaCode(num, country) {
  const phoneNumber = num.replace(/\D+/g, '').trim();
  const countryCode = Number(country);
  if (phoneNumber.charAt(0) === '1' && countryCode === 1) {
    return false;
  }
  return true;
}

export function validatePhone(phoneNumber, phoneCountry, phoneRequired) {
  if (phoneNumber.length < 1) {
    if (phoneRequired) {
      return { phoneError: true, errorMessage: 'PLEASE_ENTER_VALID_PHONE' };
    } else {
      return { phoneError: false, errorMessage: '' };
    }
  }
  const validPhone = isValidPhoneNumber(phoneNumber);
  const validAreaCode = isValidAreaCode(
    phoneNumber,
    getCountryCode(phoneCountry)
  );
  if (!validPhone || !validAreaCode) {
    return {
      phoneError: true,
      errorMessage: validAreaCode
        ? 'PLEASE_ENTER_VALID_PHONE'
        : 'AREACODE_CANT_START_WITH_1',
    };
  }
  return { phoneError: false, errorMessage: '' };
}

const validateText = (message = ERRORS.PLEASE_ENTER_RESPONSE) => {
  return (text) => {
    return new Promise((resolve, reject) => {
      isString(text) ? resolve(text) : reject(message);
    });
  };
};

export const checkEmailAvailability = (email) => {
  const url = `${API_HOST}/api/users/search/profile?email=${window.encodeURIComponent(
    email
  )}`;

  return fetchJSON(url, { headers: apiHeaders() })
    .catch((err) => Promise.reject(ERRORS.INVALID_EMAIL))
    .then((json) => {
      if (!json.available) {
        return Promise.reject(ERRORS.EMAIL_NOT_AVAILABLE);
      }
      return email;
    });
};

export const validateEmail = (value) => {
  return new Promise((resolve, reject) => {
    if ('string' !== typeof value || !value) {
      return reject(ERRORS.PLEASE_ENTER_EMAIL);
    }

    if (!emailRegex.test(value)) {
      return reject(ERRORS.INVALID_EMAIL);
    }

    // Salesforce's email length limit is 80
    if (value.length > 80) {
      return reject(ERRORS.EMAIL_TOO_LONG);
    }

    resolve(value);
  });
};

export const validateDate = (value) => {
  return new Promise((resolve, reject) => {
    if (!value || value.includes('_')) {
      return reject(ERRORS.INVALID_DATE);
    }

    const date = moment(value, displayDateFormat());

    if (!date.isValid()) {
      switch (date.invalidAt()) {
        case 0:
          return reject(momentErrors[0]);
        case 1:
          return reject(momentErrors[1]);
        case 2:
          return reject(momentErrors[2]);
        default:
          return reject(momentErrors[3]);
      }
    }

    const age = moment().diff(date, 'years', true);

    if (age < 0) {
      return reject(ERRORS.FUTURE_DATE);
    }

    if (age > maxAge) {
      return reject(ERRORS.TOO_OLD);
    }

    if (age < minAge) {
      return reject(ERRORS.TOO_YOUNG);
    }

    resolve(value);
  });
};

export const isValidPostalCode = (zip) => {
  //This is only valid for US (\d{5}) and Canada ([A-Z]\d[A-Z] ?\d[A-Z]\d) Zip Codes
  const validZipCode = new RegExp(/^(\d{5}|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/);
  const validPostalCode = validZipCode.test(zip.toUpperCase());

  if (!isString(zip) || !validPostalCode) {
    return false;
  }
  return true;
};

export const isValidEmail = (email) => {
  if ('string' !== typeof email || !email) {
    return false;
  }

  if (!emailRegex.test(email)) {
    return false;
  }

  if (email.length > 256) {
    return false;
  }

  return true;
};

export const isValidPassword = (password) => {
  if (
    !isString(password) ||
    password.length < 8 ||
    !password.match(/\d/g) ||
    !password.match(/[a-z]/g) ||
    !password.match(/[A-Z]/g)
  ) {
    return false;
  }
  return true;
};

export const isValidRegex = (regex) => {
  try {
    return new RegExp(regex);
  } catch {
    return false;
  }
};

export const isValidMedId = ({ locale, isRequired, validator, value }) => {
  const validRegex = validator && isValidRegex(validator[locale]);

  if (isRequired && !value) {
    return {
      vaild: false,
      errorMessage: 'PLEASE_ENTER_RESPONSSE',
    };
  }

  if (validRegex && value && !validRegex.test(value)) {
    return {
      valid: false,
      errorMessage: isRequired ? 'MED_ID_FORMAT' : 'MED_ID_FORMAT_OPTIONAL',
    };
  }

  return {
    valid: true,
    errorMessage: '',
  };
};

export const isValidDate = (date) => {
  if (!date || String(date).includes('_')) return false;
  if (date.length < 10) return false;
  return moment(date).isValid() ? true : false;
};

export const isValidBirthDate = (birthDate, role = '', ibx = false) => {
  if (!isValidDate(birthDate)) {
    return { valid: false, errorMessage: ERRORS.INVALID_DATE };
  }

  const age = moment().diff(moment(birthDate), 'years', false);

  if (new Date(birthDate) > new Date()) {
    return { valid: false, errorMessage: ERRORS.FUTURE_DATE };
  }

  if (age > maxAge) return { valid: false, errorMessage: ERRORS.TOO_OLD };

  if (age < minAge) return { valid: false, errorMessage: ERRORS.TOO_YOUNG };
  if (age < COPPA_AGE && role === 'patient') {
    return { valid: false, errorMessage: ERRORS.NEED_CAREGIVER };
  }

  return { valid: true, errorMessage: null };
};

export default {
  caregiverDate: validateDate,

  date: (value, role) => {
    return validateDate(value).then((date) => {
      const age = moment().diff(
        moment(date, displayDateFormat()),
        'years',
        true
      );

      if (age < minAge) {
        return Promise.reject(ERRORS.TOO_YOUNG);
      }

      if (age < COPPA_AGE && role !== 'caregiver') {
        return Promise.reject(ERRORS.NEED_CAREGIVER);
      }

      return date;
    });
  },

  text: validateText,

  email: validateEmail,

  patientEmail: (value, data = {}) => {
    return validateEmail(value).then((email) => {
      if (email === data.email) {
        return Promise.reject(ERRORS.ENTER_UNIQUE_EMAIL);
      }

      return email;
    });
  },

  password: (value, email) => {
    if (value && email && value.toLowerCase() === email.toLowerCase()) {
      return Promise.reject('PASSWORD_CANT_MATCH_EMAIL');
    }
    if (
      !isString(value) ||
      value.length < 8 ||
      !value.match(/\d/g) ||
      !value.match(/[a-z]/g) ||
      !value.match(/[A-Z]/g)
    ) {
      return Promise.reject(ERRORS.INVALID_PASSWORD);
    }
    return Promise.resolve(value);
  },

  phone: (value, country, noBlanks) => {
    return new Promise((resolve, reject) => {
      let v = value && value.replace(/\D+/g, '');
      let c = Number(country);
      if (noBlanks && v.length === 0) {
        return reject(ERRORS.PLEASE_ENTER_VALID_PHONE);
      }
      if ('string' === typeof value && v.length > 0 && !phoneRegex.test(v)) {
        return reject(ERRORS.INVALID_PHONE);
      }
      //only checking US and Canada (both country codes === 1) for initial "1"
      if (v.charAt(0) === '1' && c === 1) {
        return reject(ERRORS.AREACODE);
      }

      resolve(value);
    });
  },

  option: (message) => (text) => {
    return new Promise((resolve, reject) => {
      return isString(text) ? resolve(text) : reject(message);
    });
  },

  response: (value) => {
    return new Promise((resolve, reject) => {
      if ('undefined' === typeof value) {
        return reject(ERRORS.SELECT_OPTION);
      }

      resolve(value);
    });
  },

  medicalIds: (validator, required) => {
    const rx = validator && new RegExp(validator);
    const error = !required
      ? ERRORS.MED_ID_FORMAT_OPTIONAL
      : rx
      ? ERRORS.MED_ID_FORMAT
      : ERRORS.PLEASE_ENTER_RESPONSE;

    return (value) => {
      return new Promise((resolve, reject) => {
        value = value && value.trim();

        if (!required && !value) {
          return resolve(value);
        }

        if (!rx && value) {
          return resolve(value);
        } else if (rx && rx.test(value)) {
          return resolve(value);
        } else {
          return reject(error);
        }
      });
    };
  },

  postalCode: (text) => {
    //This is only valid for US (\d{5}) and Canada ([A-Z]\d[A-Z] ?\d[A-Z]\d) Zip Codes
    const validZipCode = /^(\d{5}|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/;
    const validPostalCode = validZipCode.test(text.toUpperCase());

    return new Promise((resolve, reject) => {
      if (!isString(text) || !validPostalCode) {
        return reject(ERRORS.INVALID_POSTAL_CODE);
      }
      return resolve(text);
    });
  },

  matchingEmail: (key) => (value, data) => {
    return new Promise((resolve, reject) => {
      const toCompare = data[key];

      return 'string' === typeof value &&
        'string' === typeof toCompare &&
        value.trim().length > 0 &&
        value.toLowerCase() === toCompare.toLowerCase()
        ? resolve(value)
        : reject(ERRORS.EMAILS_DONT_MATCH);
    });
  },

  availableEmail: (value) => validateEmail(value).then(checkEmailAvailability),
};
