import { SHOW_VIDEO, VIDEO_VIEWED } from '../actions';

export const videoToShow = function videoToShow(state = null, action) {
  switch (action.type) {
    case SHOW_VIDEO:
      return action.videoInfo;
    default:
      return state;
  }
};

export const videoViewed = function videoViewed(state = false, action) {
  switch (action.type) {
    case VIDEO_VIEWED:
      return true;
    default:
      return state;
  }
};
