import React, { useState } from 'react';
import { bool, func, string } from 'prop-types';
import { withTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

/*
  DateInputs validates day and month;
  parent component validates the complete date
*/
export function DateInput({
  disabled,
  error,
  errorText,
  label = 'BIRTHDATE_LABEL',
  name = 'date',
  onBlur,
  onChange,
  t,
  value,
}) {
  let [year = '', month = '', day = ''] = value ? value.split('-') : [];

  const [inputYear, setInputYear] = useState(year);
  const [inputMonth, setInputMonth] = useState(month);
  const [inputDay, setInputDay] = useState(day);
  const [monthError, setMonthError] = useState(false);
  const [dayError, setDayError] = useState(false);

  const setValue = (val, fieldName) => {
    switch (fieldName) {
      case 'year':
        setInputYear(val);
        break;
      case 'month':
        setInputMonth(val);
        break;
      case 'day':
        setInputDay(val);
        break;
      default:
        return;
    }
  };

  const getFullDate = (val, fieldName) => {
    if (!fieldName || !val)
      return `${inputYear}-${inputMonth.padStart(2, '0')}-${inputDay.padStart(
        2,
        '0'
      )}`;
    const month = fieldName === 'month' ? val : inputMonth;
    const day = fieldName === 'day' ? val : inputDay;
    const year = fieldName === 'year' ? val : inputYear;
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };

  const clearError = (field) => {
    if (field === 'month') {
      setMonthError(false);
    } else if (field === 'day') {
      setDayError(false);
    }
  };

  const validateField = (val, fieldName) => {
    const num = Number(val);
    if (fieldName === 'month' && (isNaN(num) || num < 1 || num > 12)) {
      setMonthError(true);
      return false;
    }
    if (fieldName === 'day' && (isNaN(num) || num < 1 || num > 31)) {
      setDayError(true);
      return false;
    }
  };

  const handleBlur = (val, fieldName) => {
    validateField(val, fieldName);
    if (onBlur) {
      onBlur({
        [name]: getFullDate(val, fieldName),
      });
    }
  };

  const handleChange = (val, fieldName) => {
    clearError(fieldName);
    setValue(val, fieldName);
    if (onChange) {
      onChange({
        [name]: getFullDate(val, fieldName),
      });
    }
  };

  return (
    <>
      <div role='group'>{t(label) || t('BIRTHDATE_LABEL')}</div>
      <Row>
        <Col xs={4}>
          <Form.Group controlId='month'>
            <Form.Label>
              {t('MONTH')} ({t('MONTH_ABBREVIATED')})
            </Form.Label>
            <Form.Control
              required
              type='tel'
              disabled={disabled}
              minLength={2}
              maxLength={2}
              onBlur={(e) => handleBlur(e.target.value, 'month')}
              onChange={(e) => handleChange(e.target.value, 'month')}
              value={inputMonth}
              max={12}
            ></Form.Control>
            {monthError ? (
              <div role='alert' aria-live='assertive' className='error-message'>
                {t('INVALID_MONTH')}
              </div>
            ) : null}
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group controlId='day'>
            <Form.Label>
              {t('DAY')} ({t('DAY_ABBREVIATED')})
            </Form.Label>
            <Form.Control
              required
              type='tel'
              disabled={disabled}
              minLength={2}
              maxLength={2}
              onBlur={(e) => handleBlur(e.target.value, 'day')}
              onChange={(e) => handleChange(e.target.value, 'day')}
              value={inputDay}
            ></Form.Control>
            {dayError ? (
              <div role='alert' aria-live='assertive' className='error-message'>
                {t('INVALID_DAY')}
              </div>
            ) : null}
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group controlId='year'>
            <Form.Label>
              {t('YEAR')} ({t('YEAR_ABBREVIATED')})
            </Form.Label>
            <Form.Control
              required
              type='tel'
              disabled={disabled}
              minLength={4}
              maxLength={4}
              onBlur={(e) => handleBlur(e.target.value, 'year')}
              onChange={(e) => handleChange(e.target.value, 'year')}
              value={inputYear}
            ></Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <FormGroup controlId={name || 'birthDate'}>
        <FormControl.Feedback />
        <div role='alert' aria-live='assertive'>
          {error ? <span className='error-message'>{t(errorText)}</span> : null}
        </div>
      </FormGroup>
    </>
  );
}

DateInput.propTypes = {
  error: bool,
  errorText: string,
  label: string,
  name: string,
  onBlur: func,
  onChange: func,
  onError: func,
  role: string,
  t: func.isRequired,
  value: string,
};

export default withTranslation(['enrollment'])(DateInput);
