import { SET_PHARMA_PROGRAM, SET_CONFIRMED_PHARMA_PROGRAM } from '../actions';
import { newStateOnChange } from './utilities';

const initialState = {
  // this is set on the "your sensors" screen and is preserved
  selectedProgram: null,
  // this is also set on the "your sensors" screen but then validated on
  // the "user agreement" screen to reflect the final program eligibility check
  confirmedProgram: null,
};

export function pharmaProgram(state = initialState, action) {
  switch (action.type) {
    case SET_PHARMA_PROGRAM:
      return newStateOnChange(
        'selectedProgram',
        action.payload,
        state,
        initialState
      );

    case SET_CONFIRMED_PHARMA_PROGRAM:
      return newStateOnChange(
        'confirmedProgram',
        action.payload,
        state,
        initialState
      );

    default:
      return state;
  }
}

// this config is overwritten in non-local environments by server/middleware/loadState.js
// be sure to add keys in both places to avoid missing values in redux
const initConfigState = {
  pharmaProgramEnableAzExpansion: true,
};

export function pharmaConfig(state = initConfigState) {
  return state;
}
