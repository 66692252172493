import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as notifications } from 'react-notification-system-redux';

import { asthmaControlTest, copdAssessmentTest } from './quizes.js';
import device from './device.js';
import {
  enrollmentData,
  enrollmentMeds,
  requiresValidation,
} from './enrollmentData.js';
import enrollmentSteps from './enrollmentSteps.js';
import { formFactors, groupConfig, groupConfig4x } from './groupConfig.js';
import hasLocalStorage from './hasLocalStorage.js';
import { videoToShow, videoViewed } from './video.js';
import mongoObjectId from './mongoObjectId.js';
import { queryParams, domain, subdomain } from './environment';
import appErrors from './appErrors.js';
import { session, showSignIn } from './session';
import walgreensProducts from './walgreensProducts';
import medSelection from './medSelection';
import { pharmaProgram, pharmaConfig } from './pharmaProgram';
import { disabledInputs, v1ESEligibility } from './expressScriptsConfig';

const dignityConfig = function dignityConfig(state = {}) {
  return state;
};
const analyticsFlags = function analyticsFlags(state = '') {
  return state;
};
const segmentWriteKey = function segmentWriteKey(state = '') {
  return state;
};

const reducers = {
  analyticsFlags,
  appErrors,
  asthmaControlTest,
  copdAssessmentTest,
  device,
  dignityConfig,
  disabledInputs,
  domain,
  enrollmentData,
  enrollmentMeds,
  enrollmentSteps,
  formFactors,
  groupConfig,
  groupConfig4x,
  hasLocalStorage,
  medSelection,
  mongoObjectId,
  pharmaConfig,
  pharmaProgram,
  queryParams,
  requiresValidation,
  segmentWriteKey,
  session,
  showSignIn,
  subdomain,
  v1ESEligibility,
  videoToShow,
  videoViewed,
  walgreensProducts,
};

const combinedReducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    ...reducers,
    notifications,
  });

export default combinedReducers;
