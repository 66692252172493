import { exists } from './presence';

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export const isObject = (item) =>
  exists(item) && typeof item === 'object' && !Array.isArray(item);
// val instanceof Object && !(val instanceof Array);

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export const mergeDeep = (target, ...sources) => {
  if (!sources.length) return target;

  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
};

export const isNotEmpty = (obj) => {
  return isObject(obj) && Object.keys(obj).length > 0;
};

export const nestAssignment = (value) => {
  return (o, k, i, a) => {
    if (i === a.length - 1) {
      o[k] = o[k] || value;
      return o;
    } else {
      o[k] = o[k] || {};
      return o[k];
    }
  };
};

/**
 * Builds object from query string
 * @param queryString
 * @returns {object}
 */
export const objectFromQueryString = (queryString) => {
  return queryString
    .substring(1)
    .split('&')
    .reduce((o, query) => {
      if (!!query) {
        let parts = query.split('=');
        o[parts[0]] = !o[parts[0]]
          ? decodeURIComponent(parts[1])
          : [].concat(o[parts[0]], decodeURIComponent(parts[1]));
      }
      return o;
    }, {});
};

/**
 * Builds query string from object
 * @param obj
 * @returns {string}
 */
export const queryStringFromObject = (obj) => {
  return Object.keys(obj)
    .map((key) => (obj[key] ? `${key}=${obj[key]}` : undefined))
    .filter(exists)
    .join('&');
};

export const removeUndefinedProps = (obj) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop) && obj[prop] === undefined) {
      delete obj[prop];
    }
  }
  return obj;
};

// mostly used to log to console, so logged objects don't mutate
export const staticObject = (obj) => JSON.parse(JSON.stringify(obj));

// converts `data["string.key.parts"]` into `data.string.key.parts`
export const getValue = (obj, key) => {
  try {
    return key.split('.').reduce((nextObj, k) => {
      if (typeof nextObj === 'undefined') {
        return nextObj;
      } else {
        return nextObj[k];
      }
    }, obj);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
};

// takes an object, returns a new object cleaned of empty strings
export const cleanEmptyStrings = (val) => {
  if (!isObject(val)) return val;
  const obj = { ...val };

  for (let key in obj) {
    if ('string' === typeof obj[key] && !obj[key].trim()) {
      delete obj[key];
    }
  }

  return obj;
};

export const removeNbspProps = function removeNbspProps(obj) {
  if (!isObject(obj)) return obj;
  const shallowCopy = { ...obj };

  for (const key in shallowCopy) {
    if (shallowCopy.hasOwnProperty(key)) {
      if (
        typeof shallowCopy[key] === 'string' &&
        (shallowCopy[key] === '&nbsp;' || !shallowCopy[key].trim())
      ) {
        shallowCopy[key] = null;
      }
    }
  }

  return shallowCopy;
};
