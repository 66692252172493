import React from 'react';
import { compose } from 'redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { withTranslation } from 'react-i18next';
import Logo from './Logo';
import container from './container';
import LanguageSelection from '../LanguageSelection';
import { isExpressScripts } from '../../lib/utilities';
import './styles.scss';

export function NavBar({ groupConfig, location, t }) {
  return (
    <div className='header' role='navigation'>
      <Container>
        <Row>
          <Col xs={9}>
            <Logo location={location} groupConfig={groupConfig} t={t} />
          </Col>
          <Col xs={3} className='locale-selector'>
            <LanguageSelection />
          </Col>
        </Row>
        {isExpressScripts() ? (
          <Row>
            <Col xs={12} className='es-tag'>
              {t('ES_TAGLINE')}
            </Col>
          </Row>
        ) : null}
      </Container>
    </div>
  );
}

export default compose(container, withTranslation('common'))(NavBar);
