import React from 'react';
import InputMask from 'react-input-mask';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import FormLabel from 'react-bootstrap/FormLabel';
import PasswordInput from './PasswordInput/PasswordInput';
import PhoneInput from './PhoneInput/PhoneInput';
import EmailInput from './EmailInput/EmailInput';

import {
  validationState,
  defaultT,
  getCustomToolTip,
} from '../../lib/utilities';

const componentForType = (type, mask) => {
  if ('select' === type) {
    // we can't pass the t function to all of these
    // how can we know what namespaces we need?
    // and properly specify keys?
    return SelectInput;
  } else if (mask) {
    return InputMask;
  } else {
    return 'input';
  }
};

const SelectInput = ({
  options,
  includeNull = false,
  placeholder,
  ...rest
}) => {
  if (includeNull && !placeholder) {
    console.warn(
      'Please provide a translated placeholder to SelectInput when includeNull is truthy'
    );
  }

  const defaultOptions = includeNull
    ? [
        <option key='null-default' disabled defaultValue data-default value=''>
          {placeholder}
        </option>,
      ]
    : [];
  const selectOptions = [].concat(
    defaultOptions,
    options.map((o) => (
      <option key={o} value={o}>
        {o}
      </option>
    ))
  );

  return <select {...rest}>{selectOptions}</select>;
};

const WrappedCheckbox = ({
  errorText,
  label,
  present,
  t = defaultT('WrappedCheckbox'),
  trData,
  ...rest
}) => {
  return (
    <div className='checkbox text-center'>
      <input className='custom-checkbox' {...rest} checked={!!rest.value} />
      <label htmlFor={rest.id}>{t(label, trData)}</label>
    </div>
  );
};

// we need to reference a bunch of props to pass a clean `rest` to the input element
const WrappedInput = ({
  label,
  id,
  errorText,
  validation,
  getter,
  setter,
  element,
  present,
  setState,
  type,
  t = defaultT('WrappedInput'),
  trData,
  placeholder,
  helpText,
  ...rest
}) => {
  const Input = componentForType(type, rest.mask);
  const validationClass = validationState(rest.value, errorText, rest.mask);
  const customToolTip = getCustomToolTip(validationClass, rest.required);
  const invalid = validationClass === 'error' ? true : false;

  return (
    <FormGroup size='lg' controlId={id} validationState={validationClass}>
      <FormLabel>{t(label, trData)}</FormLabel>
      <FormControl
        as={Input}
        title={customToolTip && t(customToolTip)}
        type={type}
        placeholder={placeholder ? t(placeholder) : null}
        aria-invalid={invalid}
        {...rest}
      />
      <FormControl.Feedback />
      <div role='alert' aria-live='assertive'>
        {!!validationClass && errorText && <span>{t(errorText, trData)}</span>}
      </div>
      <div>
        {!errorText && helpText ? <span>{t(helpText, trData)}</span> : ''}
      </div>
    </FormGroup>
  );
};

const InputField = ({
  t = defaultT('InputField'),
  phoneCountry,
  ...restProps
}) => {
  switch (restProps.type) {
    case 'checkbox':
      return <WrappedCheckbox t={t} {...restProps} />;
    case 'password':
      return <PasswordInput t={t} {...restProps} />;
    case 'phone':
      return <PhoneInput t={t} phoneCountry={phoneCountry} {...restProps} />;
    case 'email':
      return <EmailInput t={t} {...restProps} />;
    default:
      return <WrappedInput t={t} {...restProps} />;
  }
};

export default InputField;
