import { programMedFilter } from './pharmaProgram';
import { hasSensor } from './utilities';

export const getAvailableMeds = (groupMeds, enrollmentMeds) => {
  const medIds = enrollmentMeds.map(({ medicationId }) => medicationId);
  return groupMeds.filter((med) => medIds.includes(med.id));
};

export const getUnsensoredMeds = (groupMeds, enrollmentMeds, pharmaProgram) => {
  const medsForProgram = programMedFilter(pharmaProgram);
  const medIds = enrollmentMeds.map(({ medicationId }) => medicationId);
  return groupMeds.filter(
    (med) =>
      medIds.includes(med.id) && (!hasSensor(med) || !medsForProgram(med))
  );
};
