import { connect } from 'react-redux';
import { resetEnrollmentData, setError, loadSession } from '../../actions';

const mapStateToProps = (state) => {
  return {
    groupConfig: state.groupConfig,
    groupConfig4x: state.groupConfig4x,
    enrollmentData: state.enrollmentData,
    enrollmentMeds: state.enrollmentMeds,
    session: state.session,
  };
};

const mapDispatchToProps = {
  loadSession,
  resetEnrollmentData,
  setError,
};

export default connect(mapStateToProps, mapDispatchToProps);
