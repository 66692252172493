import React from 'react';
import Form from 'react-bootstrap/Form';
import { validationState, defaultT } from '../../lib/utilities';
import RadioInput from './RadioInput';

const RadioGroup = ({
  options,
  name,
  validation,
  autoFocus,
  required = false,
  value,
  onChange,
  id,
  className = '',
  disabled,
  inline,
  errorText,
  liStyle = {},
  trData,
  t = defaultT('RadioGroup'),
}) => {
  const validationClass = validationState(value, errorText);

  const _liStyle = inline
    ? Object.assign({}, liStyle, {
        width: `${(100 / options.length).toFixed(5)}%`,
        display: 'inline-block',
      })
    : liStyle;

  const radios = options.map((o, i) => {
    let key = `${name}-${o.value}`;
    return (
      <li key={key} style={_liStyle}>
        <RadioInput
          t={t}
          trData={trData}
          id={key}
          name={name}
          autoFocus={0 === i && autoFocus}
          label={o.label}
          optionValue={o.value}
          inputValue={value}
          onChange={onChange}
          disabled={disabled}
          required={required}
        />
      </li>
    );
  });

  return (
    <Form.Group size='lg' controlId={id} validationState={validationClass}>
      <ul className={`radio-selectors ${className}`}>{radios}</ul>
      {!!validationClass && errorText && (
        <Form.Text className='text-center'>
          <span role='alert' aria-live='assertive'>
            {t(errorText)}
          </span>
        </Form.Text>
      )}
    </Form.Group>
  );
};

export default RadioGroup;
