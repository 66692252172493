import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import { withTranslation } from 'react-i18next';
import { isExpressScripts } from '../../../lib/utilities';
import { EXPRESS_SCRIPTS_SUPPORT_PHONE } from '../../../lib/express_scripts';
import { SUBDOMAIN } from '../../../lib/utilities';

class CodeInput extends React.Component {
  state = {
    error: null,
    uniqueId: this.props.accessCode || '',
  };

  isValidCode = (uniqueId) => {
    const { steps } = this.props;
    if (uniqueId && steps) {
      const regexMatch = new RegExp(steps.uniqueId.regex);
      return uniqueId.match(regexMatch);
    }
    return null;
  };

  codeInputMask = () => {
    // ES codes have both numbers and letters
    if (isExpressScripts()) {
      return '****-****';
    }

  };

  //showing the placeholder based on the size of the required access code/member ID by group
  codePlaceholder = () => {
    if (isExpressScripts()) {
      return '####-####';
    }
    else{
      switch(SUBDOMAIN){
        case 'ibx':
          return '#############';
        case 'bltpa':
        case 'amerihealth':
          return '##########';
        default:
          return '9999';
      }
    }
  };

  setValue = (value) => {
    this.setState(() => {
      return { uniqueId: value };
    });
  };

  setError = (value) => {
    this.setState(() => {
      return { error: value };
    });
  };

  handleBlur = (e) => {
    const value = e.target.value;
    const { onBlur } = this.props;

    const valid = this.isValidCode(value);
    if (!valid) {
      this.setError(true);
    } else {
      this.setValue(value);
      this.setError(false);
      if (onBlur) onBlur({ uniqueId: value });
    }
  };

  handleChange = (e) => {
    const value = e.target.value;
    const { onChange } = this.props;

    this.setValue(value);
    this.setError(null);
    if (onChange) {
      onChange({ uniqueId: value });
    }
  };

  render = () => {
    const { accessCodeError, componentClass, error, t, group } = this.props;

    return (
      <FormGroup
        controlId='uniqueId'
        validationState={
          this.state.error || error
            ? 'error'
            : this.state.error === false
            ? 'success'
            : null
        }
      >
        <FormControl
          className='access-code-input'
          as={componentClass}
          mask={this.codeInputMask()}
          name='uniqueId'
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          min={0}
          placeholder={this.codePlaceholder()}
          type={isExpressScripts() ? 'text' : 'number'}
          value={this.state.uniqueId}
        />
        <FormControl.Feedback />
        {(group === 'ibx' || group === 'bltpa' || group === 'amerihealth') ?
          (<div className='error-message' role='alert' aria-live='assertive'>
          {error && !!accessCodeError
            ? t(accessCodeError, { phoneNumber: EXPRESS_SCRIPTS_SUPPORT_PHONE })
              : this.state.error || error
                ? t('CODE_ERROR_IBX')
                : ''}
          </div>) :
          (<div className='error-message' role='alert' aria-live='assertive'>
            {error && !!accessCodeError
              ? t(accessCodeError, { phoneNumber: EXPRESS_SCRIPTS_SUPPORT_PHONE })
              : this.state.error || error
                ? t('CODE_ERROR')
                : ''}
          </div>)
        }
      </FormGroup>
    );
  };
}

CodeInput.propTypes = {
  accessCodeError: PropTypes.string,
  componentClass: PropTypes.func,
  error: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  steps: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  group:PropTypes.string,
};

export default withTranslation(['verification', 'enrollment'])(CodeInput);
