import { connect } from 'react-redux';

const propsFromState = (state) => {
  return {
    groupConfig: state.groupConfig,
    subdomain: state.subdomain,
  };
};

export default connect(propsFromState);
