const adult1 = '../assets/images/act-images/adult-1.svg';
const adult2 = '../assets/images/act-images/adult-2.svg';
const adult3 = '../assets/images/act-images/adult-3.svg';
const adult4 = '../assets/images/act-images/adult-4.svg';
const adult5 = '../assets/images/act-images/adult-5.svg';
const child1 = '../assets/images/act-images/child-1.svg';
const child2 = '../assets/images/act-images/child-2.svg';
const child3 = '../assets/images/act-images/child-3.svg';
const child4 = '../assets/images/act-images/child-4.svg';
const child5 = '../assets/images/act-images/child-5.svg';
const child6 = '../assets/images/act-images/child-6.svg';
const child7 = '../assets/images/act-images/child-7.svg';

const ACT_QUESTIONS = {
  adult: [
    {
      key: 'activities',
      question: 'ADULT_ACT_ACTIVITIES',
      image: adult1,
      options: [
        { text: 'ADULT_ACT_ACTIVITIES_ALL_THE_TIME', value: 1 },
        { text: 'ADULT_ACT_ACTIVITIES_MOST_OF_THE_TIME', value: 2 },
        { text: 'ADULT_ACT_ACTIVITIES_SOME_OF_THE_TIME', value: 3 },
        { text: 'ADULT_ACT_ACTIVITIES_LITTLE_OF_THE_TIME', value: 4 },
        { text: 'ADULT_ACT_ACTIVITIES_NONE_OF_THE_TIME', value: 5 },
      ],
    },
    {
      key: 'breath',
      image: adult2,
      question: 'ADULT_ACT_BREATH',
      options: [
        { text: 'ADULT_ACT_BREATH_MORE_THAN_ONCE_A_DAY', value: 1 },
        { text: 'ADULT_ACT_BREATH_ONCE_A_DAY', value: 2 },
        { text: 'ADULT_ACT_BREATH_3_6_TIMES_WEEK', value: 3 },
        { text: 'ADULT_ACT_BREATH_ONCE_TWICE_WEEK', value: 4 },
        { text: 'ADULT_ACT_BREATH_NOT_AT_ALL', value: 5 },
      ],
    },
    {
      key: 'night-symptoms',
      image: adult3,
      question: 'ADULT_ACT_NIGHT_SYMPTOMS',
      options: [
        { text: 'ADULT_ACT_NIGHT_SYMPTOMS_4_MORE_NIGHTS', value: 1 },
        { text: 'ADULT_ACT_NIGHT_SYMPTOMS_2_3_NIGHTS', value: 2 },
        { text: 'ADULT_ACT_NIGHT_SYMPTOMS_ONCE_WEEK', value: 3 },
        { text: 'ADULT_ACT_NIGHT_SYMPTOMS_ONCE_TWICE', value: 4 },
        { text: 'ADULT_ACT_NIGHT_SYMPTOMS_NOT_AT_ALL', value: 5 },
      ],
    },
    {
      key: 'inhaler-use',
      image: adult4,
      question: 'ADULT_ACT_INHALER_USE',
      options: [
        { text: 'ADULT_ACT_INHALER_USE_3_MORE_TIMES_DAY', value: 1 },
        { text: 'ADULT_ACT_INHALER_USE_1_2_TIMES_DAY', value: 2 },
        { text: 'ADULT_ACT_INHALER_USE_2_3_TIMES_WEEK', value: 3 },
        { text: 'ADULT_ACT_INHALER_USE_ONCE_WEEK_LESS', value: 4 },
        { text: 'ADULT_ACT_INHALER_USE_NOT_AT_ALL', value: 5 },
      ],
    },
    {
      key: 'asthma-control',
      image: adult5,
      question: 'ADULT_ACT_ASTHMA_CONTROL',
      options: [
        { text: 'ADULT_ACT_NOT_CONTROLLED', value: 1 },
        { text: 'ADULT_ACT_POORLY_CONTROLLED', value: 2 },
        { text: 'ADULT_ACT_SOMEWHAT_CONTROLLED', value: 3 },
        { text: 'ADULT_ACT_WELL_CONTROLLED', value: 4 },
        { text: 'ADULT_ACT_COMPLETELY_CONTROLLED', value: 5 },
      ],
    },
  ],
  child: [
    {
      key: 'asthma-today',
      image: child1,
      question: 'CHILD_ACT_ASTHMA_TODAY',
      options: [
        { text: 'CHILD_ACT_ASTHMA_TODAY_VERY_BAD', value: 0 },
        { text: 'CHILD_ACT_ASTHMA_TODAY_BAD', value: 1 },
        { text: 'CHILD_ACT_ASTHMA_TODAY_GOOD', value: 2 },
        { text: 'CHILD_ACT_ASTHMA_TODAY_VERY_GOOD', value: 3 },
      ],
    },
    {
      key: 'exercise-problems',
      image: child2,
      question: 'CHILD_ACT_EXERCISE',
      options: [
        { text: 'CHILD_ACT_EXERCISE_BIG_PROBLEM', value: 0 },
        { text: 'CHILD_ACT_EXERCISE_PROBLEM', value: 1 },
        { text: 'CHILD_ACT_EXERCISE_LITTLE_PROBLEM', value: 2 },
        { text: 'CHILD_ACT_EXERCISE_NOT_PROBLEM', value: 3 },
      ],
    },
    {
      key: 'cough',
      image: child3,
      question: 'CHILD_ACT_COUGH',
      options: [
        { text: 'CHILD_ACT_COUGH_ALL_THE_TIME', value: 0 },
        { text: 'CHILD_ACT_COUGH_MOST_TIME', value: 1 },
        { text: 'CHILD_ACT_COUGH_SOME_TIME', value: 2 },
        { text: 'CHILD_ACT_COUGH_NONE_TIME', value: 3 },
      ],
    },
    {
      key: 'child-night-symptoms',
      image: child4,
      question: 'CHILD_ACT_NIGHT_SYMPTOMS',
      options: [
        { text: 'CHILD_ACT_NIGHT_SYMPTOMS_ALL_THE_TIME', value: 0 },
        { text: 'CHILD_ACT_NIGHT_SYMPTOMS_MOST_TIME', value: 1 },
        { text: 'CHILD_ACT_NIGHT_SYMPTOMS_SOME_TIME', value: 2 },
        { text: 'CHILD_ACT_NIGHT_SYMPTOMS_NONE_TIME', value: 3 },
      ],
    },
    {
      key: 'daytime-symptoms',
      image: child5,
      question: 'CHILD_ACT_DAYTIME_SYMPTOMS',
      options: [
        { text: 'CHILD_ACT_DAYTIME_SYMPTOMS_NOT_AT_ALL', value: 5 },
        { text: 'CHILD_ACT_DAYTIME_SYMPTOMS_1_3_DAYS', value: 4 },
        { text: 'CHILD_ACT_DAYTIME_SYMPTOMS_4_10_DAYS', value: 3 },
        { text: 'CHILD_ACT_DAYTIME_SYMPTOMS_11_18_DAYS', value: 2 },
        { text: 'CHILD_ACT_DAYTIME_SYMPTOMS_19_24_DAYS', value: 1 },
        { text: 'CHILD_ACT_DAYTIME_SYMPTOMS_EVERYDAY', value: 0 },
      ],
    },
    {
      key: 'wheeze',
      image: child6,
      question: 'CHILD_ACT_WHEEZE',
      options: [
        { text: 'CHILD_ACT_WHEEZE_NOT_AT_ALL', value: 5 },
        { text: 'CHILD_ACT_WHEEZE_1_3_DAYS', value: 4 },
        { text: 'CHILD_ACT_WHEEZE_4_10_DAYS', value: 3 },
        { text: 'CHILD_ACT_WHEEZE_11_18_DAYS', value: 2 },
        { text: 'CHILD_ACT_WHEEZE_19_24_DAYS', value: 1 },
        { text: 'CHILD_ACT_WHEEZE_EVERYDAY', value: 0 },
      ],
    },
    {
      key: 'sleep-child',
      image: child7,
      question: 'CHILD_ACT_SLEEP',
      options: [
        { text: 'CHILD_ACT_SLEEP_NOT_AT_ALL', value: 5 },
        { text: 'CHILD_ACT_SLEEP_1_3_DAYS', value: 4 },
        { text: 'CHILD_ACT_SLEEP_4_10_DAYS', value: 3 },
        { text: 'CHILD_ACT_SLEEP_11_18_DAYS', value: 2 },
        { text: 'CHILD_ACT_SLEEP_19_24_DAYS', value: 1 },
        { text: 'CHILD_ACT_SLEEP_EVERYDAY', value: 0 },
      ],
    },
  ],
};

const ACT_SCORES = {
  child: {
    min: 0,
    max: 27,
    ranges: [
      {
        value: 12,
        keyText: 'SCORE_LABEL_VERY_POORLY_CONTROLLED',
        class: 'act-pr',
        showValidation: true,
        description: 'CHILD_ACT_SCORE_VERY_POOR',
      },
      {
        value: 19,
        keyText: 'SCORE_LABEL_NOT_WELL_CONTROLLED',
        class: 'act-md',
        showValidation: true,
        description: 'CHILD_ACT_SCORE_NOT_WELL_CONTROLLED',
      },
      {
        value: 27,
        keyText: 'SCORE_LABEL_WELL_CONTROLLED',
        class: 'act-gd',
        showValidation: false,
        description: 'CHILD_ACT_SCORE_WELL_CONTROLLED',
      },
    ],
  },
  adult: {
    min: 0,
    max: 25,
    ranges: [
      {
        value: 15,
        keyText: 'SCORE_LABEL_VERY_POORLY_CONTROLLED',
        class: 'act-pr',
        showValidation: true,
        description: 'ADULT_ACT_SCORE_VERY_POORLY_CONTROLLED',
      },
      {
        value: 19,
        keyText: 'SCORE_LABEL_NOT_WELL_CONTROLLED',
        class: 'act-md',
        showValidation: true,
        description: 'ADULT_ACT_SCORE_NOT_WELL_CONTROLLED',
      },
      {
        value: 25,
        keyText: 'SCORE_LABEL_WELL_CONTROLLED',
        class: 'act-gd',
        showValidation: false,
        description: 'ADULT_ACT_SCORE_WELL_CONTROLLED',
      },
    ],
  },
};

export { ACT_SCORES, ACT_QUESTIONS };
