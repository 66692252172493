import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getStartPage } from './home-utils';

export const GetStartedLink = ({
  disease,
  buttonId,
  requiresACT,
  requiresCAT,
  text = 'GET_STARTED',
  type = 'green',
}) => {
  const { t } = useTranslation('common');
  const style =
    type === 'green'
      ? 'btn-success'
      : type === 'purple'
      ? 'btn-purple'
      : 'btn-outline btn-transparent';

  return (
    <Link
      className={`get-started-link btn btn-lg ${style}`}
      style={{ width: '12.5rem' }}
      to={(location) => ({
        pathname: getStartPage({ disease, location, requiresACT, requiresCAT }),
        search: `CTA=${buttonId}`,
      })}
    >
      {t(text)}
    </Link>
  );
};

function mapStateToProps({
  asthmaControlTest,
  copdAssessmentTest,
  enrollmentData,
  groupConfig,
}) {
  const { patient } = enrollmentData;
  const { disease } = patient;
  const requiresCAT =
    disease === 'copd' &&
    groupConfig.catRequired &&
    copdAssessmentTest.answers.length === 0;
  const requiresACT =
    disease === 'asthma' &&
    groupConfig.actRequired &&
    asthmaControlTest.answers.length === 0;

  return {
    disease,
    requiresACT,
    requiresCAT,
  };
}

GetStartedLink.propTypes = {
  buttonId: PropTypes.string,
  disease: PropTypes.oneOf(['asthma', 'copd']),
  requiresACT: PropTypes.bool,
  requiresCAT: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.oneOf(['green', 'purple', 'outline']),
};

export default connect(mapStateToProps)(GetStartedLink);
