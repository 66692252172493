// only shallow comparisons
// when a new state object returns from a reducer, it triggers a rerender.
// If we're not changing the value, don't create a new object
export const newStateOnChange = (
  key,
  newValue,
  state,
  initialState,
  allowFalsy = false
) => {
  if (newValue === state[key]) {
    return state;
  } else {
    return {
      ...state,
      [key]: allowFalsy ? newValue : newValue || initialState[key],
    };
  }
};
