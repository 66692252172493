import { ACT_QUESTIONS } from '../act-quiz';
import { CAT_QUESTIONS } from '../cat-quiz';
import { currentAge, MIN_ADULT_ACT_AGE, MIN_CHILD_ACT_AGE } from './age';

export const MIN_ADULT_ACT_SCORE = 5;
export const MAX_ADULT_ACT_SCORE = 25;

const actVersion = (age) => (age >= MIN_ADULT_ACT_AGE ? 'adult' : 'child');

const validACTForPatient = (version, data, age) => {
  const initialValidation =
    version === data.type &&
    ACT_QUESTIONS[version].length === data.answers.length;
  switch (version) {
    case 'adult':
      return initialValidation;
    case 'child':
      return (
        initialValidation && age < MIN_ADULT_ACT_AGE && age >= MIN_CHILD_ACT_AGE
      );
    default:
      return false;
  }
};

export const validQuiz = (quiz, birthDate) => {
  const answers = quiz && quiz.data && quiz.data.answers;

  switch (quiz.type) {
    case 'act':
      let age = currentAge(birthDate);
      return answers && validACTForPatient(actVersion(age), quiz.data, age);

    case 'cat':
      return answers && CAT_QUESTIONS.length === answers.length;

    default:
      return false;
  }
};
