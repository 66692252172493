import React from 'react';
import { Form, Row, Col} from 'react-bootstrap';

import moment from '../../../../lib/include-moment';
import BackNextButtons from '../../../../components/BackNextButtons';
import InputField from '../../../../components/InputField/InputField';
import PageHeading from '../../../../components/PageHeading/PageHeading';
import { logPage } from '../../../../lib/analytics';
import { displayDateFormat } from '../../../../lib/utilities';

const timeToData = (time) => {
  let _time = moment(time, displayDateFormat('LT'));
  return {
    hour: _time.hour(),
    minute: _time.minute(),
  };
};

const dataToTime = (data) => moment(data).format(displayDateFormat('LT'));

const getDoseTimes = () => {
  const DOSE_TIMES = [];

  for (let hour = 0; hour < 24; hour++) {
    DOSE_TIMES.push(moment({ hour }).format(displayDateFormat('LT')));
    DOSE_TIMES.push(moment({ hour, m: 30 }).format(displayDateFormat('LT')));
  }

  return DOSE_TIMES;
};

const timesForDoses = (usageList) => {
  if (!usageList || usageList.length === 0) return [];

  const total = usageList.length;

  return usageList.map((u, i) => {
    // when is this the case?
    if (u.hour) {
      return dataToTime(u);
    }

    const defaultTimes = {
      _8AM: moment({ hour: 8 }).format(displayDateFormat('LT')),
      _9AM: moment({ hour: 9 }).format(displayDateFormat('LT')),
      _12PM: moment({ hour: 12 }).format(displayDateFormat('LT')),
      _2PM: moment({ hour: 14 }).format(displayDateFormat('LT')),
      _4PM: moment({ hour: 16 }).format(displayDateFormat('LT')),
      _8PM: moment({ hour: 20 }).format(displayDateFormat('LT')),
    };

    switch (i) {
      case 0:
        switch (total) {
          case 1:
            return defaultTimes._9AM;
          default:
            return defaultTimes._8AM;
        }
      case 1:
        switch (total) {
          case 2:
            return defaultTimes._8PM;
          case 3:
            return defaultTimes._2PM;
          case 4:
            return defaultTimes._12PM;
          default:
            return defaultTimes._8AM;
        }
      case 2:
        switch (total) {
          case 3:
            return defaultTimes._8PM;
          case 4:
            return defaultTimes._4PM;
          default:
            return defaultTimes._8AM;
        }
      case 3:
        switch (total) {
          case 4:
            return defaultTimes._8PM;
          default:
            return defaultTimes._8AM;
        }
      default:
        return defaultTimes._8AM;
    }
  });
};

class DoseTimes extends React.Component {
  // maintain state internally so we only publish out changes when done
  // updated whenever we mount or outer key is updated (remount)
  state = {
    times: timesForDoses(this.props.med.usageList),
    error: false,
  };

  doseTimes = getDoseTimes();

  onChange(value, i) {
    this.setState({
      times: Object.assign([], this.state.times, { [i]: value }),
    });
  }

  goNext() {
    const { goNext, med } = this.props;
    const { times } = this.state;

    let dupes = times.filter((item, index) => times.indexOf(item) !== index);

    if (dupes.length) {
      this.setState({ error: true });
      return;
    }

    const usageList = med.usageList.map((u, i) => {
      return {
        ...u,
        ...timeToData(times[i]),
      };
    });

    goNext({ ...med, usageList });
  }

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.goNext = this.goNext.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    logPage('Enroll-DoseTimes');
  }

  render() {
    const { med, name, t } = this.props;
    const { times } = this.state;
    const { usageList = [] } = med;

    return (
      <Row>
        <Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
          <Form>
            <PageHeading pageTitle={t('PAGE_TITLE_SCHEDULE')} t={t}>
              <fieldset>
                <legend>{t('PLEASE_SELECT_DOSE_TIMES', { medName: name })}</legend>
                {usageList.map((u, i) => {
                  let number = i + 1;
                  let key = `dose-${i}`;
                  return (
                    <InputField
                      id={key}
                      key={key}
                      value={times[i]}
                      onChange={(e) => this.onChange(e.target.value, i)}
                      data-attr={key}
                      label={t('DOSE_NUM', { number })}
                      type='select'
                      options={this.doseTimes}
                    />
                  );
                })}
              </fieldset>
              <div role='alert' aria-live='assertive'>
                {this.state.error ? (
                  <span className='error-message'>
                    {t('DOSE_TIMES_MUST_BE_UNIQUE')}
                  </span>
                ) : (
                  ''
                )}
              </div>
              <BackNextButtons
                backClick={this.props.goBack}
                nextClick={this.goNext}
              />
            </PageHeading>
          </Form>
        </Col>
      </Row>
      
    );
  }
}

export default DoseTimes;
