import React from 'react';
import COLORS from '../../lib/style_constants';
import './styles.scss';

const ProgressIndicator = ({ question, total }) => {
  let points = new Array(total).fill().map((e, i) => {
    return (
      <div style={{ width: `${100 / total}%` }} key={`progress-${i}`}>
        <span style={i < question ? { backgroundColor: COLORS.blue } : null} />
      </div>
    );
  });

  return <div className='progress-indicator'>{points}</div>;
};

export default ProgressIndicator;
