import { i18nImg } from '../../lib/utilities';
import sensor from '../../assets/images/how-it-works/propeller-sensor.jpg';
import sensor_attach from '../../assets/images/how-it-works/propeller-hands-sensor.jpg';
const colors = ['propellerBlue', 'lime', 'purple', 'red'];

const propeller_alert = i18nImg('landing/alert.jpg');
const sensor_phone_asthma = i18nImg(
  'landing/propeller-sensor-phone-asthma.png'
);
const sensor_phone_copd = i18nImg('landing/propeller-sensor-phone-COPD.jpg');

const getSteps_BCCMI = () => {
  return [
    {
      alt: '',
      index: 2,
      color: colors[1],
      text: 'HOW_IT_WORKS_ATTACH_BCCMI',
      image: '',
    },
    {
      alt: '',
      index: 3,
      color: colors[2],
      text: `HOW_IT_WORKS_TRACK_BCCMI`,
      image: '',
    },
    {
      alt: 'NOTIFICATION_ALT_TEXT',
      index: 4,
      color: colors[3],
      text: `HOW_IT_WORKS_INSIGHT_BCCMI`,
      image: '',
    },
  ]
};

const getSteps = (disease) => {
  const DISEASE = disease === 'asthma' ? 'ASTHMA' : 'COPD';

  return [
    {
      alt: '',
      index: 0,
      color: colors[0],
      text: 'HOW_IT_WORKS_SENSOR',
      image: sensor,
    },
    {
      alt: '',
      index: 2,
      color: colors[1],
      text: 'HOW_IT_WORKS_ATTACH',
      image: sensor_attach,
    },
    {
      alt: '',
      index: 3,
      color: colors[2],
      text: `HOW_IT_WORKS_TRACK_${DISEASE}`,
      image: DISEASE === 'COPD' ? sensor_phone_copd : sensor_phone_asthma,
    },
    {
      alt: 'NOTIFICATION_ALT_TEXT',
      index: 4,
      color: colors[3],
      text: `HOW_IT_WORKS_INSIGHT`,
      image: propeller_alert,
    },
  ];
};

export {getSteps, getSteps_BCCMI};
