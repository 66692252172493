import PropTypes from 'prop-types';

export const MedType = {
  activeIngrdients: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  diseases: PropTypes.arrayOf(PropTypes.string).isRequired,
  formFactor: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
