import { oneOf, shape, string } from 'prop-types';

export const EnrollmentDataType = {
  enrollmentRole: oneOf(['patient', 'caregiver']).isRequired,
  mailingAddress: shape({
    country: string,
  }),
  group: shape({
    name: string,
  }),
  timeZone: string,
  caregiver: shape({
    role: 'caregiver',
  }),
  patient: shape({
    disease: oneOf(['asthma', 'copd']),
    role: 'patient',
  }),
};
