import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { isValidMedId } from '../../../lib/input_validation';
import TextInput from '../../../components/InputField/TextInput/TextInput';

export class MedicalId extends React.Component {
  state = {
    error: null,
    errorMessage: '',
    value: '',
  };

  isValid = (value) => {
    const { isRequired, validator } = this.props;
    const locale = i18n.language || 'en-US';

    if (isRequired && !value) {
      this.setState({ error: true, errorMessage: 'PLEASE_ENTER_RESPONSE' });
      return false;
    }

    const validation = isValidMedId({
      locale,
      isRequired,
      validator,
      value,
    });

    if (validation && validation.valid) {
      return true;
    }

    if (!validation.valid && value) {
      this.setState({
        error: true,
        errorMessage: isRequired ? 'MED_ID_FORMAT' : 'MED_ID_FORMAT_OPTIONAL',
      });
      return false;
    }

    this.setState({ error: false, errorMessage: '' });
    return true;
  };

  handleChange = (data) => {
    const { clearError, descriptorKey, onChange } = this.props;
    this.setState({
      error: null,
      errorMessage: '',
      value: data[descriptorKey],
    });
    if (clearError) clearError(descriptorKey);
    if (onChange) onChange(data);
  };

  handleBlur = (data) => {
    const { descriptorKey, onBlur, onError } = this.props;
    if (this.isValid(data[descriptorKey])) {
      onBlur(data);
    } else {
      onError(descriptorKey);
    }
  };

  render() {
    const { descriptorKey, error, label, isRequired, value, t } = this.props;

    const locale = i18n.language || 'en-US';

    const labelText = isRequired
      ? `${label[locale]}`
      : `${label[locale]} (${t('OPTIONAL')})`;

    return (
      <TextInput
        autoFocus={false}
        error={error || this.state.error}
        errorMessage={this.state.errorMessage || this.props.errorMessage}
        onChange={this.handleChange}
        label={labelText}
        name={descriptorKey}
        onBlur={this.handleBlur}
        t={t}
        value={value}
      />
    );
  }
}

MedicalId.propTypes = {
  clearError: PropTypes.func,
  descriptorKey: PropTypes.string.isRequired,
  error: PropTypes.bool,
  label: PropTypes.object.isRequired,
  onBlur: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  validator: PropTypes.object,
  value: PropTypes.string,
};

export default withTranslation('enrollment')(MedicalId);
