import { exists } from '../utilities';

const calculateProductCost = (runningTotal, product) =>
  product.price + runningTotal;

const getProduct = (med) => {
  if (!med || !med.sensor) {
    return;
  }

  return med.sensor.walgreensProduct;
};

export const getWalgreensProducts = (medsArray) => {
  if (!Array.isArray(medsArray)) {
    return [];
  }

  return medsArray.map(getProduct).filter((p) => exists(p) && p.price > 0);
};

export const getWalgreensPrice = (medsArray) => {
  if (!Array.isArray(medsArray)) {
    return 0;
  }

  return medsArray
    .map(getProduct)
    .filter(exists)
    .reduce(calculateProductCost, 0)
    .toFixed(2);
};
