import React from 'react';
import { Form, FormGroup, Row, Col, Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import BackNextButtons from '../../../../components/BackNextButtons';
import PageHeading from '../../../../components/PageHeading/PageHeading';
import FieldSet from '../../../../components/FieldSet';
import PhSelect from '../../../../components/PhSelect';
import { logPage } from '../../../../lib/analytics';
import WarningPuffsModal from '../../../../components/warningPuffModal/WarningPuffsModal';
import "./meddoses.scss";

const OPTIONS = [
  { label: 'AS_NEEDED', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
];

const OPTIONS_PUFFS = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
  { label: '13', value: 13 },
  { label: '14', value: 14 },
  { label: '15', value: 15 },
  { label: '16', value: 16 },
];

const DEFAULT_PUFFS = 1;

class MedDoses extends React.Component {
  // maintain state internally so we only publish out changes when done
  // updated whenever we mount or outer key is updated (remount)
  state = {
    med: this.props.med,
    showWarning: false,
    error: false
  };

  selectDoses(e) {
    const { med } = this.state;
    const count = Number.parseInt(e.target.value, 10);
    const doses =
      (med.usageList && med.usageList[0] && med.usageList[0].doses) ||
      DEFAULT_PUFFS;

    const usageList = new Array(count).fill().map(() => ({ doses }));

    const updatedMed = {
      ...med,
      usageList,
    };

    this.setState({
      med: updatedMed,
      error: false
    });
  }

  selectPuffs(e) {
    const { med } = this.state;
    const doses = Number.parseInt(e.target.value, 10);
    const usageList = med.usageList.map((u) => ({ ...u, doses }));
    const updatedMed = {
      ...med,
      usageList,
    };

    this.setState({
      med: updatedMed,
      showWarning: doses > 4,
      error: false
    });
  }

  goNextWrap(){
    const med = this.state.med;
    this.setState({ error: false});
   this.props.goNext(med);
    this.setState({ error: true});
  }

  onCancel(){
    this.selectPuffs({target:{value: 1}});
    
  }
  onContinue(){
    this.setState({showWarning: false});
  }

  constructor(props) {
    super(props);
    this.selectDoses = this.selectDoses.bind(this);
    this.selectPuffs = this.selectPuffs.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onContinue = this.onContinue.bind(this);
    this.goNextWrap = this.goNextWrap.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    logPage('Enroll-Doses');
  }

  render() {
    const { name, goBack, t } = this.props;
    const { med } = this.state;
    const numDoses = med && med.usageList && med.usageList.length;
    const puffs = numDoses && med.usageList[0] && med.usageList[0].doses;

    return (
      <Row>
        <Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
          <Form>
            <PageHeading pageTitle={t('PAGE_TITLE_SCHEDULE')} t={t}>
              <FormGroup>
                <legend className='text-center mt-4'>
                  {t('HOW_MANY_TIMES_A_DAY', { medName: name })}
                </legend>
                <FieldSet
                  id='doses'
                  required={true}
                  onChange={this.selectDoses}
                  value={numDoses}
                >
                  <PhSelect value={numDoses}>
                    <option selected disabled></option>
                    {OPTIONS.map((aNumber) => (
                      <option
                        key={`dose-${aNumber.value}`}
                        value={aNumber.value}
                      >
                        {aNumber.value === 0 ? t(aNumber.label) : aNumber.label}
                      </option>
                    ))}
                  </PhSelect>
                </FieldSet>
                {numDoses ? (
                  <fieldset>
                    <legend className='text-center mt-4'>
                      {t('HOW_MANY_PUFFS', { medName: name })}
                    </legend>
                    <FieldSet
                      id='doses'
                      required={true}
                      onChange={this.selectPuffs}
                      value={puffs}
                    >
                      <PhSelect value={puffs}>
                        {OPTIONS_PUFFS.map((aNumber) => (
                          <option
                            key={`dose-${aNumber.value}`}
                            value={aNumber.value}
                          >
                            {aNumber.label}
                          </option>
                        ))}
                      </PhSelect>
                    </FieldSet>
                  </fieldset>
                ) : null}
                {this.props.error || this.state.error ? (
                  <div
                    role='alert'
                    aria-live='assertive'
                    className='error-message-center'
                  >
                    {t('SELECT_OPTION')}
                  </div>
                ) : null}
                <p className='text-center mt-4'>{t('FDA_NOTICE')}</p>
              </FormGroup>
                <Modal show={this.state.showWarning} className="warning-modal">
                  <WarningPuffsModal puffs={puffs} onCancel={this.onCancel} onContinue={this.onContinue} />
                </Modal>
              <BackNextButtons
                backClick={() => goBack(med)}
                nextClick={() => this.goNextWrap(med)}
              />
            </PageHeading>
          </Form>
        </Col>
      </Row>
    );
  }
}

export default withTranslation(['enrollment', 'common'])(MedDoses);
