import { SET_ERROR, CLEAR_ERRORS } from '../actions';
import { IS_DEV_OR_TEST } from '../lib/utilities';

const appErrors = function appErrors(state = [], action) {
  switch (action.type) {
    case SET_ERROR:
      return IS_DEV_OR_TEST ? [action.error, ...state] : [];

    case CLEAR_ERRORS:
      return [];

    default:
      return state;
  }
};

export default appErrors;
