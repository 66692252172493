import { connect } from 'react-redux';
import {
  removeSelectedMed,
  resetMedSelection,
  setPharmaProgram,
  setConfirmedPharmaProgram,
} from '../../../actions';
import {
  show,
  hide as removeNotification,
} from 'react-notification-system-redux';

const mapStateToProps = ({
  groupConfig,
  enrollmentMeds,
  pharmaProgram,
  pharmaConfig,
  enrollmentData,
}) => {
  return {
    enrollmentMeds,
    groupConfig,
    pharmaConfig,
    pharmaProgram,
    country: groupConfig.country || 'US',
    patient: enrollmentData.patient,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeMed: (mid) => dispatch(removeSelectedMed(mid)),
    resetMedSelection: () => dispatch(resetMedSelection()),
    notify: (notification, level) => dispatch(show(notification, level)),
    removeNotification: (uid) => dispatch(removeNotification(uid)),
    setPharmaProgram: (program) => {
      dispatch(setPharmaProgram(program));
      dispatch(setConfirmedPharmaProgram(program));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
