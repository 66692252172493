import React from 'react';
import { node, oneOf } from 'prop-types';

import CircleWarningIcon from '../CircleWarningIcon';
import TriangleWarningIcon from '../TriangleWarningIcon';

import './styles.less';

const iconType = (type) => {
  if (type === 'warning') {
    return TriangleWarningIcon;
  }

  return CircleWarningIcon;
};

function ErrorMessage({ children, type }) {
  const Icon = iconType(type);

  return (
    <div className={`error-message error-message--${type}`} aria-live="assertive">
      <Icon width={20} height={20} /> {children}
    </div>
  );
}

ErrorMessage.propTypes = {
  children: node.isRequired,
  type: oneOf(['error', 'warning'])
};

ErrorMessage.defaultProps = {
  type: 'error'
};

export default ErrorMessage;
