import { connect } from 'react-redux';
import {
  push as navigateForward,
  goBack as navigateBack,
} from 'connected-react-router';
import {
  error as notifyError,
  hide as clearError,
} from 'react-notification-system-redux';
import { selectFormFactor as onChange } from '../../../actions';
import { enrollmentDisease } from '../../../lib/utilities';

const mapStateToProps = ({ enrollmentData, formFactors, medSelection }) => {
  // memoize this
  const { formFactors: selectedFormFactors } = medSelection;

  return {
    formFactors: formFactors.map((ff) => ({
      ...ff,
      selected: selectedFormFactors.includes(ff.id),
    })),
    disease: enrollmentDisease(enrollmentData),
  };
};

const mapDispatchToProps = {
  onChange,
  navigateBack,
  navigateForward,
  notifyError,
  clearError,
};

export default connect(mapStateToProps, mapDispatchToProps);
