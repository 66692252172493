import './index.scss';

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import BodyClassName from 'react-body-classname';
import MainApp from './MainApp';
import TermsOfUse from './pages/TermsOfUsePage';
import ScrollToTop from './components/ScrollToTop';

class App extends Component {
  render() {
    const { subdomain } = this.props;

    return (
      <ScrollToTop>
        <BodyClassName className={subdomain}>
          <Switch>
            <Route exact path='/terms-of-use' component={TermsOfUse} />
            <Route component={MainApp} />
          </Switch>
        </BodyClassName>
      </ScrollToTop>
    );
  }
}

const mapStateToProps = ({ subdomain }) => ({ subdomain });

// see https://reacttraining.com/react-router/core/guides/redux-integration/blocked-updates
export default compose(withRouter, connect(mapStateToProps))(App);
