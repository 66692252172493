import { connect } from 'react-redux';
import { enrollmentDisease, getBoolean, isPaid } from '../../lib/utilities';

const mapStateToProps = ({
  analyticsFlags,
  enrollmentData,
  groupConfig,
  mongoObjectId,
  queryParams,
  subdomain,
}) => {
  const isIFrame = queryParams ? getBoolean(queryParams.iframe) : false;
  const disease = enrollmentDisease(enrollmentData);
  const contentVersion = isPaid(subdomain) ? disease + '_paid' : disease;
  const lpVariant =
    queryParams && queryParams.lpVariant ? queryParams.lpVariant : 'none';

  return {
    analyticsFlags,
    disease,
    contentVersion,
    groupConfig,
    isIFrame,
    lpVariant,
    mongoObjectId,
  };
};

export default connect(mapStateToProps);
