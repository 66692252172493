import React from 'react';
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import { isTouchScreen } from '../../../lib/utilities';

const StandardCodeInput = ({
  value,
  validationState,
  helpMessage,
  onChange,
  t,
}) => {
  return (
    <Form.Group
      controlId='accessCodeInput'
      validationState={validationState}
      size='lg'
    >
      <Form.Label srOnly>{t('ACCESS_CODE_LABEL')}</Form.Label>
      <Form.Control
        as={InputMask}
        type='tel'
        className='access-code-input'
        mask='9999-9999'
        autoFocus={!isTouchScreen()}
        value={value}
        onChange={onChange}
      />
      <Form.Control.Feedback />
      <Form.Text>{helpMessage || ' '}</Form.Text>
    </Form.Group>
  );
};

export default StandardCodeInput;
