import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Redirect } from 'react-router-dom';

import SensorCarousel from './SensorCarousel/SensorCarousel';
import Title from '../../components/Title/Title';
import GreenButton from '../../components/Button/GreenButton';
import GaLink from '../../components/GaLink';
import KitPrice from './KitPrice';
import FAQ from '../../components/FAQ';
import AboutSponsorship from '../../components/AboutSponsorship/AboutSponsorship';
import { getUnsensoredMeds } from '../../lib/allowed-meds';
import MedNames from './MedNames';
import SensorList from './SensorList/SensorList';
import { getWalgreensProducts } from '../../lib/walgreens';
import {
  isSponsorshipGroup,
  objectFromQueryString,
  matchingArrays,
  hasSensor,
  getFullMedicationListFromEnrollmentMeds,
  exists,
  medFromHash,
  hashMed,
  SUBDOMAIN,
} from '../../lib/utilities';

import { programMedFilter } from '../../lib/pharmaProgram';
import phoneHandIcon from '../../assets/images/icons/icon_phone_in_hand.svg';

import container from './container';
import './styles.scss';
import PageHeading from '../../components/PageHeading/PageHeading';
import { logPage } from '../../lib/analytics';

export class YourSensors extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    const { groupConfig } = this.props;
    if (groupConfig.medications) {
      this.verifyMedications();
    }
    logPage('MedSelect-SensorPackage');
  }

  componentDidUpdate(prevProps) {
    const { groupConfig } = this.props;
    if (!prevProps.groupConfig.medications && groupConfig.medications) {
      this.verifyMedications();
    }
  }
  queryParams =
    objectFromQueryString(this.props.location.search) || this.props.queryParams;

  getFullMeds() {
    const { enrollmentMeds, groupConfig } = this.props;
    const groupMeds = groupConfig.medications || [];
    return getFullMedicationListFromEnrollmentMeds(groupMeds, enrollmentMeds);
  }

  hasUnsensoredMeds = memoize((enrollmentMeds, groupConfig, pharmaProgram) => {
    const groupMeds = groupConfig.medications || [];
    return getUnsensoredMeds(groupMeds, enrollmentMeds, pharmaProgram);
  });

  onClick() {
    // do I want to do this here or later?
    const { setWalgreensProducts, pharmaProgram, requiresPayment } = this.props;
    if (!requiresPayment) return;

    // At present, this logic only pertains to walgreens.
    // This predates other pharma reimbursed work, and the fact that we have
    // 2 processes for roughly equivalent outcomes can cause confusion.
    // this can't take just sensored meds, because if someone is on
    // handihaler we want pharmaReimbursed to be true
    // We'll filter on medsForProgram because, ostensibly, if they're not in the new
    // logics, we just return all the meds.
    // eventually we should be able to base this off other pharma program logic
    const medications = this.getFullMeds().filter(
      programMedFilter(pharmaProgram)
    );
    setWalgreensProducts(getWalgreensProducts(medications));
  }

  // this compares the meds in the url query params with the enrollment meds saved to redux
  // do we need to do this?
  verifyMedications() {
    const { enrollmentMeds, setEnrollmentMeds } = this.props;
    const medIds = enrollmentMeds.map((m) => m.medicationId);
    const queryMeds = []
      .concat(this.queryParams.m)
      .filter(exists)
      .map(medFromHash);

    if (enrollmentMeds.length === 0 || !matchingArrays(medIds, queryMeds)) {
      setEnrollmentMeds(queryMeds);
    }
  }

  hasNoSponsor(pharmaProgram) {
    return isSponsorshipGroup() && !pharmaProgram.selectedProgram;
  }

  hasNoMeds(enrollmentMeds) {
    return (
      enrollmentMeds.length === 0 &&
      (!this.queryParams.m || this.queryParams.m.length === 0)
    );
  }

  render() {
    const {
      enrollmentMeds,
      groupConfig,
      pharmaProgram,
      location,
      disease,
      t,
    } = this.props;

    if (this.hasNoSponsor(pharmaProgram) || this.hasNoMeds(enrollmentMeds)) {
      return (
        <Redirect
          to={{
            pathname: '/med-selection',
            state: { ...location.state, from: location },
          }}
        />
      );
    }

    const allMeds = this.getFullMeds();
    const allowedMeds = allMeds.filter(programMedFilter(pharmaProgram));
    const sensoredMeds = allowedMeds.filter(hasSensor);
    const unsensoredMeds = getUnsensoredMeds(
      groupConfig.medications || [],
      enrollmentMeds,
      pharmaProgram
    );

    return (
      <div className='your-sensors'>
        <PageHeading pageTitle={t('PAGE_TITLE_YOUR_SENSORS')} t={t}>
          <Row>
            <Col xs={12}>
              <Title text={t('SENSOR_KIT_FOR_YOU')} level={1} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {isSponsorshipGroup() ? <SensorCarousel /> : null}
            </Col>
          </Row>
          <Row>
            <Col xs={12} className='kit-cost-container'>
              <ul className='unstyled'>
                <li className='kit-item top-kit-item'>
                  <h3 className='ph-sub-heading'>
                    {`${t('AUTOMATICALLY_TRACK')}`}{' '}
                    <MedNames meds={sensoredMeds} />
                  </h3>
                  <SensorList meds={sensoredMeds} t={t} />
                  <KitPrice
                    groupConfig={groupConfig}
                    pharmaProgram={pharmaProgram}
                    t={t}
                    totalSensors={sensoredMeds.length}
                  />

                  <div>
                    {pharmaProgram?.selectedProgram === 'azExpansion' ||
                    pharmaProgram?.selectedProgram === 'btc' ? (
                      <AboutSponsorship
                        disease={groupConfig.disease}
                        pharmaProgram={pharmaProgram}
                        t={t}
                      />
                    ) : (
                      <>
                        {groupConfig.name !== 'walgreens' ? (
                          <div className='sponsor'>
                            {t('SPONSORED_BY')} {groupConfig.displayName}
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>
                </li>
                <li className='kit-item'>
                  <h3 className='ph-sub-heading'>{t('APP_ACCESS')}</h3>
                  <div className='item-wrapper'>
                    <div className='app-image'>
                      <img src={phoneHandIcon} alt={t('PROPELLER_APP')} width='75' height='75' />
                    </div>
                    <div className='item-description'>
                      {t('DISCOVER_PATTERNS_SYMPTOMS')}

                      {unsensoredMeds.length > 0 ? (
                        <>
                          <div>{t('MANUALLY_TRACK')}</div>
                          <ul>
                            {unsensoredMeds.map((med) => (
                              <li key={med.id}>· {med.name}</li>
                            ))}
                          </ul>
                        </>
                      ) : (
                        <div>{t('MANAGE_WITH_APP', { disease })}</div>
                      )}
                    </div>
                  </div>
                  <div
                    className='price font-bold'
                    aria-label={t('medSelection:REGULAR_PRICE')}
                  >
                    {t('INCLUDED')}
                  </div>
                </li>

                <li>
                  <div className='price kit-item font-bold'>
                    {t('FREE_SHIPPING')}
                    <KitPrice
                      finalPrice={true}
                      groupConfig={groupConfig}
                      pharmaProgram={pharmaProgram}
                      t={t}
                      totalSensors={sensoredMeds.length}
                    />
                  </div>
                </li>
              </ul>

              <GreenButton
                className='main-cta'
                as={GaLink}
                action='selectPackageForEnrollment'
                onClick={this.onClick}
                to={{
                  pathname: '/enrollment',
                  search: allMeds.map((m) => `m=${hashMed(m.id)}`).join('&'),
                }}
              >
                {t('START_MY_ORDER')}
              </GreenButton>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FAQ subdomain={SUBDOMAIN} groupConfig={groupConfig} t={t} />
            </Col>
          </Row>
        </PageHeading>
      </div>
    );
  }
}

YourSensors.propTypes = {
  setWalgreensProducts: PropTypes.func,
  requiresPayment: PropTypes.bool,
  enrollmentMeds: PropTypes.array.isRequired,
  setEnrollmentMeds: PropTypes.func.isRequired,
  groupConfig: PropTypes.object.isRequired,
  pharmaProgram: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  disease: PropTypes.oneOf(['asthma', 'copd']),
  t: PropTypes.func.isRequired,
};

export default compose(
  container,
  withTranslation(['medSelection', 'faq'])
)(YourSensors);
