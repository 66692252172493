import React from 'react';
import PropTypes from 'prop-types';
import Collapse from 'react-bootstrap/Collapse';
import './question.scss';

const Question = (props) => {
  const onClick = () => {
    props.onClick(props.index);
  };

  // using empty alt for decorative images
  // https://www.w3.org/WAI/tutorials/images/decorative/
  return (
    <div className='faq-question'>
      {props.type === 'mobile' ? (
        <button
          className='question btn'
          onClick={onClick}
          variant='link'
          aria-expanded={props.open}
        >
          <div className='question-text'>{props.text}</div>
          <div className='caret-wrapper'>
            <div className={`toggle caret-${props.open ? 'up' : 'down'}`} />
          </div>
        </button>
      ) : (
        <div className='question'>
          <div className='text font-bold'>{props.text}</div>
        </div>
      )}
      <Collapse in={props.open}>
        <div className='answer font-book'>{props.answer}</div>
      </Collapse>
    </div>
  );
};

Question.propTypes = {
  index: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['mobile', null]),
  open: PropTypes.bool,
};

export default Question;
