import { IS_DEV_OR_TEST } from '../lib/utilities';
const round = (number) => Math.round(number * 100) / 100;

const monitorReducerEnhancer = (createStore) => (
  reducer,
  initialState,
  enhancer
) => {
  const monitoredReducer = (state, action) => {
    if (IS_DEV_OR_TEST) {
      try {
        const start = performance.now();
        const newState = reducer(state, action);
        const end = performance.now();
        const diff = round(end - start);

        console.log('reducer process time:', diff, 'ms');

        return newState;
      } catch (e) {
        return reducer(state, action);
      }
    } else {
      return reducer(state, action);
    }
  };

  return createStore(monitoredReducer, initialState, enhancer);
};

export default monitorReducerEnhancer;
