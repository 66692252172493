import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GaLink from '../../../components/GaLink';
import GreenButton from '../../../components/Button/GreenButton';

const styles = {
  margin: '0.5rem auto',
  width: '80%',
  maxWidth: '350px',
};

const NoSelectedMeds = ({ t }) => {
  return (
    <Row>
      <Col xs={12}>
        <h1 className='sm-left-lg-center-text'>{t('SOMETHING_LOOKS_WRONG')}</h1>
        <p className='margin-2 break-words'>
          {t('YOU_HAVENT_SELECTED_MEDICATIONS')}
        </p>
        <div className='giant-question'>?</div>
        <div className='text-center'>
          <GreenButton
            autoFocus
            block
            style={styles}
            as={GaLink}
            className='auto-center'
            action='postMedsSelection-viewResults'
            to={{
              pathname: '/med-selection/form-factors',
            }}
          >
            {t('SELECT_MY_MEDICATION')}
          </GreenButton>
          <div>
            <GaLink
              action='medSummary-getSupport'
              to={{
                pathname: '/med-selection/support',
              }}
            >
              {t('COULDNT_FIND_MEDICATIONS')}
            </GaLink>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default NoSelectedMeds;
