import React from 'react';
import { withTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GreyButton from '../Button/GreyButton';
import {
  isNonNebPillSprayRescue,
  getMedImageUrl,
} from '../../lib/utilities';

import './styles.scss';

const SelectedMedication = ({ medication, remove, t, warning }) => {
  const nonNebPillSprayRescue = isNonNebPillSprayRescue(medication);

  return (
    <Container>
      <Row className='selected-med-wrapper selected-med-item'>
        <div
          className={`med-wrapper ${
            warning === 'total-warning'
              ? 'warning'
              : warning === 'rescue-warning' && nonNebPillSprayRescue
              ? 'warning'
              : ''
          }`}
        >
          <Col xs={5} className='text-center selected-med'>
            <div className='selected-med-detail'>
              <img
                className='selected-med-image'
                src={getMedImageUrl(medication, 80)}
                alt={medication.shortName}
                height={80}
              />
              <div
                className={`medication-name ${
                  medication ? '' : 'none-selected'
                }`}
              >
                <span data-testid='med-name' className='font-bold'>
                  {medication.name}
                </span>
              </div>
            </div>
          </Col>
          <Col
            xs={7}
            sm={{ span: 5, offset: 2 }}
            className='text-center nowrap'
            style={{ padding: '.25rem 0' }}
          >
            <GreyButton
              className='auto-center ph-text-button-small'
              size={null}
              onClick={remove}
              data-testid='remove'
            >
              {t('REMOVE')}
            </GreyButton>
          </Col>
        </div>
      </Row>
    </Container>
  );
};

export default withTranslation('common')(SelectedMedication);
