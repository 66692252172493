import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import CoppaIntro from './CoppaIntro';
import CaregiverBirthdate from './CaregiverBirthdate';
import CaregiverAddress from './CaregiverAddress';
import CoppaQuestions from './CoppaQuestions';
import CoppaResult from './CoppaResult';
import './coppa.scss';
class Coppa extends Component {
  render() {
    const basePath = '/enrollment/coppa';
    return (
      <Switch>
        <Route path={basePath} exact component={CoppaIntro} />
        <Route path={`${basePath}/birthdate`} component={CaregiverBirthdate} />
        <Route path={`${basePath}/address`} component={CaregiverAddress} />
        <Route path={`${basePath}/questions`} component={CoppaQuestions} />
        <Route path={`${basePath}/results`} component={CoppaResult} />
      </Switch>
    );
  }
}

export default Coppa;
