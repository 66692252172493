import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Form, Row, Col } from 'react-bootstrap';

import container from '../container';
import PageHeading from '../../../components/PageHeading/PageHeading';
import EnrollmentButtons from '../Buttons';
import EmailInput from '../../../components/InputField/EmailInput/EmailInput';
import Title from '../../../components/Title/Title';
import PasswordInput from '../../../components/InputField/PasswordInput/PasswordInput';
import { logPage } from '../../../lib/analytics';
import { isValidEmail, isValidPassword } from '../../../lib/input_validation';
import { getNextPage, delay } from '../enrollment-utils';

export class Credentials extends React.Component {
  constructor(props) {
    super(props);
    const enrollmentData = props.enrollmentData;
    const patient = enrollmentData.patient;

    this.state = {
      email:
        (this.version === 'patient' ? patient.email : enrollmentData.email) ||
        '',
      verifyEmail: '',
      password:
        (this.version === 'patient'
          ? patient.password
          : enrollmentData.password) || '',
      loginError: false,
      errors: [],
      touched: [],
    };
    this.ref = React.createRef();
  }
  // listening for message from SSO login window
  componentDidMount() {
    const { enrollmentMeds, pushHistory } = this.props;
    if (!enrollmentMeds || enrollmentMeds.length < 1) {
      pushHistory('/');
    }
    if (this.showDignity()) {
      window.addEventListener('message', this.receiveMessage, false);
    }
    logPage('Enroll-Credentials');
  }

  componentWillUnmount() {
    if (this.showDignity()) {
      window.removeEventListener('message', this.receiveMessage, false);
    }
  }

  // set to 'user' if enroller is also the patient; set to 'patient' if enroller is caregiver
  version =
    this.props.location.pathname === '/enrollment/patient-credentials'
      ? 'patient'
      : 'user';

  showDignity = () => {
    const { enrollmentData } = this.props;
    return (
      enrollmentData.enrollmentRole === 'patient' &&
      enrollmentData.hubRequired !== 'true' &&
      !this.props.loginError
    );
  };

  // if SSO login error OR unavailable email, show error msg
  receiveMessage = (message) => {
    if (message && message.data) {
      switch (message.data.action) {
        case 'loginError':
          this.setState({
            loginError: true,
          });
          if (message.source) {
            message.source.close();
          }
          window.scrollTo(0, 0);
          break;
        case 'goNext':
          this.props.updateEnrollmentData({
            oidcIdToken: message.data.oidcIdToken,
            email: message.data.email,
            password: message.data.password,
          });
          window.scrollTo(0, 0);
          const next = this.getNextPage();
          this.props.pushHistory(next);
          break;
        default:
          if (message.source) {
            message.source.close();
          }
      }
    }
  };

  checkForErrors = () => {
    const { device } = this.props;
    const email = this.state.email.trim();
    const verifyEmail = this.state.verifyEmail.trim();

    return ['email', 'verifyEmail', 'password']
      .map((item) => {
        if (item === 'email') {
          return isValidEmail(email) ? null : item;
        }
        if (item === 'verifyEmail') {
          if (device) return null;
          if (this.state.touched.includes('verifyEmail')) {
            return email === verifyEmail ? null : item;
          }
        }

        if (item === 'password') {
          if (
            isValidPassword(this.state.password) &&
            this.state.password !== email
          ) {
            return null;
          }
          if (
            email.length > 0 &&
            this.state.touched.includes('password') &&
            email === this.state.password
          ) {
            this.setState({ errorMessage: 'PASSWORD_CANT_MATCH_EMAIL' });
          }
          return item;
        }
        return this.state[item].length > 1 ? null : item;
      })
      .filter((err) => err !== null);
  };

  setErrors = (errors) => {
    this.setState(() => {
      return { errors: errors };
    });
  };

  setError = (field) => {
    if (!this.state.errors.includes(field)) {
      this.setState((prev) => {
        return { errors: [...prev.errors, field] };
      });
    }
  };

  setLoader = (value) => {
    this.ref.current = value;
  }

  setTouched = (field) => {
    if (!this.state.touched.includes(field)) {
      this.setState((prev) => {
        return { touched: [...prev.touched, field] };
      });
    }
  };

  isValid = () => {
    const errors = this.checkForErrors();
    if (errors && errors.length) {
      this.setErrors(errors);
      return false;
    }
    return this.state.errors.length ? false : true;
  };

  getNextPage = () => {
    const { enrollmentData, enrollmentMeds, groupConfig } = this.props;
    return getNextPage({
      currentPage: this.version === 'patient' ? 'patientCredentials' : 'credentials',
      enrollmentData,
      enrollmentMeds,
      groupConfig,
    });
  };

  handleBlur = (data) => {
    const key = Object.keys(data)[0];
    const value = Object.values(data)[0];
    if (key === 'password' && value === this.state.email) {
      this.setState({
        errors: ['password'],
        errorMessage: 'PASSWORD_CANT_MATCH_EMAIL',
      });
    }

    // we trimmed trailing spaces onBlur; set state & field equal to trimmed value
    if (key.includes('email') || key.includes('verifyEmail')) {
      this.setState({ ...data });
    }
  };

  handleChange = (data) => {
    const key = Object.keys(data)[0];
    const errors = this.state.errors.filter((error) => key === 'email' ? error !== key && error !== 'verifyEmail' : error !== key);
    this.setState({ ...data, errorMessage: '' });
    this.setErrors(errors);
    this.setTouched(key);
  };

  handleBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    document.querySelector('#email').blur();
    (async () => {
      await delay();
      if(this.ref.current) return;
      if (this.state.errors.length === 0 && this.isValid()) {
        const next = this.getNextPage();
        this.version === 'patient'
          ? this.props.updateEnrollmentData({
              patient: {
                email: this.state.email.trim(),
                password: this.state.password,
              },
            })
          : this.props.updateEnrollmentData({
              email: this.state.email.trim(),
              password: this.state.password,
            });
        this.props.pushHistory(next);
      }
    })();
  };

  render() {
    const { device, enrollmentData, t } = this.props;
    const patient = enrollmentData.patient;
    
    return (
      <PageHeading pageTitle={t('PAGE_TITLE_CREDENTIALS')}>
        <Title
          text={
            this.version === 'patient'
              ? t('PATIENT_CREDENTIALS_QUESTION', { patient })
              : this.state.loginError
              ? t('SIGN_IN_WITH_DIGNITY_FAILURE_HEADER')
              : t('EMAIL_PASSWORD_QUESTION')
          }
          className='sm-left-lg-center-text'
          level={1}
        />
        <Row className='subhead'>
          <Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
          {this.version === 'patient'
            ? t('PATIENT_CREDENTIALS_EXPLANATION', { patient })
            : this.state.loginError
            ? t('SIGN_IN_WITH_DIGNITY_FAILURE_TEXT')
            : t('EMAIL_PASSWORD_EXPLANATION')}
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <Form
              onSubmit={(e) => this.handleSubmit(e)}
              autoComplete='off'
            >
              <EmailInput
                caregiverEmail={
                  this.version === 'patient' ? enrollmentData.email : ''
                }
                error={this.state.errors.includes('email')}
                errorMessage='EMAIL_INVALID'
                helpText='MAX_LENGTH'
                helpTextHidden={true}
                id='email'
                label={
                  this.version === 'patient'
                    ? t('PATIENT_EMAIL_LABEL', { patient })
                    : t('EMAIL_LABEL')
                }
                setLoader={this.setLoader}
                maxLength={80}
                name='email'
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                onError={this.setError}
                t={t}
                value={this.state.email}
                warningText='EMAIL_LENGTH_LIMIT_REACHED'
              />

              {!device ? (
                <EmailInput
                  error={this.state.errors.includes('verifyEmail')}
                  errorMessage='VERIFY_EMAIL_ERROR'
                  id='verifyEmail'
                  label={t('REPEAT_EMAIL_LABEL')}
                  matchWith={this.state.email}
                  name='verifyEmail'
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  onError={this.setError}
                  t={t}
                  value={this.state.verifyEmail}
                />
              ) : null}
              <PasswordInput
                error={this.state.errors.includes('password')}
                errorMessage={this.state.errorMessage}
                id='password'
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                label={
                  this.version === 'patient'
                    ? t('PATIENT_PASSWORD_LABEL', { patient })
                    : t('PASSWORD_LABEL')
                }
                name='password'
                t={t}
                type='password'
                value={this.state.password}
              />
              <EnrollmentButtons
                handleBack={this.handleBack}
                handleSubmit={this.handleSubmit}
                t={t}
              />
            </Form>
          </Col>
        </Row>
      </PageHeading>
    );
  }
}

Credentials.propTypes = {
  device: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  enrollmentData: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pushHistory: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateEnrollmentData: PropTypes.func.isRequired,
};

export default compose(withTranslation('enrollment'), container)(Credentials);
