const demographicData = {
  gender: {
    title: 'SEX_QUESTION',
    options: [
      { label: 'FEMALE_LABEL', value: 'female' },
      { label: 'MALE_LABEL', value: 'male' },
    ],
  },
  hispanic: {
    title: 'ETHNICITY_QUESTION',
    options: [
      { label: 'ETHNICITY_OPTION_HISPANIC', value: 'true' },
      { label: 'ETHNICITY_OPTION_NOT_HISPANIC', value: 'false' },
      { label: 'PREFER_NOT_TO_SAY', value: 'null' },
    ],
  },
  race: {
    title: 'RACE_QUESTION',
    options: [
      { label: 'RACE_OPTION_NATIVE', value: 'Native American' },
      { label: 'RACE_OPTION_ASIAN', value: 'Asian' },
      { label: 'RACE_OPTION_BLACK', value: 'Black/African-American' },
      { label: 'RACE_OPTION_PACIFIC_ISLANDER', value: 'Pacific Islander' },
      { label: 'RACE_OPTION_WHITE', value: 'White/Caucasian' },
      { label: 'PREFER_NOT_TO_SAY', value: 'null' },
    ],
  },
};

export default demographicData;
