import React from 'react';
import { Redirect } from 'react-router-dom';
import QuizResults from '../../components/QuizResults';
import { objectFromQueryString } from '../../lib/utilities';
import { ACT_SCORES } from '../../lib/act-quiz';
import analytics from '../../lib/analytics';

import './styles.scss';

export const ActScore = (props) => {
  const query = objectFromQueryString(props.location.search || '');
  const test = props.type || query.test;
  const score = Number.parseInt(props.score || query.score, 10);
  const data = ACT_SCORES[test];

  if (!test || isNaN(score)) {
    return (
      <Redirect
        to={{
          pathname: '/take-the-quiz',
          state: Object.assign({}, props.location.state, {
            from: props.location,
            modal: true,
          }),
        }}
      />
    );
  }

  const category = data.ranges.find((c) => score <= c.value);

  analytics.fbq('trackCustom', 'SurveyScore', { custom_param: score });

  return (
    <QuizResults
      title='YOUR_ACT_SCORE'
      score={score}
      scoreData={data}
      category={category}
    />
  );
};

export default ActScore;
