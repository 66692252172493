import { makeActionCreator } from '../utilities';

export const INITIALIZE_MED_SELECTION = 'INITIALIZE_MED_SELECTION';
export const RESET_MED_SELECTION = 'RESET_MED_SELECTION';
export const SET_MED_SELECTION = 'SET_MED_SELECTION';
export const SELECT_FORM_FACTOR = 'SELECT_FORM_FACTOR';
export const SELECT_MED_ID = 'SELECT_MED_ID';
export const REMOVE_MED_ID = 'REMOVE_MED_ID';
export const ADD_MEDICATIONS = 'ADD_MEDICATIONS';

export const initializeMedSelection = makeActionCreator(
  INITIALIZE_MED_SELECTION
);
export const resetMedSelection = makeActionCreator(RESET_MED_SELECTION);
export const setMedSelection = makeActionCreator(SET_MED_SELECTION, 'medData');
export const selectFormFactor = makeActionCreator(
  SELECT_FORM_FACTOR,
  'formFactor'
);
export const selectMedication = makeActionCreator(SELECT_MED_ID, 'medId');
export const removeSelectedMed = makeActionCreator(REMOVE_MED_ID, 'medId');
export const setSelectedMeds = makeActionCreator(
  ADD_MEDICATIONS,
  'medications'
);
