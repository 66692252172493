import React, { Component } from 'react';
import BackNextButtons from '../BackNextButtons';

class OptionsSelector extends Component {
  static displayName = 'OptionsSelector';

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.goNext(e);
  }

  render() {
    const { children, content, goBack } = this.props;

    return (
      <form onSubmit={this.onSubmit}>
        {children || content}
        <div className='act-buttons'>
          <BackNextButtons
            nextType='submit'
            nextClick={this.onSubmit}
            backClick={goBack}
          />
        </div>
      </form>
    );
  }
}

export default OptionsSelector;
