import { makeActionCreator } from '../utilities';

export const ADD_ACT_RESPONSE = 'ADD_ACT_RESPONSE';
export const REMOVE_ACT_RESPONSE = 'REMOVE_ACT_RESPONSE';
export const SET_ACT_TYPE = 'SET_ACT_TYPE';
export const CLEAR_ACT = 'CLEAR_ACT';
export const ADD_CAT_RESPONSE = 'ADD_CAT_RESPONSE';
export const REMOVE_CAT_RESPONSE = 'REMOVE_CAT_RESPONSE';
export const CLEAR_CAT = 'CLEAR_CAT';

export const addActResponse = makeActionCreator(ADD_ACT_RESPONSE, 'value');
export const removeActResponse = makeActionCreator(REMOVE_ACT_RESPONSE);
export const setActType = makeActionCreator(SET_ACT_TYPE, 'value');
export const clearACT = makeActionCreator(CLEAR_ACT);
export const addCATResponse = makeActionCreator(ADD_CAT_RESPONSE, 'value');
export const removeCATResponse = makeActionCreator(REMOVE_CAT_RESPONSE);
export const clearCAT = makeActionCreator(CLEAR_CAT);
