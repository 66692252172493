import React from 'react';
import { number } from 'prop-types';

function CircleWarningIcon({ width, height }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30 30">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#A60000"
          fillRule="nonzero"
          d="M15 2.813c6.731 0 12.188 5.456 12.188 12.187 0 6.731-5.457 12.188-12.188 12.188C8.269 27.188 2.812 21.73 2.812 15 2.813 8.269 8.27 2.812 15 2.812zm0 1.874C9.305 4.688 4.687 9.306 4.687 15S9.306 25.313 15 25.313 25.313 20.694 25.313 15 20.694 4.687 15 4.687zm-.01 14.757c.9 0 1.632.731 1.632 1.631 0 .881-.731 1.613-1.631 1.613s-1.613-.732-1.613-1.613c0-.9.713-1.631 1.613-1.631zm1.726-10.238l-.657 9.131h-2.1l-.656-9.13h3.413z"
        />
      </g>
    </svg>
  );
}

CircleWarningIcon.propTypes = {
  width: number,
  height: number
};

CircleWarningIcon.defaultProps = {
  width: 30,
  height: 30
};

export default CircleWarningIcon;
