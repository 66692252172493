import {
  INITIALIZE_MED_SELECTION,
  RESET_MED_SELECTION,
  SET_MED_SELECTION,
  SELECT_FORM_FACTOR,
  SELECT_MED_ID,
} from '../actions';
import formFactors from '../lib/form_factors';

const FF_SORT_ORDER = formFactors.map((f) => f.id);
const formFactorSort = (a, b) =>
  FF_SORT_ORDER.indexOf(a) - FF_SORT_ORDER.indexOf(b);

const addOrRemoveFromArray = (ary, toCheck) => {
  return !ary.includes(toCheck)
    ? ary.concat(toCheck)
    : ary.filter((a) => toCheck !== a);
};

const unique = (v, i, a) => a.indexOf(v) === i;

const initialState = Object.freeze({
  formFactors: [],
  medications: [],
});

export default function medSelection(state = initialState, action) {
  const { medications: currentMeds, formFactors: currentFormFactors } = state;

  switch (action.type) {
    case INITIALIZE_MED_SELECTION:
      return initialState;

    case RESET_MED_SELECTION:
      return initialState;

    case SET_MED_SELECTION:
      const { formFactors = [], medications = [] } = action.medData;
      return {
        formFactors: [].concat(currentFormFactors, formFactors).filter(unique),
        medications: [].concat(currentMeds, medications).filter(unique),
      };

    // should we separate add & remove?
    case SELECT_FORM_FACTOR:
      return {
        ...state,
        formFactors: addOrRemoveFromArray(
          currentFormFactors,
          action.formFactor
        ).sort(formFactorSort),
      };

    // should we separate add & remove?
    case SELECT_MED_ID:
      return {
        ...state,
        medications: addOrRemoveFromArray(currentMeds, action.medId),
      };

    default:
      return state;
  }
}
