// only does simple comparisons
export const matchingArrays = (_ar1 = [], _ar2 = []) => {
  const ar1 = [].concat(_ar1),
    ar2 = [].concat(_ar2);

  if (ar1.length !== ar2.length) {
    return false;
  }

  for (let i = 0; i < ar1.length; i++) {
    if (!ar2.includes(ar1[i])) {
      return false;
    }
  }

  return true;
};

export const arraysOverlap = (ar1 = [], ar2 = []) =>
  ar1.some((a) => ar2.includes(a));
