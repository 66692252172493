import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const _loaded = {};

class ImageLoader extends React.Component {
  state = {
    loaded: _loaded[this.props.src],
  };

  static defaultProps = {
    className: '',
    loadedClassName: 'img-loaded',
    loadingClassName: 'img-loading',
  };

  onLoad = () => {
    _loaded[this.props.src] = true;
    this.setState({ loaded: true });
  };

  render() {
    let {
      alt,
      className,
      loadedClassName,
      loadingClassName,
      src,
      height,
    } = this.props;
    className = `${className} ${
      this.state.loaded ? loadedClassName : loadingClassName
    }`;

    return (
      <img
        alt={alt}
        src={src}
        className={className}
        onLoad={this.onLoad}
        height={height}
      />
    );
  }
}

ImageLoader.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  loadedClassName: PropTypes.string,
  loadingClassName: PropTypes.string,
  src: PropTypes.string,
};

export default ImageLoader;
