import React from 'react';
import PropTypes from 'prop-types';
import MedNames from '../MedNames';
import { publicImg } from '../../../lib/utilities/content';
import { MedType } from '../../../types/medication';
import './sensor-list.scss';

const sensorImg = (path) => publicImg(`sensors/${path}`);

function SensorItem({ sensor, meds, t }) {
  if (sensor === 'mdi') sensor = 'HFA';
  return (
    <li className='sensor-item'>
      <div className='item-image'>
        {meds.length > 1 ? <div className='count'>{meds.length}</div> : null}
        <img
          src={sensorImg(`${sensor.toLowerCase()}_thumb.png`)}
          width='90'
          height='90'
          alt={`sensor for ${sensor} `}
        />
      </div>
      <div className='item-text'>
        <h4 className='ph-body font-bold'>
          {t('SENSORS_FOR')} {sensor}
        </h4>
        <div className='ph-body-bold'>{t('WORKS_WITH')}</div>
        <div>
          <MedNames meds={meds} noAnd={true} t={t} />
        </div>
        <div className='mitigation'>{t('INHALERS_NOT_INCLUDED')}</div>
      </div>
    </li>
  );
}

function SensorList({ meds, t }) {
  const sensors = {};

  for (let i = 0; i < meds.length; i++) {
    let formFactor = meds[i].formFactor;
    if (sensors[formFactor]) {
      sensors[formFactor].push(meds[i]);
    } else {
      sensors[formFactor] = [meds[i]];
    }
  }

  return (
    <ul className='sensor-list unstyled'>
      {Object.keys(sensors).map((sensor) => {
        return (
          <SensorItem
            key={sensor}
            sensor={sensor}
            meds={sensors[sensor]}
            t={t}
          />
        );
      })}
    </ul>
  );
}

export default SensorList;

SensorList.propTypes = {
  meds: PropTypes.arrayOf(PropTypes.shape(MedType)),
  t: PropTypes.func,
};
