import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import container from '../container';
import PageHeading from '../../../components/PageHeading/PageHeading';
import MedDoses from './MedDoses';
import DoseTimes from './DoseTimes';
import { exists, isController } from '../../../lib/utilities';

const DOSES = 'doses';
const TIMES = 'times';

const controllerMedsForPlan = (planMeds, groupMeds) => {
  return planMeds
    .map((med) => {
      return {
        ...med,
        data: groupMeds.find((m) => med.medicationId === m.id),
      };
    })
    .filter((m) => exists(m.data) && isController(m.data));
};

export class MedScheduler extends React.Component {
  constructor(props) {
    super(props);
    this.goNext = this.goNext.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  state = {
    controllerMeds: controllerMedsForPlan(
      this.props.enrollmentMeds,
      this.props.groupConfig.medications || []
    ),
    error: false,
    medIdx: 0,
    stage: DOSES,
  };

  componentDidMount() {
    const { enrollmentMeds, pushHistory } = this.props;
    if (!enrollmentMeds || enrollmentMeds.length < 1) {
      pushHistory('/');
    }
  }

  // handle cases where we're loading meds only off url parameters,
  // and upstream set is not full established at initial render
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { controllerMeds } = this.state;

    if (controllerMeds.length > 0) {
      return;
    } else {
      this.setState({
        controllerMeds: controllerMedsForPlan(
          nextProps.enrollmentMeds,
          nextProps.groupConfig.medications || []
        ),
      });
    }
  }

  goNext(med) {
    /*
     * If we have a usageList with entries and we're defining doses, go to times
     * If we have a usageList with times, update meds and go to the next med
     * or call the outer goNext if there are no more
     */
    const { usageList } = med;

    if (!usageList) {
      return this.setState({ error: true });
    }

    const { setMedSchedule } = this.props;
    const { medIdx, controllerMeds, stage } = this.state;

    const NEEDS_TIME = usageList.length > 0 && stage === DOSES;

    if (!NEEDS_TIME) {
      setMedSchedule(med.medicationId, med.usageList);
      // looking ahead to when we may be configuring more than 1
      if (medIdx === controllerMeds.length - 1) {
        this.props.pushHistory('/enrollment/terms');
      }
    }

    this.setState({
      controllerMeds: Object.assign([], controllerMeds, {
        [medIdx]: { ...med },
      }),
      error: false,
      stage: NEEDS_TIME ? TIMES : DOSES,
      medIdx: NEEDS_TIME ? medIdx : medIdx + 1,
    });
  }

  // fugly
  goBack() {
    const { medIdx, stage } = this.state;
    if (stage === TIMES) {
      this.setState({ stage: DOSES });
    } else if (medIdx === 0) {
      this.props.history.goBack();
    } else {
      this.setState({
        medIdx: medIdx - 1,
        stage: DOSES,
      });
    }
  }

  render() {
    const { controllerMeds, medIdx, stage } = this.state;
    const { location, t } = this.props;
    const med = controllerMeds[medIdx];
    const name = med && med.data && med.data.name;
    const Stage = stage === DOSES ? MedDoses : DoseTimes;

    return (
      <PageHeading pageTitle={t('PAGE_TITLE_SCHEDULE')} t={t}>
        <h1 className='sm-left-lg-center-text'>
          {t('SET_YOUR_SCHEDULE_FOR_MED', { medName: name })}
        </h1>
        {med ? (
          <Stage
            key={med.medicationId}
            error={this.state.error}
            location={location}
            med={med}
            name={name}
            goNext={this.goNext}
            goBack={this.goBack}
            t={t}
          />
        ) : null}
      </PageHeading>
    );
  }
}

MedScheduler.propTypes = {
  enrollmentMeds: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  groupConfig: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  container,
  withTranslation(['enrollment'])
)(MedScheduler);
