import React from 'react';
import { compose } from 'redux';
import container from '../container';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import PageHeading from '../../../components/PageHeading/PageHeading';
import Title from '../../../components/Title/Title';
import DateInput from '../../../components/InputField/DateInput/DateInput';
import EnrollmentButtons from '../Buttons';
import { isIBX, isExpressScripts } from '../../../lib/utilities';
import { isValidBirthDate } from '../../../lib/input_validation';

export class CaregiverBirthdate extends React.Component {
  ibx = isIBX();
  expressScripts = isExpressScripts();

  constructor(props) {
    super(props);
    const caregiver = props.enrollmentData.caregiver;
    this.state = {
      birthDate: caregiver.birthDate || '',
      error: false,
      errorMessage: null,
    };
  }

  componentDidMount = () => {
    if (!this.props.enrollmentData.patient) {
      this.props.pushHistory('/enrollment');
    }
  };

  handleBlur = (data) => {
    this.setState(data);
    this.isValid();
  };

  handleChange = (data) => {
    this.setState({ ...data, error: false });
  };

  setError = () => {
    this.setState({ error: true });
  };

  // set role to patient so that coppa age rule applies
  isValid = () => {
    const validation = isValidBirthDate(
      this.state.birthDate,
      'patient',
      this.ibx || this.expressScripts
    );

    if (validation.valid) {
      return true;
    }

    this.setState({
      error: true,
      errorMessage: validation.errorMessage,
    });
    return false;
  };

  handleBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.isValid()) {
      this.props.updateEnrollmentData({
        caregiver: {
          birthDate: this.state.birthDate,
        },
      });
      this.props.pushHistory('/enrollment/coppa/address');
    }
  };

  render() {
    const { enrollmentData, t } = this.props;

    return (
      <Container>
        <PageHeading pageTitle={t('coppa:PAGE_TITLE_COPPA')}>
          <Title
            text={t('coppa:COPPA_BIRTHDAY_QUESTION')}
            className='sm-left-lg-center-text'
            level={1}
          />
          <div className='max-500 question-explanation'>
            {t('coppa:COPPA_BIRTHDAY_EXPLANATION')}
          </div>
          <Form
            onSubmit={(e) => this.handleSubmit(e)}
            className='max-500 padding-4'
          >
            <DateInput
              autoComplete='bday'
              error={this.state.error}
              errorText={
                this.state.errorMessage || 'enrollment:BIRTHDATE_ERROR'
              }
              name='birthDate'
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              onError={this.setError}
              role={enrollmentData.enrollmentRole}
              t={t}
              value={this.state.birthDate}
            />
            <EnrollmentButtons
              handleBack={this.handleBack}
              handleSubmit={this.handleSubmit}
              t={t}
            />
          </Form>
        </PageHeading>
      </Container>
    );
  }
}

CaregiverBirthdate.propTypes = {
  enrollmentData: PropTypes.object.isRequired,
  pushHistory: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateEnrollmentData: PropTypes.func.isRequired,
};

export default compose(
  withTranslation(['enrollment', 'coppa']),
  container
)(CaregiverBirthdate);
