import { connect } from 'react-redux';
import { setDisease } from '../../actions';
import { enrollmentDisease } from '../../lib/utilities';

const mapStateToProps = ({ enrollmentData, queryParams }) => {
  return {
    needsDisease: !enrollmentDisease(enrollmentData) && !queryParams.version,
  };
};

const mapDispatchToProps = { setDisease };

export default connect(mapStateToProps, mapDispatchToProps);
