import React from 'react';
import Button from './Button';

const WhiteButton = ({ className = '', ...rest }) => {
  return (
    <Button variant='outline' className={`btn-white ${className}`} {...rest} />
  );
};

export default WhiteButton;
