import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form } from 'react-bootstrap';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import container from '../container';
import PageHeading from '../../../components/PageHeading/PageHeading';
import TextInput from '../../../components/InputField/TextInput/TextInput';
import Select from '../../../components/InputField/Select/Select';
import Title from '../../../components/Title/Title';
import PhoneInput from '../../../components/InputField/PhoneInput/PhoneInput';
import { phoneSetter, phoneGetter } from '../../../lib/utilities';
import { validatePhone } from '../../../lib/input_validation';
import { getCountryCode } from '../../../lib/utilities/countryMap';
import { REGION_OPTIONS } from '../../../data/enrollment';
import analytics, { logPage } from '../../../lib/analytics';
import EnrollmentButtons from '../Buttons';
import { isMyAsthma } from '../../../lib/utilities';
import { getNextPage } from '../enrollment-utils';

export class Address extends React.Component {
  constructor(props) {
    super(props);
    const { enrollmentData, groupConfig, groupConfig4x } = props;
    const address = enrollmentData.mailingAddress;
    let hubOnly;
    if(groupConfig4x !== undefined){
      hubOnly = groupConfig4x?.parameters?.hubOnly?.value;
    }
    this.state = {
      street: address.street || '',
      street2: address.street2 || '',
      city: address.city || '',
      stateOrRegion: address.stateOrRegion || '',
      postalCode: address.postalCode || '',
      phoneNumber:
        phoneGetter(
          enrollmentData.phone,
          getCountryCode(enrollmentData.phoneCountry || groupConfig.country)
        ) || '',
      phoneCountry: enrollmentData.phoneCountry || groupConfig.country,
      errors: [],
      phoneErrorMessage: '',
      phoneRequired: hubOnly ? true : false,
    };
  }


  componentDidMount() {
    if (!this.props.enrollmentMeds) {
      this.props.pushHistory('/med-selection');
    } else {
      this.sendAnalytics();
    }
  }

  sendAnalytics = () => {
    logPage('Enroll-Address');
    if (isMyAsthma()) {
      analytics.fbqMyasthma({
        name: 'InitiateCheckout',
        properties: {
          content_name: 'Form Reaching - 75%',
        },
      });
    }
  };

  setErrors = (errors) => {
    this.setState(() => {
      return { errors: errors };
    });
  };

  checkForErrors = () => {
    return [
      'street',
      'city',
      'stateOrRegion',
      'postalCode',
      'street2',
      'phoneNumber',
    ]
      .map((item) => {
        if (item === 'street') {
          return this.state[item].length >= 1 && this.state[item].length <= 50
            ? null
            : item;
        }
        if (item === 'street2') {
          return this.state[item].length <= 50 ? null : item;
        }
        if (item === 'phoneNumber') {
          const { phoneError, errorMessage } = validatePhone(
            this.state.phoneNumber,
            this.state.phoneCountry,
            this.state.phoneRequired,
          );
          this.setState(() => ({ phoneErrorMessage: errorMessage }));
          return this.state.errors.includes(item) || phoneError ? item : null;
        } else {
          return this.state[item].length >= 1 ? null : item;
        }
      })
      .filter((err) => err !== null);
  };

  isValid = () => {
    // check that all required fields have content
    const errors = this.checkForErrors();
    if (errors.length || this.state.errors.length) {
      this.setErrors(errors);
      return false;
    }
    return true;
  };

  handlePhoneCountryBlur = (country) => {
    this.setState({ phoneCountry: country });
  };

  handlePhoneBlur = (value, hasError) => {
    if (hasError) {
      this.setState((prev) => {
        return { errors: [...prev.errors, 'phoneNumber'] };
      });
    } else {
      this.setState({ phoneNumber: value });
    }
  };

  handleChange = (data) => {
    const key = Object.keys(data)[0];
    const errors = this.state.errors.filter((error) => error !== key);
    this.setState(data);
    this.setErrors(errors);
  };

  handleBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.isValid()) {
      this.props.updateEnrollmentData({
        mailingAddress: {
          street: this.state.street,
          street2: this.state.street2,
          city: this.state.city,
          stateCode: this.state.stateCode,
          stateOrRegion: this.state.stateOrRegion || this.state.stateCode,
          postalCode: this.state.postalCode,
        },
        phone: phoneSetter(
          this.state.phoneNumber,
          getCountryCode(this.state.phoneCountry)
        ),
        phoneCountry: this.state.phoneCountry,
      });
      const next = getNextPage({
        device: this.props.device,
        currentPage: 'address',
        enrollmentData: this.props.enrollmentData,
        enrollmentMeds: this.props.enrollmentMeds,
        groupConfig: this.props.groupConfig,
      });
      this.props.pushHistory(next);
    }
  };

  render() {
    const { enrollmentData, groupConfig, t } = this.props;
    const { givenName } = enrollmentData.patient;
    const country = groupConfig.country;
    const options = REGION_OPTIONS[country];
    const stateLabel = country === 'CA' ? 'STATE_CA_LABEL' : 'STATE_LABEL';
    const addressTitle =
      enrollmentData.enrollmentRole === 'caregiver'
        ? t('SHIPPING_ADDRESS_QUESTION_CAREGIVER', { givenName })
        : t('SHIPPING_ADDRESS_QUESTION_PATIENT');

    return (
      <PageHeading pageTitle={t('PAGE_TITLE_ADDRESS')}>
        <Title
          text={addressTitle}
          className='sm-left-lg-center-text'
          level={1}
        />
        <Row>
          <Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <Form onSubmit={(e) => this.handleSubmit(e)}>
              <TextInput
                autoComplete='shipping address-line1'
                error={this.state.errors.includes('street')}
                errorMessage='STREET_ADDRESS_ERROR'
                helpText='MAX_LENGTH'
                helpTextHidden={true}
                label='STREET_ADDRESS_1_LABEL'
                name='street'
                onChange={this.handleChange}
                required={true}
                setErrors={this.setErrors}
                t={t}
                value={this.state.street}
              />
              <TextInput
                autoComplete='shipping address-line2'
                errorMessage='STREET_ADDRESS_TOO_LONG_ERROR'
                helpText='MAX_LENGTH'
                helpTextHidden={true}
                label='STREET_ADDRESS_2_LABEL'
                name='street2'
                onChange={this.handleChange}
                setErrors={this.setErrors}
                t={t}
                value={this.state.street2}
              />
              <TextInput
                autoComplete='shipping address-level2'
                error={this.state.errors.includes('city')}
                errorMessage='CITY_ERROR'
                helpText='MAX_LENGTH'
                helpTextHidden={true}
                label={country === 'US' ? t('CITY_LABEL') : t('CITY_CA_LABEL')}
                maxLength={40}
                name='city'
                onChange={this.handleChange}
                required={true}
                t={t}
                value={this.state.city}
                warningText='CITY_TOO_LONG'
              />
              <Row>
                <Col xs={6}>
                  <Select
                    autoComplete='shipping address-level1'
                    error={this.state.errors.includes('stateOrRegion')}
                    label={t(stateLabel)}
                    maxLength={80}
                    name='stateOrRegion'
                    onChange={this.handleChange}
                    options={options}
                    required={true}
                    t={t}
                    value={this.state.stateOrRegion}
                  />
                </Col>
                <Col xs={6}>
                  <TextInput
                    autoComplete='shipping postal-code'
                    error={this.state.errors.includes('postalCode')}
                    errorMessage='POSTAL_CODE_ERROR'
                    helpText='MAX_LENGTH'
                    helpTextHidden={true}
                    label={t('POSTAL_CODE_LABEL')}
                    maxLength={20}
                    name='postalCode'
                    onChange={this.handleChange}
                    required={true}
                    t={t}
                    value={this.state.postalCode}
                    warningText='POSTAL_CODE_TOO_LONG'
                  />
                </Col>
              </Row>
              <PhoneInput
                button={false}
                error={this.state.errors.includes('phoneNumber')}
                errorText={this.state.phoneErrorMessage}
                label='PHONE_LABEL'
                onBlur={this.handlePhoneBlur}
                onChange={this.handleChange}
                handlePhoneCountryBlur={this.handlePhoneCountryBlur}
                phoneCountry={this.state.phoneCountry}
                phoneRequired={this.state.phoneRequired}
                t={t}
                value={this.state.phoneNumber}
              />
              <EnrollmentButtons
                handleBack={this.handleBack}
                handleSubmit={this.handleSubmit}
                t={t}
              />
            </Form>
          </Col>
        </Row>
      </PageHeading>
    );
  }
}

Address.propTypes = {
  enrollmentData: PropTypes.object.isRequired,
  enrollmentMeds: PropTypes.array,
  groupConfig: PropTypes.object.isRequired,
  groupConfig4x: PropTypes.object.isRequired,
  pushHistory: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateEnrollmentData: PropTypes.func.isRequired,
};

export default compose(withTranslation('enrollment'), container)(Address);
