import {ObjectId} from 'bson';
const { RESET_OBJECT_ID } = require('../actions');

const INITIAL_OBJECT_ID = new ObjectId().toString();

export default function mongoObjectId(state = INITIAL_OBJECT_ID, action) {
  switch (action.type) {
    case RESET_OBJECT_ID:
      return null;
    default:
      return state;
  }
}
