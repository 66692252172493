import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import PageHeading from '../../components/PageHeading/PageHeading';
import container from './container';
import Title from '../../components/Title/Title';
import './styles.scss';

import { logPage } from '../../lib/analytics';
import { compose } from 'redux';

class NoneCompatible extends Component {

  state = {
    emailSent: false,
    error: false,
  };

  componentDidMount() {
    logPage('MedSelect-NoneCompatible');
  }

  afterSubmit(response) {
    if (response === 'success') {
      this.setState(() => ({ emailSent: true, error: false }));
    }
  }

  render() {
    const { t } = this.props;

    return (
      <Container className='none-compatible'>
        <Row>
          <Col xs={12} md={{ span: 10, offset: 1 }}>
            <PageHeading pageTitle={t('PAGE_TITLE_NO_SENSORS')} t={t}>
              <Title text={t('NO_COMPATIBLE_MEDICATIONS')} level={1}></Title>
              <Row className='content-row'>
                <Col xs={12} className='sm-left-lg-center-text'>
                  <div className='primary-text break-words'>
                    <p>{t('RAPIDLY_DEVELOPING_NEW_SENSORS')}</p>
                  </div>
                </Col>
              </Row>
            </PageHeading>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default compose(
  container,
  withTranslation(['medSelection', 'enrollment'])
)(NoneCompatible);
