import { passThrough } from '../../lib/utilities';
import { getGroupConfig, getGroupConfig4x } from '../../api/group';
import { makeActionCreator } from '../utilities';
import { setError } from '../index';

export const SET_GROUP_CONFIG4X = 'SET_GROUP_CONFIG4X';
export const SET_GROUP_ERROR4X = 'SET_GROUP_ERROR4X'
export const SET_GROUP_CONFIG = 'SET_GROUP_CONFIG';
export const SET_GROUP_ERROR = 'SET_GROUP_ERROR';
export const UPDATE_GROUP_CONTENT = 'UPDATE_GROUP_CONTENT';

export const setGroupConfig = makeActionCreator(SET_GROUP_CONFIG, 'data');
export const setGroupError = makeActionCreator(SET_GROUP_ERROR, 'error');
export const setGroupConfig4x = makeActionCreator(SET_GROUP_CONFIG4X, 'data');
export const setGroupError4x = makeActionCreator(SET_GROUP_ERROR4X, 'data');
const _updateGroupContent = makeActionCreator(UPDATE_GROUP_CONTENT, 'data');

export function loadGroupConfig(id) {
  return (dispatch) => {
    return getGroupConfig(id)
      .then(passThrough, () => getGroupConfig('try'))
      .then((data) => {
        return dispatch(setGroupConfig(data));
      })
      .catch((err) => {
        dispatch(setGroupError(new Error(err.statusText)));
        dispatch(setError(err));
      });
  };
}

export function loadGroupConfig4x() {
  return (dispatch) => {
    return getGroupConfig4x()
      .then(passThrough, () => getGroupConfig4x())
      .then((data) => {
        return dispatch(setGroupConfig4x(data));
      })
      .catch((err) => {
        dispatch(setGroupError4x(new Error(err.statusText)));
        dispatch(setError(err));
      });
  };
}

export function updateGroupContent() {
  return function (dispatch, getState) {
    const state = getState();
    const { groupConfig } = state;
    if (groupConfig.name) {
      return getGroupConfig(groupConfig.name)
        .then((data) => dispatch(_updateGroupContent(data)))
        .catch((err) => dispatch(setError(err)));
    }
  };
}
