import { connect } from 'react-redux';
import {
  push as pushHistory,
  goBack as goBackHistory,
} from 'connected-react-router';
import {
  resetObjectId,
  setConfirmedPharmaProgram,
  setError,
  setUserId,
  updateEnrollmentData,
  resetEnrollmentData,
} from '../../../actions';
import { getBoolean, enrollmentDisease } from '../../../lib/utilities';

const mapStateToProps = (state) => {
  const {
    groupConfig,
    enrollmentData,
    enrollmentMeds,
    mongoObjectId,
    router,
    queryParams,
    asthmaControlTest,
    copdAssessmentTest,
    pharmaProgram,
    pharmaConfig,
  } = state;
  const isIFrame = queryParams ? getBoolean(queryParams.iframe) : false;
  const isCOPD = enrollmentDisease(enrollmentData) === 'copd';

  return {
    groupConfig,
    enrollmentData,
    enrollmentMeds,
    mongoObjectId,
    location: router.location,
    isIFrame,
    pharmaProgram,
    pharmaConfig,
    quiz: {
      type: isCOPD ? 'cat' : 'act',
      data: isCOPD ? copdAssessmentTest : asthmaControlTest,
    },
  };
};

const mapDispatchToProps = {
  resetObjectId,
  setError,
  setUserId,
  updateEnrollmentData,
  resetEnrollmentData,
  pushHistory,
  goBackHistory,
  setConfirmedPharmaProgram,
};

export default connect(mapStateToProps, mapDispatchToProps);
