const STYLES = {
  blue: '#0075E3',
  green: '#15D4A1',
  lightGreen: '#93CB4D',
  lightestGreen: '#BADD8D',
  inputColor: '#4D4D4D',
  muted: '#BFD1D1',
  white: '#FFF',
  grey: '#54565A',
  red: '#B72755',
};

export default STYLES;
