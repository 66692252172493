import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';

import GreyButton from '../../components/Button/GreyButton';
import PageHeading from '../../components/PageHeading/PageHeading';
import Title from '../../components/Title/Title';
import FeelFreeToContact from '../../components/FeelFreeToContact';
import './style.scss';
import { logPage } from '../../lib/analytics';

const NoSmartphone = ({ history, t }) => {
  useEffect(() => {
    logPage('Enroll-NoSmartphone');
  });
  return (
    <div className='no-smartphone'>
      <PageHeading pageTitle={t('PAGE_TITLE_NO_SMARTPHONE')} t={t}>
        <Title text={t('THANKS_FOR_INTEREST')} level={1} className='sm-left-lg-center-text' />
        <Row className='no-smartphone content-row sm-left-lg-center-text'>
          <Col xs={12}>
            <div className='primary-text'>
              <p>{t('PROPELLER_SENSORS_REQUIRE_BLUETOOTH')}</p>
              <div className='center-text padding-4'>
                <GreyButton block onClick={() => history.goBack()}>
                  {t('common:GO_BACK')}
                </GreyButton>
              </div>
              <FeelFreeToContact />
            </div>
          </Col>
        </Row>
      </PageHeading>
    </div>
  );
};

export default withTranslation(['medSelection', 'common'])(NoSmartphone);
