export function updateChatGreeting(updatedLang) {
  // text and translations in locales sfChat.json for reference
  switch (updatedLang) {
    case 'es':
    case 'es-US':
      return {
        chatText: 'Chat',
        supportText: 'Atención al cliente',
        screenreaderText:
          'Abre un widget con el cual puede chatear con el equipo de atención al cliente',
        loadingText: 'Cargando…',
      };
    case 'fr':
    case 'fr-CA':
      return {
        chatText: 'Clavardage',
        supportText: 'Soutien technique',
        screenreaderText:
          "Ouvre un gadget logiciel grâce auquel vous pouvez clavarder avec l'équipe de soutien technique",
        loadingText: 'Chargement en cours…',
      };
    case 'en':
    case 'en-US':
    case 'en-CA':
    default:
      return {
        chatText: 'Chat',
        supportText: 'Support',
        screenreaderText:
          'Opens a widget where you can chat with the Support Team',
        loadingText: 'Loading…',
      };
  }
}

export const chatInit = function (locale) {
  // chat widgets should not show on mobile devices
  const ua = navigator.userAgent.toLowerCase();
  const platform = navigator.platform.toLowerCase();
  const platformName = ua.match(/ip(?:ad|od|hone)/)
    ? 'ios'
    : (ua.match(/(?:webos|android)/) ||
        platform.match(/mac|win|linux/) || ['other'])[0];
  const isMobile = /ios|android|webos/.test(platformName);

  var initESW = function (languageSelection, gslbBaseURL) {
    const {
      chatText,
      screenreaderText,
      supportText,
      loadingText,
    } = updateChatGreeting(languageSelection);

    // SF doesn't update assistive text with language selection changes, so we will
    try {
      let assistiveElem = document
        .getElementsByClassName('embeddedServiceHelpButton')[0]
        .getElementsByClassName('helpButton')[0]
        .getElementsByClassName('assistiveText')[0];
      assistiveElem.innerText = screenreaderText;
    } catch (e) {
      // element does not exist yet (first load); ignore
    }
    window.embedded_svc.settings.displayHelpButton = true;
    window.embedded_svc.settings.language = languageSelection;
    window.embedded_svc.settings.defaultMinimizedText = chatText;
    window.embedded_svc.settings.offlineSupportMinimizedText = supportText;
    window.embedded_svc.settings.defaultAssistiveText = screenreaderText;
    window.embedded_svc.settings.loadingText = loadingText;
    //extraPrechatFormDetails allows us to send CX more information and add information to the chat transcript
    window.embedded_svc.settings.extraPrechatFormDetails = [
      {
        label: 'First Name',
        displayToAgent: true,
      },
      {
        label: 'Last Name',
        displayToAgent: true,
      },
      {
        label: 'Email',
        displayToAgent: true,
      },
      {
        label: 'AMPLS_ID__c',
        displayToAgent: true,
      },
      {
        label: 'Primary Email',
        transcriptFields: ['Contact_Email__c'],
      },
    ];

    // extraPrechatInfo maps the pre-chat form details from the
    // extraPrechatFormDetails parameter to fields in existing or new records
    window.embedded_svc.settings.extraPrechatInfo = [
      {
        entityFieldMaps: [
          {
            doFind: true,
            isExactMatch: true,
            doCreate: true,
            fieldName: 'FirstName',
            label: 'First Name',
          },
          {
            doFind: true,
            isExactMatch: true,
            doCreate: true,
            fieldName: 'LastName',
            label: 'Last Name',
          },
          {
            doFind: true,
            isExactMatch: true,
            doCreate: true,
            fieldName: 'Email',
            label: 'Email',
          },
        ],
        entityName: 'Contact',
        showOnCreate: false,
      },
    ];
    window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    window.embedded_svc.settings.entryFeature = 'LiveAgent';
    window.embedded_svc.init(
      'https://propellerhealth.my.salesforce.com',
      'https://asthmapolis.secure.force.com/embedded',
      gslbBaseURL,
      '00DU0000000YL5g', //org ID
      'Embedded_Chat',
      {
        baseLiveAgentContentURL:
          'https://c.la2-c2-phx.salesforceliveagent.com/content',
        deploymentId: '5720P0000000A2A',
        buttonId: '5730P0000000Arx',
        baseLiveAgentURL: 'https://d.la2-c2-phx.salesforceliveagent.com/chat',
        eswLiveAgentDevName:
          'EmbeddedServiceLiveAgent_Parent04I0P0000008RDuUAM_1717de89793',
        isOfflineSupportEnabled: true,
      }
    );
  };

  if (!window.embedded_svc && !isMobile) {
    var s = document.createElement('script');
    s.setAttribute(
      'src',
      'https://propellerhealth.my.salesforce.com/embeddedservice/5.0/esw.min.js'
    );
    s.setAttribute('id', 'sfChatId');
    s.onload = function () {
      initESW(locale);
    };
    document.body.appendChild(s);
  } else if (!isMobile) {
    initESW(locale, 'https://service.force.com');
  }
};
