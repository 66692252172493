import React from 'react';
import { Trans } from 'react-i18next';
import Col from 'react-bootstrap/Col';
import GaLink from '../GaLink';
import { withTranslation } from 'react-i18next';

const FeelFreeToContact = () => {
  return (
    <Col xs={12} className='contact-us sm-left-lg-center-text'>
      <Trans i18nKey='medSelection:FEEL_FREE_CONTACT_US'>
        Feel free to{' '}
        <GaLink
          className='btn-link'
          href='mailto:help@propellerhealth.com'
          action='enrollment-noSmartDevice-contactSupport'
        >
          contact us
        </GaLink>{' '}
        with any questions.
      </Trans>
    </Col>
  );
};

export default withTranslation('medSelection')(FeelFreeToContact);
