import React, { Component } from 'react';
import { compose } from 'redux';
import container from '../container';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';

import Loading from '../../../components/Loading';
import WhiteButton from '../../../components/Button/WhiteButton';

import PageHeading from '../../../components/PageHeading/PageHeading';
import Title from '../../../components/Title/Title';
import EnrollmentButtons from '../Buttons';
import { logPage } from '../../../lib/analytics';
import {
  privacyPolicyURI,
  extractText,
  COPPA_AGE,
  publicImg,
} from '../../../lib/utilities';

const caregiver = publicImg('caretaker1.png');

class CoppaIntro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      policyVisible: false,
      policy: undefined,
    };
  }

  componentDidMount() {
    const { enrollmentData } = this.props;
    if (
      !enrollmentData.enrollmentRole ||
      enrollmentData.enrollmentRole !== 'caregiver'
    ) {
      this.props.pushHistory('/enrollment');
    }

    logPage('Coppa-Intro');
  }

  showModal(bool) {
    this.setState((props, state) => {
      bool = typeof bool === 'undefined' ? !state.toggleModal : bool;
      return {
        policyVisible: bool,
      };
    });
  }

  loadPolicy() {
    const { i18n: { language }} = this.props;
    window
      .fetch(privacyPolicyURI(language))
      .then(extractText)
      .then((html) => this.setState({ policy: html }))
      .catch((err) => {
        this.props.setError(err);
      });
  }

  handleBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.pushHistory('/enrollment/coppa/birthdate');
  };

  render() {
    const { enrollmentData, t } = this.props;
    const patient = enrollmentData.patient;
    return (
      <Container>
        <PageHeading pageTitle={t('coppa:PAGE_TITLE_COPPA')} t={t}>
          <Row>
            <Col xs={12}>
              <Title
                text={t('coppa:WE_NEED_TO_VERIFY_YOU_AS_PARENT', { patient })}
                level={1}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className='text-center'>
              <img src={caregiver} alt={t('coppa:PARENT')} height='180' />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className='question-explanation'>
              <p>{t('coppa:COPPA_EXPLANATION', { age: COPPA_AGE })}</p>
              <p className='text-center'>
                <WhiteButton
                  onClick={() => this.showModal(true)}
                  style={{ whiteSpace: 'normal', textDecoration: 'underline', color: '#00799d', cursor: 'pointer' }}
                >
                  {t('coppa:PROPELLER_COPPA_PRIVACY_POLICY')}
                </WhiteButton>
              </p>
            </Col>
          </Row>
            <EnrollmentButtons
              handleBack={this.handleBack}
              handleSubmit={this.handleSubmit}
              t={t}
            />

          <Modal
            show={this.state.policyVisible}
            onHide={() => this.showModal(false)}
            size='lg'
            onEnter={() => this.loadPolicy()}
            dialogClassName='privacy-policy-modal'
          >
            <Modal.Header closeButton />
            <Modal.Body>
              {!this.state.policy ? (
                <Loading />
              ) : (
                <div dangerouslySetInnerHTML={{ __html: this.state.policy }} />
              )}
            </Modal.Body>
          </Modal>
        </PageHeading>
      </Container>
    );
  }
}
CoppaIntro.propTypes = {
  enrollmentData: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withTranslation('coppa'), container)(CoppaIntro);
