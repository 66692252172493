import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import GreenButton from '../Button/GreenButton';
import GreyButton from '../Button/GreyButton';
import { loadTermsHTML } from '../../lib/utilities';
import { withTranslation } from 'react-i18next';

// this will be rendered inside other pages.
// we want this to be agnostic, so the hosting page
// will provide a url to fetch and actions for the buttons
class TermsOfUse extends React.Component {
  state = {
    text: null,
  };

  // these shouldn't trigger a rerender
  // these
  termsPath = null;

  loadAndSetTerms() {
    const { termsPath, setError = console.error } = this.props;

    this.termsPath = termsPath;

    loadTermsHTML(termsPath)
      .then((terms) => {
        // did something change what we expect before our promise resolved?
        if (this.termsPath === termsPath) {
          this.setState({
            text: terms,
          });
        }
      })
      .catch((err) => setError(err));
  }

  componentDidMount() {
    this.loadAndSetTerms();
  }

  componentDidUpdate() {
    const { termsPath } = this.props;

    if (termsPath !== this.termsPath) {
      this.loadAndSetTerms();
    }
  }

  // we should permit style insertion points
  render() {
    const {
      cancelFunc,
      nextFunc,
      submitting,
      submitText,
      containerStyle,
      contentStyle,
      t,
    } = this.props;

    const { text } = this.state;

    return (
      <>
        <Row className='terms-of-use-container'>
          <Col xs={12}>
            <h1 className='text-center'>
              {t('enrollment:TERMS_AND_CONDITIONS')}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={{ span: 10, offset: 1 }}>
            <Row style={containerStyle}>
              <Col
                xs={12}
                className='user-agreement overflow-scrolling-touch'
                style={contentStyle}
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </Row>
            <Row className='max-500'>
              <Col xs={6}>
                <GreyButton
                  onClick={cancelFunc}
                  disabled={submitting}
                  style={{ width: '100%' }}
                >
                  {t('common:CANCEL')}
                </GreyButton>
              </Col>
              <Col xs={6}>
                <GreenButton
                  onClick={nextFunc}
                  disabled={!text || submitting}
                  style={{ width: '100%' }}
                >
                  {t(submitText)}
                </GreenButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

TermsOfUse.propTypes = {
  cancelFunc: PropTypes.func.isRequired,
  nextFunc: PropTypes.func.isRequired,
  submiting: PropTypes.bool,
  submitText: PropTypes.string,
  containerStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  termsPath: PropTypes.string,
};

TermsOfUse.defaultProps = {
  submitText: 'enrollment:I_AGREE',
  containerStyle: {},
  contentStyle: {},
};

export default withTranslation(['enrollment', 'common'])(TermsOfUse);
