import React from 'react';
import PropTypes from 'prop-types';
import Question from './Question';
import { logEvent } from '../../lib/analytics';

class MobileFAQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openIndex: -1,
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick(index) {
    if (index === this.state.openIndex) {
      this.setState({ openIndex: -1 });
    } else {
      this.setState({ openIndex: index });
      logEvent('click', {
        action: `click-FAQ-${index + 1}`,
        label: 'FAQ',
      });
    }
  }

  render() {
    const { questions } = this.props;

    return (
      <div>
        {questions.map((faq, index) => {
          return (
            <div key={index}>
              <div className='subtitle'>
                <Question
                  index={index}
                  text={faq.question}
                  answer={faq.answer}
                  icon={faq.icon}
                  onClick={this.onClick}
                  open={this.state.openIndex === index}
                  type='mobile'
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

MobileFAQ.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
      icon: PropTypes.string,
    })
  ).isRequired,
};

export default MobileFAQ;
