import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import PageHeading from '../../components/PageHeading/PageHeading';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import GaLink from '../GaLink';
import GreenButton from '../Button/GreenButton';
import GreyButton from '../Button/GreyButton';
import { getSupportPhone } from '../../lib/support-phone';
import { openTime, closeTime } from '../../lib/utilities/locales.js';
import container from './container';
import Title from '../Title/Title';

export const SupportContact = ({
  supportEmail,
  goBack,
  groupConfig = {},
  img = {},
  t,
}) => {
  const supportPhone = getSupportPhone(groupConfig.country).phone;
  const phoneHref = `tel:${supportPhone}`;
  const emailHref = `mailto:${supportEmail}`;
  const { alt, ...imgRest } = img || '';
  const timezone = getSupportPhone(groupConfig.country).timezone;

  return (
    <div className='support-wrapper'>
    <PageHeading pageTitle={t('medSelection:PAGE_TITLE_SUPPORT')} t={t}>
      {imgRest && imgRest.src && (
        <Row>
          <Col xs={12} className='text-center'>
            <img {...imgRest} alt={alt} />
          </Col>
        </Row>
      )}
      {/* TODO: show conditionally - this component is both a page (with no heading), 
          or rendered in another page; will need heading if rendered on its own
      <Row>
        <Title className='sm-left-lg-center-text' level={1} text={t('SUPPORT')} />
      </Row> */}
      <Row className='sm-left-lg-center-text'>
        <Col xs={12}>
          <Title className='break-words' level={2} text={t('common:QUESTIONS_WE_ARE_HERE_TO_HELP')} />
          <p>
            {t('common:CALL')}: <a href={phoneHref}>{supportPhone}</a>
            <br />
            {t('common:EMAIL')}: <a href={emailHref}>{supportEmail}</a>
            <br />
            {t('common:MONDAY_FRIDAY')} {openTime(groupConfig.language)} -{' '}
            {closeTime(groupConfig.language)} {t(`faq:${timezone}`)}
          </p>
        </Col>

        <Col xs={12} md={{ span:4, offset:4 }}>
          <GreenButton
            as={GaLink}
            action='medSelecton-callHelp'
            block
            href={phoneHref}
          >
            {t('common:CALL')}
          </GreenButton>
          <GreyButton
            as={GaLink}
            action='medSelecton-emailHelp'
            block
            href={emailHref}
          >
            {t('common:EMAIL')}
          </GreyButton>
        </Col>
      </Row>

      {goBack && (
        <Row className='padding-2'>
        <Col xs={12} md={{ span:4, offset:4 }}>
          <GreyButton block onClick={goBack}>
            {t('common:BACK')}
          </GreyButton>
        </Col>
        </Row>
      )}
    </PageHeading>
    </div>
  );
};

SupportContact.propTypes = {
  supportEmail: PropTypes.string.isRequired,
  goBack: PropTypes.func,
  img: PropTypes.object,
  groupConfig:PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withTranslation(['common', 'faq', 'medSelection']),
  container
)(SupportContact);
