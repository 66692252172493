import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push as pushHistory } from 'connected-react-router';
import { useHistory } from 'react-router-dom';
import Title from '../../../components/Title/Title';
import PageHeading from '../../../components/PageHeading/PageHeading';
import EnrollmentButtons from '../Buttons';
import { logPage } from '../../../lib/analytics';
import { getNextPage } from '../enrollment-utils';
import { updateEnrollmentData } from '../../../actions';
import demographicData from './demographicData';

const mapStateToProps = (state) => {
  const { device, enrollmentData, enrollmentMeds, groupConfig, groupConfig4x } = state;
  return {
    device,
    enrollmentData,
    enrollmentMeds,
    groupConfig,
    groupConfig4x,
  };
};

const mapDispatchToProps = {
  pushHistory,
  updateEnrollmentData,
};

export function Demographics({
  device,
  enrollmentData,
  enrollmentMeds,
  groupConfig,
  pushHistory,
  updateEnrollmentData,
  groupConfig4x,
}) {
  const [demographics, setDemographics] = useState({
    gender: enrollmentData?.patient?.gender || null,
    hispanic: enrollmentData?.patient?.hispanic || null,
    race: enrollmentData?.patient?.race || null,
  });


  const [valid, setValid] = useState(true);

  const { t } = useTranslation('enrollment');
  const history = useHistory();

  useEffect(() => {
    if (enrollmentMeds.length < 1) {
      pushHistory('/med-selection');
    }
  }, [enrollmentMeds, pushHistory]);

  useEffect(() => {
    logPage('Enroll-Gender');
  }, []);

  function handleBack(e) {
    e.preventDefault();
    history.goBack();
  }

  function demographicRequired(item) {
    const questions = groupConfig.demographics;
    return questions.includes(item);
  }

  function isValid() {
    for (const key in demographics) {
      if (demographicRequired(key) && !demographics[key]) {
        return false;
      }
    }
    return true;
  }

  function removeNulls() {
    const cleanDemographics = {};
    for (const key in demographics) {
      if (typeof demographics[key] === 'string') {
        cleanDemographics[key] = demographics[key];
      }
    }
    return cleanDemographics;
  }

  function handleSubmit(e) {
    e.preventDefault();
    setValid(true);

    if (!isValid()) {
      setValid(false);
      return;
    }
    
    const next = getNextPage({
      currentPage: 'demographics',
      device,
      enrollmentData,
      groupConfig4x,
    });

    const cleanDemographics = removeNulls();
    updateEnrollmentData({ patient: cleanDemographics });
    pushHistory(next);
  }

  const patient = enrollmentData.patient;

  return (
    <PageHeading pageTitle={t('PAGE_TITLE_DEMOGRAPHICS')} t={t}>
      <Row>
        <Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
          <Form onSubmit={(e) => handleSubmit(e)}>
            {groupConfig?.demographics?.map((type) => (
              <Form.Group role='radiogroup' key={type}>
                <Title
                  text={t(demographicData[type]?.title, {
                    patient,
                  })}
                  className='sm-left-lg-center-text'
                  level={2}
                />
                <div className='radio-selectors'>
                  {demographicData[type]?.options?.map((option) => (
                    <Form.Check key={`${type}-${option.value}`}>
                      <Form.Check.Input
                        className='custom-radio'
                        checked={demographics[type] === option.value}
                        type='radio'
                        name={type}
                        value={option.value}
                        id={`${type}-${option.value}`}
                        onChange={() => {
                          setDemographics({
                            ...demographics,
                            [type]: option.value,
                          });
                        }}
                      />
                      <Form.Check.Label htmlFor={`${type}-${option.value}`}>
                        {t(option.label)}
                      </Form.Check.Label>
                    </Form.Check>
                  ))}
                </div>
              </Form.Group>
            ))}
            {!valid ? (
              <div
                className='error-message-center'
                role='alert'
                aria-live='assertive'
              >
                {t('SELECT_OPTION')}
              </div>
            ) : null}
            <EnrollmentButtons
              handleBack={handleBack}
              handleSubmit={handleSubmit}
              t={t}
            />
          </Form>
        </Col>
      </Row>
    </PageHeading>
  );
}

Demographics.propTypes = {
  device: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  enrollmentData: PropTypes.object.isRequired,
  enrollmentMeds: PropTypes.array.isRequired,
  pushHistory: PropTypes.func.isRequired,
  updateEnrollmentData: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Demographics);
