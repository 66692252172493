import { API_HOST, apiHeaders, fetchJSON } from '../../lib/utilities';

import { makeActionCreator } from '../utilities';

import { setError } from '../index';

const _loadSession = () => {
  return fetchJSON(`${API_HOST}/api/users/me`, {
    method: 'GET',
    headers: apiHeaders(),
    credentials: 'include',
  });
};

export const SET_SESSION = 'SET_SESSION';
export const CLEAR_SESSION = 'CLEAR_SESSION';
export const SHOW_SIGNIN = 'SHOW_SIGNING';

export const setSession = makeActionCreator(SET_SESSION, 'session');
export const clearSession = makeActionCreator(CLEAR_SESSION);
export const showSignIn = makeActionCreator(SHOW_SIGNIN, 'show');

export const loadSession = function loadSession() {
  return (dispatch) => {
    return _loadSession()
      .then((data) => dispatch(setSession(data)))
      .catch((err) => dispatch(setError(err)));
  };
};

export const loadSessionOrSignIn = function loadSessionOrSignIn() {
  return (dispatch) => {
    return _loadSession()
      .then((data) => dispatch(setSession(data)))
      .catch((err) => {
        dispatch(setError(err));
        dispatch(showSignIn(true));
      });
  };
};
