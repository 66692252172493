import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import container from '../container';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Title from '../../../components/Title/Title';
import LanguageSelection from '../../../components/LanguageSelection';
import ResponsiveBackgroundImg from '../../../components/ResponsiveBackgroundImg';
import { SUBDOMAIN, isNonCoveredEntity } from '../../../lib/utilities';
import GetStartedLink from '../GetStartedLink';
import './headline.scss';

const asthmaHero =
  'https://images.propellerhealth.biz/images/wse/BX099_Asthma.png?fit=cover&w=1920&h=800&crop=entropy';
const copdHero = 'https://images.propellerhealth.biz/images/wse/BX099_COPD.png';

export const Headline = ({ disease, i18n, isIFrame, t }) => {
  const { language } = i18n;
  const bccmi = SUBDOMAIN === 'bccmi';
  const heroImg = disease === 'copd' ? copdHero : asthmaHero;
  const displayDisease = disease === 'copd' ? 'COPD' : 'asthma';

  return (
    <div className={`headline ${isIFrame ? 'iframe' : ''}`}>
      <ResponsiveBackgroundImg src={heroImg} locale={language}>
        {isIFrame ? (
          <div className='iframe-language-selection'>
            <nav className='container-language-select'>
              <div className='locale-selector'>
                <LanguageSelection />
              </div>
            </nav>
          </div>
        ) : null}
        <Container className='main'>
          <Row>
            <Col xs={12} md={6}>
              {isIFrame ? <div className='iframe-logo' /> : null}
              <Title className='font-bold' text={t('HEADLINE')} level={1} />
              <div className='subhead'>
                { !bccmi ? <>
                  <p>{t('SUBHEAD_1', { disease: t(`common:${displayDisease}`) })}</p>
                  <p>{t('SUBHEAD_2')}</p>
                </> : <p>{t('SUBHEAD_BCCMI', {disease: t(`common:${displayDisease}`)})}</p> }
              </div>
              <GetStartedLink buttonId='1' type='green' />
              {isNonCoveredEntity() ? (
                <div className='privacy-link'>
                  <a href='/privacy-policy' target='_blank'>
                    {t('common:PRIVACY_NOTICE')}
                  </a>
                </div>
              ) : null}
            </Col>
          </Row>
        </Container>
      </ResponsiveBackgroundImg>
    </div>
  );
};

Headline.propTypes = {
  disease: PropTypes.oneOf(['asthma', 'copd']),
  i18n: PropTypes.object,
  isIFrame: PropTypes.bool,
  t: PropTypes.func,
};

export default compose(
  withTranslation(['landing', 'common']),
  container
)(Headline);
