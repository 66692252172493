import React from 'react';
import PropTypes from 'prop-types';
import PageHeading from '../../../components/PageHeading/PageHeading';
import { compose } from 'redux';
import { withTranslation, Trans } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Title from '../../../components/Title/Title';
import SquareInput from '../SquareInput';
import OptionsSelector from '../../../components/OptionsSelector';
import { logPage } from '../../../lib/analytics';
import container from './container';

export class FormFactors extends React.Component {
  state = {
    showModal: false,
  };

  componentDidMount() {
    logPage('MedSelect-FormFactors');
  }

  notificationUID = 'formfactorerror';

  closeModal = () => {
    this.setState({ showModal: false });
  };

  goNext = (e) => {
    e.preventDefault();
    const {
      navigateForward,
      formFactors,
      notifyError,
      clearError,
      t,
    } = this.props;

    const results = formFactors
      .filter((f) => f.selected)
      .map((f) => formFactors.indexOf(f));

    if (results.length === 0) {
      notifyError({
        title: t('ERROR'),
        message: t('FORM_FACTOR_SELECTION_ERROR'),
        position: 'tc',
        uid: this.notificationUID,
      });
    } else {
      clearError(this.notificationUID);
      navigateForward(`/med-selection/medications?type=${results[0]}`);
    }
  };

  render() {
    const { disease = 'asthma', formFactors, onChange, t } = this.props;
    return (
      <PageHeading pageTitle={t('PAGE_TITLE_FORM_FACTORS')} t={t}>
        <OptionsSelector
          goBack={this.props.navigateBack}
          goNext={(e) => this.goNext(e)}
        >
          <Row>
            <Col xs={12}>
              <Title
                level={1}
                text={t('FIND_SENSOR_TO_TRACK')}
              />
              <p className='ph-sub-heading'>
                <Trans
                  i18nKey='medSelection:SPONSOR_MED_SELECT_INTRO'
                  values={{ disease: t(`common:${disease}`) }}
                >
                  What kinds of {disease} medication do you <strong>currently</strong>{' '}
                  use?
                </Trans>
              </p>
            </Col>
          </Row>
          <Row as='fieldset' className='med-selection'>
            <legend className='visually-hidden'>
              {t('SPONSOR_MED_SELECT_INTRO_2', {
                disease: t(`common:${disease}`),
              })}
            </legend>
            {formFactors.map((option) => (
              <SquareInput
                key={option.id}
                option={option}
                label={t(option.name)}
                onChange={() => onChange(option.id)}
                htmlName='form-factors'
                selected={option.selected}
                height='90'
              />
            ))}
          </Row>
        </OptionsSelector>
      </PageHeading>
    );
  }
}

FormFactors.propTypes = {
  disease: PropTypes.string.isRequired,
  formFactors: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  container,
  withTranslation(['medSelection', 'common'])
)(FormFactors);
