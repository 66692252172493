import { connect } from 'react-redux';
import { enrollmentDisease } from '../../lib/utilities';

const propsFromState = (state) => {
  return {
    disease: enrollmentDisease(state.enrollmentData),
  };
};

export default connect(propsFromState);
