import { objectFromQueryString, HAS_WINDOW, SUBDOMAIN } from '../lib/utilities';

const INITIAL_PARAMS = HAS_WINDOW
  ? objectFromQueryString(window.location.search)
  : {};

const ORIGIN = HAS_WINDOW && window.location.origin;

export const queryParams = function queryParams(state = INITIAL_PARAMS) {
  return state;
};

export const domain = function domain(state = ORIGIN) {
  return state;
};

export const subdomain = function subdomain(state = SUBDOMAIN) {
  return state;
};
