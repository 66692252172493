import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import BodyClassName from 'react-body-classname';
import container from './container';
import CTABanner from '../../components/CTABanner';
import Headline from './Headline/Headline';
import HowPropellerWorks from '../../components/HowPropellerWorks';
import PageHeading from '../../components/PageHeading/PageHeading';
import Testimonials from '../../components/Testimonials';
import HomeFAQs from './HomeFAQs/HomeFAQs';
import { testimonials } from '../../data/landingPage/testimonials';
import { initalizeAnalytics, logPage } from '../../lib/analytics';
import pkg from '../../../package.json';
import { SUBDOMAIN } from '../../lib/utilities';

function Home({ analyticsFlags, groupConfig, lpVariant, mongoObjectId, t }) {
  const bccmi = SUBDOMAIN === 'bccmi';
  useEffect(() => {
    initalizeAnalytics({
      analyticsFlags,
      uid: mongoObjectId,
      version: pkg.version,
      fbTracking: groupConfig.fbTracking,
      lpVariant: lpVariant,
    });
    logPage('Home');
  }, [analyticsFlags, groupConfig, lpVariant, mongoObjectId]);

  return (
    <BodyClassName className='landing'>
      <PageHeading pageTitle={t('PAGE_TITLE_HOME')}>
        <Headline />
        <CTABanner />
        <HowPropellerWorks />
        <HomeFAQs />
        {!bccmi && <Testimonials testimonials={testimonials} />}
      </PageHeading>
    </BodyClassName>
  );
}

Home.propTypes = {
  analyticsFlags: PropTypes.string,
  contentVersion: PropTypes.string,
  groupConfig: PropTypes.object,
  i18n: PropTypes.object.isRequired,
  isIFrame: PropTypes.bool,
  lpVariant: PropTypes.string,
  mongoObjectId: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default compose(container, withTranslation('landing'))(Home);
