import React from 'react';

const PrivacyPolicyEn = () => (
  <div className='privacy-notice'>
    <h1>PROPELLER PRIVACY NOTICE</h1>
    <p>
      Your privacy is important to us. Reciprocal Labs Corporation (d/b/a
      Propeller Health) (throughout this notice as "Propeller Health," "we,"
      "us," "our") is committed to protecting your privacy. As part of our
      commitment to honoring your privacy, this Privacy Notice explains the
      approach we take in protecting and using the information that we gather
      from you. Your personal data is processed in compliance with all
      applicable federal regulations, state, and local regulations, including
      the California Consumer Privacy Act (CCPA) of 2018, including the Attorney
      General Regulations proposed in 2019.
    </p>
    <p>
      Our Privacy Notice explains how we process your personal data when you use
      our services. These services include: sensors, hubs, the website, and
      mobile application, (collectively, "the Propeller System") that gather,
      store and process data to provide you insights to help you breathe easier.
    </p>
    <h2>Who is In Charge:</h2>
    <p>
      Propeller Health, located at 1 South Pinckney Street, Suite 610, Madison,
      WI 53703, and email{' '}
      <a href='mailto:help@propellerhealth.com'>help@propellerhealth.com</a> is
      considered the "data controller" with regard to the processing of personal
      data in the context of the Services. Propeller Health has appointed a Data
      Privacy Officer, Stacy Tyman, located at the above address, who may be
      contacted at{' '}
      <a href='mailto:dpo@propellerhealth.com'>dpo@propellerhealth.com</a>
    </p>
    <h2>Types of Information We Collect:</h2>
    <h3>User Provided Information:</h3>
    <p>
      We collect information that you share with us when you enter your personal
      information to register for an account with us, communicate with our
      customer support team, and interact with the Propeller System.
    </p>
    <p>
      This information might include personal information, protected
      information, health information, and biometric information as described
      below:
    </p>
    <ul>
      <li>First and last name</li>
      <li>Date of birth</li>
      <li>Email Address</li>
      <li>Mailing Address</li>
      <li>Phone Number</li>
      <li>IP Address/device ID</li>
      <li>Geolocation Data (can be turned off within your device)</li>
      <li>Whether you have asthma or COPD</li>
      <li>
        Information regarding your medications include: the medication name,
        dosage, and schedule
      </li>
      <li>
        Usage information from your Sensor, a Hub or as manually entered by you
        into the mobile application
        <ul>
          <li>
            Usage information will include the date and time you used your
            medication and in some cases the location where you used your
            medication.
          </li>
        </ul>
      </li>
    </ul>
    <h3>Commercial Information:</h3>
    <p>
      Propeller Health maintains information about the products that you receive
      from us in order to support your use of the Propeller System.
    </p>
    <h3>Cookies:</h3>
    <p>
      Cookies are small text files that can be used by websites to make a user's
      experience more personalized. Propeller Health uses cookies to personalize
      content and ads, and to analyze our traffic. This helps us to provide you
      with the most relevant information to your experience with Propeller.
    </p>
    <p>
      Propeller Health uses Google Analytics, a web analytics service provided
      by Google to help us understand how people use our website and make
      changes to improve their experience. The data collected by Google will be
      stored in cookies on your browser and will be transmitted and stored on a
      Google server. The IP address (or reduced IP address) transmitted from
      your browser during the course of Google Analytics will not be associated
      with any other data held by Google. You can prevent the storage of Google
      Analytics cookies by selecting the appropriate settings on your browser.
    </p>
    <p>
      For users who access Propeller Health's System through Facebook
      advertisements, Propeller Health uses conversion tracking tools made by
      Facebook to assess how well we are reaching users like you. When you
      create a Propeller account, your name and potential respiratory condition
      is collected by Facebook to measure the effectiveness of our current
      advertising efforts and help reach other potential users who could benefit
      from Propeller.
    </p>
    <h3>Integrations:</h3>
    <p>
      Patients may have the option to enable integration with the HealthKit
      application if they use an Apple device. Users who enable this
      functionality will allow Propeller Health to access their health and
      exercise data as collected by the HealthKit application. HealthKit data
      will be collected and stored by the Propeller System. You can disable this
      integration at any time through your device settings.
    </p>
    <h3>Information for Parents of Users Who are Under Age 13:</h3>
    <p>
      In order for a parent to register their child(ren) for the Service, we
      need to obtain verified consent from the Parent. To verify your
      information, we use a third party service. Parents will submit their
      information to the third party. This data may include name, postal
      address, and date of birth. This information is used only to verify your
      identity and will not be shared with any other party.
    </p>
    <p>
      Propeller does not intend for the System to be used by children who are
      under 13 years old without the consent of a parent or guardian. If you are
      the parent or guardian of a child who is under 13 years old, and you
      believe that your child has provided us with information without your
      consent, please contact us at{' '}
      <a href='mailto:dpo@propellerhealth.com'>dpo@propellerhealth.com</a>, so
      we can promptly delete that information.
    </p>
    <h2>Use and Storage of Your Personal Information:</h2>
    <p>
      We may use personal information collected through the Propeller System for
      the following purposes:
    </p>
    <h3>Affiliates of Propeller Health:</h3>
    <p>
      Propeller Health may share your personal information with any of our
      affiliates, with ResMed (our parent company), with any affiliates or
      subsidiary of ResMed, or with any other entity that shares common control
      or ownership with us.
    </p>
    <h3>Support and Communication:</h3>
    <p>
      Propeller Health may use the information you've provided us through your
      use of the Propeller System to contact you to assist you with your use of
      the Propeller System. We may reach out to obtain updates on your health
      status, to send important Service announcements, to send you information
      about services we think may be of interest to you, or to ask for your
      feedback on the Propeller System. This information may come to you via
      email, push notifications in the App, telephone, or SMS/text messages. You
      can opt out of these notifications by changing your settings on your
      mobile device or within the web portal.
    </p>
    <h3>Analytics:</h3>
    <p>
      Propeller Health may use your information to provide you with information
      on how well your disease is being managed. We may use your location
      information to provide you with weather and environmental information that
      may impact your disease management.
    </p>
    <h3>Research:</h3>
    <p>
      Propeller Health is dedicated to understanding the management and impact
      of respiratory disease and identifying and supporting better ways to
      address it. We may de-identify and combine your data with the data of
      other users (aggregated data) to conduct research on chronic respiratory
      disease, with the goals of improving diagnosis and treatment,mitigating
      the impact of environmental factors such as pollution, and continually
      improving Propeller to provide you the best possible experience.
    </p>
    <h3>Laws, Regulations, and Legal Processes</h3>
    <p>
      Propeller Health may be required to use information collected from you to
      comply with various laws and regulations, in the event of an investigation
      or legal proceedings, in the event of a merger, sale or other transfer of
      assets, and to protect the rights and safety of Propeller Health, its
      employees, its customers, or the public.
    </p>
    <h2>Sharing Your Personal Information</h2>
    <p>We may share your personal information with the following groups:</p>
    <h3>Third Parties Who Perform Services for Propeller</h3>
    We may share your information with third-party service providers who help us
    to carry out the services we provide through the Propeller System. We limit
    the information shared with these providers solely to the information needed
    for the purposes described in our contracts.
    <h3>Third Party Partners or Research/Public Health Agencies</h3>
    <p>
      In addition to serving your individual needs, and the needs of our
      customers, we provide information to our Partners who may be supporting
      the services you receive from Propeller, and other third parties such as
      public health agencies, to include information for use in their
      health-related activities and scientific research. We remove personally
      identifiable information that could be used to identify you prior to
      sharing this information.
    </p>
    <h3>Sale of Propeller Health:</h3>
    <p>
      In the event of a business transaction such as the sale of all or part of
      our business (ex: a merger, liquidation, reorganization, transfer, or
      other business transaction or negotiation) we reserve the right to
      transfer your information to a third-party acquiring our assets. You will
      receive communication regarding this.
    </p>
    <h3>Caregivers, Health Providers, Other Individuals</h3>
    <p>
      As a Propeller Health user, you have the option to share your information
      with Caregivers, Health Care Providers, or other individuals. This is set
      up by you within your profile and can be removed at any time.
    </p>
    <h2>Your Choices</h2>
    <p>
      Propeller Health offers you certain choices on how your personal data is
      used and how we interact with you. You can withdraw your consent at any
      time from the following:
    </p>
    <ul>
      <li>
        You can elect not to receive marketing materials or information from us,
        by changing your notification settings within the mobile application or
        the web portal or via the communications we send you.
      </li>
      <li>
        You can elect not to receive SMS messaging by changing your settings
        within the user web portal.
      </li>
      <li>
        You can elect not to receive push notifications by changing the settings
        on your mobile device.
      </li>
      <li>
        You can manage and update the personal information you provide us within
        your account settings.
      </li>
      <li>
        After receiving an SMS message, you can reply with the word STOP to be
        removed from receiving future SMS messages.
      </li>
      <li>
        If at any point, you decide you no longer want to use the Propeller
        System, you may contact our support team at help@propellerhealth.com or
        by calling 877-251-5451 to delete your account.
      </li>
    </ul>
    <p>
      Propeller Health will not change collect additional personal information
      or use the personal information we collect for a different, unrelated, or
      incompatible purpose without providing you notice.
    </p>
    <h2>Additional Information for California Residents</h2>
    <h3>Sale of Personal Information:</h3>
    <p>
      Over the past twelve (12) months, we have not sold any categories of
      personal information. Propeller Health does not sell personal information
      to third parties.
    </p>
    <h3>Your Rights and Choices:</h3>
    <p>
      CCPA provides consumers (California residents) with specific rights
      regarding their personal information. You have the following rights:
    </p>
    <h3>Disclosure Rights:</h3>
    You have the right to request that we disclose the categories of personal
    information we collect in the 12-month period preceding the request date.
    Consumer requests of this nature may be made no more than twice in a
    12-month period. The categories of personal information are as follows
    (note: areas in bold are categories where Propeller collects data):
    <ul>
      <li className='font-bold'>Category A: Identifiers</li>
      <li className='font-bold'>
        Category B: California Customer Records personal information categories
      </li>
      <li className='font-bold'>
        Category C: Protected classification characteristics under California or
        federal law
      </li>
      <li className='font-bold'>Category D: Commercial information</li>
      <li className='font-bold'>Category E: Biometric information</li>
      <li className='font-bold'>
        Category F: Internet or other similar network activity
      </li>
      <li className='font-bold'>Category G: Geolocation data</li>
      <li className='font-bold'>Category H: Sensory data</li>
      <li className='font-bold'>
        Category I: Professional or employment-related information
      </li>
      <li className='font-bold'>
        Category J: Non-public education information
      </li>
      <li className='font-bold'>
        Category K: Inferences drawn from other personal information
      </li>
    </ul>
    <h3>Data Access Request:</h3>
    <p>
      You have the right to request that Propeller Health disclose certain
      information to you about our collection and use of your personal
      information over the past 12 months. Once we receive and confirm your
      request (see: "Exercising Your Data Rights"), we will disclose the
      following information to you:
    </p>
    <ul>
      <li>The categories of personal information we collected about you.</li>
      <li>
        The categories of sources for the personal information we collected
        about you.
      </li>
      <li>
        Our business or commercial purpose for collecting or selling that
        personal information.
      </li>
      <li>
        The specific pieces of personal information we collected about you.
      </li>
      <li>
        If we shared personal information with third parties, we will disclose
        the categories of these third parties.
      </li>
      <li>
        If we disclosed your personal information for a business purpose, we
        will provide a list identifying the personal information categories that
        each category of recipient obtained.
      </li>
    </ul>
    <h3>Deletion Requests:</h3>
    <p>
      You have the right to request that Propeller Health delete any of your
      personal information that we collected from you and retained, subject to
      certain exceptions as required by applicable law. Once we receive and
      confirm your consumer request (see: "Exercising Your Data Rights"), we
      will delete (and direct our service providers to delete) your personal
      information from our records, unless an exception applies.
    </p>
    <p>
      We may deny your deletion request if retaining the information is
      necessary for us or our service provider(s) to:
    </p>
    <ul>
      <li>
        Complete the transaction for which we collected the personal
        information, provide a good or service that you requested, take actions
        reasonably anticipated within the context of our ongoing business
        relationship with you, or otherwise perform our contract with you
      </li>
      <li>
        Detect security incidents, protect against malicious, deceptive,
        fraudulent, or illegal activity, or prosecute those responsible for such
        activities
      </li>
      <li>
        Identify and repair errors that impair existing intended product
        functionality
      </li>
      <li>
        Exercise free speech, ensure the right of another consumer to exercise
        their free speech rights, or exercise another right provided for by law
      </li>
      <li>
        Engage in public or peer-reviewed scientific, historical, or statistical
        research in the public interest that adheres to all other applicable
        ethics and privacy laws, when the information's deletion may likely
        render impossible or seriously impair the research's achievement, if you
        previously provided informed consent
      </li>
      <li>
        Enable internal-only uses that are reasonably aligned with consumer
        expectations based on your relationship with us
      </li>
      <li>Comply with a legal obligation</li>
      <li>
        Make other internal and lawful uses of that information that are
        compatible with the context in which you provided it.
      </li>
    </ul>
    <h3>Exercising Your Data Rights:</h3>
    <p>
      To exercise your disclose, access, data portability, and deletion rights
      described above, submit a request to us through one of the following
      methods:
    </p>
    <ul>
      <li>Call us at 877-251-5451</li>
      <li>
        Email us at{' '}
        <a href='mailto:datarequests@propellerhealth.com'>
          datarequests@propellerhealth.com
        </a>
      </li>
      <li>
        Complete this{' '}
        <a href='https://propellerhealth.force.com/helpcenter/s/contactsupport?language=en_US'>
          Online Web Form
        </a>
      </li>
    </ul>
    <p>
      Only you, or a person registered with the California Secretary of State
      who you authorize to act on your behalf, may make a consumer request
      related to your personal information. You may also make a consumer request
      on behalf of your minor child. You may only make a consumer request for
      access or data portability twice within a 12-month period. The consumer
      request must:
    </p>
    <ul>
      <li>
        Provide sufficient information that allows us to reasonably verify you
        are the person about whom we collected personal information or proves
        you are an authorized representative of that person
      </li>
      <li>
        Describe your request with sufficient detail that allows us to properly
        understand, evaluate and respond to it. We cannot respond to your
        request or provide you with personal information if we cannot verify
        your identity or authority to make the request and confirm the personal
        information relates to you. We will only use personal information
        provided in a consumer request to authenticate the requestor's identity
        or authority to make the request.
      </li>
    </ul>
    <h3>Response timing and format</h3>
    <p>
      Our intent is to respond to a consumer request within 45 days of its
      receipt. If we require more time (up to 90 days), we will inform you of
      the reason and extension period in writing. We will deliver our written
      response electronically. Any disclosures we provide will only cover the
      12-month period preceding the consumer request's receipt. The response we
      provide will also explain the reasons we cannot comply with a request, if
      applicable. For data access requests, we will deliver the data in a
      readily usable format.
    </p>
    <h3>Non-discrimination</h3>
    <p>
      We will not discriminate against you for exercising any of your CCPA
      rights.
    </p>
    <h3>Other California privacy rights</h3>
    <p>
      California's "Shine the Light" law (Civil Code Section § 1798.83) permits
      users of our website who are California residents to request certain
      information regarding our disclosure of personal information to third
      parties for their direct marketing purposes. We do not disclose personal
      information to third parties for their direct marketing purposes.
    </p>
    <h2>Revisions</h2>
    <p>
      This Privacy Notice is subject to change at our discretion. We will
      indicate changes, including material changes, tothe Privacy Notice by
      updating the "Effective date." Your use of the Service after any update to
      this Privacy Notice will constitute your acceptance of the changes.
    </p>
    <h2>Contact information</h2>
    <p>
      If you have any questions or concerns about this Privacy Notice, please
      send us an email to{' '}
      <a href='mailto:dpo@propellerhealth.com'>dpo@propellerhealth.com</a>
    </p>
    <p className='version'>Effective date: 05/26/2020</p>
  </div>
);

export default PrivacyPolicyEn;
