import React from 'react';
// import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import './warning-puff-modal.scss';

const WarningPuffsModal = ({ puffs, onCancel, onContinue }) => {
  return (
    <form>
      <Modal.Header>
        <div className="puffs-warning-title">Continue with {puffs} puffs?</div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <p className="puffs-warning-description">
            Your entry of {puffs} puffs is higher than usual. Do you want to
            continue?
          </p>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <div className="footer-buttons">
            <Button variant="outline-secondary" onClick={onCancel}>
              Cancel
            </Button>
            &nbsp;&nbsp;
            <Button variant="success" onClick={onContinue}>
              Continue
            </Button>
          </div>
        </Container>
      </Modal.Footer>
    </form>
  );
};

export default WarningPuffsModal;
