import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import container from '../container';
import { withTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import PageHeading from '../../../components/PageHeading/PageHeading';
import Title from '../../../components/Title/Title';
import EnrollmentButtons from '../Buttons';
import { logPage } from '../../../lib/analytics';
import { Col, Row } from 'react-bootstrap';

export class Role extends React.Component {
  constructor(props) {
    super(props);

    const role = props.enrollmentData && props.enrollmentData.enrollmentRole;
    this.state = {
      role: role || '',
      valid: null,
    };
  }

  roleEnum = ['patient', 'caregiver'];

  componentDidMount() {
    const { enrollmentMeds, pushHistory } = this.props;
    if (!enrollmentMeds || enrollmentMeds.length < 1) {
      pushHistory('/');
    }
    logPage('Enroll-Who');
  }

  isValid = (value) => {
    return this.roleEnum.includes(value);
  };

  handleBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  handleChange = (e) => {
    this.setState({ role: e.target.value, valid: null });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const role = this.state.role;
    if (this.isValid(role)) {
      this.props.updateEnrollmentData({ enrollmentRole: role });
      this.props.pushHistory('/enrollment/patient-info');
    } else {
      this.setState({ valid: 'error' });
    }
  };

  render() {
    const { t } = this.props;

    return (
      <PageHeading
        className='user-role'
        pageTitle={t('PAGE_TITLE_SIGNUP')}
        t={t}
      >
        <Title
          text={t('WHO_ENROLLING_QUESTION')}
          className='sm-left-lg-center-text'
          id='user-role'
          level={1}
        />
        <Row>
          <Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <Form onSubmit={(e) => this.handleSubmit(e)}>
              <FormGroup
                controlId='role'
                role='radiogroup'
                validationState={this.state.valid}
                aria-labelledby='user-role'
              >
                <ul className='radio-selectors'>
                  <li>
                    <input
                      className='custom-radio'
                      checked={this.state.role === 'patient'}
                      type='radio'
                      name='role'
                      value='patient'
                      id='role-patient'
                      onChange={this.handleChange}
                    />
                    <label htmlFor='role-patient'>
                      {t('MYSELF_PATIENT_LABEL')}
                    </label>
                  </li>
                  <li>
                    <input
                      className='custom-radio'
                      checked={this.state.role === 'caregiver'}
                      type='radio'
                      name='role'
                      value='caregiver'
                      id='role-caregiver'
                      onChange={this.handleChange}
                    />
                    <label htmlFor='role-caregiver'>
                      {t('CAREGIVER_LABEL')}
                    </label>
                  </li>
                </ul>
                {this.state.valid === 'error' ? (
                  <div
                    className='error-message-center'
                    role='alert'
                    aria-live='assertive'
                  >
                    {t('SELECT_OPTION')}
                  </div>
                ) : null}
              </FormGroup>

              <EnrollmentButtons
                handleBack={this.handleBack}
                handleSubmit={this.handleSubmit}
                t={t}
              />
            </Form>
          </Col>
        </Row>
      </PageHeading>
    );
  }
}

Role.propTypes = {
  enrollmentData: PropTypes.object.isRequired,
  enrollmentMeds: PropTypes.array,
  pushHistory: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateEnrollmentData: PropTypes.func.isRequired,
};

export default compose(withTranslation('enrollment'), container)(Role);
