import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import container from '../container';
import { withTranslation } from 'react-i18next';
import { Form, Row, Col } from 'react-bootstrap';
import FormGroup from 'react-bootstrap/FormGroup';
import PageHeading from '../../../components/PageHeading/PageHeading';
import TextInput from '../../../components/InputField/TextInput/TextInput';
import DateInput from '../../../components/InputField/DateInput/DateInput';
import EnrollmentButtons from '../Buttons';
import Title from '../../../components/Title/Title';
import { logPage } from '../../../lib/analytics';
import {
  displayPhone,
  isNotEmpty,
  isIBX,
  isExpressScripts,
} from '../../../lib/utilities';
import { isValidBirthDate } from '../../../lib/input_validation';
import { getNextPage } from '../enrollment-utils';

export class PatientInfo extends React.Component {
  constructor(props) {
    super(props);
    const patient = props.enrollmentData.patient;

    this.state = {
      givenName: patient.givenName || '',
      familyName: patient.familyName || '',
      birthDate: patient.birthDate || '',
      errors: [],
      errorMessage: null,
    };
  }

  componentDidMount = () => {
    const { enrollmentData, enrollmentMeds, pushHistory } = this.props;
    if (!enrollmentMeds || enrollmentMeds.length < 1) {
      pushHistory('/');
    }
    if (!enrollmentData.enrollmentRole) {
      pushHistory('/enrollment');
    }
    logPage('Enroll-BasicInfo');
  };

  checkForErrors = () => {
    const role = this.props.enrollmentData.enrollmentRole;

    return ['givenName', 'familyName', 'birthDate']
      .map((item) => {
        if (item === 'birthDate') {
          const validation = isValidBirthDate(
            this.state.birthDate,
            role,
            isIBX() || isExpressScripts()
          );
          if (validation.valid) {
            return null;
          }
          this.setState({ errorMessage: validation.errorMessage });
          return item;
        }
        return this.state[item].length >= 1 ? null : item;
      })
      .filter((err) => err !== null);
  };

  setErrors = (errors) => {
    this.setState(() => {
      return { errors: errors };
    });
  };

  setError = (field) => {
    this.setState((prev) => {
      return { errors: [...prev.errors, field] };
    });
  };

  isValid = () => {
    const errors = this.checkForErrors();
    if (errors.length) {
      this.setErrors(errors);
      return false;
    }
    if (this.state.errors.length > 0) return false;
    return true;
  };

  getNextPage = () => {
    const { device, enrollmentData, groupConfig, groupConfig4x } = this.props;
    return getNextPage({
      currentPage: 'patientInfo',
      device,
      enrollmentData,
      groupConfig,
      groupConfig4x,
    });
  };

  handleChange = (data) => {
    const key = Object.keys(data)[0];
    const errors = this.state.errors.filter((error) => error !== key);
    this.setState(data);
    this.setErrors(errors);
  };

  handleBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.isValid()) {
      const next = this.getNextPage();
      this.props.updateEnrollmentData({
        patient: {
          givenName: this.state.givenName,
          familyName: this.state.familyName,
          birthDate: this.state.birthDate,
        },
      });
      this.props.pushHistory(next);
    }
  };

  render() {
    const { disabledInputs, enrollmentData, groupConfig, t } = this.props;
    const { enrollmentRole } = enrollmentData;
    let title, givenNameLabel, familyNameLabel, birthdateLabel;

    if (enrollmentRole === 'caregiver') {
      title = 'BASIC_INFO_CAREGIVER';
      givenNameLabel = 'GIVEN_NAME_LABEL_THEIR';
      familyNameLabel = 'FAMILY_NAME_LABEL_THEIR';
      birthdateLabel = 'BIRTHDATE_LABEL_THEIR';
    } else {
      title = 'BASIC_INFO_PATIENT';
      givenNameLabel = 'GIVEN_NAME_LABEL_YOUR';
      familyNameLabel = 'FAMILY_NAME_LABEL_YOUR';
      birthdateLabel = 'BIRTHDATE_LABEL_YOUR';
    }
    
    return (
        <PageHeading pageTitle={t('PAGE_TITLE_PATIENT_INFO')}>
          <Title
            text={t(title)}
            className='sm-left-lg-center-text'
            id='patient-info'
            level={1}
          />
          {isNotEmpty(disabledInputs) ? (
            <Row>
              <p>{t('NOT_EDITABLE_EXPLANATION')}</p>
              <p>
                {t('PLEASE_CONTACT_IF_NOT_CORRECT', {
                  phoneNumber: displayPhone(groupConfig.supportPhone),
                })}
              </p>
            </Row>
          ) : null}
          <Row>
            <Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
              <Form
                onSubmit={(e) => this.handleSubmit(e)}
              >
                <FormGroup aria-labelledby='patient-info'>
                  <TextInput
                    autoComplete='given-name'
                    disabled={disabledInputs['patient.givenName']}
                    error={this.state.errors.includes('givenName')}
                    errorMessage='GIVEN_NAME_ERROR'
                    onChange={this.handleChange}
                    label={givenNameLabel}
                    minLength={1}
                    maxLength={40}
                    name='givenName'
                    setError={this.setError}
                    t={t}
                    value={this.state.givenName}
                  />
                  <TextInput
                    autoComplete='family-name'
                    disabled={disabledInputs['patient.familyName']}
                    error={this.state.errors.includes('familyName')}
                    errorMessage='FAMILY_NAME_ERROR'
                    onChange={this.handleChange}
                    label={familyNameLabel}
                    maxLength={80}
                    name='familyName'
                    setError={this.setError}
                    t={t}
                    value={this.state.familyName}
                  />
                  <DateInput
                    autoComplete='bday'
                    disabled={disabledInputs['patient.birthDate']}
                    error={this.state.errors.includes('birthDate')}
                    errorText={this.state.errorMessage || 'BIRTHDATE_ERROR'}
                    label={birthdateLabel}
                    name='birthDate'
                    onChange={this.handleChange}
                    onError={this.setError}
                    role={enrollmentData.enrollmentRole}
                    t={t}
                    value={this.state.birthDate}
                  />
                  <EnrollmentButtons
                    handleBack={this.handleBack}
                    handleSubmit={this.handleSubmit}
                    t={t}
                  />
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </PageHeading>
    );
  }
}

PatientInfo.propTypes = {
  disabledInputs: PropTypes.object,
  enrollmentData: PropTypes.object.isRequired,
  groupConfig: PropTypes.object.isRequired,
  groupConfig4x: PropTypes.object.isRequired,
  pushHistory: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateEnrollmentData: PropTypes.func.isRequired,
};

export default compose(withTranslation('enrollment'), container)(PatientInfo);
