// First set up various polyfills
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'bootstrap/dist/css/bootstrap.min.css';
// React
import React from 'react';
import { hydrate } from 'react-dom';
import { Router } from 'react-router-dom';
// Redux
import { Provider as StateProvider } from 'react-redux';
import configureStore from './configureStore';

import { createBrowserHistory } from 'history';

// import Loadable from "react-loadable";

import {
  initEnrollmentForGroup,
  setDeviceType,
  updateEnrollmentData,
} from './actions';

import {
  hydrate as hydrateState,
  updateLocalStorage,
  IS_DEV_OR_TEST,
  staticObject,
  getValue,
  objectFromQueryString,
  MOBILE_DEVICE,
} from './lib/utilities';

import i18n from './i18n';
import { init as initMedMap } from './lib/medNameHashMap';
import App from './App';

const RECOVERED_STATE = hydrateState();
const SERVER_STATE = window.__PRELOADED_STATE__ || {};
const INITIAL_LANGUAGE = window.__INITIAL_LOCALE__;
const INITIAL_STRINGS = window.__INITIAL_STRINGS__;
delete window.__PRELOADED_STATE__;
delete window.__INITIAL_STRINGS__;

if (IS_DEV_OR_TEST) {
  console.group('RUNING IN DEV OR TEST');
  console.log('RECOVERED_STATE:', staticObject(RECOVERED_STATE));
  console.log('SERVER_STATE:', staticObject(SERVER_STATE));
}

const browserHistory = createBrowserHistory();
const preloadedState = {
  ...SERVER_STATE,
  ...RECOVERED_STATE,
};
const store = configureStore(preloadedState, browserHistory);

// some additional hydration that needs to happen on the client

// SET_DEVICE_TYPE applies changes to
// device
// enrollmentSteps
store.dispatch(setDeviceType(MOBILE_DEVICE()));

if (preloadedState.groupConfig && preloadedState.groupConfig.name) {
  store.dispatch(initEnrollmentForGroup(preloadedState.groupConfig));
}

const initialState = store.getState();

let priorState;
let unsubscribe = store.subscribe(() => {
  let newState = store.getState();
  if (newState.hasLocalStorage === false) {
    unsubscribe();
    return;
  }
  updateLocalStorage(newState, priorState);
  priorState = newState;
});

if (IS_DEV_OR_TEST) {
  console.log('REDUX STORE:', store);
  console.log('INITIAL STATE:', staticObject(initialState));
  console.groupEnd();
}

const urlParams = objectFromQueryString(document.location.search);
if (urlParams && urlParams.source) {
  const { email = '', source } = urlParams;
  store.dispatch(updateEnrollmentData({ email, source }));
}

const country = getValue(initialState, 'groupConfig.country') || undefined;
const groupLng = getValue(initialState, 'groupConfig.language') || undefined;
const groupMeds = getValue(initialState, 'groupConfig.medications') || [];
initMedMap(groupMeds);

i18n.initialize({
  country,
  groupLng,
  lng: INITIAL_LANGUAGE,
  resources: INITIAL_STRINGS,
  reduxStore: store,
});

// `this` inside `logPage` is null without binding here,
// probably because we're responding inside an eventhandler
// browserHistory.listen(logPage.bind(logPage));
// const RoutedApp = withRouter(AppState);

// window.onload = () => {
// Loadable.preloadReady().then(() => {
// console.log("preload ready");
hydrate(
  <StateProvider store={store}>
    <Router history={browserHistory}>
      <App />
    </Router>
  </StateProvider>,
  document.getElementById('root')
);
// }).catch(err => {
// console.error("preload error");
// console.error(err);
// });
// };
