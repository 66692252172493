import deepEqual from 'deep-equal';
import { HAS_WINDOW } from './environment';

const LAST_CHECK = 'lastCheck';
const TIMEOUT_SECONDS = 60 * 1;
const LOCAL_STORAGE_TIMEOUT = TIMEOUT_SECONDS * 1000; // 1 minute

// const STATE_COOKIE_KEY = "ph.uistate";

const COOKIED_STATE = ['medSelection', 'requiresValidation'];

const NON_COOKIED_STATE = [
  'asthmaControlTest',
  'copdAssessmentTest',
  'enrollmentMeds',
  'enrollmentData',
  'mongoObjectId',
  'walgreensProducts',
  'disabledInputs',
  'pharmaProgram',
];

const PERSISTED_STATE = [].concat(COOKIED_STATE, NON_COOKIED_STATE);

export const readCookie = (key) => {
  const COOKIE_REGEX = new RegExp(
    `(?:(?:^|.*;\\s*)${key}\\s*\\=\\s*([^;]*).*$)|^.*$`
  );
  return document.cookie.replace(COOKIE_REGEX, '$1');
};

export function setCookie(key, value, seconds = TIMEOUT_SECONDS, props = {}) {
  const cookieProps = Object.keys(props)
    .map((p) =>
      props[p] === null || props[p] === undefined
        ? `;${p}`
        : `;${p}=${props[p]}`
    )
    .join('');

  document.cookie = `${key}=${value};max-age=${seconds}${cookieProps}`;
}

export const isExpired = (time) => {
  const timeout =
    HAS_WINDOW && window.location.hostname.includes('localhost')
      ? LOCAL_STORAGE_TIMEOUT * 10
      : LOCAL_STORAGE_TIMEOUT;
  return new Date() - new Date(Number.parseInt(time, 10)) > timeout;
};

export const getParsedItem = (key) => {
  let item = localStorage.getItem(key);
  try {
    return JSON.parse(item);
  } catch (e) {
    console.error('Error parsing key %s', key, e);
    return undefined;
  }
};

export const checkLocalStorage = () => {
  try {
    if (!localStorage) {
      throw new Error('cannot access localStorage');
    }
    let x = 'init-local-storage' + new Date().toISOString();
    localStorage.setItem(x, x);
    let y = localStorage.getItem(x);
    localStorage.removeItem(x);
    return x === y;
  } catch (e) {
    return false;
  }
};

export const loadStateFromLocalStorage = (HAS_LOCAL_STORAGE) => {
  if (!HAS_LOCAL_STORAGE) return;

  try {
    const lastCheck = localStorage.getItem(LAST_CHECK);

    if (!lastCheck || isExpired(lastCheck)) {
      PERSISTED_STATE.forEach((key) => {
        localStorage.removeItem(key);
      });
    }
  } catch (e) {
    console.error('Error checking and purging local storage', e);
  }

  return PERSISTED_STATE.reduce((acc, key) => {
    let value = getParsedItem(key);
    if ('undefined' !== typeof value && null !== value) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

export const updateLocalStorage = (state, priorState) => {
  if (!state.hasLocalStorage) return;
  // serialize parts of state into localStorage
  PERSISTED_STATE.forEach((key) => {
    const val = state[key];
    if (val === undefined || val === null) {
      // don't allow unset values to be saved as strings
      localStorage.removeItem(key);
    } else if (!priorState || !deepEqual(val, priorState[key])) {
      localStorage.setItem(key, JSON.stringify(val));
    }
  });
  localStorage.setItem(LAST_CHECK, Date.now());

  // try {
  //   const storageObj = COOKIED_STATE.reduce((obj, key) => {
  //     obj[key] = state[key];
  //     return obj;
  //   }, {});
  //   const storageCookie = JSON.stringify(storageObj);

  // } catch(e) {}
};

export const hydrate = () => {
  const HAS_LOCAL_STORAGE = checkLocalStorage();
  return Object.assign(
    {},
    { hasLocalStorage: HAS_LOCAL_STORAGE },
    loadStateFromLocalStorage(HAS_LOCAL_STORAGE)
  );
};
