import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import ImageLoader from '../ImageLoader/ImageLoader';
import { i18nImgSrc } from '../../lib/utilities';
import ColorBar from '../ColorBar/ColorBar';
import './styles.scss';

export const HowItWorksStep = (props) => {
  const { alt = '', color, language = 'en-US', image, index, t, text, bccmi } = props;

  return (
    <div className={`step ${bccmi ? 'minHeight175' : 'minHeight300'}`} data-index={index}>
      <ColorBar color={color} />
      <div className='step-title'>{t(text)}</div>
      { !!image && <div className='img-wrapper'>
        <LazyLoad debounce={false} offset={100}>
          <ImageLoader src={i18nImgSrc(image, language)} alt={alt} />
        </LazyLoad>
      </div> }
    </div>
  );
};

HowItWorksStep.propTypes = {
  alt: PropTypes.string,
  color: PropTypes.string.isRequired,
  image: PropTypes.string,
  index: PropTypes.number.isRequired,
  language: PropTypes.string,
  t: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  bccmi: PropTypes.bool
};

export default HowItWorksStep;
