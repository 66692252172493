import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import PageHeading from '../../../components/PageHeading/PageHeading';
import container from './container';
import Title from '../../../components/Title/Title';
import IntroSteps from './IntroSteps';
import GreenButton from '../../../components/Button/GreenButton';
import { initalizeAnalytics, logPage } from '../../../lib/analytics';
import { chatInit } from '../../../lib/sfChat';
import pkg from '../../../../package.json';

export const IntroPage = ({
  analyticsFlags,
  disease,
  groupConfig,
  history,
  i18n,
  lang,
  lpVariant,
  mongoObjectId,
  setDisease,
  t,
}) => {
  useEffect(() => {
    // this disease var comes from query params & means patient came from Instapage
    // so disease and lang (below) need to be set, & analytics initialized
    if (disease) {
      setDisease(disease);
    }
  }, [disease, setDisease]);

  useEffect(() => {
    i18n.changeLanguage(lang);
    if (window && !window.embedded_svc) {
      chatInit(lang);
    }
  }, [i18n, lang]);

  useEffect(() => {
    if (window && !window.analytics) {
      initalizeAnalytics({
        analyticsFlags,
        uid: mongoObjectId,
        version: pkg.version,
        fbTracking: groupConfig.fbTracking,
        lpVariant: lpVariant,
      });
    }
    logPage('GetStarted');
  }, [analyticsFlags, groupConfig, mongoObjectId, lpVariant]);

  function handleClick() {
    const basePath = '/med-selection';
    history.push(`${basePath}/form-factors`);
  }

  return (
    <div className='get-started'>
      <PageHeading pageTitle={t('common:GET_STARTED')}>
        <Title
          className='sm-left-lg-center-text'
          text={t('SPONSORSHIP_INTRO_HEADLINE_AZ')}
          level={1}
        />
        <ul className='get-started-list'>
          <IntroSteps />
        </ul>
        <div className='cta-button-div'>
          <GreenButton size='lg' onClick={handleClick}>
            {t('ENTER_MEDS_CTA')}
          </GreenButton>
        </div>
      </PageHeading>
    </div>
  );
};

IntroPage.propTypes = {
  analyticsFlags: PropTypes.string,
  disease: PropTypes.string,
  groupConfig: PropTypes.object,
  history: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  lang: PropTypes.string,
  lpVariant: PropTypes.string,
  mongoObjectId: PropTypes.string,
  setDisease: PropTypes.func,
  t: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  withTranslation(['medSelection', 'common']),
  container
)(IntroPage);
