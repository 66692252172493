import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import FormLabel from 'react-bootstrap/FormLabel';

export class Select extends React.Component {
  state = {
    [this.props.name]: '',
  };

  setValue = (val) => {
    this.setState(() => {
      return { [this.props.name]: val };
    });
  };

  onChange = (e) => {
    const value = e.target.value;
    const { onChange } = this.props;
    this.setValue(value);
    if (onChange) onChange({ [this.props.name]: value });
  };

  render() {
    const {
      error,
      errorMessage = 'STATE_ERROR',
      label,
      name = 'stateOrRegion',
      options,
      t,
      value,
    } = this.props;

    return (
      <FormGroup
        controlId={name}
        validationState={
          error
            ? 'error'
            : this.state[this.props.name].length
            ? 'success'
            : null
        }
      >
        <FormLabel>{t(label)}</FormLabel>
        <Form.Select
          defaultValue={value}
          onChange={this.onChange}
          value={this.state.value}
        >
          <option key='null-default' value=''></option>

          {options
            ? Object.keys(options).map((prop) => (
                <option key={prop} value={options[prop]}>
                  {options[prop]}
                </option>
              ))
            : null}
        </Form.Select>
        <FormControl.Feedback />
        {error ? (
          <div role='alert' aria-live='assertive' className='error-message'>
            {t(errorMessage)}
          </div>
        ) : (
          ''
        )}
      </FormGroup>
    );
  }
}

Select.propTypes = {
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

export default withTranslation('enrollment')(Select);
