import { connect } from 'react-redux';
import { clearErrors as clear } from '../../actions';

const mapStateToProps = (state) => {
  return { errors: state.appErrors };
};

const mapDispatchToProps = { clear };

export default connect(mapStateToProps, mapDispatchToProps);
