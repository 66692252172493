import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import moment from '../../../lib/include-moment';
import { withTranslation } from 'react-i18next';
import TermsOfUse from '../../../components/TermsOfUse';
import PageHeading from '../../../components/PageHeading/PageHeading';
import {
  API_HOST,
  buildEnrollmentTags,
  dataForRole,
  determineTerms,
  establishUserContext,
  fetchLoginToken,
  getFullMedicationListFromEnrollmentMeds,
  IS_IOS,
  isMobileGroup,
  SUBDOMAIN,
  submitPlansAndQuiz,
  TERMS_ACCEPTED_MESSAGE,
  TERMS_CANCELED_MESSAGE,
  termsPath,
  windowProps,
} from '../../../lib/utilities';
import { ensureControllerMedsHaveUsageList } from '../../../lib/utilities/medications';
import { getSupportPhone } from '../../../lib/support-phone';
import {
  determinePharmaProgramEligibility,
  DEFAULT_PHARMA_PROGRAM,
} from '../../../lib/pharmaProgram';
import analytics, { logEvent, logPage, identify } from '../../../lib/analytics';
import { submitUser } from '../../../api/user';

import container from './container';

const borderedBox = {
  display: 'block',
  marginTop: '20px',
  marginBottom: '20px',
  marginLeft: 'auto',
  marginRight: 'auto',

  border: '2px solid #54565A',
  borderRadius: '10px',
  padding: '10px',
};

const scrollingContent = {
  overflowY: 'scroll',
  height: '155px',
  overflowX: 'hidden',
  maxWidth: '100%',
};

export class UserAgreement extends Component {
  constructor(props) {
    super(props);
    this.signUp = this.signUp.bind(this);
    this.receiveMessage = this.receiveMessage.bind(this);
    this.handleStorage = this.handleStorage.bind(this);
    this.launchTermsModal = this.launchTermsModal.bind(this);
  }

  state = {
    submitting: false,
    terms: null,
  };

  didComputeTerms = false;

  // store reference to external window displaying terms. we may need to message a new URL to it.
  termsWindow = undefined;

  componentDidMount() {
    const { enrollmentMeds, enrollmentData } = this.props;
    if (enrollmentMeds.length < 1 || !enrollmentData.patient.givenName) {
      this.props.pushHistory('/');
    }
    logPage('UserAgreement');
    this.computeTerms();
  }

  componentWillUnmount() {
    try {
      window.removeEventListener('message', this.receiveMessage, false);
      window.removeEventListener('storage', this.handleStorage, false);
      this.termsWindow && this.termsWindow.close();
    } catch (e) {}
  }

  componentDidUpdate() {
    // should only be delayed if groupConfig was still loading,
    // which should only happen on refresh when running locally
    if (!this.didComputeTerms) {
      this.computeTerms();
    }
  }

  determinePharmaProgram({
    enrollmentMeds,
    groupConfig,
    patient,
    pharmaConfig,
    pharmaProgram,
  }) {
    const enrollmentMedsWithDetails = getFullMedicationListFromEnrollmentMeds(
      groupConfig.medications,
      enrollmentMeds
    );
    let programName = determinePharmaProgramEligibility({
      patient,
      meds: enrollmentMedsWithDetails,
      pharmaConfig,
      subdomain: SUBDOMAIN,
    });

    if (programName !== pharmaProgram.selectedProgram) {
      programName = DEFAULT_PHARMA_PROGRAM;
    }
    return programName;
  }

  computeTerms() {
    if (this.didComputeTerms) return;

    const {
      enrollmentData,
      enrollmentMeds,
      groupConfig,
      i18n,
      isIFrame,
      pharmaConfig,
      pharmaProgram,
      setConfirmedPharmaProgram,
      updateEnrollmentData,
    } = this.props;
    const { patient } = enrollmentData;

    // should only ever affect local development reload
    // since group is fetched async
    if (!groupConfig.medications || groupConfig.medications.length === 0)
      return;

    // 1. recompute pharma program in case they no longer qualify now that we know more
    const programName = this.determinePharmaProgram({
      enrollmentMeds,
      groupConfig,
      patient,
      pharmaConfig,
      pharmaProgram,
    });

    // 2. figure out the terms we need to show
    const terms = determineTerms(groupConfig, programName);

    this.didComputeTerms = true;
    this.setState({ terms });

    if (programName && programName !== pharmaProgram.confirmedProgram) {
      setConfirmedPharmaProgram(programName);
    }
    updateEnrollmentData({ acceptedUserAgreements: terms });

    // if we're in an iframe, set up events and launch window
    if (isIFrame && IS_IOS() && !isMobileGroup()) {
      window.addEventListener('message', this.receiveMessage, false);
      window.addEventListener('storage', this.handleStorage, false);
      const uri = termsPath(
        terms[0].filename,
        groupConfig.country,
        i18n.language
      );
      this.termsWindow = this.launchTermsModal(uri);
    }
  }

  launchTermsModal(terms) {
    logEvent('click', {
      action: 'click-LaunchTermsModal',
      label: 'click-LaunchTermsModal',
    });

    const termsWindow = window.open(
      `/terms-of-use?terms_uri=${encodeURIComponent(terms)}`,
      'Propeller Health | Terms of Use',
      windowProps(800)
    );

    // what to do for this?
    // console.log(termsWindow);
    // if (!termsWindow) {
    //   window.alert('window blocked. do something else');
    // }

    return termsWindow;
  }

  receiveMessage(e) {
    const { goBackHistory } = this.props;
    if (e.origin !== window.location.origin) {
      console.warn('received message', e.data, 'from', e.origin);
      return;
    }

    if (e.data === TERMS_ACCEPTED_MESSAGE) {
      e.source.close();
      this.signUp();
    } else if (e.data === TERMS_CANCELED_MESSAGE) {
      e.source.close();
      goBackHistory();
    } else {
      console.warn('received message', e.data, 'from', e.origin);
    }
  }

  handleStorage(e) {
    const { goBackHistory } = this.props;
    if (e.key === TERMS_ACCEPTED_MESSAGE) {
      this.signUp();
    } else if (e.key === TERMS_CANCELED_MESSAGE) {
      goBackHistory();
    }
  }

  returnToMobileEnrollment(id, enrollmentData) {
    const { email, password } = enrollmentData;
    if (window.Enrollment || window.webkit) {
      fetchLoginToken(id)
        .then((data) => {
          if (window.Enrollment) {
            window.Enrollment.completeEnrollment(
              data.loginToken,
              API_HOST,
              email,
              password
            );
          } else if (window.webkit) {
            window.webkit.messageHandlers.completeEnrollment.postMessage({
              token: data.loginToken,
              url: API_HOST,
              email,
              password,
            });
          }
        })
        .catch((err) => {
          if (window.Enrollment) {
            window.Enrollment.error(err);
          } else if (window.webkit) {
            window.webkit.messageHandlers.error.postMessage(err);
          }
        });
    }
  }

  signUp() {
    let {
      enrollmentData,
      enrollmentMeds,
      mongoObjectId,
      pharmaProgram,
      quiz,
      goBackHistory,
      pushHistory,
      resetObjectId,
      setError,
      groupConfig,
      i18n,
      t,
    } = this.props;
    const { terms } = this.state;

    const IS_CAREGIVER = 'caregiver' === enrollmentData.enrollmentRole;
    const id = enrollmentData.id;

    const analyticsLabel = `createAccount-${mongoObjectId}`;
    logEvent('User', {
      action: analyticsLabel,
      label: analyticsLabel,
    });

    this.setState({ submitting: true });

    // build out terms path before saving.
    // is it safe to assume they actually saw the correct terms?
    // or should we recieve the terms viewed as arguments?
    const uas = terms.map((ua) => ({
      date: moment.utc().toISOString(),
      filename: termsPath(ua.filename, groupConfig.country, i18n.language),
    }));

    enrollmentData = {
      ...enrollmentData,
      acceptedUserAgreements: uas,
    };

    // first we establish that the current user is the same
    establishUserContext(IS_CAREGIVER, id)
      .then((caregiver) => {
        // console.log("after establishUserContext", caregiver);
        return caregiver && caregiver.id
          ? Promise.resolve(caregiver)
          : IS_CAREGIVER
          ? this.submitUser('caregiver', enrollmentData)
          : Promise.resolve();
      })
      .then((caregiver) => {
        // update enrollment data with tags
        enrollmentData = {
          ...enrollmentData,
          tags: buildEnrollmentTags({
            pharmaProgram,
            patientMeds: enrollmentMeds,
            groupMeds: groupConfig.medications,
          }),
        };
        return this.submitUser('patient', enrollmentData, caregiver);
      })
      .then((user) => {
        const updatedEnrollmentMeds = ensureControllerMedsHaveUsageList(
          enrollmentMeds,
          groupConfig.medications
        );
        return submitPlansAndQuiz(
          user.id,
          updatedEnrollmentMeds,
          quiz,
          enrollmentData
        );
      })
      .then(identify(mongoObjectId))
      .then(resetObjectId)
      .then(() => {
        analytics.fbq('track', 'CompleteRegistration');
        if (
          isMobileGroup() &&
          enrollmentData.source === 'mobile' &&
          mongoObjectId
        ) {
          this.returnToMobileEnrollment(mongoObjectId, enrollmentData);
        } else {
          pushHistory('/download');
        }
      })
      .catch((err) => {
        this.setState({ submitting: false });
        setError(err);
        window.alert(
          t('SUBMIT_ERROR', {
            phone: getSupportPhone(groupConfig.country).phone,
          })
        );
        goBackHistory();
      });
  }

  submitUser(role, data, caregiver) {
    const { mongoObjectId, updateEnrollmentData, setUserId } = this.props;
    const hubRequired = {
      hubRequired: ['true', true].includes(data.hubRequired) ? true : undefined,
    };

    updateEnrollmentData(hubRequired);

    return submitUser(
      dataForRole(role, data, hubRequired, caregiver, mongoObjectId)
    ).then((user) => {
      if (role === data.enrollmentRole) {
        setUserId(user.id);
      }

      return Promise.resolve(user);
    });
  }

  render() {
    const {
      goBackHistory,
      groupConfig: { country },
      i18n,
      t,
    } = this.props;
    const { submitting, terms } = this.state;

    return (
      <PageHeading pageTitle={t('TERMS_AND_CONDITIONS')} t={t}>
        {terms && terms.length > 0 ? (
          <TermsOfUse
            cancelFunc={goBackHistory}
            nextFunc={this.signUp}
            submitting={submitting}
            submitText={
              submitting ? 'enrollment:SUBMITTING' : 'enrollment:I_AGREE'
            }
            containerStyle={borderedBox}
            contentStyle={scrollingContent}
            termsPath={termsPath(terms[0].filename, country, i18n.language)}
          />
        ) : (
          <div style={{ textAlign: 'center' }}>Loading terms...</div>
        )}
      </PageHeading>
    );
  }
}

UserAgreement.propTypes = {
  enrollmentData: PropTypes.object.isRequired,
  enrollmentMeds: PropTypes.array.isRequired,
  goBackHistory: PropTypes.func.isRequired,
  groupConfig: PropTypes.object.isRequired,
  isIFrame: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  mongoObjectId: PropTypes.string,
  pushHistory: PropTypes.func.isRequired,
  quiz: PropTypes.object.isRequired,
  resetObjectId: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setUserId: PropTypes.func.isRequired,
  updateEnrollmentData: PropTypes.func.isRequired,
};

UserAgreement.defaultProps = {
  enrollmentMeds: [],
};

export default compose(
  container,
  withTranslation(['enrollment'])
)(UserAgreement);
