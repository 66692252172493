import {
  SET_GROUP_CONFIG,
  SET_DEVICE_TYPE,
  CONFIGURE_MED_SCHEDULING,
  CONFIGURE_CAREGIVER,
  CONFIGURE_COPPA,
  SHARE_ACCOUNT_ACCESS,
} from '../actions';

import { stepList } from '../lib/enrollment_steps';
import { exists, isController } from '../lib/utilities';

const REQUIREABLE = ['gender'];

const setActivation = (step, isActive) => {
  return (s) => {
    if (s.key === step) {
      return { ...s, active: isActive };
    }
    return s;
  };
};

// make adjustments based on retrieved group config
const stepsForGroupConfig = (steps, config) => {
  const requirements = [].concat(config.demographics);

  return steps.map((s) => {
    if (s.key === 'medicalIds') {
      return {
        ...s,
        active:
          config.medicalIdsRequired && config.medicalIdsRequired.length > 0,
      };
    }

    if (REQUIREABLE.includes(s.key)) {
      return {
        ...s,
        active: requirements.includes(s.key),
      };
    }

    return s;
  });
};

// manipulate enrollment steps based on other state factors
// refactred to only determin whether or not a step should be presented.
// the objects that represent a step are now grabbed from a function outside of the state
export default function enrollmentSteps(state = stepList, action) {
  switch (action.type) {
    case SET_GROUP_CONFIG:
      return stepsForGroupConfig(state, action.data);

    case SET_DEVICE_TYPE:
      // determine if we need to show the smartphone question
      return state.map(setActivation('smartphone', !action.device));

    case CONFIGURE_MED_SCHEDULING:
      // display med scheduling if they have controller med
      const { enrollmentMeds, groupMeds = [] } = action;
      const controllerMeds = enrollmentMeds
        .map((m) => groupMeds.find((_m) => m.medicationId === _m.id))
        .filter((m) => exists(m) && isController(m));

      const HAS_CONTROLLER = controllerMeds.length > 0;

      return state.map(setActivation('schedule', HAS_CONTROLLER));

    case CONFIGURE_COPPA:
      return state.map(setActivation('coppa', !!action.coppaRequired));

    case CONFIGURE_CAREGIVER:
      const isCaregiver = 'caregiver' === action.role;
      return state.map((s) => {
        if (s.key === 'caregiverInfo') {
          return { ...s, active: isCaregiver };
        } else if (s.key === 'shareAccess') {
          return { ...s, active: isCaregiver };
        } else {
          return s;
        }
      });

    case SHARE_ACCOUNT_ACCESS:
      return state.map(
        setActivation('patientCredentials', action.shareAccess === 'yes')
      );

    default:
      return state;
  }
}
