import React from 'react';
import PropTypes from 'prop-types';

import './color-bar.scss';

export const ColorBar = ({ color }) => {
  return <div className={`color-bar ${color}`} />;
};

ColorBar.propTypes = {
  color: PropTypes.string,
};

export default ColorBar;
