import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputMask from 'react-input-mask';

const styles = {
  wrapper: { position: 'relative' },
  button: {
    position: 'absolute',
    right: '1px',
    top: '1px',
    border: 'none',
    backgroundColor: 'white',
  },
};

class EditableCodeInput extends React.Component {
  state = {
    edit: false,
    accessCode: this.props.value,
    message: { type: null, msg: null },
    validating: false,
  };

  constructor(props) {
    super(props);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.edit && this.state.edit) {
      // we render a react-input-mask element inside the bootstrap component
      // the inputRef functon we pass through gets applied to the masked input element
      // in react-input-mask 2.0.3, myref.input was removed, so we instead use
      // myref.getInputDOMNode(), which is defined in the input mask component
      const input = this.textInput.getInputDOMNode();
      input.focus();
      input.select();
    }
  }

  toggleEdit() {
    const { edit } = this.state;

    if (!edit) {
      this.setState({ edit: true });
    }
  }

  onChange(e) {
    this.setState({ accessCode: e.target.value });
  }

  onBlur() {
    const { accessCode } = this.state;
    const { value: initialValue } = this.props;

    // has it changed?
    if (accessCode !== initialValue) {
      this.verifyChange(accessCode);
    } else {
      this.setState({ edit: false });
    }
  }

  verifyChange(code) {
    const { validate, onUpdate, value: initialValue } = this.props;
    code = code.replace(/[^0-9-]/g, '').toUpperCase();

    if (code.length < 9) {
      return this.setState({
        message: { type: 'warning', text: 'ES_CODE_TOO_SHORT' },
      });
    }

    validate(code)
      .then(({ eligible }) => {
        const newState = { edit: false, validating: false };
        // if it's good, we publish it out
        // if it's not, we print a message
        // and go back to the initial props value?
        if (eligible) {
          this.setState({
            ...newState,
            message: { type: 'success', text: 'VERIFIED_UPDATED_CODE' },
          });
          onUpdate(code);
        } else {
          this.setState({
            ...newState,
            message: { type: 'error', text: 'COULD_NOT_VERIFY_UPDATED_CODE' },
            accessCode: initialValue,
          });
        }
      })
      .catch(console.error);
  }

  render() {
    const { t } = this.props;
    const { edit, accessCode, message } = this.state;
    return (
      <Form.Group
        controlId='accessCodeInput'
        size='lg'
        style={styles.wrapper}
        validationState={message.type}
      >
        <Form.Label srOnly>{t('ACCESS_CODE_LABEL')}</Form.Label>
        <Form.Control
          as={InputMask}
          className='access-code-input'
          type='tel'
          readOnly={!edit}
          value={accessCode}
          mask='9999-9999'
          onChange={this.onChange}
          onBlur={this.onBlur}
          inputRef={(input) => {
            this.textInput = input;
          }}
        />
        {!edit && (
          <Button
            size='lg'
            onClick={this.toggleEdit}
            tabIndex={-1}
            style={styles.button}
          ></Button>
        )}
        <Form.Text>{message.text ? t(message.text) : ' '}</Form.Text>
      </Form.Group>
    );
  }
}

export default EditableCodeInput;
