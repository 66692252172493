const languageMap = {
  'ca-ES': 'Català',
  'de-DE': 'Deutsch',
  'en-AU': 'English',
  'en-CA': 'English',
  'en-GB': 'English',
  'en-IE': 'English',
  'en-US': 'English',
  'es-AR': 'Español',
  'es-ES': 'Español',
  'es-MX': 'Español',
  'es-US': 'Español',
  'fr-CA': 'Français',
  'fr-FR': 'Français',
  'it-IT': 'Italiano',
  'ko-KR': '한국어',
  'nl-NL': 'Nederlands',
  'pt-PT': 'Português',
  'ru-RU': 'русский язык',
};

export const getLanguageName = (code) => {
  return languageMap[code];
};
