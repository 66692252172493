import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import PageHeading from '../../../components/PageHeading/PageHeading';
import Title from '../../../components/Title/Title';
import { SUBDOMAIN } from '../../../lib/utilities/environment';
import { logEvent, logPage } from '../../../lib/analytics';
import { basePrice } from '../../../lib/sensors';
import container from './container';
import './sponsorship.scss';

class NoSponsor extends Component {
  componentDidMount() {
    logPage('MedSelect-NoSponsor');
  }

  handleClick() {
    logEvent('click', {
      action: 'launch-WAG-findcare',
      label: 'no-sponsor',
    });
  }

  WAGlink() {
    return (
      <Trans i18nKey='VISIT_WAG_TO_ORDER' basePrice={basePrice}>
          <a
            href='https://www.walgreens.com/findcare/partner/propeller'
            rel='noopener noreferrer'
            target='_blank'
            className='btn-link'
            onClick={this.handleClick}
          >
            Walgreens Find Care
          </a>
        </Trans>
    );
  }

  render() {
    const { disease, t } = this.props;

    return (
      <div className='sponsorship no-sponsor'>
        <PageHeading pageTitle={t('PAGE_TITLE_NO_SPONSOR')} t={t}>
          <Row>
            <Col xs={12}>
              {disease === 'copd' && SUBDOMAIN === 'iadtc' ? (
                <Title
                  className='sm-left-lg-center-text'
                  text={t('ORDER_OPTIONS')}
                />
              ) : (
                <>
                  <Title
                    className='sm-left-lg-center-text'
                    text={t('SENSORS_AVAILABLE_BUT_NOT_THRU_SPONSORS')}
                    level={1}
                  />
                  <Title
                    className='sm-left-lg-center-text'
                    text={t('ORDER_OPTIONS')}
                    level={2}
                  />
                </>
              )}
            </Col>
            <Col xs={12}>
              <ol>
                <li className='sponsorship-text'>
                  <p>{t('ORDER_OPTIONS_1_a')}{this.WAGlink()}{t('ORDER_OPTIONS_1_b')}</p>
                </li>
                <li className='sponsorship-text'>
                  <p>{t('ORDER_OPTIONS_2')}</p>
                </li>
              </ol>
            </Col>
          </Row>
        </PageHeading>
      </div>
    );
  }
}

NoSponsor.propTypes = {
  disease: PropTypes.string.isRequired,
  groupConfig: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withTranslation('medSelection'), container)(NoSponsor);
