import React from 'react';
import { Row, Col} from 'react-bootstrap';

import GaLink from '../../components/GaLink';
import GreenButton from '../../components/Button/GreenButton';
import {
  queryStringFromObject,
  ANDROID_LINK,
  IOS_LINK,
  MOBILE_DEVICE,
} from '../../lib/utilities';
import Title from '../../components/Title/Title';
import { getReferrerLink } from '../../api/user';
import download_app from '../../assets/images/download_app.svg';

import './styles.scss';

class Smartphone extends React.Component {

state = {
  referrerLink: null,
};
mobileDevice = MOBILE_DEVICE();
IS_DESKTOP = !this.mobileDevice;

  androidPlayStoreLink(shortUrl) {
    const { group } = this.props;

    const query = {
      gc: (group && group.name) || 'try',
      pre: 'false',
      slink: encodeURI(shortUrl),
    };

    return `${ANDROID_LINK}&referrer=${encodeURIComponent(
      queryStringFromObject(query)
    )}`;
  }

  componentDidMount() {
    const body = {
      email: this.props.enrollmentData && this.props.enrollmentData.email,
    };
    if (this.props.enrollmentData && this.props.enrollmentData.email) {
      getReferrerLink(body)
        .then((data) => {
          const referrerLink = this.androidPlayStoreLink(data.shortUrl);
          this.setState(() => {
            return { referrerLink: referrerLink };
          });
        })
        .catch((err) => {
          this.props.setError(err);
        });
    }
  }


  render() {
    const { t } = this.props;

    // order is important here as ios never has a referrerLink
    const downloadLink = 
      'ios' === this.mobileDevice
      ? IOS_LINK
      : (Boolean(this.state.referrerLink))
      ? this.state.referrerLink
      : 'https://play.google.com/store/apps/details?id=com.asthmapolis.mobile'

    const mobileLinkOptions = {
      href: downloadLink,
      target: '_blank',
      rel: 'noopener noreferrer',
    };

    return (
      <div className='smartphone-grid break-words'>
        <Title text={t('ONE_LAST_STEP')} level={1} className='font-bold' />
          <Row>
            <Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
              <img src={download_app} alt={t('PROPELLER_APP')} width={166} height={166} />
              <Title text={t('SIGN_INTO_APP_COMPLETE_ORDER')} level={2} className='font-bold' />
              <p>{t('SIGN_IN_TO_SHIP_SENSOR')}</p>
              <div className='app-stores-container'>
                {this.IS_DESKTOP ? (
                  <div className='store-container'>
                    <GreenButton
                      href='/download-instructions'
                      className='btn-wrap main-dl-btn'
                      as={GaLink}
                      action='afterEnroll-downloadButton'
                    >
                      {t('DOWNLOAD_APP')}
                    </GreenButton>
                  </div>
                ) : null}

                {'android' === this.mobileDevice && (
                  <div className='store-container'>
                        <GreenButton
                          {...mobileLinkOptions}
                          className='btn-wrap main-dl-btn'
                          as={GaLink}
                          action='afterEnroll-downloadButton'
                        >
                          {t('DOWNLOAD_APP')}
                        </GreenButton>
                  </div>
                )}

                {'ios' === this.mobileDevice && (
                  <div className='store-container'>
                    <GreenButton
                      {...mobileLinkOptions}
                      className='btn-wrap main-dl-btn'
                      as={GaLink}
                      action='afterEnroll-downloadButton'
                    >
                      {t('DOWNLOAD_APP')}
                    </GreenButton>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
    );
  }
}

export default Smartphone;
