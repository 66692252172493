import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import BodyClassName from 'react-body-classname';

import mainImage from '../../assets/images/main-image1152x768.jpg';
import NavBar from '../NavBar';

import { HAS_WINDOW } from '../../lib/utilities';

const description =
  'Propeller helps you understand what may be causing your asthma and COPD symptoms so you can live a healthy and active life.';

const SCROLL_CLASS = 'scrolled';

export class MainLayout extends React.Component {
  state = {
    scrolled: HAS_WINDOW && window.pageYOffset > 20,
  };

  setScroll() {
    const scrolled = window.pageYOffset > 20;
    if (scrolled !== this.state.scrolled) {
      this.setState({
        scrolled,
      });
    }
  }

  constructor(props) {
    super(props);
    this.setScroll = this.setScroll.bind(this);
  }

  componentDidMount() {
    const { t } = this.props;
    document.addEventListener('scroll', this.setScroll, false);

    if (window && window.zE) {
      window.zE(function () {
        window.$zopim(function () {
          window.$zopim.livechat.prechatForm.setGreetings(
            t('CHAT_GREETING_MESSAGE')
          );
        });
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.setScroll, false);
  }
  render() {
    const { location, children, isIFrame, domain, t, i18n } = this.props;
    const { scrolled } = this.state;
    const lang =
      typeof i18n.language === 'string' ? i18n.language.split(/[-_]/)[0] : 'en';

    const origin =
      domain && !domain.includes('localhost')
        ? domain
        : 'https://signup.propellerhealth.com';

    const bodyClasses = scrolled ? SCROLL_CLASS : '';

    return (
      <BodyClassName className={bodyClasses}>
        <Fragment>
          <Helmet
            defaultTitle='Propeller Health'
            titleTemplate='Propeller Health - %s'
          >
            <html lang={lang} />
            <title itemProp='name' lang={lang}>
              Propeller Health
            </title>
            <meta name='description' content={description} />
            <meta property='og:url' content={`${origin}${location.pathname}`} />
            <meta property='og:title' content='Propeller Health' />
            <meta property='og:description' content={description} />
            <meta property='og:image' content={`${origin}${mainImage}`} />
            <meta property='fb:profile_id' content='PropellerHealth' />
            <meta property='og:type' content='website' />
            <meta name='twitter:card' content='summary' />
            <meta name='twitter:title' content='Propeller Health' />
            <meta name='twitter:description' content={description} />
            <meta name='twitter:image' content={`${origin}${mainImage}`} />
            <meta
              name='twitter:image:alt'
              content={t('PROPELLER_SENSOR_APP')}
            />
            <link rel='canonical' href={`${origin}${location.pathname}`} />
          </Helmet>
          {!isIFrame && <NavBar t={t} />}
          {children}
        </Fragment>
      </BodyClassName>
    );
  }
}

export default withTranslation(['common'])(MainLayout);
