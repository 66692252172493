import React, { Component } from 'react';
import { compose } from 'redux';
import PageHeading from '../../components/PageHeading/PageHeading';
import { withTranslation } from 'react-i18next';
import { Route, Switch, Redirect } from 'react-router-dom';

import container from './container';

import CATScore from '../CATScore';
import BackNextButtons from '../../components/BackNextButtons';
import ProgressIndicator from '../../components/ProgressIndicator';

import { camelPath } from '../../lib/utilities';
import { logEvent } from '../../lib/analytics';

import bad from '../../assets/images/ic_cat_bad.svg';
import good from '../../assets/images/ic_cat_good.svg';

export class CATQuestions extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.goNext = this.goNext.bind(this);
    this.updateSelection = this.updateSelection.bind(this);

    this.state = {
      selection: undefined,
    };
  }

  scrollToTop() {
    const el = document.querySelector('.question-content-wrapper');

    if (el) {
      el.scrollTop = 0;
    }
  }

  goNext(e) {
    if (e && e.preventDefault) e.preventDefault();

    const { location, answer_count: questionIdx, addResponse } = this.props;

    const label = `${camelPath(
      location.pathname
    )}-click-catNextButton-to${questionIdx}`;

    logEvent('click', {
      action: label,
      label: label,
    });
    addResponse(this.state.selection);
    this.setState({
      selection: undefined,
    });

    this.scrollToTop();
  }

  goBack() {
    const {
      answer_count,
      answers,
      removeResponse,
      location,
      goBackHistory,
    } = this.props;

    const baseLabel = `${camelPath(location.pathname)}-click-catBackButton-to}`;

    const label = `${baseLabel}${answer_count > 0 ? answer_count : 'Start'}`;

    logEvent('click', {
      action: label,
      label: label,
    });

    if (answer_count > 0) {
      this.setState({
        selection: answers[answers.length - 1].value,
      });
      removeResponse();
      this.scrollToTop();
    } else {
      goBackHistory();
    }
  }

  updateSelection(e) {
    this.setState({ selection: Number.parseInt(e.target.value, 10) });
  }

  render() {
    const {
      quiz_length,
      current_question,
      answer_count: idx = 0,
      score,
      t,
      updateEnrollmentData,
    } = this.props;

    if (idx === quiz_length) {
      updateEnrollmentData({
        patient: {
          cat: score,
        },
      });
      return <Redirect to={`/assessment/result?&score=${score}`} push />;
    }

    const options = [],
      opts = current_question.options,
      selection = this.state.selection,
      low = 0,
      high = 5,
      width = `${(100 / 6).toFixed(4)}%`;

    for (var i = low; i <= high; i++) {
      let key = `cat-${i}`;
      options[i] = (
        <li key={key} style={{ width: width }}>
          <input
            className='custom-radio'
            id={key}
            type='radio'
            name={`cat-${i}`}
            value={i}
            checked={i === selection}
            onChange={this.updateSelection}
          />
          <label htmlFor={key}>
            {i}
            {/**
             * ideally this would be inside .cat-question, but we want it
             * positioned relative to the label center and i could't think
             * of a better way right now
             */}
            {opts[i] && (
              <div className='pointer-wrapper'>
                <div className='pointer' />
              </div>
            )}
          </label>
          {/**
           * this is outside the label because display is more robust
           * if it's positioned relative to the full width of the list
           * of options, eg. for setting the width;
           */}
          {opts[i] && (
            <div className='cat-question break-words'>{t(opts[i])}</div>
          )}
        </li>
      );
    }

    return (
      <PageHeading pageTitle={t('landing:TAKE_THE_QUIZ')} t={t}>
        <form className='modal-form' onSubmit={this.goNext}>
          <ProgressIndicator total={quiz_length} question={idx + 1} />
          <div className='question-content-wrapper'>
            <div className='question-content'>
              <div className='cat-header question-header text-center'>
                {t('QUESTION_NUM', { number: idx + 1, total: quiz_length })}
              </div>
              <div className='act-question cat-question-text text-center'>
                {t(current_question.question)}
              </div>
              <div className='clearfix'>
                <img
                  src={good}
                  alt=''
                  style={{ width: '30%', float: 'left' }}
                />
                {/*
                idx === 0 &&
                <div style={{position: "absolute", left: "0", right: "0"}}>
                  <div className="cat-help-text text-center">
                    Select a number below from a scale of 0-5 that best describes you.
                  </div>
                </div>
              */}
                <img
                  src={bad}
                  alt=''
                  style={{ width: '30%', float: 'right' }}
                />
              </div>
              <ul className='radio-selectors number-options cat-options border-top text-center'>
                {options}
              </ul>
            </div>
          </div>
          <div className='act-buttons'>
            <BackNextButtons
              backClick={this.goBack}
              nextClick={this.goNext}
              nextType='submit'
              disabled={'number' !== typeof selection}
              rowStyle={{ margin: '0 auto' }}
            />
          </div>
        </form>
      </PageHeading>
    );
  }
}

export const CATQuiz = ({ match, ...rest }) => {
  return (
    <Switch>
      <Route
        exact
        path={match.url}
        render={(props) => <CATQuestions {...rest} {...props} />}
      />
      <Route
        exact
        path={`${match.url}/result`}
        render={(props) => <CATScore {...rest} {...props} />}
      />
    </Switch>
  );
};

export default compose(
  container,
  withTranslation(['quiz', 'landing'])
)(CATQuiz);
