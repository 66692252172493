import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GreenButton from '../../components/Button/GreenButton';
import GreyButton from '../../components/Button/GreyButton';

export const EnrollmentButtons = ({ handleBack, handleSubmit, t }) => {
  return (
    <Row className='enrollment-buttons'>
      <Col xs={{ span: 7, order: 'last' }}>
        <GreenButton type='submit' onClick={handleSubmit}>
          {t('NEXT')}
        </GreenButton>
      </Col>
      <Col xs={5}>
        <GreyButton onClick={handleBack} style={{ width: '100%' }}>
          {t('BACK')}
        </GreyButton>
      </Col>
    </Row>
  );
};

EnrollmentButtons.propTypes = {
  handleBack: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(EnrollmentButtons);
