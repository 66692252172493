import React, { Component } from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import BodyClassName from 'react-body-classname';
import GreenButton from '../Button/GreenButton';
import ResponsiveBackgroundImage from '../ResponsiveBackgroundImg';
import { logEvent } from '../../lib/analytics';
import { i18nImg, i18nImgSrc } from '../../lib/utilities';
import i18n from 'i18next';
import transparentLogo from '../../assets/images/propeller-dots-logo250.svg';
import container from './container';

import './styles.scss';

const styles = {
  button: {
    width: '100%',
    maxWidth: '250px',
    marginTop: '20px',
  },
};

const splashImg1200 = i18nImg('splash-screen/splash-screen-img-992.png');
const splashImg992 = i18nImg('splash-screen/splash-screen-img-992.png');
const splashImg768 = i18nImg('splash-screen/splash-screen-img-768.png');
const splashImg400 = i18nImg('splash-screen/splash-screen-img-400.png');
const splashSrcSet = `${splashImg1200} 1200w, ${splashImg992} 992w, ${splashImg768} 768w, ${splashImg400} 400w`;

class DiseaseSelection extends Component {
  state = {
    show: false,
  };

  componentDidMount() {
    if (this.props.needsDisease) {
      this.handleShow();
    }
  }
  handleShow = () => {
    this.setState(() => {
      return { show: true };
    });
  };
  handleClose = () => {
    this.setState(() => {
      return { show: false };
    });
  };
  onClick = (disease) => {
    const { setDisease } = this.props;
    const label = `click-setDisease-${disease}`;
    logEvent('click', {
      action: label,
      label: label,
    });

    setDisease(disease);
    this.handleClose();
  };

  render() {
    const { className = '', t } = this.props;
    const lng = i18n.language || 'en-US';
    return (
      <div id='disease-selection'>
        <Modal
          show={this.state.show}
          dialogClassName='disease-selection-modal'
          backdrop={true}
        >
          <BodyClassName className='no-scroll'>
            <ResponsiveBackgroundImage
              src={i18nImgSrc(splashImg992, lng)}
              srcSet={i18nImgSrc(splashSrcSet, lng)}
              sizes='(max-width: 400px) 400px, (max-width: 768px) 768px, (max-width: 991px) 992px, (min-width: 992px) 1200px'
              locale={lng}
              className='splash-screen'
            >
              <Container
                as='section'
                fluid
                className={`disease-selection-outer-wrapper ${className}`}
              >
                <Row>
                  <Col role='group' aria-labelledby='disease-selection-text'>
                    <div className='text-center col-xs-12 '>
                      <div className='disease-selection-text'>
                        <img
                          src={transparentLogo}
                          alt=''
                          width='50'
                          height='50'
                        />
                        <h1 id='disease-selection-text'>
                          {t('EXPLORE_PROPELLER')}
                        </h1>
                        <p className='subtitle'>{t('TO_GET_STARTED')}</p>
                      </div>
                    </div>
                    <Container>
                      <Row>
                        <Col
                          xs={12}
                          md={{ span: 3, offset: 3 }}
                          className='text-center'
                        >
                          <GreenButton
                            className='btn-primary'
                            style={styles.button}
                            onClick={() => this.onClick('asthma')}
                          >
                            {t('ASTHMA_CAPITALIZED')}
                          </GreenButton>
                        </Col>

                        <Col xs={12} md={3} className='text-center'>
                          <GreenButton
                            className='btn-primary'
                            style={styles.button}
                            onClick={() => this.onClick('copd')}
                          >
                            {t('COPD')}
                          </GreenButton>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </ResponsiveBackgroundImage>
          </BodyClassName>
        </Modal>
      </div>
    );
  }
}

export default compose(
  container,
  withTranslation(['common'])
)(DiseaseSelection);
