import { IS_DEV_OR_TEST } from '../lib/utilities';

const logger = (store) => (next) => (action) => {
  if (IS_DEV_OR_TEST) {
    console.group(action.type);
    console.info('dispatching', action);
    let result = next(action);
    console.log('next state', store.getState());
    console.groupEnd();
    return result;
  } else {
    return next(action);
  }
};

export default logger;
