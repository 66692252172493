import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import container from '../container';
import { withTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import PageHeading from '../../../components/PageHeading/PageHeading';
import TextInput from '../../../components/InputField/TextInput/TextInput';
import Title from '../../../components/Title/Title';
import Select from '../../../components/InputField/Select/Select';
import EnrollmentButtons from '../Buttons';
import { REGION_OPTIONS } from '../../../data/enrollment';
import { logPage } from '../../../lib/analytics';

export class CaregiverAddress extends React.Component {
  constructor(props) {
    super(props);
    const address = props.enrollmentData.mailingAddress;

    this.state = {
      street: address.street || '',
      street2: address.street2 || '',
      city: address.city || '',
      stateOrRegion: address.stateOrRegion || '',
      postalCode: address.postalCode || '',
      errors: [],
    };
  }

  componentDidMount = () => {
    if (!this.props.enrollmentData.caregiver) {
      this.props.pushHistory('/enrollment');
    }

    logPage('Coppa-CaregiverAddress');
  };

  setErrorMessage = (error) => {
    this.setState(() => {
      return { errorMessages: error };
    });
  };

  setErrors = (errors) => {
    this.setState(() => {
      return { errors: errors };
    });
  };

  // check that no required fields are empty
  checkForErrors = () => {
    return ['street', 'city', 'stateOrRegion', 'postalCode', 'street2']
      .map((item) => {
        if (item === 'street') {
          return this.state[item].length >= 1 && this.state[item].length <= 50
            ? null
            : item;
        }
        if (item === 'street2') {
          return this.state[item].length <= 50 ? null : item;
        } else {
          return this.state[item].length >= 1 ? null : item;
        }
      })
      .filter((err) => err !== null);
  };

  isValid = () => {
    const errors = this.checkForErrors();

    if (errors.length || this.state.errors.length) {
      this.setErrors(errors);
      return false;
    }
    return true;
  };

  handleChange = (data) => {
    const key = Object.keys(data)[0];
    const errors = this.state.errors.filter((error) => error !== key);
    this.setState(data);
    this.setErrors(errors);
  };

  handleBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.isValid()) {
      this.props.updateEnrollmentData({
        mailingAddress: {
          street: this.state.street,
          street2: this.state.street2,
          city: this.state.city,
          stateCode: this.state.stateCode,
          stateOrRegion: this.state.stateOrRegion || this.state.stateCode,
          postalCode: this.state.postalCode,
        },
      });
      this.props.pushHistory('/enrollment/coppa/questions');
    }
  };

  render() {
    const { groupConfig, t } = this.props;
    const country = groupConfig.country;
    const options = REGION_OPTIONS[country];

    const stateLabel = country === 'CA' ? 'STATE_CA_LABEL' : 'STATE_LABEL';
    // const statePlaceholder =
    //   country === 'CA' ? 'STATE_CA_PLACEHOLDER' : 'STATE_PLACEHOLDER';
    return (
      <Container>
        <PageHeading pageTitle={t('coppa:PAGE_TITLE_COPPA')}>
          <Title
            text={t('coppa:COPPA_ADDRESS_QUESTION')}
            className='sm-left-lg-center-text'
            level={1}
          />
          <Form
            onSubmit={(e) => this.handleSubmit(e)}
            className='max-500 padding-4'
          >
            <TextInput
              autoComplete='shipping address-line1'
              error={this.state.errors.includes('street')}
              errorMessage='STREET_ADDRESS_ERROR'
              helpText='MAX_LENGTH'
              helpTextHidden={true}
              onChange={this.handleChange}
              label='STREET_ADDRESS_1_LABEL'
              name='street'
              required={true}
              setErrors={this.setErrors}
              t={t}
              value={this.state.street}
            />
            <TextInput
              autoComplete='shipping address-line2'
              errorMessage='STREET_ADDRESS_TOO_LONG_ERROR'
              helpText='MAX_LENGTH'
              helpTextHidden={true}
              label='STREET_ADDRESS_2_LABEL'
              name='street2'
              onChange={this.handleChange}
              setErrors={this.setErrors}
              t={t}
              value={this.state.street2}
            />
            <TextInput
              autoComplete='shipping address-level2'
              error={this.state.errors.includes('city')}
              errorMessage='CITY_ERROR'
              helpText='MAX_LENGTH'
              helpTextHidden={true}
              onChange={this.handleChange}
              label={country === 'US' ? t('CITY_LABEL') : t('CITY_CA_LABEL')}
              maxLength={40}
              name='city'
              required={true}
              t={t}
              value={this.state.city}
              warningText='CITY_TOO_LONG'
            />
            <Select
              autoComplete='shipping address-level1'
              error={this.state.errors.includes('stateOrRegion')}
              label={t(stateLabel)}
              maxLength={80}
              name='stateOrRegion'
              onChange={this.handleChange}
              options={options}
              required={true}
              t={t}
              value={this.state.stateOrRegion}
            />
            <TextInput
              autoComplete='shipping postal-code'
              error={this.state.errors.includes('postalCode')}
              errorMessage='POSTAL_CODE_ERROR'
              helpText='MAX_LENGTH'
              helpTextHidden={true}
              onChange={this.handleChange}
              label={t('POSTAL_CODE_LABEL')}
              maxLength={20}
              name='postalCode'
              required={true}
              t={t}
              value={this.state.postalCode}
              warningText='POSTAL_CODE_TOO_LONG'
            />
            <EnrollmentButtons
              handleBack={this.handleBack}
              handleSubmit={this.handleSubmit}
              t={t}
            />
          </Form>
        </PageHeading>
      </Container>
    );
  }
}

CaregiverAddress.propTypes = {
  enrollmentData: PropTypes.object.isRequired,
  groupConfig: PropTypes.object.isRequired,
  pushHistory: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateEnrollmentData: PropTypes.func.isRequired,
};

export default compose(
  withTranslation(['enrollment', 'coppa']),
  container
)(CaregiverAddress);
