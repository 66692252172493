import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

function MedNames({ meds, noAnd = false, t }) {
  let medNames;
  const andOrComma = noAnd ? ',' : ` ${t('AND')}`;

  if (meds.length === 1) {
    medNames = `${meds[0].name}`;
  } else if (meds.length === 2) {
    medNames = `${meds[0].name}${andOrComma} ${meds[1].name}`;
  } else if (meds.length > 2) {
    let name = `${meds[0].name},`;
    for (let i = 1; i < meds.length - 1; i++) {
      name = `${name} ${meds[i].name},`;
    }
    medNames = `${name} ${!noAnd ? t('AND') : ''} ${
      meds[meds.length - 1].name
    }`;
  }
  return <>{medNames}</>;
}

export default withTranslation(['medSelection'])(MedNames);

MedNames.propTypes = {
  meds: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ),
  noAnd: PropTypes.bool,
  t: PropTypes.func.isRequired,
};
