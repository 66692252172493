import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import container from '../container';
import PageHeading from '../../../components/PageHeading/PageHeading';
import TextInput from '../../../components/InputField/TextInput/TextInput';
import Title from '../../../components/Title/Title';
import EnrollmentButtons from '../Buttons';
import { getNextPage } from '../enrollment-utils';
import { logPage } from '../../../lib/analytics';
import { EnrollmentDataType } from '../../../types/enrollmentData';

export class CaregiverInfo extends React.Component {
  static propTypes = {
    enrollmentData: EnrollmentDataType,
  };

  constructor(props) {
    super(props);
    const caregiver = props.enrollmentData.caregiver;
    this.state = {
      givenName: caregiver.givenName || '',
      familyName: caregiver.familyName || '',
      errors: [],
    };
  }

  componentDidMount = () => {
    const { enrollmentData, enrollmentMeds, pushHistory } = this.props;
    if (!enrollmentMeds || enrollmentMeds.length < 1) {
      pushHistory('/');
    }
    if (!enrollmentData.enrollmentRole) {
      pushHistory('/enrollment');
    }
    logPage('Enroll-CaregiverInfo');
  };

  checkForErrors = () => {
    return ['givenName', 'familyName']
      .map((item) => {
        return this.state[item].length >= 1 ? null : item;
      })
      .filter((err) => err !== null);
  };

  setErrors = (errors) => {
    this.setState(() => {
      return { errors: errors };
    });
  };

  isValid = () => {
    // check that all fields have content
    const errors = this.checkForErrors();
    if (errors.length) {
      this.setErrors(errors);
      return false;
    }
    return true;
  };

  getNextPage = () => {
    const { enrollmentData, groupConfig, groupConfig4x } = this.props;
    return getNextPage({
      currentPage: 'caregiverInfo',
      enrollmentData,
      groupConfig,
      groupConfig4x,
    });
  };

  handleChange = (data) => {
    const key = Object.keys(data)[0];
    const errors = this.state.errors.filter((error) => error !== key);
    this.setState(data);
    this.setErrors(errors);
  };

  handleBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.isValid()) {
      const next = this.getNextPage();
      this.props.updateEnrollmentData({
        caregiver: {
          givenName: this.state.givenName,
          familyName: this.state.familyName,
        },
      });
      this.props.pushHistory(next);
    }
  };

  render() {
    const { t } = this.props;

    return (
      <PageHeading pageTitle={t('PAGE_TITLE_CAREGIVER_INFO')}>
        <Title
          text={t('CAREGIVER_QUESTION')}
          className='sm-left-lg-center-text'
          level={1}
        />
        <Row>
          <Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <Form
              onSubmit={(e) => this.handleSubmit(e)}
            >
              <TextInput
                autoComplete='given-name'
                error={this.state.errors.includes('givenName')}
                errorMessage='GIVEN_NAME_ERROR'
                onChange={this.handleChange}
                label='GIVEN_NAME_LABEL_YOUR'
                maxLength={40}
                name='givenName'
                t={t}
                value={this.state.givenName}
              />
              <TextInput
                autoComplete='family-name'
                error={this.state.errors.includes('familyName')}
                errorMessage='FAMILY_NAME_ERROR'
                onChange={this.handleChange}
                label='FAMILY_NAME_LABEL_YOUR'
                maxLength={80}
                name='familyName'
                t={t}
                value={this.state.familyName}
              />
              <EnrollmentButtons
                handleBack={this.handleBack}
                handleSubmit={this.handleSubmit}
                t={t}
              />
            </Form>
          </Col>
        </Row>
      </PageHeading>
    );
  }
}

export default compose(withTranslation('enrollment'), container)(CaregiverInfo);
