import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import container from './container';
import { camelPath } from '../../lib/utilities';
import { logEvent } from '../../lib/analytics';

export class GaLink extends Component {
  refCallBack = (node) => {
    this.anchorElement = node;
  };

  constructor(props) {
    super(props);
    this._onClick = this._onClick.bind(this);
  }

  _onClick(...rest) {
    const { onClick, action = 'clickLink', location } = this.props;
    const label = `${camelPath(location.pathname)}-click-${action}`;

    logEvent('click', {
      action: label,
      label: label,
    });

    if ('function' === typeof onClick) {
      onClick(...rest);
    }
  }

  componentDidMount() {
    if (this.props.autoFocus && this.anchorElement) {
      this.anchorElement.focus();
    }
  }

  render() {
    const { children, action, href, location, dispatch, ...rest } = this.props;

    return href ? (
      <a
        href={href}
        className={rest.className}
        disabled={rest.disabled}
        target={rest.target}
        rel={rest.rel}
        style={rest.style}
      >
        {children}
      </a>
    ) : (
      <Link {...rest} innerRef={this.refCallBack} onClick={this._onClick}>
        {children}
      </Link>
    );
  }
}

GaLink.propTypes = {
  action: PropTypes.string,
  href: PropTypes.string,
  location: PropTypes.object,
  onClick: PropTypes.func,
};

export default container(GaLink);
