import React from 'react';
import PageHeading from '../PageHeading/PageHeading';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ScoreBar from '../ScoreBar';
import { withTranslation } from 'react-i18next';
import GetStartedLink from '../../pages/Home/GetStartedLink';
import './style.scss';

const QuizResults = ({ title, score, scoreData, category, t }) => {
  return (
    <div className='act-results-wrapper'>
      <PageHeading pageTitle={t('PAGE_TITLE_QUIZ_RESULTS')} t={t}>
        <Container className='text-center' style={{ marginBottom: '3rem' }}>
          <Row>
            <Col xs={12}>
              <h1>{t(title)}</h1>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <ScoreBar data={scoreData} score={score} />
            </Col>
          </Row>

          <div className='act-result-text-wrapper'>
            <div className='act-result-text'>
              {category && category.description && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(category.description, { score }), //category.description(score)
                  }}
                />
              )}
            </div>
            <GetStartedLink buttonId='quiz-results' type='green' />
            {category.showValidation && (
              <div className='claim-validation text-center'>
                <em>* {t('SOURCE_AGGREGATED_DATA')}</em>
              </div>
            )}
          </div>
        </Container>
      </PageHeading>
    </div>
  );
};

export default withTranslation(['quiz', 'common'])(QuizResults);
