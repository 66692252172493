import React from 'react';
import BsButton from 'react-bootstrap/Button';

import './Button.scss';

const Button = ({ bsSize = 'large', ...rest }) => {
  return <BsButton size={bsSize} {...rest} />;
};

export default Button;
