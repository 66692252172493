import { connect } from 'react-redux';
import { PROPELLER_SUPPORT_EMAIL } from '../../lib/utilities';

const mapStateToProps = ({ groupConfig }) => {
  return {
    groupConfig,
    supportEmail: groupConfig.supportEmail || PROPELLER_SUPPORT_EMAIL,
  };
};

export default connect(mapStateToProps);
