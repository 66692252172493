import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import GreenButton from '../../components/Button/GreenButton';
import { logEvent } from '../../lib/analytics';
import './about-sponsorship.scss';
import Icon from '../../assets/images/icons/icon-info.svg';

const WhoText = ({ pharmaProgram, t, disease }) => {
  const sponsor = pharmaProgram && pharmaProgram.selectedProgram;
  return (
    <div>
      {sponsor === 'azExpansion' || sponsor === 'btc' ? (
        <div>
          <strong>{t('WHO')}:</strong> {t('SPONSOR_DETAIL_WHO_AZ')}
        </div>
      ) : (
        <div>
          <strong>{t('WHO')}:</strong>{' '}
          {t('SPONSOR_DETAIL_WHO_GENERIC', { disease })}
        </div>
      )}
    </div>
  );
};

const WhyText = ({ pharmaProgram, t, disease }) => {
  const sponsor = pharmaProgram && pharmaProgram.selectedProgram;
  return (
    <div>
      {sponsor === 'azExpansion' || sponsor === 'btc' ? (
        <div>
          <strong>{t('WHY')}:</strong> {t('SPONSOR_DETAIL_WHY_AZ', { disease })}
        </div>
      ) : (
        <div>
          <strong>{t('WHY')}:</strong> {t('SPONSOR_DETAIL_WHY', { disease })}
        </div>
      )}
    </div>
  );
};

const AboutMySponsorContent = ({
  disease,
  handleClose,
  show,
  t,
  pharmaProgram,
}) => {
  return (
    <Modal show={show}>
      <Modal.Body>
        <button
          aria-label='CLOSE'
          type='button'
          className='close-modal'
          onClick={handleClose}
        />
        <div className='about-sponsorship'>
          <h1>{t('SPONSOR_DETAIL_HEADLINE')}</h1>
          <div className='modal-text'>
            <WhoText disease={disease} pharmaProgram={pharmaProgram} t={t} />
            <WhyText disease={disease} pharmaProgram={pharmaProgram} t={t} />
            <div>
              <strong>{t('DETAILS')}:</strong> {t('SPONSORS_DATA_SHARING')}
            </div>
          </div>
          <GreenButton onClick={handleClose}>{t('OK_GOT_IT')}</GreenButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export class AboutSponsorship extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
    logEvent('click', {
      action: 'show-modal',
      label: 'about-sponsorship',
    });
  }

  render() {
    const { pharmaProgram, t } = this.props;

    const disease =
      this.props.disease === 'copd'
        ? this.props.disease.toUpperCase()
        : this.props.disease;
    const sponsor = pharmaProgram && pharmaProgram.selectedProgram;

    return (
      <div className='about-sponsorship-button'>
        <Button className='btn-link modal-link' onClick={this.handleShow}>
          {t('SPONSORED_BY')}{' '}
          {sponsor === 'azExpansion' || sponsor === 'btc'
            ? 'AstraZeneca'
            : sponsor}
          <img src={Icon} alt='' width='16' height='16' />
        </Button>

        <AboutMySponsorContent
          disease={disease}
          handleClose={this.handleClose}
          show={this.state.showModal}
          pharmaProgram={pharmaProgram}
          t={t}
        />
      </div>
    );
  }
}

AboutSponsorship.propTypes = {
  disease: PropTypes.oneOf(['asthma', 'copd']),
  pharmaProgram: PropTypes.shape({
    selectedProgram: PropTypes.string,
    confirmedProgram: PropTypes.string,
  }),
  t: PropTypes.func,
};

export default compose(withTranslation(['medSelection', 'common']))(
  AboutSponsorship
);
