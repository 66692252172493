import moment from '../include-moment';
import { API_DATE_FORMAT } from './constants';

export const COPPA_AGE = 13;
export const MIN_CHILD_ACT_AGE = 4;
export const MIN_ADULT_ACT_AGE = 12;

export const currentAge = (birthDate, float = false) =>
  moment().diff(moment(birthDate, API_DATE_FORMAT), 'years', float);

export const requiresCoppa = (birthdate, verified, country) =>
  country !== 'US' || [true, 'true'].includes(verified)
    ? false
    : currentAge(birthdate) < COPPA_AGE;
