import { requiresCoppa } from '../../lib/utilities/age';
import { controllerMedsForPlan } from '../../lib/utilities/medications';

// returns true if any med in the enrollmentMeds array
// is on the groupConfig's strict dose list
export function hasStrictDoseMed(meds, restrictedDoseList) {
  if (!restrictedDoseList || restrictedDoseList.length === 0) return false;
  const isStrict = meds.map((med) => {
    return restrictedDoseList.includes(med.medicationId);
  });
  return isStrict.includes(true);
}

function requiresMedId(groupConfig) {
  return groupConfig?.medicalIdsRequired?.length;
}

function isHubOnly(groupConfig4x) {
  if(groupConfig4x !== undefined){
    return groupConfig4x?.parameters?.hubOnly?.value;
  }

  return false;
}

// returns true if a strict dose med is in enrollmentMeds, or if
// patient needs hub & has a controller med in enrollmentMeds
// UPDATE: Reverting this logic temporarily. All patients with controller
// meds should see the schedule
function requiresMedSchedule(enrollmentData, enrollmentMeds, groupConfig) {
  return (
    controllerMedsForPlan(enrollmentMeds, groupConfig.medications).length > 0
  );
  // return (
  //   // NOTE: hubRequired can be “true” or true or “false” or false or “ios” or “android”
  //   ((enrollmentData.hubRequired === 'true' || enrollmentData.hubRequired === true)
  //     && planHasControllerMeds)
  //   || hasStrictDoseMed(enrollmentMeds, groupConfig.strictDoseMedicationList)
  // );
}

function requiresDemographics(groupConfig) {
  return groupConfig.demographics && groupConfig.demographics.length;
}

function isCaregiver(enrollmentData) {
  return enrollmentData.enrollmentRole === 'caregiver';
}

// documentation for WSE flow: https://docs.google.com/document/d/1v7Oh0GsW3--JZVWimOL_dpTESISQO-FQUnjkm2X71Uw/edit?usp=sharing
// returns path for next page
// leaving `/enrollment/` repetition to make it clear that this returns a URL path
export function getNextPage({
  currentPage,
  device,
  enrollmentData,
  enrollmentMeds,
  groupConfig,
  groupConfig4x,
}) {
  switch (currentPage) {
    case 'patientInfo':
      if (isCaregiver(enrollmentData)) return '/enrollment/caregiver-info';
      if (requiresMedId(groupConfig)) return '/enrollment/medical-id';
    // falls through
    case 'medicalId':
      if (requiresDemographics(groupConfig)) return '/enrollment/demographics';
      if (!requiresDemographics(groupConfig) && isHubOnly(groupConfig4x)) return '/enrollment/credentials';
    // falls through
    case 'demographics':
      if (!device && !isHubOnly(groupConfig4x)) return '/enrollment/smartphone';
      return '/enrollment/credentials';
    case 'smartphone':
      return '/enrollment/credentials';
    case 'caregiverInfo':
      if (
        requiresCoppa(
          enrollmentData.patient.birthDate,
          enrollmentData.verified,
          groupConfig.country
        )
      )
        return '/enrollment/coppa';
      if (requiresMedId(groupConfig)) return '/enrollment/medical-id';
      if (requiresDemographics(groupConfig)) return '/enrollment/demographics';
      if (!device && !isHubOnly(groupConfig4x)) return '/enrollment/smartphone';
      return '/enrollment/credentials';
    case 'coppa':
      console.log('cippaaa', groupConfig4x);
      if (requiresMedId(groupConfig)) return '/enrollment/medical-id';
      if (requiresDemographics(groupConfig)) return '/enrollment/demographics';
      if (!device && !isHubOnly(groupConfig4x)) return '/enrollment/smartphone';
      return '/enrollment/credentials';
    case 'credentials':
      if (isCaregiver(enrollmentData)) return '/enrollment/share-access';
      return '/enrollment/address';
    case 'shareAccess':
      return '/enrollment/address';
    case 'patientCredentials':
      return '/enrollment/address';
    case 'address':
      if (requiresMedSchedule(enrollmentData, enrollmentMeds, groupConfig))
        return '/enrollment/schedule';
      return '/enrollment/terms';
    default:
      return '/enrollment';
  }
}

export function delay(time = 1000) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(true);
    }, time);
  })
}