import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Col, Row } from 'react-bootstrap';
import container from './container';
import EmailThankYou from './Sponsorship/EmailThankYou';
import FormFactors from './FormFactors';
import IntroPage from './Intro/IntroPage';
import Medications from './Medications';
import MedsError from './MedsError';
import MedSummary from './MedSummary';
import NoSponsor from './Sponsorship/NoSponsor';
import YourSensors from '../YourSensors';
import './styles.scss';

const MedSelection = () => {
  const basePath = '/med-selection';

  return (
    <div className='med-selection-outer-wrapper'>
      <Container className='medications-selection'>
        <Row>
          <Col xs={12} md={{ span: 10, offset: 1 }}>
            <Switch>
              <Route path={basePath} exact component={IntroPage} />
              <Route path={`${basePath}/form-factors`} component={FormFactors} />
              <Route path={`${basePath}/medications`} component={Medications} />
              <Route path={`${basePath}/summary`} component={MedSummary} />
              <Route path={`${basePath}/your-sensors`} component={YourSensors} />
              <Route path={`${basePath}/no-sponsor`} component={NoSponsor} />
              <Route path={`${basePath}/support`} component={MedsError} />
              <Route path={`${basePath}/thank-you`} component={EmailThankYou} />
            </Switch>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default container(MedSelection);
