import i18next from 'i18next';
import moment from './lib/include-moment';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import FetchBackend from 'i18next-fetch-backend';
import { updateGroupContent } from './actions/groupConfig';
import { isObject } from './lib/utilities/objects';
import { localesForCountry } from './lib/utilities/locales';

// we may need to also update the fallback logic on a per-country basis
// ex. 'es' in Spain should fallback to 'es-ES'

i18next.use(detector).use(FetchBackend).use(initReactI18next);

// when rendered on the server we have group config before this is executed on the client.
// thus, we will ahve a country and groupLanguage. When we're not rendering on the server (local development),
// we won't have group config yet. Do we want to do something on the end of loadGroupConfig to update the i18n rules?
function initialize({ country, groupLng, lng = null, resources, reduxStore }) {
  const IS_DEV = false;
  const whitelist = localesForCountry(country).concat(IS_DEV ? 'dev' : []);

  // in case someone configures the group language to be one not supported by the country
  const defaultLng =
    groupLng && whitelist.includes(groupLng) ? groupLng : whitelist[0];

  // we need to update these options based on group config.
  const i18nextOptions = {
    whitelist: whitelist,
    // fallback seems to relate to string file lookups,
    // not any type of coercion
    lng: lng,
    fallbackLng: [defaultLng, 'en-US'],
    ns: [
      'common',
      'coppa',
      'enrollment',
      'faq',
      'landing',
      'medSelection',
      'quiz',
      'sfChat',
      'verification',
      'wag',
    ],
    defaultNS: 'common',
    load: 'currentOnly',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    debug: IS_DEV,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },

    // react-i18next options
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
      useSuspense: false,
    },
  };

  i18next.init(i18nextOptions);
  i18next.on('languageChanged', function (lang) {
    // fetch moment locale data?
    // update moment locale
    moment.locale(lang);
    // reload groupconfig (marketing content: incl. some FAQ titles and content)
    reduxStore.dispatch(updateGroupContent());
  });

  if (isObject(resources)) {
    Object.keys(resources).forEach((language) => {
      const namespaces = Object.keys(resources[language]);
      namespaces.forEach((ns) => {
        i18next.addResources(language, ns, resources[language][ns]);
      });
    });
  }
}

export default {
  initialize,
  i18n: i18next.default ? i18next.default : i18next,
};
