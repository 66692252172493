import { connect } from 'react-redux';
import { goBack as goBackHistory } from 'connected-react-router';
import { CAT_QUESTIONS } from '../../lib/cat-quiz';

import {
  addCATResponse as addResponse,
  removeCATResponse as removeResponse,
  updateEnrollmentData,
} from '../../actions';

const CAT_LENGTH = CAT_QUESTIONS.length;

const checkForCompletion = (length) => length === CAT_LENGTH;
const getCurrentQuestion = (length) => CAT_QUESTIONS[length];

const mapStateToProps = (state) => {
  const CAT = state.copdAssessmentTest;
  const answers = CAT.answers;
  const length = answers.length;

  return {
    score: CAT.score,
    is_complete: checkForCompletion(length),
    answer_count: length,
    current_question: getCurrentQuestion(length),
    answers: answers,
    quiz_length: CAT_LENGTH,
  };
};

const mapDispatchToProps = {
  addResponse,
  removeResponse,
  goBackHistory,
  updateEnrollmentData,
};

export default connect(mapStateToProps, mapDispatchToProps);
