import React from 'react';
import { Redirect } from 'react-router-dom';
import QuizResults from '../../components/QuizResults';
import { CAT_SCORES } from '../../lib/cat-quiz';
import { objectFromQueryString } from '../../lib/utilities';
import analytics from '../../lib/analytics';

import '../ActScore/styles.scss';

const CATScore = (props) => {
  const query = objectFromQueryString(props.location.search || '');
  const score = Number.parseInt(props.score || query.score, 10);

  if (isNaN(score)) {
    return (
      <Redirect
        to={{
          pathname: '/take-the-quiz',
          state: Object.assign({}, props.location.state, {
            from: props.location,
            modal: true,
          }),
        }}
      />
    );
  }

  const category = CAT_SCORES.ranges.find((c) => score <= c.value);

  analytics.fbq('trackCustom', 'SurveyScore', { custom_param: score });

  return (
    <QuizResults
      title='YOUR_CAT_SCORE'
      score={score}
      scoreData={CAT_SCORES}
      category={category}
    />
  );
};

export default CATScore;
