import {
  GOOGLE_ADS_SUBDOMAINS,
  SUBDOMAIN,
  IS_DEV_OR_TEST,
} from '../utilities/environment';

function gtag() {
  window.dataLayer.push(arguments);
}

const initialize = () => {
  window.dataLayer = window.dataLayer || [];
  gtag('js', new Date());
  gtag('config', 'AW-859048457', { restricted_data_processing: true });
};

export const fireGoogleAdsEvent = () => {
  gtag('event', 'conversion', {
    send_to: 'AW-859048457/gDeXCPOU-ZgBEImU0JkD',
  });
};

export const allowGoogleAds = (fbTracking) => {
  if (
    GOOGLE_ADS_SUBDOMAINS.includes(SUBDOMAIN) &&
    fbTracking === 'all' &&
    !IS_DEV_OR_TEST
  ) {
    return true;
  }
  return false;
};

export const initGoogleAds = () => {
  const newScript = document.createElement('script');
  newScript.setAttribute(
    'src',
    'https://www.googletagmanager.com/gtag/js?id=AW-859048457'
  );
  newScript.addEventListener('load', () => {
    if (window.dataLayer) {
      initialize();
    }
  });
  const head = document.querySelector('head');
  head.appendChild(newScript);
};
