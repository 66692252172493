import React from 'react';
import { defaultT } from '../../lib/utilities';

const RadioInput = ({
  className = 'custom-radio',
  id,
  optionValue,
  inputValue,
  name,
  label,
  t = defaultT('RadioInput'),
  trData,
  ...rest
}) => {
  const _id = id || `${name}-${optionValue}`;

  return (
    <React.Fragment>
      <input
        className={className}
        id={_id}
        type='radio'
        name={name}
        value={optionValue}
        checked={optionValue === inputValue}
        data-attr={name}
        {...rest}
      />
      <label htmlFor={id}>{t(label, trData)}</label>
    </React.Fragment>
  );
};

export default RadioInput;
