import React from 'react';
import container from './container';

export const AppErrors = ({ errors = [], clear }) => {
  return errors.length > 0 ? (
    <div
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        overflowY: 'initial',
        backgroundColor: '#333',
        color: '#EEE',
        zIndex: '10000000000000',
      }}
    >
      <h3>ERROR ERROR</h3>
      {errors.map((err, i) => {
        return (
          <div key={`error-${i}`}>
            <h4>{err.code}</h4>
            <div>{err.statusText}</div>
            <code>{err.response}</code>
          </div>
        );
      })}
      <button onClick={() => clear()}>Clear errors</button>
    </div>
  ) : null;
};

export default container(AppErrors);
