import {
  ADD_ACT_RESPONSE,
  ADD_CAT_RESPONSE,
  CLEAR_ACT,
  CLEAR_CAT,
  REMOVE_ACT_RESPONSE,
  REMOVE_CAT_RESPONSE,
  SET_ACT_TYPE,
} from '../actions';

const initialACT = {
  score: 0,
  type: undefined,
  answers: [],
};

const initialCAT = {
  score: 0,
  answers: [],
};

const calculateScore = (acc, val) => acc + val;

const addResponse = (state, value) => {
  let r = [].concat(...state.answers, value);

  return Object.assign({}, state, {
    answers: r,
    score: r.reduce(calculateScore, 0),
  });
};

const removeResponse = (state) => {
  let r = state.answers.slice(0, state.answers.length - 1);

  return Object.assign({}, state, {
    answers: r,
    score: r.reduce(calculateScore, 0),
  });
};

export const asthmaControlTest = function asthmaControlTest(
  state = initialACT,
  action
) {
  switch (action.type) {
    // case SET_ACT_RESPONSES:
    //   return Object.assign({}, state, {
    //     answers : [].concat(action.answers),
    //     score     : state.answers.reduce(calculateScore, 0)
    //   });
    case ADD_ACT_RESPONSE:
      return addResponse(state, action.value);
    case REMOVE_ACT_RESPONSE:
      return removeResponse(state);
    case SET_ACT_TYPE:
      return Object.assign({}, initialACT, { type: action.value });
    case CLEAR_ACT:
      return Object.assign({}, initialACT);
    default:
      return state;
  }
};

export const copdAssessmentTest = function copdAssessmentTest(
  state = initialCAT,
  action
) {
  switch (action.type) {
    case CLEAR_CAT:
      return Object.assign({}, initialCAT);
    case ADD_CAT_RESPONSE:
      const newState = addResponse(state, action.value);
      return newState;
    case REMOVE_CAT_RESPONSE:
      return removeResponse(state);
    default:
      return state;
  }
};
