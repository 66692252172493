import { hashMed } from './utilities/medications';

const map = {};

export const init = function init(meds) {
  meds.forEach((med) => {
    const { id } = med;
    map[hashMed(id)] = id;
  });
};

export default map;

// function testPerf(func, ...args) {
//   const N = 10000;
//   const t1 = performance.now();
//   for (let i = 0; i < N; i++) {
//     func.apply(null, args);
//   }
//   const t2 = performance.now();
//   console.log('total time in milliseconds:', t2-t1);
//   console.log('average time in milliseconds', (t2-t1) / N);
// }
