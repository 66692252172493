import React from 'react';
import BodyClassName from 'react-body-classname';
import { Col, Container, Row } from 'react-bootstrap';

import TermsOfUse from '../../components/TermsOfUse';
import {
  TERMS_ACCEPTED_MESSAGE,
  TERMS_CANCELED_MESSAGE,
  SUBDOMAIN,
} from '../../lib/utilities';
import analytics from '../../lib/analytics';
import container from './container';
import propellerLogo from '../../assets/images/logo.svg';
import './styles.scss';

const borderedBox = {
  display: 'block',
  marginTop: '20px',
  marginBottom: '20px',
  marginLeft: 'auto',
  marginRight: 'auto',

  border: '2px solid #54565A',
  borderRadius: '10px',
  padding: '10px',
};

const scrollingContent = {
  overflowY: 'scroll',
  height: '155px',
  overflowX: 'hidden',
  maxWidth: '100%',
};

// we may need to work with an array here (or better, in the consuming component)
const getTermsURI = (params = {}) => {
  if (params.terms_uri) {
    return decodeURIComponent(params.terms_uri);
  }
};

// our assumption is that this has been created by opening a new window,
// and we need to communicate completion back to the parent

// do we need to listen for messages iin case the terms url changes?
class TermsOfUsePage extends React.Component {
  state = {
    termsURI: getTermsURI(this.props.queryParams),
  };

  communicateToParent(message) {
    if (window.opener) {
      window.opener.postMessage(message, window.location.origin);
    } else {
      try {
        localStorage.setItem(message, true);
        localStorage.removeItem(message);
        window.close();
      } catch (e) {
        console.error(e);
      }
    }
  }

  onNext() {
    this.communicateToParent(TERMS_ACCEPTED_MESSAGE);
    if (SUBDOMAIN === 'myasthma') {
      analytics.fbqMyasthma({
        name: 'CompleteRegistration',
        properties: {
          content_name: 'Form Completed - 100%',
        },
      });
    }
  }

  onCancel() {
    this.communicateToParent(TERMS_CANCELED_MESSAGE);
  }

  constructor(props) {
    super(props);
    this.onNext = this.onNext.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  render() {
    const { termsURI } = this.state;

    return (
      <BodyClassName className='terms-of-use'>
        <Container className='terms-wrapper'>
          <Row>
            <Col xs={12} md={{ span: 10, offset:1 }}>
              <div className='text-center'>
                <img src={propellerLogo} alt='Propeller Health' width={180} />
              </div>
              <TermsOfUse
                termsPath={termsURI}
                cancelFunc={this.onCancel}
                nextFunc={this.onNext}
                containerStyle={borderedBox}
                contentStyle={scrollingContent}
              />
            </Col>
          </Row>
        </Container>
      </BodyClassName>
    );
  }
}

export default container(TermsOfUsePage);
