import React from 'react';
import { Redirect } from 'react-router-dom';
import container from './container';
import AccessValidation from '../../components/AccessValidation';
import { objectFromQueryString } from '../../lib/utilities';

const redirectLocation = (path, state) => {
  const a = document.createElement('a');
  a.href = path;

  return {
    pathname: a.pathname,
    search: a.search,
    hash: a.hash,
    state,
  };
};

export class AccessCode extends React.Component {
  render() {
    const { location = {}, requiresValidation, ...restProps } = this.props;
    const { state = {}, search = '' } = location;
    const { from, ...locationState } = state;

    if (!requiresValidation) {
      const params = objectFromQueryString(search);

      const redirectTo = from
        ? from
        : params.from
        ? redirectLocation(params.from, locationState)
        : { pathname: '/', state: locationState };

      return <Redirect to={redirectTo} />;
    }

    return <AccessValidation {...restProps} />;
  }
}

export default container(AccessCode);
