export default function hasLocalStorage(state) {
  if (state !== undefined) {
    return state;
  }

  try {
    if (!window.localStorage) {
      throw new Error('cannot access localStorage');
    }
    let x = 'init-local-storage' + new Date().toISOString();
    window.localStorage.setItem(x, x);
    let y = window.localStorage.getItem(x);
    window.localStorage.removeItem(x);
    return x === y;
  } catch (e) {
    return false;
  }
}
