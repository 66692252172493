import { TLD } from '../utilities/environment';

const DEV_WRITE_KEY = '3Eq5ZKF2kac5yBXOepVPxgZ8kOURdXX5';
const TEST_WRITE_KEY = 'NQKuYWOHM7ETOamhMIljr7HERdXgo82r';
const PROD_WRITE_KEY = 'wWR5yQGt69i39svVpN2HRgziD4sJWdR9';

export const initSegment = () => {
  function getWriteKey() {
    switch (TLD) {
      case 'com':
        return PROD_WRITE_KEY;
      case 'io':
        return TEST_WRITE_KEY;
      case 'biz':
        return DEV_WRITE_KEY;
      default:
        return DEV_WRITE_KEY;
    }
  }

  const write_key = getWriteKey();

  if (window) {
    const init = function () {
      var analytics = (window.analytics = window.analytics || []);
      if (!analytics.initialize)
        if (analytics.invoked)
          window.console &&
            console.error &&
            console.error('Segment snippet included twice.');
        else {
          analytics.invoked = !0;
          analytics.methods = [
            'trackSubmit',
            'trackClick',
            'trackLink',
            'trackForm',
            'pageview',
            'identify',
            'reset',
            'group',
            'track',
            'ready',
            'alias',
            'debug',
            'page',
            'once',
            'off',
            'on',
          ];
          analytics.factory = function (t) {
            return function () {
              var e = Array.prototype.slice.call(arguments);
              e.unshift(t);
              analytics.push(e);
              return analytics;
            };
          };
          for (var t = 0; t < analytics.methods.length; t++) {
            var e = analytics.methods[t];
            analytics[e] = analytics.factory(e);
          }
          analytics.load = function (t, e) {
            var n = document.createElement('script');
            n.type = 'text/javascript';
            n.async = !0;
            n.src =
              'https://cdn.segment.com/analytics.js/v1/' +
              t +
              '/analytics.min.js';
            var a = document.getElementsByTagName('script')[0];
            a.parentNode.insertBefore(n, a);
            analytics._loadOptions = e;
          };
          analytics.SNIPPET_VERSION = '4.1.0';
          analytics.load(write_key);
        }
    };
    init();
  }
};
