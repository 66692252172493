import { publicImg } from './utilities/content';
import { isController } from './utilities/medications';
import { exists } from './utilities/presence';

const sensorImg = (path) => publicImg(`sensors/${path}`);

// tests for this should ensure structure of each item
const ble = sensorImg('ble.png');
const bleThumb = sensorImg('ble_thumb.png');
const bleAttach = sensorImg('ble_attach.png');
const bleAttachThumb = sensorImg('ble_attach_thumb.png');

const diskus = sensorImg('diskus.png');
const diskusThumb = sensorImg('diskus_thumb.png');
const diskusAttach = sensorImg('diskus_attach.png');
const diskusAttachThumb = sensorImg('diskus_attach_thumb.png');

// easyhaler formfactor
// const limulus = sensorImg('limulus.png');
// const limulusThumb = sensorImg('limulus_thumb.png');
// const limulusAttach = sensorImg('limulus_attach.png');
// const limulusAttachThumb = sensorImg('limulus_attach_thumb.png');
const limulus = 'https://via.placeholder.com/500x900';
const limulusThumb = 'https://via.placeholder.com/60x60';
const limulusAttach = 'https://via.placeholder.com/500x900';
const limulusAttachThumb = 'https://via.placeholder.com/60x60';

const ellipta = sensorImg('ellipta.png');
const elliptaThumb = sensorImg('ellipta_thumb.png');
const elliptaAttach = sensorImg('ellipta_attach.png');
const elliptaAttachThumb = sensorImg('ellipta_attach_thumb.png');

const jelly = sensorImg('jelly.png');
export const jellyThumb = sensorImg('jelly_thumb.png');
const jellyAttach = sensorImg('jellyfish_attach.png');
const jellyAttachThumb = sensorImg('jellyfish_attach_thumb.png');

const neohaler = sensorImg('neohaler.png');
const neohalerThumb = sensorImg('neohaler_thumb.png');
const neohalerAttach = sensorImg('neohaler_attach.png');
const neohalerAttachThumb = sensorImg('neohaler_attach_thumb.png');

const respimat = sensorImg('respimat.png');
const respimatThumb = sensorImg('respimat_thumb.png');
const respimatAttach = sensorImg('respimat_attach.png');
const respimatAttachThumb = sensorImg('respimat_attach_thumb.png');

// symbicort formfactor
const waterbear = sensorImg('waterbear.png');
const waterbearThumb = sensorImg('waterbear_thumb.png');
const waterbearAttach = sensorImg('waterbear_attach.png');
const waterbearAttachThumb = sensorImg('waterbear_attach_thumb.png');

const PRECISION_FEATURE = {
  feature: 'PRECISION_FEATURE',
  benefit: 'PRECISION_BENEFIT',
};

const BLUETOOTH_FEATURE = {
  feature: 'BLUETOOTH_FEATURE',
  benefit: 'BLUETOOTH_BENEFIT',
};

const BATTERY_FEATURE = {
  feature: 'BATTERY_FEATURE',
  benefit: 'BATTERY_BENEFIT',
};

export const basePrice = 79.99;

const appFeature = (type, disease) => {
  switch (type) {
    case 'controller':
      return 'APP_BENEFIT_CONTROLLER';

    default:
      return disease === 'copd' ? 'APP_BENEFIT_COPD' : 'APP_BENEFIT_ASTHMA';
  }
};

// don't use arrow function so `this` will be the sensor object, not the array.
const featureSet = function featureSet(medication, disease) {
  const APP_FEATURE = {
    feature: 'APP_FEATURE',
    benefit: appFeature(medication.type, disease),
  };

  const REMINDER_FEATURE =
    isController(medication) && 'ble1' !== this.id
      ? {
          feature: 'REMINDER_FEATURE',
          benefit: 'REMINDER_BENEFIT',
        }
      : undefined;

  return [
    {
      feature: 'SENSOR_FEATURE',
      benefit: 'SENSOR_BENEFIT',
    },
    PRECISION_FEATURE,
    BLUETOOTH_FEATURE,
    APP_FEATURE,
    REMINDER_FEATURE,
    BATTERY_FEATURE,
  ].filter(exists);
};


export default [
  {
    id: 'ble1',
    formFactor: 'mdi',
    features: featureSet,
    images: {
      primary: {
        thumb: bleThumb,
        full: ble,
      },
      internal: {
        thumb: bleAttachThumb,
        full: bleAttach,
        alt: 'MDI_ATTACH_IMG_ALT',
      },
    },
  },
  {
    id: 'dolphin',
    formFactor: 'neohaler',
    features: featureSet,
    images: {
      primary: {
        thumb: neohalerThumb,
        full: neohaler,
      },
      internal: {
        thumb: neohalerAttachThumb,
        full: neohalerAttach,
        alt: 'NEOHALER_ATTACH_IMG_ALT',
      },
    },
  },
  {
    id: 'jellyfish87',
    formFactor: 'mdi',
    features: featureSet,
    images: {
      primary: {
        thumb: jellyThumb,
        full: jelly,
      },
      internal: {
        thumb: jellyAttachThumb,
        full: jellyAttach,
        alt: 'MDI_ATTACH_IMG_ALT',
      },
    },
    walgreensProduct: {
      sku: 'sku6320521',
      productDesc: 'Sensor Model 2016-M-S',
      price: basePrice,
    },
  },
  {
    id: 'jellyfish92',
    formFactor: 'mdi',
    features: featureSet,
    images: {
      primary: {
        thumb: jellyThumb,
        full: jelly,
      },
      internal: {
        thumb: jellyAttachThumb,
        full: jellyAttach,
        alt: 'MDI_ATTACH_IMG_ALT',
      },
    },
    walgreensProduct: {
      sku: 'sku6320519',
      productDesc: 'Sensor Model 2016-M-L',
      price: basePrice,
    },
  },
  {
    id: 'limulus',
    formFactor: 'easyhaler',
    features: featureSet,
    images: {
      primary: {
        full: limulus,
        thumb: limulusThumb,
      },
      internal: {
        alt: 'LIMULUS_ATTACH_IMG_ALT',
        full: limulusAttach,
        thumb: limulusAttachThumb,
      },
    },
  },
  {
    id: 'manta',
    formFactor: 'ellipta',
    features: featureSet,
    images: {
      primary: {
        thumb: elliptaThumb,
        full: ellipta,
      },
      internal: {
        thumb: elliptaAttachThumb,
        full: elliptaAttach,
        alt: 'ELLIPTA_ATTACH_IMG_ALT',
      },
    },
    walgreensProduct: {
      sku: 'sku6320522',
      productDesc: 'Sensor Model 2015-E',
      price: basePrice,
    },
  },
  {
    id: 'squid',
    formFactor: 'respimat',
    features: featureSet,
    images: {
      primary: {
        thumb: respimatThumb,
        full: respimat,
      },
      internal: {
        thumb: respimatAttachThumb,
        full: respimatAttach,
        alt: 'RESPIMAT_ATTACH_IMG_ALT',
      },
    },
    walgreensProduct: {
      // respimat sku IS different than others (see https://docs.google.com/spreadsheets/d/1IkzGZWXLmmHnkfb4rAzBxutd8Tzjg_IQmf1YWwSGK1I/edit#gid=1720860399)
      sku: '400626774',
      productDesc: 'Sensor Model 2014-R',
      price: basePrice,
    },
  },
  {
    id: 'stingray',
    formFactor: 'ellipta',
    features: featureSet,
    images: {
      primary: {
        thumb: elliptaThumb,
        full: ellipta,
      },
      internal: {
        thumb: elliptaAttachThumb,
        full: elliptaAttach,
        alt: 'ELLIPTA_ATTACH_IMG_ALT',
      },
    },
    walgreensProduct: {
      sku: 'sku6320522',
      productDesc: 'Sensor Model 2015-E',
      price: basePrice,
    },
  },
  {
    id: 'sunfish',
    formFactor: 'diskus',
    features: featureSet,
    images: {
      primary: {
        thumb: diskusThumb,
        full: diskus,
      },
      internal: {
        thumb: diskusAttachThumb,
        full: diskusAttach,
        alt: 'DISKUS_ATTACH_IMG_ALT',
      },
    },
    walgreensProduct: {
      sku: 'sku6320516',
      productDesc: 'Sensor Model 2014-D',
      price: basePrice,
    },
  },
  {
    id: 'waterbear',
    formFactor: 'symbicort',
    features: featureSet,
    images: {
      primary: {
        full: waterbear,
        thumb: waterbearThumb,
      },
      internal: {
        alt: 'WATERBEAR_ATTACH_IMAGE_ALT',
        full: waterbearAttach,
        thumb: waterbearAttachThumb,
      },
    },
  },
];
