import { connect } from 'react-redux';
import { localesForCountry } from '../../lib/utilities/locales';

// it'd be nice to tie these together with what's in localesForCountry
// also, perhaps to have this list be based off i18n.whitelist.
// worth noting: when group config updates (local development),
// this will update but the whitelist, fallback rules, etc currently will not

const mapStateToProps = (state, ownProps) => {
  const { groupConfig } = state;

  const country = groupConfig.country || 'US';
  const locales = localesForCountry(country);
  const lng = ownProps.i18n.language;
  const language =
    locales.find((locale) => locale === lng) ||
    (console.warn('did not find given language: ', lng), locales[0]);

  return {
    country: country,
    language: language,
    locales: locales,
  };
};

export default connect(mapStateToProps);
