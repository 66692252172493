import React from 'react';
import {Row, Col } from 'react-bootstrap';
import GreenButton from '../../components/Button/GreenButton';
import GaLink from '../../components/GaLink';
import { PATIENT_PORTAL_URL } from '../../lib/utilities';

import hubWelcome from '../../assets/images/img_welcome_hub.svg';

// empty timeline is not inspiring, so take them to their meds
const pathForPatient = (id) => {
  if (id) {
    return `${id}/medications`;
  }

  return '';
};

const Hub = ({ id, excitedText: _excitedText, t }) => {
  const patientPortalURL = `${PATIENT_PORTAL_URL}/${pathForPatient(id)}`;
  const excitedText = t(_excitedText);
  return (
      <Row>
        <Col xs={12}>
          <h1 className='text-center'>{t('HUB_SENSOR_PREPPED_TO_SHIP')}</h1>
        </Col>
        <Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
          <div
            className='image-bullets-container'
            style={{ margin: '2rem auto' }}
          >
            <img className='thumbs-up' src={hubWelcome} alt={t('HUB_AND_SENSOR')} />
          </div>
        </Col>
        <Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
          <p>{ excitedText } <strong><GaLink
                href={patientPortalURL}
                target='_blank'
                rel='noopener noreferrer'
                action='hubEnroll-toPatientPortalLink'
              >
                {t("PORTAL")}
              </GaLink></strong></p>
          <div className='app-stores-container text-center'>
            <GreenButton
              as={GaLink}
              className='btn-wrap'
              href={patientPortalURL}
              target='_blank'
              rel='noopener noreferrer'
              action='hubEnroll-toPatientPortalButton'
            >
              {t('VISIT_PATIENT_PORTAL')}
            </GreenButton>
          </div>
        </Col>
      </Row>
  );
};

export default Hub;
