import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Notifications from 'react-notification-system-redux';
import MainLayout from './components/MainLayout';
import ModalRoute from './components/ModalRoute';
import AccessControlRoute from './components/AccessControlRoute';
import AppErrors from './components/AppErrors';
import DiseaseSelection from './components/DiseaseSelection';
import SignIn from './components/SignIn';
import Footer from './components/Footer';
import Download from './pages/Download';
import ACTQuiz from './pages/ActQuiz';
import CATQuiz from './pages/CATQuiz';
import MedSelection from './pages/MedSelection';
import NoneCompatible from './pages/NoneCompatible';
import AccessCode from './pages/AccessCode';
import DownloadInstructions from './pages/DownloadInstructions';
import PrepareCart from './pages/PrepareCart';
import PrivacyPolicy from './pages/PrivacyPolicy';
import container from './containers/AppState';
import Enrollment from './pages/Enrollment';
import Home from './pages/Home';
import { objectFromQueryString, IS_DEV_OR_TEST } from './lib/utilities';

class MainApp extends React.Component {
  componentDidMount() {
    const { subdomain, groupConfig, loadGroupConfig, loadGroupConfig4x } = this.props;

    // if we don't have a group (local only?)
    const HAS_CONFIG = groupConfig && groupConfig.name;

    if (!HAS_CONFIG) {
      loadGroupConfig(subdomain);
      loadGroupConfig4x(subdomain);
    }
  }

  componentWillUpdate() {
    const { location, action, history } = this.props;
    // set previousLocation if props.location is not modal
    if (action !== 'POP' && (!location.state || !location.state.modal)) {
      this.previousLocation = location;
      this.returnIdx = history.length - 1 || 0;
    }
  }

  render() {
    const {
      domain,
      location,
      notifications,
      showSignIn,
      isIFrame,
    } = this.props;
    const params = objectFromQueryString(location.search);

    if (
      location.pathname === '/take-the-quiz' ||
      location.pathname === '/assessment'
    ) {
      location.state = { ...location.state, modal: true };
    }

    const isModal =
      !!(location.state && location.state.modal) || // &&
      // this.previousLocation !== location // not initial render
      [true, 'true'].includes(params.modal);
    // If isModal and no previousLocation (eg. initial render), display over Home
    // Is this the behavior we want?

    return (
      <MainLayout location={location} isIFrame={isIFrame} domain={domain}>
        <Switch
          location={
            isModal ? this.previousLocation || { pathname: '/' } : location
          }
        >
          <Route exact path='/' component={Home} />
          <Route path='/take-the-quiz' component={ACTQuiz} />
          <Route path='/assessment' component={CATQuiz} />
          <Route
            path='/copd-assessment'
            render={() => <Redirect to='/assessment' />}
          />
          <AccessControlRoute path='/med-selection' component={MedSelection} />
          <Route path='/none-compatible' component={NoneCompatible} />
          <AccessControlRoute path='/enrollment' component={Enrollment} />
          <Route path='/download' component={Download} />
          <Route path='/prepare-cart' component={PrepareCart} />
          <Route
            path='/download-instructions'
            component={DownloadInstructions}
          />
          <Route path='/access-code' component={AccessCode} />
          <Route path='/privacy-policy' component={PrivacyPolicy} />
        </Switch>
        {isModal ? (
          <Switch>
            <ModalRoute
              path='/take-the-quiz'
              component={ACTQuiz}
              modalSize={location.state && location.state.modalSize}
              className='routed-modal'
              returnTo={this.previousLocation || { pathname: '/' }}
              returnIdx={this.returnIdx || 0}
              autoFocus={true}
            />
            <ModalRoute
              path='/assessment'
              component={CATQuiz}
              modalSize={location.state && location.state.modalSize}
              className='routed-modal'
              returnTo={this.previousLocation || { pathname: '/' }}
              returnIdx={this.returnIdx || 0}
              autoFocus={true}
            />
          </Switch>
        ) : null}
        <DiseaseSelection />
        <Notifications notifications={notifications} />
        {IS_DEV_OR_TEST && <AppErrors />}
        <SignIn show={showSignIn} />
        <Footer />
      </MainLayout>
    );
  }
}

export default container(MainApp);
