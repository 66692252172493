import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Trans, useTranslation } from 'react-i18next';
import './home-faqs.scss';
import { SUBDOMAIN } from '../../../lib/utilities';

export default function HomeFAQs() {
  const bccmi = (SUBDOMAIN === 'bccmi');
  const { t } = useTranslation('faq');
  return (
    <Container className='home-faqs'>
      <Row style={{ marginBottom: '2rem' }}>
        <Col className='text-center'>
          <h2>{t('FREQUENTLY_ASKED_QUESTION')}</h2>
        </Col>
      </Row>
      {bccmi && <>
        <Row style={{ marginBottom: '2rem' }}>
        <Col xs={12} md={5}>
          <h3>{t('FAQ_QUESTION_NO_COST')}</h3>
        </Col>
        <Col xs={0} md={1}>
          <div className='vertical-line' />
        </Col>
        <Col xs={12} md={6}>
          <p>
            {t('FAQ_ANSWER_NO_COST')}
          </p>
        </Col>
      </Row>

      <Row style={{ marginBottom: '2rem' }}>
        <Col xs={12} md={5}>
          <h3>{t('FAQ_QUESTION_CAREGIVER_ACCOUNT')}</h3>
        </Col>
        <Col xs={0} md={1}>
          <div className='vertical-line' />
        </Col>
        <Col xs={12} md={6}>
          <p>
            {t('FAQ_ANSWER_CAREGIVER_ACCOUNT')}
          </p>
        </Col>
      </Row>
      </>}
      <Row style={{ marginBottom: '2rem' }}>
        <Col xs={12} md={5}>
          <h3>{t('FAQ_QUESTION_AFTER_SIGNUP')}</h3>
        </Col>
        <Col xs={0} md={1}>
          <div className='vertical-line' />
        </Col>
        <Col xs={12} md={6}>
          <p>
            <Trans i18nKey={'FAQ_ANSWER_AFTER_SIGNUP_1'} t={t}>
              After you sign up you need to download the Propeller app. You can
              find it on the&nbsp;
              <a
                href='https://apps.apple.com/us/app/propeller/id520741533'
                rel='noreferrer noopener'
                target='_blank'
              >
                App Store
              </a>
              <a
                href='https://play.google.com/store/apps/details?id=com.asthmapolis.mobile&hl=en_US&gl=US'
                rel='noreferrer noopener'
                target='_blank'
              >
                Google Play
              </a>
              . Once you download the app and sign in, we will ship your
              sensor(s) to you. You'll be able to track your sensor shipment
              from the Today tab in the Propeller app, and access to your
              Propeller account is right there in the app.
            </Trans>
          </p>
          <p>{t('FAQ_ANSWER_AFTER_SIGNUP_2')}</p>
        </Col>
      </Row>

      <Row style={{ marginBottom: '2rem' }}>
        <Col xs={12} md={5}>
          <h3>{t('FAQ_QUESTION_SMARTPHONE')}</h3>
        </Col>
        <Col xs={0} md={1}>
          <div className='vertical-line' />
        </Col>
        <Col xs={12} md={6}>
          <p>{t('FAQ_ANSWER_SMARTPHONE_1')}</p>
          <p>
            <Trans i18nKey={'FAQ_ANSWER_SMARTPHONE_2'} t={t}>
              To access your Propeller account, you can login online with any
              device with an internet browser. Your online account lets you
              track your medication use, see your list of medications, manage
              your Propeller account and more.
              <a
                href='https://patient.propellerhealth.com/'
                rel='noreferrer noopener'
                target='_blank'
              >
                To access your online Propeller account, go to
                patient.propellerhealth.com
              </a>
              &nbsp; and log in using the email address and password you created
              during sign up.
            </Trans>
          </p>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={5}>
          <h3>{t('FAQ_QUESTION_CONTACT')}</h3>
        </Col>
        <Col xs={0} md={1}>
          <div className='vertical-line' />
        </Col>
        <Col xs={12} md={6}>
          <p>
            <Trans i18nKey={'FAQ_ANSWER_CONTACT'} t={t}>
              Absolutely. Our friendly support team answers the phone weekdays
              between 8 a.m. and 8 p.m. EST. You can reach them at +1 (877)
              251-5451. Or you can send us an email anytime at&nbsp;
              <a href='mailto:help@propellerhealth.com.'>
                help@propellerhealth.com
              </a>
              .
            </Trans>
          </p>
        </Col>
      </Row>
      {bccmi && <div className='rights'>
        <span>{t('rights1')}</span>
        <span>{t('rights2')}</span>
        <span>{t('rights3')}</span>
      </div>}
    </Container>
  );
}
