import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import Title from '../../../components/Title/Title';

const Heading = ({ formFactor, disease, t }) => {
  if (formFactor.id !== 'misc') {
    const { name, image } = formFactor;
    const formFactorName = t(name);
    return (
      <>
        <Title
          text={
            <Trans
              i18nKey='medSelection:WHICH_MED_FOR_FORMFACTOR_PRESCRIBED'
              values={{ formFactorName }}
            >
              Which {{ formFactorName }} are you <strong>currently prescribed</strong>?
            </Trans>
          }
          icon={image}
          alt={formFactorName}
        />
      </>
    );
  } else {
    return (
      <h1
        id='select-medications-header'
        className='med-select-header medium-header'
      >
        <Trans
          i18nKey='medSelection:SELECT_ANY_OTHER'
          values={{ disease: `$t(common:${disease})` }}
        >
          Select any other {{ disease }} medication you are <strong>currently prescribed</strong>:
        </Trans>
      </h1>
    );
  }
};

export default withTranslation(['medSelection', 'common'])(Heading);
