import { connect } from 'react-redux';

import {
  loadGroupConfig4x,
  loadGroupConfig,
  setGroupConfig,
  setGroupConfig4x,
  setDeviceType,
  initEnrollmentForGroup,
} from '../actions';

import { getBoolean } from '../lib/utilities';

const mapStateToProps = ({
  groupConfig4x,
  groupConfig,
  notifications,
  queryParams,
  showSignIn,
  domain,
  subdomain,
  router,
}) => {
  const isIFrame = queryParams ? getBoolean(queryParams.iframe) : false;

  return {
    groupConfig4x,
    notifications,
    showSignIn,
    groupConfig,
    domain,
    subdomain,
    action: router.action,
    isIFrame,
  };
};

const mapDispatchToProps = {
  loadGroupConfig4x,
  loadGroupConfig,
  setGroupConfig,
  setGroupConfig4x,
  setDeviceType,
  initEnrollmentForGroup,
};

export default connect(mapStateToProps, mapDispatchToProps);
