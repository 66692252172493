import { SET_DEVICE_TYPE, UPDATE_DEVICE } from '../actions';

export default function device(state = false, action) {
  switch (action.type) {
    case SET_DEVICE_TYPE:
      return action.device;
    case UPDATE_DEVICE:
      return action.device;
    default:
      return state;
  }
}
