import { connect } from 'react-redux';
import {
  push as pushHistory,
  goBack as goBackHistory,
  replace as replaceHistory,
} from 'connected-react-router';
import {
  configureEnrollment,
  updateEnrollmentData,
  setEnrollmentMeds,
  setError,
  setMedSchedule,
} from '../../actions';
import { enrollmentDisease } from '../../lib/utilities';

const mapStateToProps = (state) => {
  const {
    device,
    dignityConfig,
    disabledInputs,
    enrollmentData,
    enrollmentMeds,
    groupConfig4x,
    groupConfig,
  } = state;
  const isCOPD = enrollmentDisease(enrollmentData) === 'copd';
  return {
    device: device,
    dignityConfig: dignityConfig,
    disabledInputs: disabledInputs,

    enrollmentData: enrollmentData,
    enrollmentMeds: enrollmentMeds,
    groupConfig: groupConfig,
    groupConfig4x: groupConfig4x,
    quiz: {
      type: isCOPD ? 'cat' : 'act',
      data: isCOPD ? state.copdAssessmentTest : state.asthmaControlTest,
    },
    action: state.router.action,
    location: state.router.location,
  };
};

const mapDispatchToProps = {
  configureEnrollment,
  updateEnrollmentData,
  setEnrollmentMeds,
  setError,
  setMedSchedule,
  pushHistory,
  goBackHistory,
  replaceHistory,
};

export default connect(mapStateToProps, mapDispatchToProps);
