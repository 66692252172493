import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import PageHeading from '../../components/PageHeading/PageHeading';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormGroup from 'react-bootstrap/FormGroup';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import container from './container';
import withSession from '../../hocs/withSession';
import Loading from '../../components/Loading';
import PhoneInput from '../../components/InputField/PhoneInput/PhoneInput.js';
import {
  checkResponse,
  API_HOST,
  apiHeaders,
  phoneSetter,
  phoneGetter,
  isTouchScreen,
  i18nImgSrc,
} from '../../lib/utilities';
import { appDownload } from '../../lib/page_content';
import { validatePhone } from '../../lib/input_validation';
import { getCountryCode } from '../../lib/utilities/countryMap';
import { getSignInLink } from '../../api/user';

import './styles.scss';
import Title from '../../components/Title/Title';

const loadingStyle = {
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 100,
  background: 'rgba(255,255,255,0.7)',
};

export class DownloadInstructions extends React.Component {
  state = {
    message: '',
    error: false,
    deviceType: this.props.hubRequired || 'android',
    showInstructions: false,
    submitting: false,
    showDone: false,
    phoneCountry: this.props.enrollmentData.phoneCountry,
    phone: phoneGetter(
      this.props.enrollmentData.phone,
      getCountryCode(this.props.enrollmentData.phoneCountry)
    ),
  };

  PHONE_REQUIRED = true;

  setCountryName = (countryName) => {
    this.setState(() => {
      return { phoneCountry: countryName };
    });
  };

  onChange = (data) => {
    // for consistent error experience (behaves like Enrollment/Address)
    const value = data.phoneNumber;
    this.setState(() => {
      return {
        phone: value,
        error: false,
        message: '',
      };
    });
  };

  onBlur = (value, hasError) => {
    if (hasError) {
      this.setState(() => {
        return {
          error: true,
        };
      });
    } else {
      this.setState(() => {
        return {
          phone: value,
          error: false,
          message: '',
        };
      });
    }
  };

  onDeviceTypeChange = (val) => {
    this.setState({ deviceType: val });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { phoneError, errorMessage } = validatePhone(
      this.state.phone,
      this.state.phoneCountry,
      this.PHONE_REQUIRED
    );
    if (phoneError) {
      return this.setState({
        message: errorMessage,
        error: true,
      });
    }
    if (!phoneError) {
      this.updateAndSend().then(() => {
        this.setState({ submitting: false });
        this.props.resetEnrollmentData();
      });
    }
  };

  toggleInstructions = () => {
    this.setState({ showInstructions: !this.state.showInstructions });
  };

  toggleDone = () => {
    this.setState({ showDone: !this.state.showDone });
  };

  handleClose = () => {
    this.setState({ showDone: false });
  };

  updateAccount() {
    const { session } = this.props;
    const { phone, phoneCountry } = this.state;

    return window
      .fetch(`${API_HOST}/api/users/${session.id}`, {
        method: 'PATCH',
        headers: apiHeaders(),
        body: JSON.stringify({
          phone: phoneSetter(phone, getCountryCode(phoneCountry)),
        }),
        credentials: 'include',
      })
      .then(checkResponse);
  }

  requestSMS() {
    const { session } = this.props;
    return getSignInLink({
      email: session.email,
      sendDownloadAppLinkSms: true,
      sendSignInLinkSms: false,
      sendSignInLinkEmail: false,
    });
  }

  updateAndSend() {
    this.setState({ submitting: true, error: undefined });
    return this.updateAccount()
      .then(() => this.requestSMS())
      .then(() => this.toggleDone())
      .catch((err) => {
        this.props.setError(err);
        return this.setState({
          message: 'CHECK_PHONE_NUMBER_ERROR',
          error: true,
        });
      });
  }

  render() {
    const { t, i18n } = this.props;
    const lng = i18n.language;
    const {
      phoneCountry,
      deviceType,
      showInstructions,
      submitting,
      showDone,
      phone,
      error,
      message,
    } = this.state;

    const mode = showInstructions ? 'download' : 'sms';
    const IMAGES = appDownload[deviceType][mode];
    const STEPS = appDownload[deviceType].steps;

    return (
      <PageHeading pageTitle={t('PAGE_TITLE_DOWNLOAD_INSTRUCTIONS')} t={t}>
        {submitting && <Loading style={loadingStyle} />}
        <Container as='main' className='download-instructions'>
          <Row>
            <Col xs={12}>
              <Row>
                <Col xs={12} md={6}>
                  <Title
                    text={t('DOWNLOAD_SIGN_IN')}
                    level={1}
                    className='big-bold-title'
                  />
                  <p className='download-intro-text'>
                    {t('TO_RECEIVE_KIT_DOWNLOAD_SIGN_IN')}
                  </p>

                  <form onSubmit={(e) => this.onSubmit(e)}>
                    <header className='form-header download-form'>
                      {t('ENTER_PHONE_SMS_LINK')}
                    </header>
                    <Row>
                      <Col xs={12}>
                        <FormGroup>
                          <PhoneInput
                            label='PHONE_NUMBER'
                            value={phoneGetter(phone, phoneCountry)}
                            onChange={(e) => this.onChange(e)}
                            onBlur={(e) => this.onBlur(e)}
                            onSubmit={this.onSubmit}
                            autoFocus={!isTouchScreen()}
                            name='phoneNumber'
                            phoneCountry={phoneCountry}
                            setCountryName={this.setCountryName}
                            error={error}
                            errorText={message}
                            phoneRequired={this.PHONE_REQUIRED}
                            {...this.props}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </form>
                  <div>— {t('UPPECASE_OR')} —</div>
                  <fieldset role='group' className='download-instructions-div'>
                    <legend>
                      <Button
                        style={{ width: '100%' }}
                        className='dropdown-toggle'
                        onClick={() => this.toggleInstructions()}
                        aria-expanded={this.state.showInstructions}
                      >
                        {t('SEE_STEP_INSTRUCTIONS')}{' '}
                        <span
                          className={`caret ph-caret caret-${
                            showInstructions ? 'up' : 'down'
                          }`}
                        />
                      </Button>
                    </legend>

                    <Collapse in={showInstructions}>
                      <div>
                        <Row
                          id='device-download-instructions'
                          className='text-center'
                        >
                          <div
                            role='tablist'
                            aria-orientation='horizontal'
                            className='tabs'
                          >
                            <Button
                              role='tab'
                              aria-selected={
                                this.state.deviceType === 'android'
                                  ? 'true'
                                  : 'false'
                              }
                              id='android-tab'
                              aria-controls='android-content-panel'
                              className={`tab android ${
                                this.state.deviceType === 'android'
                                  ? 'active'
                                  : ''
                              }`}
                              onClick={() => this.onDeviceTypeChange('android')}
                            >
                              Android
                            </Button>
                            <Button
                              role='tab'
                              aria-selected={
                                this.state.deviceType === 'ios'
                                  ? 'true'
                                  : 'false'
                              }
                              id='ios-tab'
                              aria-controls='ios-content-panel'
                              className={`tab ios ${
                                this.state.deviceType === 'ios' ? 'active' : ''
                              }`}
                              onClick={() => this.onDeviceTypeChange('ios')}
                            >
                              iOS
                            </Button>
                          </div>
                        </Row>
                        <div role='group' aria-describedby='instruction-steps'>
                          <legend
                            id='instruction-steps'
                            className='visually-hidden'
                          >
                            instruction steps
                          </legend>
                          <ol className='blue-numbers'>
                            {STEPS.map((s, i) => (
                              <li key={`dl-step-${i}`}>{t(s)}</li>
                            ))}
                          </ol>
                        </div>
                      </div>
                    </Collapse>
                  </fieldset>
                </Col>

                <Col>
                  <img
                    src={i18nImgSrc(IMAGES.src, lng)}
                    srcSet={i18nImgSrc(IMAGES.srcSet, lng)}
                    alt={t(IMAGES.alt)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Modal show={showDone} onHide={() => this.handleClose()}>
          <Modal.Body>
            <h3>{t('SENT_NOW_GRAB_PHONE')}</h3>
            <p>{t('TAP_LINKS_TO_DOWNLOAD_SIGN_IN')}</p>
          </Modal.Body>
        </Modal>
      </PageHeading>
    );
  }
}

export default compose(
  container,
  withTranslation(['enrollment', 'common']),
  withSession
)(DownloadInstructions);
