import { SUBDOMAIN } from '../../lib/utilities/';
/**
 * returns path for first page of enrollment flow,
 * based on group and user data
 */

export function getStartPage({ disease, location, requiresACT, requiresCAT }) {
  if (SUBDOMAIN === 'iadtc' && disease === 'copd') {
    return '/med-selection/no-sponsor';
  }
  if (requiresACT) {
    return '/take-the-quiz';
  }
  if (requiresCAT) {
    return '/assessment';
  }
  if (location.pathname === '/med-selection') {
    return '/med-selection/form-factors';
  }
  return '/med-selection';
}
