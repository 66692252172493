import { API_HOST } from '../lib/utilities/environment';
import { apiHeaders, fetchJSON } from '../lib/utilities/requests';

export const getGroupConfig = (groupId) => {
  if ('signup' === groupId || 'localhost' === groupId) groupId = 'try';
  return fetchJSON(`${API_HOST}/api/groups/${groupId}/config`, {
    headers: apiHeaders(),
  });
};

export const getGroupConfig4x = () => {
  return fetch(`/api/app-access/group-config?TLD=biz`).then((response) => response.json());
};

export default getGroupConfig4x;