export const addBodyClasses = (...klasses) => {
  try {
    document.body.classList.add(...klasses);
  } catch (e) {
    let classes = document.body.className.split(' ');

    klasses.forEach((klass) => {
      if (!classes.includes(klass)) {
        classes.push(klass);
      }
    });

    document.body.className = classes.join(' ');
  }
};

export const removeBodyClasses = (...klasses) => {
  try {
    document.body.classList.remove(...klasses);
  } catch (e) {
    let classes = document.body.className.split(' ');
    klasses.forEach((klass) => {
      let idx = classes.indexOf(klass);
      classes = [...classes.slice(0, idx), ...classes.slice(idx + 1)];
    });

    document.body.className = classes.join(' ');
  }
};

export const inView = (element) => {
  const rect = element.getBoundingClientRect();

  // console.log(rect);
  // console.log(
  //   rect.top >= 0,
  //   rect.left >= 0,
  //   rect.bottom <= (window.innerHeight || document.documentElement.clientHeight),
  //   rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  // );

  return (
    rect.top + rect.height >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const windowProps = (widthPixels = 380, heightPercent = 80) => {
  const width = Math.floor(
    window.outerWidth <= widthPixels ? window.outerWidth : widthPixels
  );

  const height = Math.floor((window.outerHeight * heightPercent) / 100);

  const left = Math.floor(window.screenX + (window.outerWidth - width) / 2);

  const top = Math.floor(window.screenY + (window.outerHeight - height) / 8);

  return `toolbar=0,scrollbars=1,status=1,resizable=1,location=1,menuBar=0,
    width=${width},height=${height},
    left=${left},top=${top}`;
};
