import React from 'react';
import { number } from 'prop-types';

function TriangleWarningIcon({ width, height }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#854900"
          fillRule="nonzero"
          d="M11.344 2.636c.286-.515 1.026-.515 1.312 0l10 18c.277.5-.084 1.114-.656 1.114H2c-.572 0-.933-.614-.656-1.114zM12 4.544L3.274 20.25h17.451L12 4.544zM12 17c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm0-8c.552 0 1 .448 1 1v4c0 .552-.448 1-1 1s-1-.448-1-1v-4c0-.552.448-1 1-1z"
        />
      </g>
    </svg>
  );
}

TriangleWarningIcon.propTypes = {
  width: number,
  height: number
};

TriangleWarningIcon.defaultProps = {
  width: 30,
  height: 30
};

export default TriangleWarningIcon;
