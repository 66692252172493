import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form, FormGroup, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import container from '../container';
import PageHeading from '../../../components/PageHeading/PageHeading';
import Title from '../../../components/Title/Title';
import EnrollmentButtons from '../Buttons';
import { logPage } from '../../../lib/analytics';
import { getNextPage } from '../enrollment-utils';

export class ShareAccess extends React.Component {
  constructor(props) {
    super(props);

    const shareAccess =
      props.enrollmentData && props.enrollmentData.shareAccess;
    this.state = {
      shareAccess: shareAccess || '',
      valid: null,
    };
  }

  componentDidMount() {
    const { enrollmentMeds, pushHistory } = this.props;
    if (!enrollmentMeds || enrollmentMeds.length < 1) {
      pushHistory('/');
    }
    logPage('Enroll-ShareAccss');
  }

  getNextPage = () => {
    const { enrollmentData, enrollmentMeds, groupConfig } = this.props;
    if (this.state.shareAccess === 'yes') {
      return '/enrollment/patient-credentials';
    }
    return getNextPage({
      currentPage: 'shareAccess',
      enrollmentData,
      enrollmentMeds,
      groupConfig,
    });
  };

  handleChange = (e) => {
    this.setState({ shareAccess: e.target.value, valid: null });
  };

  isValid = () => {
    return this.state.shareAccess === 'yes' || this.state.shareAccess === 'no';
  };

  handleBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const value = e.target.value;
    const next = this.getNextPage();

    if (this.isValid(value)) {
      this.props.updateEnrollmentData({
        shareAccess: this.state.shareAccess,
      });
      this.props.pushHistory(next);
    } else {
      this.setState({ valid: 'error' });
    }
  };

  render() {
    const { enrollmentData, t } = this.props;
    const patient = enrollmentData.patient;
    const noMobile = enrollmentData.hubRequired === 'true';

    return (
      <PageHeading pageTitle={t('PAGE_TITLE_SHARE_ACCESS')} t={t}>
        <Title
          text={
            noMobile
              ? t('SHARE_ACCESS_QUESTION', { enrollmentData })
              : t('MOBILE_SHARE_ACCESS_QUESTION', {
                  patient,
                })
          }
          className='sm-left-lg-center-text'
          level={1}
        />
        <Row>
          <Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <Form
              onSubmit={(e) => this.handleSubmit(e)}
            >
              <FormGroup controlId='role' validationState={this.state.valid}>
                <ul className='radio-selectors'>
                  <li>
                    <input
                      className='custom-radio'
                      checked={this.state.shareAccess === 'yes'}
                      type='radio'
                      name='shareAccess'
                      value='yes'
                      id='shareAccess-yes'
                      onChange={this.handleChange}
                    />
                    <label htmlFor='shareAccess-yes'>
                      {noMobile
                        ? t('SHARE_ACCESS_YES_LABEL', { patient })
                        : t('MOBILE_SHARE_ACCESS_YES_LABEL', { patient })}
                    </label>
                  </li>
                  <li>
                    <input
                      className='custom-radio'
                      checked={this.state.shareAccess === 'no'}
                      type='radio'
                      name='shareAccess'
                      value='no'
                      id='shareAccess-no'
                      onChange={this.handleChange}
                    />
                    <label htmlFor='shareAccess-no'>
                      {noMobile
                        ? t('SHARE_ACCESS_NO_LABEL', { patient })
                        : t('MOBILE_SHARE_ACCESS_NO_LABEL', { patient })}
                    </label>
                  </li>
                </ul>
                {this.state.valid === 'error' ? (
                  <div
                    role='alert'
                    aria-live='assertive'
                    className='error-message-center'
                  >
                    {t('SELECT_OPTION')}
                  </div>
                ) : null}
              </FormGroup>
              <EnrollmentButtons
                handleBack={this.handleBack}
                handleSubmit={this.handleSubmit}
                t={t}
              />
            </Form>
          </Col>
        </Row>
      </PageHeading>
    );
  }
}

ShareAccess.propTypes = {
  enrollmentData: PropTypes.object.isRequired,
  groupConfig: PropTypes.object.isRequired,
  pushHistory: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateEnrollmentData: PropTypes.func.isRequired,
};

export default compose(withTranslation('enrollment'), container)(ShareAccess);
