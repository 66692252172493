import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import Title from '../../Title/Title';
import CodeInput from './CodeInput';
import GreenButton from '../../Button/GreenButton';
import DateInput from '../../InputField/DateInput/DateInput';
import TextInput from '../../InputField/TextInput/TextInput';
import { camelToSnakeCase, isExpressScripts, displayPhone, makeFirstLetterLowerCase, SUBDOMAIN } from '../../../lib/utilities';
import { placeholderText } from '../../../data/eligibilityService';
import { EXPRESS_SCRIPTS_SUPPORT_PHONE } from '../../../lib/express_scripts';
import { isValidBirthDate } from '../../../lib/input_validation';

const phoneNumber = displayPhone(EXPRESS_SCRIPTS_SUPPORT_PHONE);
export class EligibilityForm extends React.Component {
  state = {
    errors: [],
    accessCodeError: null,
    formData: {
      uniqueId: this.props.accessCode,
    },
  };

  handleBlur = (data) => {
    this.setState((prev) => {
      return { formData: { ...prev.formData, ...data } };
    });
  };

  handleChange = (data) => {
    const fieldName = Object.keys(data)[0];
    this.clearFieldError(fieldName);
    this.setState((prev) => {
      return { formData: { ...prev.formData, ...data } };
    });
  };

  clearFieldError = (fieldName) => {
    const fieldIndex = this.state.errors.indexOf(fieldName);
    if (fieldIndex >= 0) {
      this.state.errors.splice(fieldIndex, 1);
    }
  };

  clearErrors = () => {
    this.setState(() => {
      return { errors: [], accessCodeError: null };
    });
  };

  setErrors = (errors) => {
    this.setState(() => {
      return { errors: errors };
    });
  };

  setAccessCodeError = (errorType) => {
    this.setState(() => {
      return { accessCodeError: errorType };
    });
  };

  checkForErrors = () => {
    const { steps } = this.props;
    const fields = [...steps.userInfo, 'uniqueId'];

    return fields
      .map((item) => {
        if (item === 'birthDate') {
          const validation = isValidBirthDate(
            this.state.formData[item],
            '',
            true
          );
          if (validation.valid) {
            return null;
          }
          return item;
        }
        // check access code
        if (item === 'uniqueId') {
          const formData = this.state.formData[item];
          const underscoreRegex = /_/g;
          const demaskedFormData = !!formData
            ? formData.replace(underscoreRegex, '')
            : '';
          const accessCodeRegex = RegExp(steps.uniqueId.regex);

          if (accessCodeRegex.test(demaskedFormData)) {
            return null;
          }

          if(SUBDOMAIN !== 'es'){
            this.setAccessCodeError('Please enter a valid member ID');
          }
          if( SUBDOMAIN === 'es'){
            if (demaskedFormData.length < 9) {
              this.setAccessCodeError(this.props.accessCodeErrors.tooShort);
            } else {
              this.setAccessCodeError(this.props.accessCodeErrors.invalid);
            }
          }
          return item;
        }
        return this.state.formData[item] &&
          this.state.formData[item].length >= 1
          ? null
          : item;
      })
      .filter((err) => err !== null);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.clearErrors();

    // make sure that all the necessary values are present and valid
    const errors = this.checkForErrors();
    if (errors.length) {
      this.setErrors(errors);
      return;
    }

    this.props.onSuccess({
      ...this.state.formData,
    });
    if (this.props.onSubmit) {
      this.props.onSubmit();
    }
  };

  displayInput = (item, errorMessage, label, t) => {
    switch (item) {
      case 'birthDate':
        return (
          <DateInput
            autoComplete='bday'
            error={this.state.errors.includes('birthDate')}
            errorText='enrollment:INVALID_DATE'
            name={item}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            t={t}
          />
        );
      case 'postalCode':
        return (
          <TextInput
            autoComplete='postal-code'
            autoFocus={false}
            as={InputMask}
            error={this.state.errors.includes(item)}
            errorMessage={errorMessage}
            label={label}
            minLength={5}
            maxLength={5}
            name={item}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            pattern='\d{5}'
            placeholder={placeholderText[item]}
            t={t}
          />
        );
      default:
        return (
          <TextInput
            autoFocus={false}
            error={this.state.errors.includes(item)}
            errorMessage={errorMessage}
            label={label}
            maxLength={item === 'givenName' ? 40 : null}
            name={item}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            placeholder={placeholderText[item]}
            t={t}
          />
        );
    }
  };

  getErrorMessage = (t, group, usedCode, statusCode) => {
    if(statusCode === '503') {
      if (group === 'es') return 'We could not verify your information. Please try again or call Express Scripts at 1 (844) 399-5542.';
      return 'We could not verify your information. Please try again or contact Propeller Support by calling 1-877-251-5451.';
    }
    switch (group) {
      case 'ibx':
      case 'amerihealth':
      case 'bltpa':
        if (usedCode === true) {
          return 'We could not verify your information. Please try again or contact Propeller Support by calling 1-877-251-5451.';
        }
        else {
          return `We could not verify your information. Please try again or contact Customer Service at the telephone number on the back of your ID card.`;
        }    
      case 'es':
        if(usedCode === true){
          return 'We could not verify your information. Please try again or call Express Scripts at 1 (844) 399-5542.';
        }
        else{
          return `${t('verification:TRY_AGAIN')} ${makeFirstLetterLowerCase(t('verification:ES_PROPELLER_SUPPORT', { phoneNumber }))}`;
        }
      default:
        return `${t('verification:TRY_AGAIN')} ${makeFirstLetterLowerCase(t('verification:PROPELLER_SUPPORT', { phoneNumber: '1-877-251-5451' }))}`;
    }
  }

  render = () => {
    const { accessCode, error, steps, usedCode, statusCode, t } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <Title text={t('ENTER_YOUR_ACCESS_CODE', {uniqueId: this.props.steps.uniqueId.name})} level={2} className='big-title' />
        {isExpressScripts() ? (
          <CodeInput
            accessCode={accessCode}
            accessCodeError={this.state.accessCodeError}
            as={InputMask}
            error={this.state.errors.includes('uniqueId')}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            steps={steps}
            t={t}
            group={SUBDOMAIN}
          />
        ) : (
          <CodeInput
            error={this.state.errors.includes('uniqueId')}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            steps={steps}
            t={t}
            group={SUBDOMAIN}
          />
        )}

        <div className='error-message' role='alert' aria-live='assertive'>
          {this.state.codeError ? t('CODE_ERROR') : ''}
        </div>
        <Title text={t('PLEASE_CONFIRM_INFORMATION')} level={2} className='big-title' />
        {steps.userInfo
          ? steps.userInfo.map((item) => {
              const label = `enrollment:${camelToSnakeCase(item)}_LABEL`;
              const errorMessage = `enrollment:${camelToSnakeCase(item)}_ERROR`;

              return (
                <div key={item}>
                  {this.displayInput(item, errorMessage, label, t)}
                </div>
              );
            })
          : null}
        {error ? (
          <div className='error-message' role='alert' aria-live='polite'>
            {this.getErrorMessage(t, SUBDOMAIN, usedCode, statusCode)}
          </div>
        ) : null}
        <GreenButton
          type='submit'
          onClick={this.handleSubmit}
          block
          style={{
            maxWidth: '250px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          {t('common:SUBMIT')}
        </GreenButton>
      </form>
    );
  };
}

EligibilityForm.propsTypes = {
  accessCodeErrors: PropTypes.object,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  steps: PropTypes.object.isRequired,
  //TODO: steps.uniqueId object must be {name type:string, regex type:string}
  t: PropTypes.func.isRequired,
  usedCode: PropTypes.bool,
  statusCode: PropTypes.string,
};

export default withTranslation(['verification', 'enrollment'])(EligibilityForm);
