import React, { Component } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import RadioGroup from '../../../components/InputField/RadioGroup';
import GreenButton from '../../../components/Button/GreenButton';
import {
  displayPhone,
  isTouchScreen,
  dateInputMask,
  displayDateFormat,
  exists,
} from '../../../lib/utilities';
import validation from '../../../lib/input_validation';
import { EXPRESS_SCRIPTS_SUPPORT_PHONE } from '../../../lib/express_scripts';
import container from './container';

const phoneNumber = displayPhone(EXPRESS_SCRIPTS_SUPPORT_PHONE);

const REQUIRED_INPUTS = ['birthDate', 'postalCode'];

const requiredInputs = (needsDisease) =>
  REQUIRED_INPUTS.concat(needsDisease ? 'disease' : []);

export class ConfirmDetails extends Component {
  state = {
    birthDate: '',
    postalCode: '',
    disease: undefined,
    messages: {
      birthDate: { type: null, text: null },
      postalCode: { type: null, text: null },
      disease: { type: null, text: null },
    },
    submitting: false,
  };

  validation = {
    birthDate: validation.caregiverDate,
    postalCode: validation.postalCode,
    disease: validation.response,
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  // don't show errors as they're typing
  onChange(e) {
    const { name, value } = e.target;
    const { messages } = this.state;
    const newState = { [name]: value };

    return this.validation[name](value)
      .then(() => ({ type: 'success', text: null }))
      .catch(() => ({ type: null, text: null }))
      .then((msg) => {
        newState.messages = {
          ...messages,
          [name]: msg,
        };

        return this.setState(newState);
      });
  }

  // show errors since they're done
  onBlur(e) {
    const { name, value } = e.target;
    const { messages } = this.state;

    this.validation[name](value)
      .then(() => ({ type: 'success', text: null }))
      .catch((err) => ({ type: 'error', text: err }))
      .then((msg) => {
        this.setState({
          messages: {
            ...messages,
            [name]: msg,
          },
        });
      });
  }

  onSubmit(e) {
    e.preventDefault();
    const { onSuccess, needsDisease, validate } = this.props;
    const { messages, disease, birthDate, postalCode } = this.state;

    // go through all inputs, don't eject on first error
    // return null if not an error, so we can build an array
    // which contains either nulls or errors
    const inputValidations = requiredInputs(needsDisease).map((field) =>
      this.validation[field](this.state[field])
        .then(() => null)
        .catch((error) => ({
          type: 'error',
          text: error,
          field,
        }))
    );

    Promise.all(inputValidations)
      .then((results) => {
        const errors = results.filter(exists);
        if (errors.length > 0) {
          throw errors;
        } else {
          return results;
        }
      })
      .then(() => {
        this.setState({ submitting: true, error: null });

        return validate({ birthDate, postalCode })
          .then((data) => {
            // v2 doesn't return data.eligible on a good result,
            // only when they're no longer eligible
            if (data.eligible === false) {
              this.setState({
                submitting: false,
                error: 'ES_VERIFY_ERROR',
              });
            } else {
              this.setState({ submitting: false });
              onSuccess(data, disease);
            }
          })
          .catch((err) => {
            console.error(err);
            this.setState({
              submitting: false,
              error: 'ES_ERROR',
            });
          });
      })
      .catch((errors) =>
        this.setState({
          messages: {
            ...messages,
            ...errors.reduce((acc, err) => {
              acc[err.field] = { type: err.type, text: err.text };
              return acc;
            }, {}),
          },
          error: 'PLEASE_CORRECT_ERRORS',
        })
      );
  }

  render() {
    const { eligible, needsDisease, t } = this.props;
    const {
      birthDate,
      postalCode,
      disease,
      submitting,
      messages,
      error,
    } = this.state;

    const dateFormat = displayDateFormat();

    return (
      <Collapse in={eligible}>
        <div>
          <h2 style={{ fontSize: '1.8rem', margin: '2rem 0' }}>
            {t('PLEASE_CONFIRM_INFORMATION')}
          </h2>
          <form onSubmit={this.onSubmit}>
            <Row>
              <Col xs={12} sm={6}>
                <Form.Group
                  controlId='birthDateInput'
                  validationState={messages.birthDate.type}
                  size='lg'
                >
                  <Form.Label>{t('DATE_OF_BIRTH_LABEL')}</Form.Label>
                  <Form.Control
                    autoComplete='bday'
                    autoFocus={!isTouchScreen()}
                    as={InputMask}
                    type='tel'
                    mask={dateInputMask()}
                    value={birthDate}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    name='birthDate'
                  />
                  <Form.Control.Feedback />
                  <Form.Text>
                    {messages.birthDate.text
                      ? t(messages.birthDate.text, { dateFormat })
                      : dateFormat}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group
                  controlId='postalCodeInput'
                  validationState={messages.postalCode.type}
                  size='lg'
                >
                  <Form.Label>{t('ZIP_CODE_LABEL')}</Form.Label>
                  <Form.Control
                    as={InputMask}
                    type='tel'
                    mask='99999'
                    maskChar={null}
                    pattern='\d{5}'
                    autoComplete='shipping postal-code'
                    value={postalCode}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    name='postalCode'
                  />
                  <Form.Control.Feedback />
                  <Form.Text>{t(messages.postalCode.text)}</Form.Text>
                </Form.Group>
              </Col>
            </Row>
            {needsDisease && (
              <fieldset>
                <legend style={{ fontSize: '1.2rem', marginBottom: '0px' }}>
                  {t('IM_INTERESTED_IN_PROPELLER_FOR')}
                </legend>
                <RadioGroup
                  options={[
                    { value: 'asthma', label: t('common:ASTHMA_CAPITALIZED') },
                    { value: 'copd', label: t('common:COPD') },
                  ]}
                  required
                  inline
                  name='disease'
                  value={disease}
                  errorText={messages.disease.text}
                  validationClass={messages.disease.type}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  id='condition-selection'
                  t={t}
                />
              </fieldset>
            )}
            {error && (
              <Form.Group validationState='error'>
                <Form.Text>{t(error, { phoneNumber })}</Form.Text>
              </Form.Group>
            )}
            <GreenButton type='submit' block disabled={submitting}>
              {t('VERIFY')}
            </GreenButton>
          </form>
        </div>
      </Collapse>
    );
  }
}

export default container(ConfirmDetails);
