import moment from '../include-moment';
import { API_DATE_FORMAT } from './constants';

export const displayDateFormat = (format = 'L') =>
  moment.localeData().longDateFormat(format);

export const displayTimeFormat = (input, format = 'LT') =>
  moment(input, 'h:mm a').format(displayDateFormat(format));

export const dateInputMask = (format = 'L') =>
  displayDateFormat(format).replace(/\w/g, '9');

export const formatAPIDate = (date) => {
  return moment(date, displayDateFormat()).format(API_DATE_FORMAT);
};

export const formatForDisplay = (date) => {
  return moment(date, API_DATE_FORMAT).format(displayDateFormat());
};

export const openTime = (locale) => {
  moment.locale(locale);
  const open = moment({ hour: 8 });
  return open.format('LT');
};

export const closeTime = (locale) => {
  moment.locale(locale);
  const close = moment({ hour: 20 });
  return close.format('LT');
};

export const isValidDate = (value) => {
  const regex = new RegExp(
    /^([0-9]{4})-?(1[0-2]|0[1-9])-?(3[01]|0[1-9]|[12][0-9])$/
  );
  return value.match(regex);
};

export const localesForCountry = function localesForCountry(country) {
  country = 'string' === typeof country ? country : 'NA';

  switch (country.toUpperCase()) {
    case 'US':
      return ['en-US', 'es-US'];
    case 'CA':
      return ['en-CA', 'fr-CA'];
    case 'AU':
      return ['en-US'];
    case 'AR':
      return ['en-US'];
    case 'DE':
      return ['en-US'];
    case 'ES':
      return ['en-US'];
    case 'FR':
      return ['en-US'];
    case 'GB':
      return ['en-US'];
    case 'IE':
      return ['en-US'];
    case 'IT':
      return ['en-US'];
    case 'KR':
      return ['en-US'];
    case 'MX':
      return ['en-US'];
    case 'NL':
      return ['en-US'];
    case 'PT':
      return ['en-US'];
    case 'RU':
      return ['en-US'];
    default:
      return ['en-US', 'es-US', 'en-CA', 'fr-CA'];
  }
};

export const isNotEU = (country) => {
  return ['US', 'AU', 'AR', 'CA', 'KR', 'MX', 'RU'].includes(country);
};
