import React from 'react';
import memoize from 'memoize-one';
import GaLink from '../../GaLink';
import { isExpressScripts, publicImg, isIBX } from '../../../lib/utilities';
import logo from '../../../assets/images/logo_blue.svg';
import './logo.scss';

const esLogoDark = publicImg('es/es-dark.png');
const HOME_PATH = '/';

const _pathForLogo = memoize((pathname, modal, currentPath) => {
  if (modal && !currentPath) {
    return HOME_PATH;
  } else if (pathname !== currentPath && !modal) {
    return pathname;
  }
});

const logoForGroup = ({ assets = [] }) => {
  const logoObj = assets && assets.find((a) => 'logo' === a.name);
  return logoObj && logoObj.url;
};

// we're wrapping this so that we can memoize once and only pass it shallow params
const pathForLogo = ({ pathname, state: { modal } = {} } = {}, currentPath) =>
  _pathForLogo(pathname, modal, currentPath);

const darkLogoForGroup = (groupConfig = {}) => {
  if (isExpressScripts(groupConfig.name)) {
    return esLogoDark;
  } else {
    return logoForGroup(groupConfig);
  }
};

class Logo extends React.Component {
  state = {
    pathForNav: pathForLogo(this.props.location),
  };

  static getDerivedStateFromProps({ location = {} }, { pathForNav }) {
    const path = pathForLogo(location, pathForNav);
    if (path) {
      return { pathForNav: path };
    } else {
      return null;
    }
  }

  render() {
    const { groupConfig, t } = this.props;
    const { pathForNav } = this.state;

    const showPartnerLogo =
      HOME_PATH === pathForNav ||
      isExpressScripts(groupConfig.name) ||
      isIBX(groupConfig.name);

    const partnerLogo =
      pathForNav === HOME_PATH
        ? logoForGroup(groupConfig)
        : darkLogoForGroup(groupConfig);

    return (
      <GaLink
        to='/'
        action='topNav-logo'
        aria-label={t('PROPELLER_HEALTH_LOGO')}
        className='logo-link'
      >
        <div className='propeller-logo'>
          <img
            src={logo}
            height='55'
            width='256'
            alt={t('PROPELLER_HEALTH_LOGO')}
          />
        </div>
        {showPartnerLogo && !!partnerLogo && (
          <div className='partner-logo'>
            <span className='logo-divider' />
            <img src={partnerLogo} alt={groupConfig.displayName} height='50' />
          </div>
        )}
      </GaLink>
    );
  }
}

export default Logo;
