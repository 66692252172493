import i18n from 'i18next';
import { validQuiz } from './quiz';
import { API_HOST, isPharmaProgramEligible, SUBDOMAIN } from './environment';
import { MIN_ADULT_ACT_AGE, currentAge } from './age';
import { MAX_ADULT_ACT_SCORE, MIN_ADULT_ACT_SCORE } from './quiz';
import { TERMS_ROOT, DEFAULT_TERMS_DIR } from './constants';
import { apiHeaders, checkResponse, extractText } from './requests';
import { isPresent } from './presence';
import { cleanEmptyStrings, getValue } from './objects';
import { fetchCurrentUser, signOut, submitQuiz } from '../../api/user';

import {
  hasSensor,
  getFullMedicationListFromEnrollmentMeds,
} from './medications';
import {
  getUserAgreementForProgram,
  programMedFilter,
  DEFAULT_PHARMA_PROGRAM,
  BTC,
} from '../pharmaProgram';
import { getCountryCode } from '../../lib/utilities/countryMap';

export const dataForRole = (
  role,
  data,
  hubRequired,
  caregiver = {},
  mongoObjectId
) => {
  const IS_SELF = role === data.enrollmentRole;
  // some hacky value coercion to deal with people who take the child act
  // but are enrolling as an adult (based on age provided)
  const actScore = data.patient.act;
  if ('patient' === role && 'number' === typeof actScore) {
    const age = currentAge(data.patient.birthDate);
    if (age >= MIN_ADULT_ACT_AGE) {
      data.patient.act =
        actScore < MIN_ADULT_ACT_SCORE
          ? MIN_ADULT_ACT_SCORE
          : actScore > MAX_ADULT_ACT_SCORE
          ? MAX_ADULT_ACT_SCORE
          : actScore;
    }
  }

  return {
    ...data[role],
    id: IS_SELF && mongoObjectId ? mongoObjectId : undefined,
    oidcIdToken: data.oidcIdToken,
    mailingAddress: cleanEmptyStrings(data.mailingAddress),
    group: data.group,
    language: i18n.language,
    timeZone: data.timeZone,
    phone: IS_SELF ? data.phone : undefined,
    sendSMSLinks: IS_SELF ? data.sendSMSLinks : undefined,
    email: IS_SELF ? data.email : data[role].email,
    password: IS_SELF ? data.password : data[role].password,
    birthDate: role === 'caregiver' ? undefined : data[role].birthDate,
    caregiverId: role === 'patient' ? caregiver.id : undefined,
    acceptedUserAgreements:
      data.acceptedUserAgreements.length > 0
        ? data.acceptedUserAgreements
        : undefined,
    tags: !!data.tags ? data.tags : undefined,
    ...hubRequired,
  };
};

const medicalIdInputData = ({ medicalIds = [] }) =>
  medicalIds.reduce((acc, kv) => {
    acc[kv.key] = kv.value;
    return acc;
  }, {});

// this builds an object mapping the inputs to values from enrollmentData,
const standardInputData = (step, enrollmentData) =>
  step.inputs.filter(isPresent).reduce((acc, s) => {
    const val = getValue(enrollmentData, s.key);
    if (s.key === 'phone') {
      const countryCode = getCountryCode(acc['phoneCountry']);
      acc[s.key] = s.getter ? s.getter(val, countryCode) : val;
    } else {
      acc[s.key] = s.getter ? s.getter(val) : val;
    }
    return acc;
  }, {});

export const inputDataForStep = (step, enrollmentData) =>
  step.key === 'medicalIds'
    ? medicalIdInputData(enrollmentData.patient)
    : standardInputData(step, enrollmentData);

export const validationState = (value, error, mask) => {
  if (mask && value.replace(/_/g, '9') === mask) {
    return null;
  }

  if (!!error) {
    return 'error';
  }

  if (error === false) {
    return 'success';
  }

  return null;
};

export const enrollmentDisease = (enrollmentData) =>
  enrollmentData.patient.disease;

export const termsURI = (filename) => `${TERMS_ROOT}/${filename}`;

export const establishUserContext = (isCaregiver, id) => {
  // if this is not caregiver enrollment, or if it is but
  // we don't have a base userId, destroy any current session cookies
  if (!isCaregiver || !id) {
    return signOut().catch(() => Promise.resolve({}));
  }

  // we should only be here if it's caregiver enrollment
  // and we have a user id on the enrollmentData,
  // meaning it's likely something was recently submitted
  return fetchCurrentUser()
    .then((user) => {
      if (user.id === id) {
        return Promise.resolve(user);
      } else {
        return signOut().catch(() => Promise.resolve({}));
      }
    })
    .catch(() => Promise.resolve({}));
};

export const buildPlanRequests = (id) => {
  const URL = `${API_HOST}/api/users/${id}/plan/medication`;
  const OPTIONS = {
    method: 'POST',
    headers: apiHeaders(),
    credentials: 'include',
  };

  return (promiseChain, med) =>
    promiseChain
      .then(checkResponse)
      .then(() => window.fetch(URL, { ...OPTIONS, body: JSON.stringify(med) }));
};

export const termsPath = (filename, country = 'US', locale = 'en-US') => {
  // if fully specfified, just return for backwards compatibility
  if (/.*\.html$/.test(filename)) return filename;

  // handle development artifacts
  if (locale === 'dev') locale = 'en-US';

  // remove trailing slash for cleaner path construction below
  const name =
    filename[filename.length - 1] === '/' ? filename.slice(0, -1) : filename;

  return `${name}/${country.toUpperCase()}/${locale}.html`;
};

export const loadTermsHTML = (filename) => {
  return window.fetch(termsURI(filename)).then(checkResponse).then(extractText);
};

export const submitPlansAndQuiz = (id, medications, quiz, enrollmentData) => {
  const requestReducer = buildPlanRequests(id);

  return medications
    .reduce(requestReducer, Promise.resolve({ ok: true }))
    .then(() => {
      if (validQuiz(quiz, enrollmentData.patient.birthDate)) {
        submitQuiz(id, quiz).catch(() => Promise.resolve({}));
      } else {
        return Promise.resolve({ ok: true });
      }
    });
};

// removes country code for displaying phone number
export const phoneGetter = (val = '', code = 1) => {
  let phone = val.replace('+', '');
  let regex = new RegExp(`^${code}`);
  let cleanVal = regex.test(phone) ? phone.replace(regex, '') : phone;
  return 'string' === typeof val ? cleanVal : '';
};

// converts phone number into E.164 format
export const phoneSetter = (val, code = 1) => {
  // remove non-digits and initial zeros (per Twilio)
  let num = 'string' === typeof val && val.replace(/\D/g, '').replace(/^0/, '');
  return num && num.length > 0 ? `+${code}${num}` : undefined;
};

// groupConfig.defaultTermsAndConditions includes the country, but downstream we append the country
// To be most explicit, strip the country from defaultTermsAndConditions
// rather than guessing later
function termsForGroup(directory) {
  if (directory) {
    // remove trailing slash if there is one
    const path =
      directory[directory.length - 1] === '/'
        ? directory.slice(0, -1)
        : directory;

    // remove terms root and country. Will we need to inspect this more closely?
    return path.replace(`${TERMS_ROOT}/`, '').split('/').slice(0, -1).join('/');
  } else {
    return DEFAULT_TERMS_DIR;
  }
}

// by importing SUBDOMAIN and using it in the call,
// we can override the value in tests to cover different cases
export function determineTerms(groupConfig, program) {
  const filename =
    isPharmaProgramEligible(SUBDOMAIN) && program
      ? getUserAgreementForProgram(program)
      : termsForGroup(groupConfig.defaultTermsAndConditions);

  return [{ filename }];
}

export function buildEnrollmentTags({ pharmaProgram, patientMeds, groupMeds }) {
  const meds = getFullMedicationListFromEnrollmentMeds(groupMeds, patientMeds);

  if (isPharmaProgramEligible(SUBDOMAIN)) {
    const medsForProgram = programMedFilter(pharmaProgram);
    const programName = pharmaProgram.confirmedProgram;
    return meds
      .filter((med) => medsForProgram(med) && hasSensor(med))
      .map((med) => ({
        name: med.id,
        category: 'fulfillment',
      }))
      .concat(
        programName !== DEFAULT_PHARMA_PROGRAM && programName !== BTC
          ? // add tag to distinguish pharma program patients
            { name: programName, category: 'program' }
          : []
      );
  } else {
    // tag every sensorable medication for the user
    return meds.filter(hasSensor).map((med) => ({
      name: med.id,
      category: 'fulfillment',
    }));
  }
}
