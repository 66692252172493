import process from 'process';

const IOS_RX = /ip(?:ad|od|hone)/;
const MARKETING_SUBDOMAINS = ['signup', 'iadtc', 'myasthma', 'btc'];

export const ES_SUBDOMAINS = ['es', 'es-sandbox', 'esi'];
const WALGREENS_SUBDOMAINS = ['walgreens'];
const IBX_SUBDOMAINS = ['ibx', 'amerihealth', 'bltpa'];

export const PHARMA_PROGRAM_SUBDOMAINS = [].concat(
  MARKETING_SUBDOMAINS,
  WALGREENS_SUBDOMAINS
);
export const GOOGLE_ADS_SUBDOMAINS = MARKETING_SUBDOMAINS.filter(
  (subdomain) => subdomain !== 'iadtc'
);
const PAID_SUBDOMAINS = [].concat(WALGREENS_SUBDOMAINS);
const VALIDATION_SUBDOMAINS = [...ES_SUBDOMAINS, ...IBX_SUBDOMAINS];

export const HAS_WINDOW = 'undefined' !== typeof window;

const HOSTNAME = HAS_WINDOW && window.location.hostname;
export const SUBDOMAIN = HOSTNAME && HOSTNAME.split('.')[0];

export const TLD =
  'development' === process.env.NODE_ENV
    ? 'biz'
    : HOSTNAME && HOSTNAME.split('.').pop();

export const isNonCoveredEntity = (sd = SUBDOMAIN) => {
  return [
    ...WALGREENS_SUBDOMAINS,
    ...MARKETING_SUBDOMAINS,
    'aan',
    'azdemo',
    'asthma',
    'begin',
    'breathers',
    'demo-ph',
    'dtp1',
    'engage',
    'go',
    'gsk',
    'join',
    'navigator',
    'novartis',
    'omed',
    'oxyensure',
    'start',
  ].includes(sd);
};

export const isSponsorshipGroup = (sd = SUBDOMAIN) => {
  return MARKETING_SUBDOMAINS.includes(sd);
};

export const isBCBS = (sd = SUBDOMAIN) => {
  return ['bluecrossmn', 'blueplus'].includes(sd);
};

export const isExpressScripts = (sd = SUBDOMAIN) =>
  sd && ES_SUBDOMAINS.includes(sd.toLowerCase());

export const isIBX = (sd = SUBDOMAIN) => {
  return sd && IBX_SUBDOMAINS.includes(sd.toLowerCase());
};

export const isWAG = (sd = SUBDOMAIN) =>
  sd && WALGREENS_SUBDOMAINS.includes(sd);
export const requiresValidation = (sd = SUBDOMAIN) =>
  sd && VALIDATION_SUBDOMAINS.includes(sd.toLowerCase());

export const isPaid = (sd = SUBDOMAIN) =>
  sd && PAID_SUBDOMAINS.includes(sd.toLowerCase());

export const isPharmaProgramEligible = (sd = SUBDOMAIN) =>
  sd && PHARMA_PROGRAM_SUBDOMAINS.includes(sd);

export const isMobileGroup = () => SUBDOMAIN === 'iadtc';

export const isMyAsthma = (subdomain = SUBDOMAIN) => subdomain === 'myasthma';
/**
 * Determine api host for environment. Hideous but, for now, effective.
 * @param hostname
 * @returns {string}
 */
export const hostForEnv = (apiHost, tld = TLD) => {
  if (apiHost) return apiHost;

  switch (tld) {
    case 'biz': // dev
    case 'localhost': // dev
      return 'https://dev.propellerhealth.biz';
    case 'io': // test
      return 'https://test.propellerhealth.io';
    case 'com': // production
      return 'https://my.propellerhealth.com';
    default:
      return `https://api.propellerhealth.${tld}`;
  }
};

// REACT_APP_API_URL is set in deploy-react-enroll.sh
export const API_HOST = hostForEnv(process.env.REACT_APP_API_URL);

export const isTouchScreen = () =>
  HAS_WINDOW &&
  ('ontouchstart' in window ||
    (window.DocumentTouch && document instanceof window.DocumentTouch));

export const IS_DEV_OR_TEST =
  HAS_WINDOW && ['localhost', 'biz', 'io'].includes(TLD);

export const isDev = (tld = TLD) => HAS_WINDOW && ['localhost'].includes(tld);

export const isBrowser = process.title === 'browser';

export const IS_IOS = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  return IOS_RX.test(ua);
};

// returns true if user agent is iphone, ipad, or android, otherwise false
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
export function isMobileDevice(userAgent) {
  const ua = userAgent.toLowerCase();
  const matches = ua.match(/(android|iphone|ipad)/i);
  return matches && matches.length > 0 ? true : false;
}

// returns "ios", "android", or false
export const MOBILE_DEVICE = () => {
  if (!HAS_WINDOW) return false;

  var ua = window.navigator.userAgent.toLowerCase();
  // platform     = window.navigator.platform.toLowerCase();
  // platformName = ua.match(/ip(?:ad|od|hone)/)
  //   ? "ios"
  //   : (ua.match(/(?:webos|android)/) || platform.match(/mac|win|linux/) || ["other"])[0];

  return ua.match(IOS_RX) ? 'ios' : (ua.match(/(?:android)/) || [false])[0];

  // return /ios|android/.test(platformName);
};

export const isWebview = (userAgentString) => {
  return (
    userAgentString.includes('wv') ||
    new RegExp(/Version\/[\d.]+\sChrome/).test(userAgentString) ||
    new RegExp(/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i).test(
      userAgentString
    )
  );
};
