import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import eyeOpen from '../../../assets/images/icons/icon-eye-open.svg';
import eyeClosed from '../../../assets/images/icons/icon-eye-closed.svg';
import iconSuccess from '../../../assets/images/icons/icon-success-green.svg';
import iconError from '../../../assets/images/icons/icon-error-red.svg';
import './password-input.scss';

const passwordChecks = [
  {
    test: /[A-Z]/,
    text: '1_UPPER_CASE_LETTER',
  },
  {
    test: /[a-z]/,
    text: '1_LOWER_CASE_LETTER',
  },
  {
    test: /[0-9]/,
    text: '1_NUMBER',
  },
  {
    test: /.{8,}/,
    text: 'MIN_8_CHARACTERS',
  },
];

export class PasswordInput extends Component {
  state = {
    visible: false,
    password: this.props.value || '',
  };

  toggleVisbility = () => {
    this.setState({ visible: !this.state.visible });
  };

  onChange = (e) => {
    const value = e.target.value;
    const { onChange } = this.props;
    this.setState({ password: value });
    if (onChange) onChange({ password: value });
  };

  onBlur = (e) => {
    const value = e.target.value;
    if (this.props.onBlur) this.props.onBlur({ password: value });
  };

  render() {
    const { error, id, label, type, t } = this.props;

    const { visible } = this.state;

    const requirements = passwordChecks.map((check) => {
      check.present = this.state.password.match(check.test);
      return check;
    });

    return (
      <div>
        <Form.Group className='password-input' controlId={id}>
          <Form.Label>{t(label)}</Form.Label>
          <InputGroup>
            <Form.Control
              as='input'
              maxLength={64}
              minLength={8}
              onBlur={this.onBlur}
              onChange={this.onChange}
              type={visible ? 'text' : type}
              value={this.state.password}
            />

            <Button
              className='toggle-password'
              onClick={() => this.toggleVisbility()}
              aria-label={
                visible ? t('HIDE_PASSWORD_BUTTON') : t('SHOW_PASSWORD_BUTTON')
              }
            >
              {visible ? (
                <img
                  src={eyeOpen}
                  alt={t('HIDE_PASSWORD_BUTTON')}
                  width='24'
                  height='24'
                />
              ) : (
                <img
                  src={eyeClosed}
                  alt={t('SHOW_PASSWORD_BUTTON')}
                  width='24'
                  height='24'
                />
              )}
            </Button>
          </InputGroup>

          <div className='password-requirements-wrapper'>
            {error ? (
              <span
                className='has-error error-message'
                role='alert'
                aria-live='assertive'
              >
                {t(this.props.errorMessage) || t('enrollment:INVALID_PASSWORD')}
              </span>
            ) : (
              ''
            )}
            <ul className='password-requirements'>
              {requirements.map((r, i) => {
                const extraClasses = r.present
                  ? 'valid'
                  : error
                  ? 'missing'
                  : '';
                return (
                  <li
                    key={`requirement-${i}`}
                    className={`requirement ${extraClasses}`}
                  >
                    {r.present ? (
                      <img
                        src={iconSuccess}
                        alt={t('SUCCESS')}
                        width='16'
                        height='16'
                      />
                    ) : error ? (
                      <img
                        src={iconError}
                        alt={t('ERROR')}
                        width='16'
                        height='16'
                      />
                    ) : null}
                    {t(r.text)}
                  </li>
                );
              })}
            </ul>
          </div>
        </Form.Group>
      </div>
    );
  }
}

PasswordInput.propTypes = {
  error: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  t: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default PasswordInput;
