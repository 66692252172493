import i18n from 'i18next';
import { API_HOST } from '../lib/utilities/environment';
import { apiHeaders, fetchJSON, TLD } from '../lib/utilities';

export const fetchCurrentUser = () => {
  return fetchJSON(`${API_HOST}/api/users/me`, {
    method: 'GET',
    headers: apiHeaders(),
    credentials: 'include',
  });
};

export const signIn = (data) => {
  return fetchJSON(`${API_HOST}/api/users/signIn`, {
    method: 'POST',
    headers: apiHeaders(),
    body: data,
    credentials: 'include',
  });
};

export const signOut = () => {
  return window.fetch(`${API_HOST}/api/users/signOut`, {
    method: 'POST',
    headers: apiHeaders(),
    credentials: 'include',
  });
};

export const submitUser = (user) => {
  return fetchJSON(`${API_HOST}/api/users`, {
    method: 'POST',
    body: user,
    headers: apiHeaders(),
    credentials: 'include',
  });
};

export const submitQuiz = (id, quiz) => {
  return fetchJSON(`${API_HOST}/api/users/${id}/${quiz.type}s`, {
    method: 'POST',
    body: quiz.data,
    headers: apiHeaders(),
    credentials: 'include',
  });
};

export const verifyId = (data) => {
  return fetchJSON(`${API_HOST}/api/verifyid`, {
    method: 'POST',
    headers: apiHeaders(),
    body: data,
  });
};

export const verifyAnswers = (data) => {
  const answers = { answers: data.answers };
  return fetchJSON(`${API_HOST}/api/verifyid/${data.id}`, {
    method: 'POST',
    headers: apiHeaders(),
    body: answers,
  });
};

export const getSignInLink = (data) => {
  return fetch(`/api/app-access/signin-link?TLD=${TLD}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      AcceptLanguage: i18n.language || 'en-US',
    },
  });
};

export const getReferrerLink = (data) => {
  return fetchJSON(`/api/app-access/referrer-link?TLD=${TLD}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      AcceptLanguage: i18n.language || 'en-US',
    },
  });
};

