import React from 'react';
import { compose } from 'redux';
import Support from '../../../components/Support';
import container from './container';

export const MedsError = ({ navigateBack }) => {
  return <Support goBack={navigateBack} />;
};

export default compose(container)(MedsError);
