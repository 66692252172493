import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import Flag from '../../Flag';
import { getCountryCodes } from '../../../lib/utilities/countryMap';
import './CountryCodeSelection.scss';
import caret from '../../../assets/images/icons/icon-caret.svg';

export class CountryCodeSelection extends React.Component {
  state = {
    dropDownSelected: false,
  };

  componentDidMount() {
    this.setState(() => ({
      phoneCountry: this.props.countryName,
    }));
  }

  menuSelection = (country) => {
    this.setState(() => ({
      dropDownSelected: false,
      phoneCountry: country.name,
    }));
    this.setCountry(country);
  };

  setCountry = (country) => {
    if (this.props.setCountry) {
      this.props.setCountry(country);
    }
  };

  createList = () => {
    const countries = getCountryCodes();
    const { t } = this.props;
    return countries.map((country) => {
      return (
        <Dropdown.Item
          key={'list-item-' + country.name}
          className='locale-list-item'
          onClick={() => this.menuSelection(country)}
        >
          <Flag country={country.name} />
          <label
            data-attr='phoneCountry'
            key='phoneCountry'
            value={country.name}
          >
            {t(country.name)} (+{country.code})
          </label>
        </Dropdown.Item>
      );
    });
  };

  render() {
    return (
      <Dropdown className='country-code-selector'>
        <Dropdown.Toggle variant='outline'>
          <Flag country={this.state.phoneCountry} />
          <img src={caret} width='24' height='24' alt='' />
        </Dropdown.Toggle>
        <Dropdown.Menu>{this.createList()}</Dropdown.Menu>
      </Dropdown>
    );
  }
}

CountryCodeSelection.propTypes = {
  countryName: PropTypes.string,
  countryCode: PropTypes.number,
  setCountry: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation('common')(CountryCodeSelection);
