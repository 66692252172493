// lets have this know nothing about translation.
// text should be passed in translated
import React from 'react';
import { string, func, bool, shape } from 'prop-types';
import Col from 'react-bootstrap/Col';
import LazyLoad from 'react-lazyload';
import ImageLoader from '../../../components/ImageLoader/ImageLoader';
import './square-input.scss';

const SquareInput = ({
  option,
  label,
  selected = false,
  onChange,
  idx,
  htmlName,
  height,
}) => {
  const { id, image, name, subtext } = option;
  // we want allow default images ('no-match') but not
  // display them on the final "I don't see my..." tile
  const NO_IMG = !image || image.includes('no-match');
  return (
    <Col xs={6} sm={4} md={3} className='square-input'>
      <input
        id={id}
        data-option={idx && `${idx + 1}`}
        type='checkbox'
        name={htmlName}
        value={id}
        checked={selected}
        onChange={onChange}
      />
      <label className='square-radio-input' htmlFor={id}>
        <span
          data-attr={id}
          className={`square-radio-wrapper ${
            NO_IMG ? 'vertical-center-child' : ''
          }`}
        >
          {NO_IMG ? (
            <span className='subtext'></span>
          ) : (
            <LazyLoad once={true}>
              <ImageLoader src={image} alt={name} height={height || '100'} />
            </LazyLoad>
          )}
          <span className={NO_IMG ? 'no-img' : 'label'}>
            <span className='pre-subtext'>{label}</span>
            {subtext ? <div>({subtext})</div> : null}
          </span>
        </span>
      </label>
    </Col>
  );
};

SquareInput.propTypes = {
  height: string,
  option: shape({
    id: string.isRequired,
    image: string,
    name: string.isRequired,
    subtext: string,
  }).isRequired,
  onChange: func,
  selected: bool,
};

export default SquareInput;
