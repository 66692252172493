import { connect } from 'react-redux';
import {
  push as navigateForward,
  goBack as navigateBack,
} from 'connected-react-router';
import memoize from 'memoize-one';
import {
  error as notifyError,
  hide as clearError,
} from 'react-notification-system-redux';
import {
  selectMedication as onChange,
  setSelectedMeds,
  setEnrollmentMeds
} from '../../../actions';

import {
  objectFromQueryString,
  medsForType,
  enrollmentDisease,
  countryRegex,
} from '../../../lib/utilities';

const calculateFormFactor = memoize(
  (search) => objectFromQueryString(search).type
);

const mapStateToProps = (props, ownProps) => {
  const { groupConfig, formFactors, medSelection, enrollmentData } = props;
  const { location } = ownProps;
  const {
    formFactors: selectedFormFactors,
    medications: selectedMedications,
  } = medSelection;

  const ffid = calculateFormFactor(location.search);
  // memoize this
  const currentFormFactor = formFactors[ffid];

  const _meds = groupConfig.medications || [];
  const medications = medsForType(_meds, currentFormFactor).map((med) => ({
    ...med,
    selected: selectedMedications.includes(med.id),
  }));

  return {
    regex: countryRegex(groupConfig.country || 'US'),
    formFactors,
    selectedFormFactors,
    selectedMedications,
    medications,
    currentFormFactor,
    disease: enrollmentDisease(enrollmentData),
  };
};


const mapDispatchToProps = {
  onChange,
  navigateBack,
  navigateForward,
  notifyError,
  clearError,
  setSelectedMeds,
  setEnrollmentMeds
};

export default connect(mapStateToProps, mapDispatchToProps);
