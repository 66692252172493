import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { getCountryCode } from '../../../lib/utilities/countryMap';
import { validatePhone } from '../../../lib/input_validation';
import PropTypes from 'prop-types';
import CountryCodeSelection from '../CountryCodeSelection/CountryCodeSelection.js';
import Form from 'react-bootstrap/Form';
import GreenButton from '../../Button/GreenButton';
import InputGroup from 'react-bootstrap/InputGroup';
import InputMask from 'react-input-mask';
import './PhoneInput.scss';

export function PhoneInput({
  button = true,
  error = false,
  errorText,
  label,
  onBlur,
  onChange,
  handlePhoneCountryBlur,
  phoneCountry,
  phoneRequired,
  setCountryName,
  t,
  value,
}) {
  const code = getCountryCode(phoneCountry);
  const [countryName, setName] = useState(phoneCountry || 'US');
  const [countryCode, setCode] = useState(code || '1');
  const [phoneNumber, setNumber] = useState(value || '');
  const [phoneError, setPhoneError] = useState(error);
  const [errorMessage, setErrorMessage] = useState(errorText || '');

  const setCountry = (country) => {
    setName(country.name);
    if (setCountryName) {
      setCountryName(country.name);
    }
    if (handlePhoneCountryBlur) {
      handlePhoneCountryBlur(country.name);
    }
    setCode(country.code);
  };

  const handleChange = (value) => {
    setNumber(value);
    setPhoneError(false);
    if (onChange) {
      onChange({ phoneNumber: value });
    }
  };

  const handleBlur = (value) => {
    setNumber(value);
    const validation = validatePhone(value, countryName, phoneRequired);
    setPhoneError(validation.phoneError);
    setErrorMessage(validation.errorMessage);
    if (onBlur) {
      onBlur(value, validation.phoneError);
    }
  };

  return (
    <Form.Group
      className='phone-input'
      controlId='phoneInputControlGroup'
      size='md'
    >
      <Form.Label>
        {phoneRequired
          ? t('enrollment:MOBILE_PHONE_LABEL_NOT_OPTIONAL')
          : t('enrollment:PHONE_LABEL')}
      </Form.Label>

      <InputGroup size='md'>
        <CountryCodeSelection
          t={t}
          countryName={countryName}
          countryCode={Number(countryCode)}
          setCountry={setCountry}
        />

        <InputGroup.Text>&nbsp;+{countryCode}&nbsp;</InputGroup.Text>

        {Number(countryCode) === 1 ? (
          <Form.Control
            autoComplete='tel-national'
            aria-label={t(label)}
            as={InputMask}
            data-attr='phone'
            name='phoneNumber'
            onBlur={(e) => handleBlur(e.target.value)}
            onChange={(e) => handleChange(e.target.value)}
            type='tel'
            value={value}
          />
        ) : (
          <Form.Control
            autoComplete='tel-national'
            className='sign-in-link-input'
            data-attr='phone'
            name='phoneNumber'
            maxLength='40'
            onBlur={(e) => handleBlur(e.target.value)}
            onChange={(e) => handleChange(e.target.value)}
            type='tel'
            value={phoneNumber}
          />
        )}

        {button ? (
          <GreenButton className='send-btn' type='submit'>
            {t('common:SEND')}
          </GreenButton>
        ) : (
          ''
        )}
      </InputGroup>
      <Form.Text className='ph-body-2'>
        {t('enrollment:TCPA_PHONE_BLURB')}
      </Form.Text>
      <Form.Control.Feedback />
      {(error || phoneError) && (errorText || errorMessage) ? (
        <div role='alert' aria-live='assertive' className='error-message'>
          {t(errorText || errorMessage)}
        </div>
      ) : (
        ''
      )}
    </Form.Group>
  );
}

PhoneInput.propTypes = {
  button: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  phoneCountry: PropTypes.string,
  setCountryName: PropTypes.func,
  t: PropTypes.func,
  value: PropTypes.string,
};

export default withTranslation(['enrollment', 'common'])(PhoneInput);
