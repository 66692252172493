import { interlaceContent, publicImg, i18nImg } from './utilities';

import iconBenefitHeart from '../assets/images/icons/icon_benefit_heart.svg';
import iconFDACleared from '../assets/images/icons/icon_fda_cleared.svg';
import iconEasyToUse from '../assets/images/icons/icon_easy_to_use.svg';

import iconAppSmall from '../assets/images/icons/icon_app_small.svg';
import iconAppLarge from '../assets/images/icons/icon_app_large.svg';
import iconCartLarge from '../assets/images/icons/icon_cart_large.svg';
import iconCartSmall from '../assets/images/icons/icon_cart_small.svg';
import iconComputerSmall from '../assets/images/icons/icon_computer_small.svg';
import iconComputerLarge from '../assets/images/icons/icon_computer_large.svg';
import iconInsightSmall from '../assets/images/icons/icon_insight_small.svg';
import iconInsightLarge from '../assets/images/icons/icon_insight_large.svg';
import iconShareSmall from '../assets/images/icons/icon_share_small.svg';
import iconShareLarge from '../assets/images/icons/icon_share_large.svg';
import iconSyncSmall from '../assets/images/icons/icon_sync_small.svg';
import iconSyncLarge from '../assets/images/icons/icon_sync_large.svg';

const imgAppScreen1x = i18nImg('img_app_screen_768x512.jpg');
const imgAppScreen2x = i18nImg('img_app_screen_1152x768.jpg');
const imgAppScreen3x = i18nImg('img_app_screen_1536x1024.jpg');

const imgAppScreenCOPD1x = i18nImg('img_app_screen_COPD_768x512.jpg');
const imgAppScreenCOPD2x = i18nImg('img_app_screen_COPD_1152x768.jpg');
const imgAppScreenCOPD3x = i18nImg('img_app_screen_COPD_1536x1024.jpg');

const lifestyleImg = (file) => publicImg(`lifestyle_grid/${file}`);

const imgFamily1x = lifestyleImg('img_family_768x512.jpg');
const imgFamily2x = lifestyleImg('img_family_1152x768.jpg');
const imgFamily3x = lifestyleImg('img_family_1536x1024.jpg');

const imgCouple1x = lifestyleImg('img_couple_768x512.jpg');
const imgCouple2x = lifestyleImg('img_couple_1152x768.jpg');
const imgCouple3x = lifestyleImg('img_couple_1536x1024.jpg');

const imgBle1x = lifestyleImg('img_ble_768x512.jpg');
const imgBle2x = lifestyleImg('img_ble_1152x768.jpg');
const imgBle3x = lifestyleImg('img_ble_1536x1024.jpg');

const imgAsthmaApp1_1x = i18nImg('screenshots/img_asthma_app_1.png');
const imgAsthmaApp1_2x = i18nImg('screenshots/img_asthma_app_1_2x.png');
const imgAsthmaApp1_3x = i18nImg('screenshots/img_asthma_app_1_3x.png');

const imgAsthmaApp2_1x = i18nImg('screenshots/img_asthma_app_2.png');
const imgAsthmaApp2_2x = i18nImg('screenshots/img_asthma_app_2_2x.png');
const imgAsthmaApp2_3x = i18nImg('screenshots/img_asthma_app_2_3x.png');

const imgAsthmaApp3_1x = i18nImg('screenshots/img_asthma_app_3.png');
const imgAsthmaApp3_2x = i18nImg('screenshots/img_asthma_app_3_2x.png');
const imgAsthmaApp3_3x = i18nImg('screenshots/img_asthma_app_3_3x.png');

const imgCOPDApp1_1x = i18nImg('screenshots/img_COPD_app_1.png');
const imgCOPDApp1_2x = i18nImg('screenshots/img_COPD_app_1_2x.png');
const imgCOPDApp1_3x = i18nImg('screenshots/img_COPD_app_1_3x.png');

const imgCOPDApp2_1x = i18nImg('screenshots/img_COPD_app_2.png');
const imgCOPDApp2_2x = i18nImg('screenshots/img_COPD_app_2_2x.png');
const imgCOPDApp2_3x = i18nImg('screenshots/img_COPD_app_2_3x.png');

const imgCOPDApp3_1x = i18nImg('screenshots/img_COPD_app_3.png');
const imgCOPDApp3_2x = i18nImg('screenshots/img_COPD_app_3_2x.png');
const imgCOPDApp3_3x = i18nImg('screenshots/img_COPD_app_3_3x.png');

const asthmaHeader = i18nImg('img_asthma_header.jpg');
const asthmaHeader2x = i18nImg('img_asthma_header_2x.jpg');
const asthmaHeader3x = i18nImg('img_asthma_header_3x.jpg');

const copdHeader = i18nImg('img_COPD_hero.jpg');
const copdHeader2x = i18nImg('img_COPD_hero_2x.jpg');
const copdHeader3x = i18nImg('img_COPD_hero_3x.jpg');

const androidSMSLink1x = i18nImg('download/img_android_download_link.png');
const androidSMSLink2x = i18nImg('download/img_android_download_link@2x.png');
const androidSMSLink3x = i18nImg('download/img_android_download_link@3x.png');
const androidStore1x = i18nImg('download/img_android_play_store.png');
const androidStore2x = i18nImg('download/img_android_play_store@2x.png');
const androidStore3x = i18nImg('download/img_android_play_store@3x.png');

const iosSMSLink1x = i18nImg('download/img_ios_download_link.png');
const iosSMSLink2x = i18nImg('download/img_ios_download_link@2x.png');
const iosSMSLink3x = i18nImg('download/img_ios_download_link@3x.png');
const iosStore1x = i18nImg('download/img_ios_download_app.png');
const iosStore2x = i18nImg('download/img_ios_download_app@2x.png');
const iosStore3x = i18nImg('download/img_ios_download_app@3x.png');

const GET_STARTED_QUESTIONS = {
  icon: iconComputerSmall,
  iconLg: iconComputerLarge,
  text: 'ANSWER_A_FEW_QUICK_QUESTIONS',
};

const GET_STARTED_SHIP = {
  icon: iconAppSmall,
  iconLg: iconAppLarge,
  text: 'DOWNLOAD_APP_TO_SHIP',
};

const GET_STARTED_SYNC = {
  icon: iconSyncSmall,
  iconLg: iconSyncLarge,
  text: 'ATTACH_TO_INHALER_AUTO_SYNC',
};

const GET_STARTED_INSIGHT = {
  icon: iconInsightSmall,
  iconLg: iconInsightLarge,
  text: null,
};

const GET_STARTED_FREE_DEFAULT = [
  GET_STARTED_QUESTIONS,
  GET_STARTED_SHIP,
  GET_STARTED_SYNC,
  GET_STARTED_INSIGHT,
  {
    icon: iconShareSmall,
    iconLg: iconShareLarge,
    text: 'CHOOSE_TO_SHARE_PROGRESS',
  },
];

const GET_STARTED_PAID_DEFAULT = [
  GET_STARTED_QUESTIONS,
  {
    icon: iconCartSmall,
    iconLg: iconCartLarge,
    text: 'COMPLETE_CHECKOUT',
  },
  GET_STARTED_SHIP,
  GET_STARTED_SYNC,
  GET_STARTED_INSIGHT,
];

const GET_STARTED_STEPS_ASTHMA_FREE = [
  null,
  null,
  null,
  {
    text: 'USE_AS_PRESCRIBED_ASTHMA',
  },
  null,
];

const GET_STARTED_STEPS_COPD_FREE = [
  null,
  null,
  null,
  {
    text: 'USE_AS_PRESCRIBED_COPD',
  },
  null,
];

const GET_STARTED_STEPS_ASTHMA_PAID = [
  null,
  null,
  null,
  null,
  {
    text: 'USE_AS_PRESCRIBED_ASTHMA',
  },
];

const GET_STARTED_STEPS_COPD_PAID = [
  null,
  null,
  null,
  null,
  {
    text: 'USE_AS_PRESCRIBED_COPD',
  },
];

export const getStartedSteps = {
  header: 'GET_STARTED_FIVE_MINUTES',
  cta: 'GET_STARTED',
  asthma: GET_STARTED_FREE_DEFAULT.map(
    interlaceContent(GET_STARTED_STEPS_ASTHMA_FREE)
  ),
  copd: GET_STARTED_FREE_DEFAULT.map(
    interlaceContent(GET_STARTED_STEPS_COPD_FREE)
  ),
  asthma_paid: GET_STARTED_PAID_DEFAULT.map(
    interlaceContent(GET_STARTED_STEPS_ASTHMA_PAID)
  ),
  copd_paid: GET_STARTED_PAID_DEFAULT.map(
    interlaceContent(GET_STARTED_STEPS_COPD_PAID)
  ),
};

const CHECKER_DEFAULT = [
  {
    src: null,
    srcSet: null,
    sizes: '(max-width: 767px) 100%, 50%',
    text: 'OFFERED_AT_NO_COST',
    icon: iconBenefitHeart,
  },
  {
    src: null,
    srcSet: null,
    sizes: '(max-width: 767px) 100%, 50%',
    text: 'EASY_TO_USE',
    icon: iconEasyToUse,
  },
  {
    src: imgBle1x,
    srcSet: `${imgBle1x} 768w, ${imgBle2x} 1152w, ${imgBle3x} 1536w`,
    sizes: '(max-width: 767px) 100%, 50%',
    text: 'CLEARED_BY_FDA',
    icon: iconFDACleared,
  },
];

const CHECKER_PAID = [
  {
    text: 'JOINING_IS_SIMPLE',
  },
];

const CHECKER_ASTHMA = [
  {
    src: imgAppScreen1x,
    srcSet: `${imgAppScreen1x} 768w, ${imgAppScreen2x} 1152w, ${imgAppScreen3x} 1536w`,
  },
  {
    src: imgFamily1x,
    srcSet: `${imgFamily1x} 768w, ${imgFamily2x} 1152w, ${imgFamily3x} 1536w`,
  },
];

const CHECKER_COPD = [
  {
    src: imgAppScreenCOPD1x,
    srcSet: `${imgAppScreenCOPD1x} 768w, ${imgAppScreenCOPD2x} 1152w, ${imgAppScreenCOPD3x} 1536w`,
  },
  {
    src: imgCouple1x,
    srcSet: `${imgCouple1x} 768w, ${imgCouple2x} 1152w, ${imgCouple3x} 1536w`,
  },
];

const CHECKER_ASTHMA_FREE = CHECKER_DEFAULT.map(
  interlaceContent(CHECKER_ASTHMA)
);
const CHECKER_COPD_FREE = CHECKER_DEFAULT.map(interlaceContent(CHECKER_COPD));

export const checkerContent = {
  asthma: CHECKER_ASTHMA_FREE,
  copd: CHECKER_COPD_FREE,
  asthma_paid: CHECKER_ASTHMA_FREE.map(interlaceContent(CHECKER_PAID)),
  copd_paid: CHECKER_COPD_FREE.map(interlaceContent(CHECKER_PAID)),
};

export const rotatingHeadline = {
  staticText: 'SENSOR_PLUS_APP_EQUALS',
  asthma: {
    features: [
      'AUTOMATIC_TRACKING',
      'FACTS_ABOUT_ASTHMA',
      'SMART_MEDICATION_REMINDERS',
      'PERSONALIZED_ASTHMA_TIPS_FORECASTS',
      'INSIGHTS_FOR_DOCTOR',
    ],
    subtitle: 'PROPELLER_HELPING_THOUSANDS_ASTHMA',
    videoText: 'SEE_HOW',
    buttonText: 'GET_STARTED',
    src: asthmaHeader,
    srcSet: `${asthmaHeader} 1x, ${asthmaHeader2x} 2x, ${asthmaHeader3x} 3x`,
  },
  copd: {
    features: [
      'PERSONALIZED_COPD_TIPS',
      'SMART_MEDICATION_REMINDERS',
      'INSIGHTS_FOR_DOCTOR',
      'AUTOMATIC_TRACKING',
      'FACTS_ABOUT_COPD',
    ],
    subtitle: 'PROPELLER_HELPING_THOUSANDS_COPD',
    videoText: 'SEE_HOW',
    buttonText: 'GET_STARTED',
    src: copdHeader,
    srcSet: `${copdHeader} 1x, ${copdHeader2x} 2x, ${copdHeader3x} 3x`,
  },
};

export const appImages = {
  asthma: [
    {
      src: imgAsthmaApp1_1x,
      srcSet: `${imgAsthmaApp1_1x} 1x, ${imgAsthmaApp1_2x} 2x, ${imgAsthmaApp1_3x} 3x`,
    },
    {
      src: imgAsthmaApp2_1x,
      srcSet: `${imgAsthmaApp2_1x} 1x, ${imgAsthmaApp2_2x} 2x, ${imgAsthmaApp2_3x} 3x`,
    },
    {
      src: imgAsthmaApp3_1x,
      srcSet: `${imgAsthmaApp3_1x} 1x, ${imgAsthmaApp3_2x} 2x, ${imgAsthmaApp3_3x} 3x`,
    },
  ],
  copd: [
    {
      src: imgCOPDApp1_1x,
      srcSet: `${imgCOPDApp1_1x} 1x, ${imgCOPDApp1_2x} 2x, ${imgCOPDApp1_3x} 3x`,
    },
    {
      src: imgCOPDApp2_1x,
      srcSet: `${imgCOPDApp2_1x} 1x, ${imgCOPDApp2_2x} 2x, ${imgCOPDApp2_3x} 3x`,
    },
    {
      src: imgCOPDApp3_1x,
      srcSet: `${imgCOPDApp3_1x} 1x, ${imgCOPDApp3_2x} 2x, ${imgCOPDApp3_3x} 3x`,
    },
  ],
};

export const appIntroVideo = {
  asthma:
    'https://player.vimeo.com/external/256443847.sd.mp4?s=c5f6f6feb303e335f1d5badbc8fd8f3608ad63fa&profile_id=164',
  copd:
    'https://player.vimeo.com/external/256483292.sd.mp4?s=6ffe1ba27bea0572e47b313254e0f2d987bc7c57&profile_id=164',
};

export const appDownload = {
  android: {
    sms: {
      src: androidSMSLink1x,
      srcSet: `${androidSMSLink1x} 1x, ${androidSMSLink2x} 2x, ${androidSMSLink3x} 3x`,
      alt: 'EXAMPLE_DOWNLOAD_SMS',
    },
    download: {
      src: androidStore1x,
      srcSet: `${androidStore1x} 1x, ${androidStore2x} 2x, ${androidStore3x} 3x`,
      alt: 'PROPELLER_PLAY_STORE',
    },
    steps: [
      'GRAB_PHONE_OPEN_PLAY_STORE',
      'SEARCH_PLAY_STORE',
      'CLICK_INSTALL',
      'OPEN_APP_SIGN_IN',
    ],
  },
  ios: {
    sms: {
      src: iosSMSLink1x,
      srcSet: `${iosSMSLink1x} 1x, ${iosSMSLink2x} 2x, ${iosSMSLink3x} 3x`,
      alt: 'EXAMPLE_DOWNLOAD_SMS',
    },
    download: {
      src: iosStore1x,
      srcSet: `${iosStore1x} 1x, ${iosStore2x} 2x, ${iosStore3x} 3x`,
      alt: 'PROPELLER_APP_STORE',
    },
    steps: [
      'GRAB_PHONE_OPEN_APP_STORE',
      'SEARCH_APP_STORE',
      'CLICK_GET',
      'OPEN_APP_SIGN_IN',
    ],
  },
};
