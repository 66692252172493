import { SET_SESSION, CLEAR_SESSION, SHOW_SIGNIN } from '../actions';

export const session = function session(state = null, action) {
  switch (action.type) {
    case SET_SESSION:
      return Object.assign({}, action.session);

    case CLEAR_SESSION:
      return null;

    default:
      return state;
  }
};

export const showSignIn = function showSignIn(state = false, action) {
  switch (action.type) {
    case SHOW_SIGNIN:
      return action.show;

    default:
      return state;
  }
};
