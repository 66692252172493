import { hasSensor, isRescue } from './utilities/medications';
import { currentAge } from './utilities/age';
import { isWAG, isMyAsthma } from './utilities/environment';

// program identifiers
export const DEFAULT_PHARMA_PROGRAM = 'propeller';
export const SELF_PAID = 'selfPaid';
const AZEXPANSION = 'azExpansion';
export const BTC = 'btc';

// if we don't know the age yet, assume patient is old enough
const ageForProgram = (birthDate, defaultAge) =>
  !!birthDate ? currentAge(birthDate) : defaultAge;

// to qualify for azexpansion, patients must have asthma and at least 1 sensored rescue med
export const isEligibleForAZExpansion = ({ patient, meds }) => {
  const hasAsthma = patient?.disease === 'asthma';
  const sensorableRescueMeds = meds.filter((m) => isRescue(m) && hasSensor(m));
  const patientAge = ageForProgram(patient.birthDate, 12); //assume program minimum if not set, i.e. med select
  return hasAsthma && patientAge >= 12 && sensorableRescueMeds.length > 0;
};

export const isEligibleForBTC = ({ patient, meds }) => {
  const hasAsthma = patient?.disease === 'asthma';
  const sensorableRescueMeds = meds.filter((m) => isRescue(m) && hasSensor(m));
  return hasAsthma && sensorableRescueMeds.length > 0;
};

export const determinePharmaProgramEligibility = ({
  patient,
  meds,
  pharmaConfig,
  subdomain,
}) => {
  // if azExpansion isn't enabled or the user isn't on myasthma
  // return default for anything except walgreens, which needs selfPaid tag
  if (subdomain === 'btc' && isEligibleForBTC({ patient, meds })) {
    return BTC;
  }
  if (
    !pharmaConfig ||
    !pharmaConfig.pharmaProgramEnableAzExpansion ||
    !isMyAsthma(subdomain)
  ) {
    return subdomain === 'walgreens' ? SELF_PAID : DEFAULT_PHARMA_PROGRAM;
  }
  // if azExpansion is enabled AND the user is on myasthma,
  // check for azExpansion eligibility
  if (isEligibleForAZExpansion({ patient, meds })) {
    return AZEXPANSION;
  }
  // we won't get here while azExpansion is myasthma only
  if (
    pharmaConfig.pharmaProgramEnableAzExpansion &&
    isEligibleForAZExpansion({ patient, meds }) &&
    isWAG(subdomain)
  ) {
    return AZEXPANSION;
  }
  // we won't get here while azExpansion is myasthma only
  if (
    pharmaConfig.pharmaProgramEnableAzExpansion &&
    !isEligibleForAZExpansion({ patient, meds }) &&
    isWAG(subdomain)
  ) {
    return SELF_PAID;
  }
  return DEFAULT_PHARMA_PROGRAM;
};

export const getUserAgreementForProgram = (programTagName) => {
  switch (programTagName) {
    case AZEXPANSION:
      return 'termsAndConditions/azAsthmaExpansion';
    case SELF_PAID:
    case DEFAULT_PHARMA_PROGRAM:
    default:
      return 'termsAndConditions/defaultNonCE';
  }
};

export const programMedFilter = function programMedFilter(pharmaProgram) {
  // use selectedProgram here because we're showing the meds that will / won't
  // get sensored before we confirm the program. If they fall out of the
  // initial program, we should still fulfill what we said we would.
  switch (pharmaProgram.selectedProgram) {
    case AZEXPANSION:
      return (med) => isRescue(med);
    case BTC:
      return (med) => isRescue(med);
    default:
      // everything
      return () => true;
  }
};
