import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import container from './container';
import { logPage } from '../../../lib/analytics';
import { camelPath } from '../../../lib/utilities';
import PageHeading from '../../../components/PageHeading/PageHeading';
import Title from '../../../components/Title/Title';

export const EmailThankYou = (props) => {
  const { location, t } = props;
  useEffect(() => {
    const path = `${camelPath(location.pathname)}`;
    logPage({
      action: `load-${path}`,
      label: path,
    });
  }, [location]);

  return (
    <div className='sponsorship'>
      <PageHeading pageTitle={t('PROPELLER_THANK_YOU')} t={t}>
        <div className='sponsorship-text'>
          <Title
            className='sm-left-lg-center-text'
            text={t('PROPELLER_THANK_YOU')}
            level={1}
          />
          <p>{t('SPONSOR_RESULT_EMAIL_THANK_YOU')}</p>
        </div>
      </PageHeading>
    </div>
  );
};

EmailThankYou.propTypes = {
  location: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  container,
  withTranslation('medSelection')
)(EmailThankYou);
