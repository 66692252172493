/* eslint-disable react/jsx-props-no-spreading */
// This can be supplied to a PhInput component
import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';

function PhSelect({ children, className, ...props }) {
  return (
    <Form.Select className={classNames('ph-select', className)} as="select" {...props}>
      {children}
    </Form.Select>
  );
}

PhSelect.propTypes = {
  children: node.isRequired,
  className: string
};

PhSelect.defaultProps = {
  className: null
};

export default PhSelect;
