export const CAT_QUESTIONS = [
  {
    question: 'CAT_QUESTION_COUGH',
    options: {
      0: 'CAT_ANSWER_NEVER_COUGH',
      5: 'CAT_ANSWER_COUGH_ALL_THE_TIME',
    },
  },
  {
    question: 'CAT_QUESTION_PHLEGM',
    options: {
      0: 'CAT_ANSWER_NO_PHLEGM',
      5: 'CAT_ANSWER_FULL_OF_PHLEGM',
    },
  },
  {
    question: 'CAT_QUESTION_TIGHTNESS',
    options: {
      0: 'CAT_ANSWER_NOT_TIGHT',
      5: 'CAT_ANSWER_VERY_TIGHT',
    },
  },
  {
    question: 'CAT_QUESTION_WALK_UPHILL',
    options: {
      0: 'CAT_ANSWER_WALK_NOT_BREATHLESS',
      5: 'CAT_ANSWER_WALK_BREATHLESS',
    },
  },
  {
    question: 'CAT_QUESTION_HOME_ACTIVITY',
    options: {
      0: 'CAT_ANSWER_HOME_NOT_LIMITED',
      5: 'CAT_ANSWER_HOME_LIMITED',
    },
  },
  {
    question: 'CAT_QUESTION_CONFIDENCE',
    options: {
      0: 'CAT_ANSWER_AM_CONFIDENT',
      5: 'CAT_ANSWER_NOT_CONFIDENT',
    },
  },
  {
    question: 'CAT_QUESTION_SLEEP',
    options: {
      0: 'CAT_ANSWER_SLEEP_SOUNDLY',
      5: 'CAT_ANSWER_SLEEP_NOT_SOUNDLY',
    },
  },
  {
    question: 'CAT_QUESTION_ENERGY',
    options: {
      0: 'CAT_ANSWER_LOTS_ENERGY',
      5: 'CAT_ANSWER_NO_ENERGY',
    },
  },
];

export const CAT_SCORES = {
  min: 0,
  max: 40,
  interval: 5,
  ranges: [
    {
      value: 5,
      keyText: 'SCORE_LABEL_NORMAL',
      class: 'act-gd',
      showValidation: false,
      description: 'CAT_SCORE_NORMAL',
    },
    {
      value: 9,
      keyText: 'SCORE_LABEL_LOW',
      class: 'act-lo',
      showValidation: false,
      description: 'CAT_SCORE_LOW',
    },
    {
      value: 20,
      keyText: 'SCORE_LABEL_MEDIUM',
      class: 'act-md',
      showValidation: false,
      description: 'CAT_SCORE_MEDIUM',
    },
    {
      value: 30,
      keyText: 'SCORE_LABEL_HIGH',
      class: 'act-wn',
      showValidation: false,
      description: 'CAT_SCORE_HIGH',
    },
    {
      value: 40,
      keyText: 'SCORE_LABEL_VERY_HIGH',
      class: 'act-pr',
      showValidation: false,
      description: 'CAT_SCORE_VERY_HIGH',
    },
  ],
};
