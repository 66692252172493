import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import container from './container';
import { Container, Row, Col } from 'react-bootstrap';

import Address from './Address/Address';
import CaregiverInfo from './CaregiverInfo/CaregiverInfo';
import Coppa from './Coppa';
import Credentials from './Credentials/Credentials';
import Demographics from './Demographics/Demographics';
import MedicalIds from './MedicalIds/MedicalIds';
import NoSmartphone from '../NoSmartphone';
import PatientInfo from './PatientInfo/PatientInfo';
import Role from './Role/Role';
import Smartphone from './Smartphone/Smartphone';
import ShareAccess from './ShareAccess/ShareAccess';
import MedScheduler from './MedScheduler';
import UserAgreement from './UserAgreement';

import './enrollment.scss';

const Enrollment = (props) => {
  const basePath = '/enrollment';

  if (!props.enrollmentMeds.length) {
    props.pushHistory('/med-selection');
  }

  return (
    <Container className='enrollment-outer-wrapper'>
      <Row>
        <Col xs={12} md={{ span: 10, offset: 1 }}>
          <Switch>
            <Route path={basePath} exact component={Role} />
            <Route path={`${basePath}/patient-info`} component={PatientInfo} />
            <Route path={`${basePath}/caregiver-info`} component={CaregiverInfo} />
            <Route path={`${basePath}/coppa`} component={Coppa} />
            <Route path={`${basePath}/smartphone`} component={Smartphone} />
            <Route path={`${basePath}/no-smartphone`} component={NoSmartphone} />
            <Route path={`${basePath}/address`} component={Address} />
            <Route path={`${basePath}/medical-id`} component={MedicalIds} />
            <Route path={`${basePath}/demographics`} component={Demographics} />
            <Route path={`${basePath}/credentials`} component={Credentials} />
            <Route
              path={`${basePath}/patient-credentials`}
              component={Credentials}
            />
            <Route path={`${basePath}/share-access`} component={ShareAccess} />
            <Route path={`${basePath}/schedule`} component={MedScheduler} />
            <Route path={`${basePath}/terms`} component={UserAgreement} />
          </Switch>
        </Col>
      </Row>
    </Container>
  );
};

export default compose(container)(Enrollment);
