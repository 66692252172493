import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'react-bootstrap/FormGroup';
import Title from '../../../components/Title/Title';

export const CoppaQuestion = ({ questionIdx, onChange, question }) => {
  return (
    <div className='radio-selectors'>
      <Title text={question.prompt} id='coppa-question' level={1} />
      <div className='max-500'>
        {question.answers.map((answer, index) => {
          return (
            <FormGroup
              key={`${questionIdx}-${index}`}
              role='radiogroup'
              aria-labelledby='coppa-question'
            >
              <input
                className='custom-radio'
                type='radio'
                name={questionIdx}
                value={index}
                id={`${questionIdx}-${index}`}
                onChange={onChange}
              />
              <label htmlFor={`${questionIdx}-${index}`}>{answer}</label>
            </FormGroup>
          );
        })}
      </div>
    </div>
  );
};

CoppaQuestion.propTypes = {
  onChange: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  questionIdx: PropTypes.number.isRequired,
};

export default CoppaQuestion;
