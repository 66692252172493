import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { basePrice } from '../../../lib/sensors';
import { logEvent } from '../../../lib/analytics';

function KitPrice({
  finalPrice = false,
  groupConfig,
  pharmaProgram = 'propeller',
  t,
  totalSensors,
}) {
  const userPays =
    groupConfig.name === 'walgreens' &&
    (pharmaProgram.selectedProgram === 'propeller' ||
      pharmaProgram.selectedProgram === 'selfPaid');

  const totalPrice = Number.parseFloat(basePrice * totalSensors).toFixed(2);
  const price = Number.parseFloat(basePrice).toFixed(2);

  useEffect(() => {
    let priceLogged = false;

    function logPrice(price) {
      if (!priceLogged) {
        const label = 'kitPrice';
        logEvent('KitPrice', {
          action: label,
          label: label,
          value: price,
          nonInteraction: true,
        });
        priceLogged = true;
      }
    }
    if (finalPrice) {
      userPays ? logPrice(totalPrice) : logPrice(0);
    }
  }, [finalPrice, totalPrice, userPays]);

  const displayPrice =
    totalSensors > 1 ? `${price} ${t('EACH')} (${totalSensors})` : `${price}`;

  if (finalPrice) {
    return (
      <div className='price font-bold'>
        {t('YOU_PAY')} ${userPays ? totalPrice : 0}
      </div>
    );
  }

  const priceA11yDescription = `${t(
    'medSelection:REGULAR_PRICE'
  )} ${price}, ${t('medSelection:PRICE_YOU_PAY')} $0`;

  return (
    <div className='price font-bold'>
      {userPays ? (
        <span>
          ${displayPrice}
          {totalSensors > 1 ? (
            <div>
              ${`${totalPrice}`} {t('TOTAL')}
            </div>
          ) : null}
        </span>
      ) : (
        <div aria-label={priceA11yDescription}>
          <span className='free'>
            ${price} {totalSensors > 1 ? t('EACH') : ''}
          </span>{' '}
          $0
        </div>
      )}
    </div>
  );
}

export default withTranslation('medSelection')(KitPrice);

KitPrice.propTypes = {
  finalPrice: PropTypes.bool,
  groupConfig: PropTypes.object.isRequired,
  pharmaProgram: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  totalSensors: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};
