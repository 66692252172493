import React from 'react';
import { compose } from 'redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Redirect } from 'react-router-dom';
import container from './container';

import Heading from './Heading';
import OptionsSelector from '../../../components/OptionsSelector';
import SquareInput from '../SquareInput';

import {
  getMedImageUrl,
  isNonNebPillSprayRescue,
} from '../../../lib/utilities';

import { withTranslation } from 'react-i18next';
import PageHeading from '../../../components/PageHeading/PageHeading';
import { logPage } from '../../../lib/analytics';

// we are potentially getting a list of for factors to iterate over.
// we should navigate through the list with qp for the one we're working over
export class Medications extends React.Component {
  notificationUID = 'medselectmedicationserror';

  // when navigating across selected form factors, we're simply rerendering this component with new data (url param)
  // if we have this state as a boolean, then we need to reset it on every change and we loose back/forward memory
  // by setting as an object, we should be able to preserve selections on the current pass of all selected form factors
  state = {
    noMatch: {}
  };

  onChange(id) {
    const { onChange, currentFormFactor } = this.props;
    const { noMatch } = this.state;
    const ffId = currentFormFactor.id;

    if ('no-match' === id) {
      this.setState({
        noMatch: {
          ...noMatch,
          [ffId]: !noMatch[ffId],
        },
      });
    } else {
      onChange(id);
    }
  }

  goNext(e) {
    e.preventDefault();
    const {
      location,
      currentFormFactor,
      medications,
      formFactors,
      selectedFormFactors,
      selectedMedications,
      setSelectedMeds,
      navigateForward,
      notifyError,
      clearError,
      t,
    } = this.props;
    const { noMatch } = this.state;

    const ffId = currentFormFactor.id;

    const selectedMeds = medications.filter((m) => m.selected);
    if (!noMatch[ffId] && selectedMeds.length === 0) {
      return notifyError({
        title: t('common:ERROR'),
        message: t('MED_SELECTION_ERROR'),
        position: 'tc',
        uid: this.notificationUID,
      });
    }

    const nextIdx = selectedFormFactors.indexOf(ffId) + 1;
    const nextFF = selectedFormFactors[nextIdx];
    const ffIndex = formFactors.map((ff) => ff.id).indexOf(nextFF);

    clearError(this.notificationUID);

    if (nextIdx >= selectedFormFactors.length) {
      setSelectedMeds(selectedMedications);
      navigateForward('/med-selection/summary');
    } else {
      navigateForward(`${location.pathname}?type=${ffIndex}`);
    }
  }
  constructor(props) {
    super(props);
    this.goNext = this.goNext.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    logPage('MedSelect-Medications');
    //this.props.setEnrollmentMeds([]);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {
      currentFormFactor,
      disease,
      medications,
      navigateBack,
      t,
    } = this.props;
    const { noMatch } = this.state;

    if (!currentFormFactor) {
      return <Redirect to='/med-selection/form-factors' />;
    }

    const ffId = currentFormFactor.id;

    // at one point I set a key on OptionsSeletor to trigger rerender when the key changes,
    // so I didn't need to manually reset state. However, it doesn't seem like this is
    // being used at the moment, and it's possible some stuff could be refactored so we
    // can do this more cleanly.
    return (
      <PageHeading pageTitle={t('PAGE_TITLE_MEDS')} t={t}>
        <OptionsSelector goBack={navigateBack} goNext={this.goNext} key={ffId}>
          <Row>
            <Col xs={12}>
              <Heading formFactor={currentFormFactor} disease={disease} />
            </Col>
          </Row>
          <Row as='fieldset' className='med-selection'>
            <legend className='visually-hidden'>
              {t('medSelection:WHICH_MED_FOR_FORMFACTOR_PRESCRIBED_2', {
                formFactorName: t(currentFormFactor.name),
              })}
            </legend>
            {medications.map((m, idx) => {
              const mid = m.id;
              const isNoMatch = 'no-match' === mid;
              const label = isNoMatch
                ? t(m.name, { name: t(currentFormFactor.name) })
                : m.name;
              const selected = isNoMatch ? noMatch[ffId] : m.selected;
              if (isNonNebPillSprayRescue(m) && m.activeIngredients) {
                //m.subtext = activeIngredientList(m.activeIngredients);
              }
              // takes an image object and width
              m.image = getMedImageUrl(m, 110);
              return (
                <SquareInput
                  key={mid}
                  option={m}
                  onChange={() => this.onChange(mid)}
                  idx={idx}
                  htmlName='medications'
                  selected={selected}
                  label={label}
                  height='100'
                />
              );
            })}
          </Row>
        </OptionsSelector>
      </PageHeading>
    );
  }
}

export default compose(
  withTranslation(['medSelection', 'common']),
  container
)(Medications);
