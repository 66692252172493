import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './style.scss';

// This is to orient screen-reader users on page change
// The "skip to main" link is not visible
// More-or-less follows the skip-navigation convention: https://webaim.org/techniques/skipnav/
class PageHeading extends Component {
  constructor(props) {
    super(props);
    this.skipLink = React.createRef();
  }

  componentDidMount() {
    this.skipLink.current.focus();
  }

  render() {
    const { pageTitle, children, t } = this.props;
    return (
      <div className='page-heading'>
        <Helmet>
          {/* meta tag prevents safari from turning phone numbers into inaccessible links */}
          <meta name='format-detection' content='telephone=no'></meta>
          <title>{pageTitle}</title>
        </Helmet>
        <a className='skip-link' href='#maincontent' ref={this.skipLink}>
          {pageTitle}. {t('common:SKIP_TO_MAIN')}.
        </a>
        <div id='maincontent'>{children}</div>
      </div>
    );
  }
}

PageHeading.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(PageHeading);
