import expressScriptsConfig from '../express_scripts';
import { SUBDOMAIN, isExpressScripts } from '../utilities/environment';

export default function configureVerification(sd = SUBDOMAIN, config) {
  if (isExpressScripts(sd)) return expressScriptsConfig(config);
  console.warn(
    `Subdomain ${sd} is configured for access validation but no validation is defined`
  );

  return {
    validateCode: function defaultValidateCode() {
      const msg =
        'You did not override validateCode and are trying to call it. Doing nothing.';
      console.warn(msg);
      return Promise.reject(new Error(msg));
    },
    validatePatient: function defaultValidatePatient() {
      const msg =
        'You did not override validatePatient and are trying to call it. Doing nothing.';
      console.warn(msg);
      return Promise.reject(new Error(msg));
    },
    adaptPatient: function defaultAdaptPatient() {
      console.warn(
        'You did not override adaptPatient and are trying to call it. Doing nothing.'
      );
      return null;
    },
    adaptMedications: function defaultAdaptMedications() {
      console.warn(
        'You did not override adaptMedications and are trying to call it. Doing nothing.'
      );
      return null;
    },
    steps: [{}],
  };
}
