import i18n from 'i18next';
import { X_PH_API_VERSION } from './constants';
import { isObject } from './objects';
import { API_HOST} from './environment';


export const apiHeaders = () => {
  // if we're not fully initialized, this can be undefined.
  // why are we not fully initiliazed?
  const language = i18n.language || 'en-US';

  return {
    'x-ph-api-version': X_PH_API_VERSION,
    'Content-Type': 'application/json',
    'Accept-Language': language,
  };
};

// helper functions for working with the Fetch API
export const checkResponse = (response) =>
  response.ok ? response : Promise.reject(response);

export const extractJSON = (response) => response.json();
export const extractText = (response) => response.text();

export const fetchJSON = (url, data = {}) => {
  let body = data.body;
  if (isObject(body)) {
    body = JSON.stringify(body);
  }
  data.body = body;

  data.headers = {
    ...data.headers,
    'Content-Type': 'application/json',
  };

  return window.fetch(url, data).then(checkResponse).then(extractJSON);
};

// tokens last for 2 hours, and can have a max 10 at any point...
export const fetchLoginToken = (userId) => {
  return fetchJSON(`${API_HOST}/api/users/${userId}/loginToken`, {
    method: 'POST',
    headers: apiHeaders(),
    credentials: 'include',
  });
};
