import React from 'react';
import container from './container';

function withSession(WrappedComponent) {
  class withSessionWrapper extends React.Component {
    componentDidMount() {
      const { session } = this.props;
      if (!session || !session.id) {
        this.props.loadSession();
      }
    }

    render() {
      const { loadSession, ...rest } = this.props;
      return <WrappedComponent {...rest} />;
    }
  }

  return container(withSessionWrapper);
}

export default withSession;
