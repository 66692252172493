import React from 'react';
import { compose } from 'redux';
import { Container, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import container from './container';
import PageHeading from '../../components/PageHeading/PageHeading';
import Hub from './Hub';
import Smartphone from './Smartphone';
import {
  allowGoogleAds,
  fireGoogleAdsEvent,
} from '../../lib/analytics/initGoogleAds';
import { logPage } from '../../lib/analytics';

const excitedText = (isCaregiver) =>
  isCaregiver
    ? 'WERE_EXCITED_CAREGIVER'
    : 'SHIP_SENSOR_HUB_INFO';

export class Download extends React.Component {
  static getDerivedStateFromProps(props, state) {
    const { session } = props;
    if (session) {
      return {
        email: state.email || session.email,
        id: state.id || session.id,
        group: state.group || session.group,
        caregiver: state.caregiver || 'caregiver' === session.role,
      };
    } else {
      return null;
    }
  }

  constructor(props) {
    super(props);
    const { enrollmentData = {}, session = {}} = props;

    // hoist out the data we want to use in our child components
    // so that we can clear out the data from our central store (and localStorage)
    // but still have everything we need to render
    this.state = {
      email: (session && session.email) || undefined,
      id: (session && session.id) || undefined,
      group: (session && session.group) || undefined,
      caregiver:
        (enrollmentData.caregiver && enrollmentData.caregiver.email) ||
        (session && 'caregiver' === session.role),
      hubRequired: enrollmentData.hubRequired,
      disease: enrollmentData.patient.disease,
    };
  }

  componentDidMount() {
    const { groupConfig } = this.props;
    logPage('Download');

    this.props.loadSession();
    this.props.resetEnrollmentData();
    window.addEventListener('message', this.receiveMessage, false);
    if (allowGoogleAds(groupConfig.fbTracking)) {
      fireGoogleAdsEvent();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.receiveMessage, false);
  }

  receiveMessage = (message) => {
    if (message && message.data) {
      if (message.source && message.data.action === 'logout') {
        message.source.close();
      }
    }
  };

  render() {
    const { hubRequired, caregiver} = this.state;
    const text = excitedText(caregiver);
    const { t, groupConfig4x } = this.props;
    return (
      <Container className='download-page-wrapper'>
        <Row>
          <Col xs={12} md={{ span: 10, offset: 1 }}>
            <PageHeading pageTitle={t('DOWNLOAD')} t={t}>
              {hubRequired || groupConfig4x?.parameters?.hubOnly?.value ? (
                <Hub {...this.props} {...this.state} excitedText={text} />
              ) : (
                <Smartphone {...this.props} {...this.state} />
              )}
            </PageHeading>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default compose(container, withTranslation(['enrollment']))(Download);
