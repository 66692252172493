import { connect } from 'react-redux';
import {
  addActResponse as addResponse,
  setActType as setType,
  removeActResponse as removeResponse,
  updateEnrollmentData,
} from '../../actions';
import { ACT_QUESTIONS } from '../../lib/act-quiz';

const checkForCompletion = (act) => {
  if (!act.type) {
    return false;
  }

  return act.answers.length === ACT_QUESTIONS[act.type].length;
};

const getCurrentQuestion = (act) => {
  if (!act.type) {
    return undefined;
  }

  return ACT_QUESTIONS[act.type][act.answers.length];
};

const getActLength = (act) => {
  if (!act.type) {
    return;
  }

  return ACT_QUESTIONS[act.type].length;
};

const mapStateToProps = (state) => {
  const act = state.asthmaControlTest;
  const { answers = [] } = act;

  return {
    score: act.score,
    is_complete: checkForCompletion(act),
    type: act.type,
    answer_count: answers.length,
    current_question: getCurrentQuestion(act),
    answers: answers,
    quiz_length: getActLength(act),
  };
};

const mapDispatchToProps = {
  setType,
  addResponse,
  removeResponse,
  updateEnrollmentData,
};

export default connect(mapStateToProps, mapDispatchToProps);
