import memoize from 'memoize-one';

export const generatePassword = () => {
  const letters = 'abcdefghijklmnopqrstuvwxyz';
  const randomLetter = letters[Math.floor(Math.random() * 13)];
  const randomNumber = Math.random().toString().substr(2, 4);

  return (
    Math.random().toString(36).slice(2) +
    Math.random().toString(36).slice(2).toUpperCase() +
    randomLetter +
    randomNumber +
    randomLetter.toUpperCase()
  );
};

export const countryRegex = memoize(
  (country) => new RegExp(`^${country.toLowerCase()}_`)
);

export const capitalize = (word) => {
  if ('string' !== typeof word || word.length === 0) {
    // console.error(word, "is not a string");
    return word;
  }

  return word[0].toUpperCase() + word.slice(1);
};

export const upperCaseToCapitalized = (word) => {
  if ('string' !== typeof word) {
    // console.error(word, "is not a string");
    return word;
  }

  if (word.length === 0) return word;

  return capitalize(word.toLowerCase());
};

export const upperCaseWords = (string) => {
  const SEPERATOR = ' ';
  return string.split(SEPERATOR).map(upperCaseToCapitalized).join(SEPERATOR);
};

export const toCamel = (string) => {
  return string.split(/[-_\s/]/).reduce((acc, s) => acc + capitalize(s));
};

export const camelToSnakeCase = (string) => {
  return string
    .split(/(?=[A-Z])/)
    .join('_')
    .toUpperCase();
};

// paths are preceeded buy a "/"
export const camelPath = (pathname) => toCamel(pathname.substring(1));

export const interpolate = (string, data = {}) => {
  const matches = string.match(/{{([a-zA-Z.]+)}}/g) || [];
  return matches.reduce((str, match) => {
    try {
      const val = match
        .replace(/[{}]/g, '')
        .split('.')
        .reduce((o, i) => o[i], data);
      return str.replace(match, val);
    } catch (e) {
      return str;
    }
  }, string);
};

export const displayPhone = (phone, country = 'US') => {
  if (!phone) return phone;

  switch (country) {
    case 'US':
    case 'CA':
    default:
      // +18772515451
      // there appears to be uncertainty about how extensions can be included in a tel href.
      // https://stackoverflow.com/questions/9482633/how-do-i-include-extensions-in-the-tel-uri
      // https://digitalfortress.tech/tricks/creating-direct-click-to-call-sms-links/
      // the version that appears to best supported has the extension preceeded by a comma
      // so that's what I've opted to do
      const parts = phone.split(',');
      const [baseNumber] = parts;
      if (/\+\d{11}$/.test(baseNumber)) {
        // 1 (877) 447-4415
        parts[0] = baseNumber
          .replace(/[^\d]/g, '')
          .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '1 ($2) $3-$4');
        return parts.join(' ext. ');
      } else {
        return phone;
      }
  }
};

export const getCustomToolTip = (validationClass, required) => {
  const needsToolTip = validationClass === 'error' && required;
  return needsToolTip ? 'common:TOOLTIP_REQUIRED_FIELD' : '';
};

//This could very well go anywhere else, this may not be the best spot for it.
const truthyValues = [
  true,
  'true',
  'TRUE',
  'True',
  't',
  'y',
  'yes',
  'YES',
  'Yes',
];
export const getBoolean = (value) => {
  return truthyValues.includes(value);
};

export const defaultT = (name) => (k) => {
  console.warn(
    `${name} provided translation key ${k} but no translate function t(). Caller is expected to provide t().`
  );
  return k;
};

export const padLeft = (val, length = 2, char = '0') => {
  let paddedValue = val.toString();
  while (paddedValue.length < length) {
    paddedValue = char + paddedValue;
  }

  return paddedValue;
};

export const textOrUndefined = (string) => {
  if (typeof string !== 'string') return undefined;

  if (string.length === 0) {
    return undefined;
  } else {
    return string;
  }
};

// removes the +tag in user name of email and/or will return lowercased email
export const stripEmail = (emailToStrip) => {
  const email = emailToStrip.toLowerCase();
  // we shouldn't get invalid emails with a + in the domain, but just incase
  if (email.includes('+') && email.indexOf('+') < email.indexOf('@')) {
    const strippedEmail = email
      .slice(0, email.indexOf('+'))
      .concat(email.slice(email.indexOf('@')));
    return strippedEmail;
  }
  return email;
};

export const makeFirstLetterLowerCase = (str) => str.charAt(0).toLowerCase() + str.slice(1);
