import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    groupConfig: state.groupConfig,
    location: state.router.location,
  };
};

export default connect(mapStateToProps);
