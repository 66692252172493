import React, { Component } from 'react';
import PageHeading from '../../components/PageHeading/PageHeading';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Route, Switch, Redirect } from 'react-router-dom';

import container from './container';

import WhiteOutlineButton from '../../components/Button/WhiteOutlineButton';
import BackNextButtons from '../../components/BackNextButtons';
import GaLink from '../../components/GaLink';
import ProgressIndicator from '../../components/ProgressIndicator';
import ActScore from '../ActScore';

import { logEvent } from '../../lib/analytics';
import { camelPath } from '../../lib/utilities';

import './styles.scss';
import whoHas from '../../assets/images/act-images/age-verification.svg';

export class ActIntro extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick({ target }) {
    this.props.setType(target.getAttribute('data-act-version'));
  }

  render() {
    const { match, location, t } = this.props;

    return (
      <div className='act-wrapper'>
        <PageHeading pageTitle={t('landing:TAKE_THE_QUIZ')} t={t}>
          <div className='question-wrapper'>
            <div className='question-content act-intro'>
              <div className='question-header intro-header text-center'>
                {t('ANSWER_A_COUPLE_QUESTIONS')}
                <img className='act-image intro-image' src={whoHas} alt='' />
              </div>
              <div className='blue-text text-center'>
                {t('PLEASE_SELECT_AGE_ASTHMA')}
              </div>
            </div>
            <div className='pointer pointer-down' />
          </div>
          <div className='text-center act-buttons intro-buttons'>
            <WhiteOutlineButton
              as={GaLink}
              to={{
                pathname: `${match.url}/child`,
                state: Object.assign({}, location.state, { from: location }),
              }}
              data-act-version='child'
              onClick={this.onClick}
              action='act-selectChild'
            >
              {t('CHILD')} (4-11)
            </WhiteOutlineButton>
            <WhiteOutlineButton
              as={GaLink}
              to={{
                pathname: `${match.url}/adult`,
                state: Object.assign({}, location.state, { from: location }),
              }}
              data-act-version='adult'
              onClick={this.onClick}
              action='act-selectAdult'
            >
              {t('ADULT')} (12+)
            </WhiteOutlineButton>
          </div>
        </PageHeading>
      </div>
    );
  }
}

export class ActQuestions extends Component {
  constructor(props) {
    super(props);
    if (!props.type) {
      this.props.setType(props.match.params.actType);
    }
    this.goBack = this.goBack.bind(this);
    this.goNext = this.goNext.bind(this);
    this.updateSelection = this.updateSelection.bind(this);

    this.state = {
      nextDisabled: true,
      currentSelection: undefined,
    };
  }

  goNext(e) {
    if (e && e.preventDefault) e.preventDefault();

    const {
      location,
      answer_count: questionIdx,
      type,
      addResponse,
    } = this.props;
    const label = `${camelPath(
      location.pathname
    )}-click-actNextButton-${type}-to${questionIdx}`;

    logEvent('click', {
      action: label,
      label: label,
    });
    addResponse(this.state.currentSelection);
    this.setState({
      nextDisabled: true,
      currentSelection: undefined,
    });

    this.scrollToTop();
  }

  goBack() {
    const {
      answer_count,
      type,
      answers,
      location,
      removeResponse,
      setType,
    } = this.props;
    const baseLabel = `${camelPath(
      location.pathname
    )}-click-actBackButton-${type}-to}`;
    const label = `${baseLabel}${answer_count > 0 ? answer_count : 'Start'}`;

    logEvent('click', {
      action: label,
      label: label,
    });

    if (answer_count > 0) {
      this.setState({
        nextDisabled: false,
        currentSelection: answers[answers.length - 1].value,
      });
      removeResponse();
    } else {
      setType();
    }

    this.scrollToTop();
  }

  scrollToTop() {
    const el = document.querySelector('.question-content-wrapper');

    if (el) {
      el.scrollTop = 0;
    }
  }

  updateSelection(p) {
    let val = Number.parseInt(p.target.value, 10);
    if (val > -1) {
      this.setState({
        nextDisabled: false,
        currentSelection: val,
      });
    }
  }

  render() {
    const {
      match,
      location,
      type,
      current_question,
      answer_count: idx = 0,
      quiz_length,
      score,
      is_complete,
      t,
      updateEnrollmentData,
    } = this.props;

    // TODO: If they come in on this url, set type instead of redirect back
    if (!type) {
      return (
        <Redirect
          to={{
            pathname: match.url.replace(`/${match.params.actType}`, ''),
            state: Object.assign({}, location.state, { from: location }),
          }}
        />
      );
    }

    if (is_complete) {
      updateEnrollmentData({
        patient: {
          act: score,
        },
      });
      return (
        <Redirect to={`/take-the-quiz/complete?test=${type}&score=${score}`} />
      );
    }

    const selection = this.state.currentSelection;

    let options = current_question.options.map((o) => {
      let key = `${type}-act-${idx + 1}-${o.value}`;
      return (
        <li key={key}>
          <input
            className='custom-radio'
            id={key}
            type='radio'
            name={`${type}-act-${idx + 1}`}
            value={o.value}
            checked={o.value === selection}
            onChange={this.updateSelection}
          />
          <label htmlFor={key}>{t(o.text)}</label>
        </li>
      );
    });

    return (
      <form className='act-form' onSubmit={this.goNext}>
        <ProgressIndicator total={quiz_length} question={idx + 1} />
        <div className='question-content-wrapper'>
          <div className='question-content'>
            <div
              id='act-question-header'
              className='question-header text-center'
            >
              {t('QUESTION_NUM', { number: idx + 1, total: quiz_length })}
              <img className='act-image' src={current_question.image} alt='' />
            </div>
            <div className='act-question text-center'>
              {t(current_question.question)}
            </div>
            <ul className='radio-selectors act-options' role='radiogroup'>
              {options}
            </ul>
          </div>
        </div>
        <div className='act-buttons'>
          <BackNextButtons
            backClick={this.goBack}
            nextClick={this.goNext}
            disabled={this.state.nextDisabled}
            nextType='submit'
            rowStyle={{ margin: '0 auto' }}
          />
        </div>
      </form>
    );
  }
}

export const ActQuiz = ({ match, ...rest }) => {
  return (
    <Switch>
      <Route
        exact
        path={match.url}
        render={(props) => <ActIntro {...props} {...rest} />}
      />
      <Route
        exact
        path={`${match.url}/complete`}
        render={(props) => <ActScore {...props} {...rest} />}
      />
      <Route
        path={`${match.url}/:actType`}
        render={(props) => <ActQuestions {...props} {...rest} />}
      />
    </Switch>
  );
};

export default compose(
  container,
  withTranslation(['quiz', 'landing'])
)(ActQuiz);
