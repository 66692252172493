import moment from '../lib/include-moment';
import {
  mergeDeep,
  requiresValidation as needsValidation,
} from '../lib/utilities';

import {
  SET_ENROLLMENT_MEDS,
  INITIALIZE_ENROLLMENT,
  RESET_PATIENT_DATA,
  RESET_ENROLLMENT_DATA,
  SET_USER_ID,
  SET_GROUP_CONFIG,
  SET_MEDICATION_SCHEDULE,
  UPDATE_ENROLLMENT_DATA,
  SET_DISEASE,
  ACCESS_VALIDATED,
  SET_ACCEPTED_USER_AGREEMENTS,
  SHARE_ACCOUNT_ACCESS,
  INITIALIZE_MED_SELECTION,
  REMOVE_MED_ID,
  ADD_MEDICATIONS,
  RESET_ACCESS_VALIDATION,
} from '../actions';

const initialEnrollmentData = {
  id: undefined,
  enrollmentRole: undefined,
  verified: undefined,
  source: undefined,
  email: undefined,
  password: undefined,
  mailingAddress: {
    street: undefined,
    street2: undefined,
    city: undefined,
    stateOrRegion: undefined,
    country: 'US',
    stateCode: undefined,
    postalCode: undefined,
  },
  group: { name: 'try' },
  timeZone: moment.tz.guess(),
  hubRequired: undefined,
  countryCode: undefined,
  phone: undefined,
  sendSMSLinks: undefined,
  acceptedUserAgreements: undefined,
  caregiver: {
    givenName: undefined,
    familyName: undefined,
    birthDate: undefined,
    role: 'caregiver',
  },
  patient: {
    givenName: undefined,
    familyName: undefined,
    birthDate: undefined,
    act: undefined,
    cat: undefined,
    email: undefined, // during caregiver enrollment, if they want to set up the patient's account
    password: undefined, // during caregiver enrollment, if they want to set up the patient's account
    disease: undefined,
    role: 'patient',
    medicalIds: undefined,
  },
  oidcIdToken: undefined,
};

function initialDataForGroup(state, groupConfig, ...rest) {
  return mergeDeep(
    {},
    state,
    {
      group: {
        name: groupConfig.name,
      },
      timeZone: moment.tz.guess() || groupConfig.timeZone,
      patient: {
        disease:
          groupConfig.diseases && groupConfig.diseases.length === 1
            ? groupConfig.diseases[0]
            : state.patient.disease,
      },
      mailingAddress: {
        country: groupConfig.country,
      },
    },
    ...rest
  );
}

export const enrollmentData = function enrollmentData(
  state = initialEnrollmentData,
  action
) {
  switch (action.type) {
    case SET_USER_ID:
      return Object.assign({}, state, { id: action.id });

    case SET_GROUP_CONFIG:
      const { data: groupConfig } = action;
      return groupConfig ? initialDataForGroup(state, groupConfig) : state;

    case UPDATE_ENROLLMENT_DATA:
      return mergeDeep({}, state, action.data);

    case INITIALIZE_ENROLLMENT:
      return initialDataForGroup(state, action.groupConfig);

    case RESET_PATIENT_DATA:
      return mergeDeep({}, state, { patient: initialEnrollmentData.patient });

    case RESET_ENROLLMENT_DATA:
      return initialDataForGroup(initialEnrollmentData, action.groupConfig, {
        patient: { disease: state.patient.disease },
        phoneCountry: state.phoneCountry,
        phone: state.phone,
      });

    case SET_DISEASE:
      return mergeDeep({}, state, { patient: { disease: action.disease } });

    case SET_ACCEPTED_USER_AGREEMENTS:
      return mergeDeep({}, state, {
        acceptedUserAgreements: action.acceptedUserAgreements,
      });

    case SHARE_ACCOUNT_ACCESS:
      const shareAcccess = action.shareAccess === 'yes';
      return shareAcccess
        ? state
        : mergeDeep({}, state, {
            patient: { email: undefined, password: undefined },
          });

    default:
      return state;
  }
};

export const requiresValidation = function requiresValidation(
  state = needsValidation(),
  action
) {
  switch (action.type) {
    case ACCESS_VALIDATED:
      return !action.validated;

    case RESET_ACCESS_VALIDATION:
      return needsValidation();

    default:
      return state;
  }
};

export const enrollmentMeds = function enrollmentMeds(state = [], action) {
  switch (action.type) {
    case INITIALIZE_MED_SELECTION:
      return [];

    case REMOVE_MED_ID:
      return state.filter(({ medicationId }) => medicationId !== action.medId);

    // used in med selection and access validation
    case ADD_MEDICATIONS:
      return [].concat(action.medications).reduce((newState, medId) => {
        if (
          newState.findIndex(({ medicationId }) => medicationId === medId) ===
          -1
        ) {
          return newState.concat({ medicationId: medId });
        } else {
          return newState;
        }
      }, state);

    // used to replace medications, eg. to trust what's in the url
    case SET_ENROLLMENT_MEDS:
      return action.medications.map((medId) => ({ medicationId: medId }));

    case SET_MEDICATION_SCHEDULE:
      const idx = state.findIndex((m) => m.medicationId === action.medId);

      return Object.assign([], state, {
        [idx]: Object.assign({}, state[idx], { usageList: action.schedule }),
      });

    default:
      return state;
  }
};
