import { connect } from 'react-redux';
import { show } from 'react-notification-system-redux';

import {
  updateEnrollmentData,
  setDisease,
  accessValidated,
  setMedSelection,
  setDisabledInputs,
} from '../../actions';

const mapStateToProps = (state) => {
  return {
    displayName: state.groupConfig.displayName,
    subdomain: state.subdomain,
    queryParams: state.queryParams,
    medsList: state.groupConfig.medications,
    v1ESEligibility: state.v1ESEligibility,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resolveVerification: (data, medData, disease) => {
      dispatch(accessValidated(true));
      dispatch(updateEnrollmentData(data));
      dispatch(
        setDisabledInputs({
          'patient.givenName': !!data.patient.givenName,
          'patient.familyName': !!data.patient.familyName,
          'patient.birthDate': !!data.patient.birthDate,
        })
      );
      if (medData) dispatch(setMedSelection(medData));
      if (disease) dispatch(setDisease(disease));
    },
    notify: (...args) => dispatch(show(...args)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
