import moment from './include-moment';
import validation from './input_validation';
import {
  isTouchScreen,
  API_DATE_FORMAT,
  MOBILE_DEVICE,
  phoneGetter,
  phoneSetter,
  displayDateFormat,
  dateInputMask,
} from './utilities';
import countries from './countries';
import STATE_REFERENCE from '../data/state_reference';

// TODO: figure out what to do with "Optional"
export function buildMedIdInputs(medIds, locale = 'en-US') {
  return [].concat(medIds).map((mId, idx) => {
    const required = !!mId.isRequired;
    const validator = mId.validator && mId.validator[locale];

    return {
      label: `${mId.label[locale]}${required ? '' : ' ($t(OPTIONAL))'}`,
      placeholder: required ? undefined : 'OPTIONAL',
      key: mId.descriptorKey,
      autoFocus: !isTouchScreen() && idx === 0 ? true : undefined,
      required: required,
      validation: validation.medicalIds(validator, required),
    };
  });
}

export const steps = {
  whoEnrolling: () => ({
    title: 'WHO_ENROLLING_QUESTION',
    inputs: [
      {
        type: 'radio',
        key: 'enrollmentRole',
        name: 'enrollmentRole',
        required: true,
        autoFocus: !isTouchScreen(),
        validation: validation.response,
        options: [
          {
            label: 'MYSELF_PATIENT_LABEL',
            value: 'patient',
          },
          {
            label: 'CAREGIVER_LABEL',
            value: 'caregiver',
          },
        ],
      },
    ],
  }),

  basicInfo: () => {
    const dateFormat = displayDateFormat();
    return {
      title: 'BASIC_INFO_QUESTION',
      inputs: [
        {
          label: 'GIVEN_NAME_LABEL',
          type: 'text',
          key: 'patient.givenName',
          autoFocus: !isTouchScreen(),
          placeholder: 'Jane',
          required: true,
          maxLength: 40,
          validation: validation.text('GIVEN_NAME_ERROR'),
          autoComplete: 'given-name',
        },
        {
          label: 'FAMILY_NAME_LABEL',
          type: 'text',
          key: 'patient.familyName',
          placeholder: 'Smith',
          required: true,
          maxLength: 80,
          validation: validation.text('FAMILY_NAME_ERROR'),
          autoComplete: 'family-name',
        },
        {
          label: 'BIRTHDATE_LABEL',
          type: 'tel',
          key: 'patient.birthDate',
          required: true,
          placeholder: dateFormat,
          helpText: 'BIRTHDATE_HELP_TEXT',
          validation: validation.date,
          autoComplete: 'on',
          mask: dateInputMask(),
          getter: (val) => moment(val, API_DATE_FORMAT).format(dateFormat),
          setter: (val) => moment(val, dateFormat).format(API_DATE_FORMAT),
        },
      ],
    };
  },

  medicalIds: ({ groupConfig, locale }) => {
    return {
      title: `MEDICAL_IDS_QUESTION`,
      inputs: buildMedIdInputs(groupConfig.medicalIdsRequired || [], locale),
    };
  },

  gender: () => ({
    title: 'SEX_QUESTION',
    inputs: [
      {
        type: 'radio',
        key: 'patient.gender',
        name: 'patient.gender',
        autoFocus: !isTouchScreen(),
        required: true,
        validation: validation.response,
        options: [
          {
            label: 'FEMALE_LABEL',
            value: 'female',
          },
          {
            label: 'MALE_LABEL',
            value: 'male',
          },
        ],
      },
    ],
  }),

  caregiverInfo: () => ({
    title: 'CAREGIVER_QUESTION',
    inputs: [
      {
        label: 'GIVEN_NAME_LABEL',
        type: 'text',
        key: 'caregiver.givenName',
        autoFocus: !isTouchScreen(),
        placeholder: 'Jane',
        required: true,
        maxLength: 40,
        validation: validation.text('GIVEN_NAME_ERROR'),
        autoComplete: 'given-name',
      },
      {
        label: 'FAMILY_NAME_LABEL',
        type: 'text',
        key: 'caregiver.familyName',
        placeholder: 'Smith',
        required: true,
        maxLength: 80,
        validation: validation.text('FAMILY_NAME_ERROR'),
        autoComplete: 'family-name',
      },
    ],
  }),

  smartphone: () => ({
    title: 'SMARTPHONE_QUESTION',
    inputs: [
      {
        type: 'radio',
        key: 'hubRequired',
        name: 'hubRequired',
        required: true,
        autoFocus: !isTouchScreen(),
        validation: validation.response,
        options: [
          {
            label: 'YES_IOS_LABEL',
            value: 'ios',
          },
          {
            label: 'YES_ANDROID_LABEL',
            value: 'android',
          },
          {
            label: 'NO_DEVICE_LABEL',
            value: 'true',
          },
        ],
      },
    ],
  }),

  address: ({ groupConfig }) => {
    const baseInputs = [
      {
        label: 'STREET_ADDRESS_1_LABEL',
        type: 'text',
        autoFocus: !isTouchScreen(),
        key: 'mailingAddress.street',
        placeholder: 'STREET_ADDRESS_1_PLACEHOLDER',
        required: true,
        maxLength: 50,
        autoComplete: 'shipping address-line1',
        validation: validation.text('STREET_ADDRESS_ERROR'),
      },
      {
        label: 'STREET_ADDRESS_2_LABEL',
        type: 'text',
        key: 'mailingAddress.street2',
        placeholder: 'STREET_ADDRESS_2_PLACEHOLDER',
        required: false,
        maxLength: 50,
        autoComplete: 'shipping address-line2',
      },
      {
        label: 'CITY_LABEL',
        type: 'text',
        key: 'mailingAddress.city',
        required: true,
        maxLength: 40,
        autoComplete: 'shipping address-level2',
        validation: validation.text('CITY_ERROR'),
      },
      {
        label: 'STATE_LABEL',
        type: 'select',
        options: [],
        includeNull: true,
        placeholder: 'STATE_PLACEHOLDER',
        key: 'mailingAddress.stateCode',
        present: true,
        required: true,
        maxLength: 80,
        autoComplete: 'shipping address-level1',
        validation: validation.option('STATE_ERROR'),
        setState: (val) => STATE_REFERENCE[val],
      },
      {
        label: 'STATE_LABEL',
        type: 'select',
        options: [],
        includeNull: true,
        placeholder: 'STATE_PLACEHOLDER',
        key: 'mailingAddress.stateOrRegion',
        present: false,
        required: true,
        maxLength: 80,
        autoComplete: 'shipping address-level1',
        validation: validation.option('STATE_ERROR'),
      },
      {
        label: 'STATE_LABEL',
        type: 'text',
        key: 'mailingAddress.stateOrRegion',
        present: false,
        required: true,
        maxLength: 80,
        autoComplete: 'shipping address-level1',
        validation: validation.text('STATE_ERROR'),
      },
      {
        label: 'POSTAL_CODE_LABEL',
        type: 'text',
        key: 'mailingAddress.postalCode',
        required: true,
        maxLength: 20,
        autoComplete: 'shipping postal-code',
        validation: validation.text('POSTAL_CODE_ERROR'),
      },
    ];

    return {
      title: 'SHIPPING_ADDRESS_QUESTION',
      inputs: countries.inputsForCountry(groupConfig.country, baseInputs),
    };
  },

  credentials: () => ({
    title: 'EMAIL_PASSWORD_QUESTION',
    explanation: 'EMAIL_PASSWORD_EXPLANATION',
    inputs: [
      {
        label: 'EMAIL_LABEL',
        type: 'email',
        key: 'email',
        autoFocus: !isTouchScreen(),
        required: true,
        placeholder: 'jane@example.com',
        autoComplete: 'email',
        maxLength: 80,
        validation: validation.email,
        onBlur: validation.availableEmail,
        setter: (val) => val.toLowerCase(),
      },
      {
        label: 'REPEAT_EMAIL_LABEL',
        type: 'email',
        key: 'verifyEmail',
        maxLength: 80,
        required: true,
        autoComplete: 'email',
        validation: validation.matchingEmail('email'),
        present: !isTouchScreen(),
      },
      {
        label: 'PASSWORD_LABEL',
        type: 'password',
        key: 'password',
        required: true,
        maxLength: 64,
        minLength: 8,
        autoComplete: 'new-password',
        validation: validation.password,
      },
    ],
  }),

  phone: ({ enrollmentData }) => {
    const IS_DESKTOP = !MOBILE_DEVICE();
    const NO_MOBILE = enrollmentData.hubRequired === 'true';

    return {
      title: NO_MOBILE ? 'PHONE_QUESTION' : 'MOBILE_PHONE_QUESTION',
      explanation: NO_MOBILE ? 'PHONE_EXPLANATION' : 'MOBILE_PHONE_EXPLANATION',
      inputs: [
        {
          type: 'phoneCountry',
          key: 'phoneCountry',
        },
        {
          label: NO_MOBILE ? 'PHONE_LABEL' : 'MOBILE_PHONE_LABEL',
          type: 'phone',
          key: 'phone',
          autoFocus: !isTouchScreen(),
          autoComplete: 'tel-national',
          mask: '( 999 ) 999-9999',
          maxLength: 40,
          validation: validation.phone,
          getter: phoneGetter,
          setter: phoneSetter,
          button: false,
        },
        {
          label: 'SIGN_IN_LINK_LABEL',
          type: 'checkbox',
          key: 'sendSMSLinks',
          dependentKey: 'phone',
          present: !NO_MOBILE && !IS_DESKTOP,
          dependentCheck: (dependency, val) => {
            let _val =
              'string' === typeof val ? val.replace(/[-_\s()]/g, '') : '';
            let isDisabled = !_val || _val.length < 10;
            return {
              disabled: isDisabled,
              defaultValue: !isDisabled ? true : undefined,
            };
          },
        },
      ],
    };
  },

  shareAccess: ({ enrollmentData }) => {
    const NO_MOBILE = enrollmentData.hubRequired === 'true';

    return {
      title: NO_MOBILE
        ? 'SHARE_ACCESS_QUESTION'
        : 'MOBILE_SHARE_ACCESS_QUESTION',
      explanation: NO_MOBILE
        ? 'SHARE_ACCESS_EXPLANATION'
        : 'MOBILE_SHARE_ACCESS_EXPLANATION',
      inputs: [
        {
          type: 'radio',
          key: 'shareAccess',
          name: 'shareAccess',
          required: true,
          autoFocus: !isTouchScreen(),
          validation: validation.response,
          options: [
            {
              label: NO_MOBILE
                ? 'SHARE_ACCESS_YES_LABEL'
                : 'MOBILE_SHARE_ACCESS_YES_LABEL',
              value: 'yes',
            },
            {
              label: NO_MOBILE
                ? 'SHARE_ACCESS_NO_LABEL'
                : 'MOBILE_SHARE_ACCESS_NO_LABEL',
              value: 'no',
            },
          ],
        },
      ],
    };
  },

  patientCredentials: () => ({
    title: 'PATIENT_CREDENTIALS_QUESTION',
    explanation: 'PATIENT_CREDENTIALS_EXPLANATION',
    inputs: [
      {
        label: 'PATIENT_EMAIL_LABEL',
        type: 'email',
        key: 'patient.email',
        autoFocus: !isTouchScreen(),
        required: true,
        placeholder: 'jane@example.com',
        autoComplete: 'email',
        maxLength: 256,
        validation: validation.patientEmail,
        onBlur: validation.availableEmail,
        setter: (val) => val.toLowerCase(),
      },
      {
        label: 'REPEAT_EMAIL_LABEL',
        type: 'email',
        key: 'patient.verifyEmail',
        maxLength: 256,
        required: true,
        autoComplete: 'email',
        validation: validation.matchingEmail('patient.email'),
        present: !isTouchScreen(),
      },
      {
        label: 'PATIENT_PASSWORD_LABEL',
        type: 'password',
        key: 'patient.password',
        required: true,
        maxLength: 64,
        minLength: 8,
        autoComplete: 'new-password',
        validation: validation.password,
      },
    ],
  }),
};

export const stepList = [
  {
    key: 'whoEnrolling',
    active: true,
  },
  {
    key: 'basicInfo',
    active: true,
  },
  {
    key: 'medicalIds',
    active: false,
  },
  {
    key: 'gender',
    active: false,
  },
  {
    key: 'caregiverInfo',
    active: false,
  },
  {
    key: 'smartphone',
    active: true,
  },
  {
    key: 'coppa',
    active: false,
  },
  {
    key: 'address',
    active: true,
  },
  {
    key: 'credentials',
    active: true,
  },
  {
    key: 'phone',
    active: true,
  },
  {
    key: 'shareAccess',
    active: false,
  },
  {
    key: 'patientCredentials',
    active: false,
  },
  {
    key: 'schedule',
    active: false,
  },
  {
    key: 'terms',
    active: true,
  },
];


export default {
  steps: stepList,
};
