import { connect } from 'react-redux';

import { enrollmentDisease } from '../../../lib/utilities/index.js';

const mapStateToProps = (state) => {
  return {
    needsDisease: !enrollmentDisease(state.enrollmentData),
  };
};

export default connect(mapStateToProps);
