import { SET_WALGREENS_PRODUCTS } from '../actions';

export default function walgreensProducts(state = [], action) {
  switch (action.type) {
    case SET_WALGREENS_PRODUCTS:
      return action.walgreensProducts || [];
    default:
      return state;
  }
}
