import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import container from './container';
import MobileFAQ from './MobileFAQ';

import iconFaqCost from '../../assets/images/icons/icon-faq-cost.svg';
import iconFaqData from '../../assets/images/icons/icon-faq-data.svg';
import iconFaqBatteryLife from '../../assets/images/icons/icon-faq-battery.svg';
import iconFaqCall from '../../assets/images/icons/icon-faq-smart-phone.svg';


import './faq.scss';

const getFAQs = (groupConfig, subdomain, t) => {


  if (
    groupConfig &&
    (!groupConfig.costFAQHeader || !groupConfig.dataPrivacyFAQHeader)
  ) {
    return null;
  }
  

  return [
    {
      icon: iconFaqCost,
      question: groupConfig.costFAQHeader,
      answer: groupConfig.costFAQBody,
    },
    {
      icon: iconFaqData,
      question: groupConfig.dataPrivacyFAQHeader,
      answer: groupConfig.dataPrivacyFAQBody,
    },
    {
      icon: iconFaqBatteryLife,
      question: t('FAQ_QUESTION_BATTERY'),
      answer: t('FAQ_ANSWER_BATTERY'),
    },
    {
      icon: iconFaqCall,
      question: t('FAQ_QUESTION_CONTACT'),
      answer: t('FAQ_ANSWER_CONTACT'),
    },
  ];
};

export const FAQ = (props) => {
  const { groupConfig, t, subdomain } = props;
  const questions = getFAQs(groupConfig, subdomain, t);

  if (!questions) return null;

  return (
    <section className='faqs'>
      <MobileFAQ questions={questions} />
    </section>
  );
};

FAQ.propTypes = {
  groupConfig: PropTypes.object.isRequired,
  subdomain: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(container, withTranslation('faq'))(FAQ);
