import { connect } from 'react-redux';
import { setError, setSession, showSignIn } from '../../actions';

function mapDispatchToProps(dispatch) {
  return {
    setSession: (user) => {
      dispatch(setSession(user));
      dispatch(showSignIn(false));
    },
    setError: (err) => dispatch(setError(err)),
  };
}

export default connect(undefined, mapDispatchToProps);
