import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';
import FormGroup from 'react-bootstrap/FormGroup';
import { withTranslation } from 'react-i18next';
import { getNextPage } from '../enrollment-utils';
import container from '../container';
import Title from '../../../components/Title/Title';
import PageHeading from '../../../components/PageHeading/PageHeading';
import EnrollmentButtons from '../Buttons';
import { logPage } from '../../../lib/analytics';

export class Smartphone extends React.Component {
  constructor(props) {
    super(props);

    const hubRequired =
      props.enrollmentData && props.enrollmentData.hubRequired;
    this.state = {
      hubRequired: hubRequired || '',
      valid: null,
    };
  }

  smartphoneEnum = ['ios', 'android', 'true'];

  componentDidMount() {
    const { enrollmentMeds, pushHistory } = this.props;
    if (!enrollmentMeds || enrollmentMeds.length < 1) {
      pushHistory('/');
    }
    logPage('Enroll-Smartphone');
  }

  isValid = (value) => {
    return this.smartphoneEnum.includes(value);
  };

  handleChange = (e) => {
    this.setState({ hubRequired: e.target.value, valid: null });
  };
  handleBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  handleSubmit = (e) => {
    const { device, enrollmentData, enrollmentMeds, groupConfig } = this.props;
    e.preventDefault();
    const hubRequired = this.state.hubRequired;
    if (hubRequired === 'true' && !groupConfig.hubAllowed) {
      return '/enrollment/no-smartphone';
    }
    const next = getNextPage({
      device,
      currentPage: 'smartphone',
      enrollmentData,
      enrollmentMeds,
      groupConfig,
    });
    if (this.isValid(hubRequired)) {
      this.props.updateEnrollmentData({ hubRequired: hubRequired });
      this.props.pushHistory(next);
    } else {
      this.setState({ valid: 'error' });
    }
  };

  render() {
    const { t } = this.props;

    return (
      <PageHeading pageTitle={t('PAGE_TITLE_SMARTPHONE')} t={t}>
        <Title
          text={t('SMARTPHONE_QUESTION')}
          className='sm-center-lg-left-text'
          id='smartphone'
          level={1}
        />

        <div className='sm-center-lg-left-text content-row' xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>{t('DEVICE_DESCRIPTION')}</div>
        <Row>
          <Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <Form onSubmit={(e) => this.handleSubmit(e)}>
              <FormGroup
                controlId='hubRequired'
                id='smartphone-question'
                aria-labelledby='smartphone'
                role='radiogroup'
                validationState={this.state.valid}
              >
                <ul className='radio-selectors'>
                  <li>
                    <input
                      className='custom-radio'
                      checked={this.state.hubRequired === 'ios'}
                      type='radio'
                      name='hubRequired'
                      value='ios'
                      id='ios'
                      onChange={this.handleChange}
                    />
                    <label htmlFor='ios'>{t('YES_IOS_LABEL')}</label>
                  </li>
                  <li>
                    <input
                      className='custom-radio'
                      checked={this.state.hubRequired === 'android'}
                      type='radio'
                      name='hubRequired'
                      value='android'
                      id='android'
                      onChange={this.handleChange}
                    />
                    <label htmlFor='android'>{t('YES_ANDROID_LABEL')}</label>
                  </li>
                  <li>
                    <input
                      className='custom-radio'
                      checked={this.state.hubRequired === 'true'}
                      type='radio'
                      name='hubRequired'
                      value='true'
                      id='no-device'
                      onChange={this.handleChange}
                    />
                    <label htmlFor='no-device'>{t('NO_DEVICE_LABEL')}</label>
                  </li>
                </ul>
                {this.state.valid === 'error' ? (
                  <div
                    role='alert'
                    aria-live='assertive'
                    className='error-message-center'
                  >
                    {t('SELECT_OPTION')}
                  </div>
                ) : null}
                {!(this.props.groupConfig && this.props.groupConfig.hubAllowed) && this.state.hubRequired === 'true' ? (
                  <div
                    role='alert'
                    aria-live='assertive'
                    className='error-message-center'
                  >
                    {t('DEVICE_ERROR')}
                  </div>
                ) : null}
              </FormGroup>
              <EnrollmentButtons
                handleBack={this.handleBack}
                handleSubmit={this.handleSubmit}
                t={t}
              />
            </Form>
          </Col>
        </Row>
      </PageHeading>
    );
  }
}

Smartphone.propTypes = {
  device: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  enrollmentData: PropTypes.object.isRequired,
  enrollmentMeds: PropTypes.array.isRequired,
  groupConfig: PropTypes.object.isRequired,
  pushHistory: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateEnrollmentData: PropTypes.func.isRequired,
};

export default compose(withTranslation('enrollment'), container)(Smartphone);
