import React from 'react';
import PropTypes from 'prop-types';
import './title.scss';

// Title corresponds to the headline text style defined in the Zeplin web style guide
// for phTitle and phTitle2 (corresponding to level 1 and 2 respectively)
// It optionally takes a leading image and image alt text

const Title = ({ alt, className, icon, id, level = 1, text }) => {
  const Heading = `h${level}`;
  const combinedClass = className ? `title ${className}` : 'title';
  return (
    <Heading className={combinedClass} id={id}>
      {icon ? <img className='icon' src={icon} alt={alt} height='55' /> : null}
      {text}
    </Heading>
  );
};

Title.propTypes = {
  alt: PropTypes.string, // optional: alt text for an image if there is one
  className: PropTypes.string, // optional: any additional class(es)
  icon: PropTypes.string, // optional: url for an image
  id: PropTypes.string, // optional
  level: PropTypes.number, // optional: level of heading, eg 1 = h1, 2, = h2
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired, // required: headline text. usually a string, but object if Trans is passed in
};

export default Title;
