import { connect } from 'react-redux';
import { setDisease } from '../../../actions';
import i18n from 'i18next';

const mapStateToProps = ({
  analyticsFlags,
  groupConfig,
  mongoObjectId,
  queryParams,
}) => {
  const lpVariant =
    queryParams && queryParams.lpVariant ? queryParams.lpVariant : 'none';

  const disease =
    queryParams && queryParams.version
      ? queryParams.version === '2'
        ? 'copd'
        : 'asthma'
      : null;

  let lang;
  if (Boolean(queryParams && queryParams.lang)) {
    lang = queryParams.lang === 'es-US' ? 'es-US' : 'en-US';
  } else {
    lang = i18n.language;
  }

  return {
    analyticsFlags,
    disease,
    groupConfig,
    lang,
    lpVariant,
    mongoObjectId,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDisease: (disease) => dispatch(setDisease(disease)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);