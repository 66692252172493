import { Component } from 'react';
import { connect } from 'react-redux';

const modalTransition = (location) => location.state && location.state.modal;
const samePlace = (path, priorPath) => path === priorPath;
const fromModal = (location, action) =>
  modalTransition(location) && /POP|REPLACE/.test(action);

export class ScrollToTop extends Component {
  componentDidUpdate({ location: lastLocation }) {
    const { location: nextLocation, action } = this.props;
    const isSamePlace = samePlace(nextLocation.pathname, lastLocation.pathname);

    if (
      !isSamePlace &&
      /PUSH|REPLACE/.test(action) &&
      !modalTransition(nextLocation)
    ) {
      return window.scrollTo(0, 0);
    }

    if (
      isSamePlace || // same url. don't jank
      modalTransition(nextLocation) || // next screen is a modal
      fromModal(lastLocation, action) // going back from a modal
    )
      return;

    window.scrollTo(0, 0);
  }

  render() {
    return this.props.children;
  }
}

const mapStateToProps = ({ router }) => {
  return {
    location: router.location,
    action: router.action,
  };
};

export default connect(mapStateToProps)(ScrollToTop);
