const countryCodes = [
  { name: 'AR', code: 54 },
  { name: 'AU', code: 61 },
  { name: 'CA', code: 1 },
  { name: 'DE', code: 49 },
  { name: 'ES', code: 34 },
  { name: 'FR', code: 33 },
  { name: 'GB', code: 44 },
  { name: 'IE', code: 353 },
  { name: 'IT', code: 39 },
  { name: 'MX', code: 52 },
  { name: 'NL', code: 31 },
  { name: 'PT', code: 351 },
  { name: 'US', code: 1 },
];

export const getCountryCodes = () => {
  return countryCodes;
};

export const getCountryCode = (name) => {
  let country = countryCodes.filter((country) => {
    return name === country.name;
  });
  return country[0] ? country[0].code : undefined;
};

export const getCountryName = (code) => {
  let country = countryCodes.filter((country) => {
    return Number(country.code) === Number(code);
  });
  return country[0] ? country[0].name : undefined;
};

export const getCurrentCountryName = (props) => {
  const { countryCode, lng, trData } = props;
  if (countryCode) {
    return getCountryName(countryCode);
  } else if (trData && trData.countryCode) {
    return getCountryName(trData.countryCode);
  } else if (lng) {
    return lng.split('-')[1];
  } else {
    return 'US';
  }
};

export const getCurrentCountryCode = (props) => {
  const { countryCode, lng, trData } = props;
  if (countryCode) {
    return countryCode;
  } else if (trData && trData.countryCode) {
    return trData.countryCode;
  } else if (lng) {
    return getCountryCode(lng.split('-')[1]);
  } else {
    return 1;
  }
};
