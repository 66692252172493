import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import HasSelectedMeds from './HasSelectedMeds';
import NoSelectedMeds from './NoSelectedMeds';
import { logPage } from '../../../lib/analytics';

import container from './container';
// TODO: write separate containers for each page option so we're not passing params through
// here as an intermediary

// when react hydrates after rendering on the server, it does minimal checking
// of current vs. expected dom.  It only really compares text.
// Much of our enrollment-specific state is only known on the client,
// so it isn't applied on server render, and during hydration
// React won't do certain updates based on that state.
// componentDidMount does not run on server rendering, so we can use it to
// defer rendering until we're running on the client.

export class MedSummary extends React.Component {
  state = {
    isClient: false,
  };

  componentDidMount() {
    this.props.resetMedSelection();
    this.setState({ isClient: true });
    logPage('MedSelect-Summary');
  }

  render() {
    const {
      country,
      enrollmentMeds,
      groupConfig,
      notify,
      patient,
      pharmaConfig,
      pharmaProgram,
      removeNotification,
      removeMed,
      setPharmaProgram,
      t,
    } = this.props;
    const { isClient } = this.state;

    if (!isClient) return null;

    const groupMeds = groupConfig.medications || [];
    const medIds = enrollmentMeds.map(({ medicationId }) => medicationId);
    const medications = groupMeds.filter((med) => medIds.includes(med.id));

    if (medications.length > 0) {
      return (
        <HasSelectedMeds
          country={country}
          medications={medications}
          notify={notify}
          patient={patient}
          pharmaConfig={pharmaConfig}
          pharmaProgram={pharmaProgram}
          removeMed={removeMed}
          removeNotification={removeNotification}
          setPharmaProgram={setPharmaProgram}
          t={t}
        />
      );
    } else {
      return <NoSelectedMeds t={t} />;
    }
  }
}

MedSummary.displayName = 'MedSummary';

MedSummary.propTypes = {
  enrollmentMeds: PropTypes.array,
  groupConfig: PropTypes.object,
  notify: PropTypes.func,
  removeMed: PropTypes.func,
  removeNotifciation: PropTypes.func,
  resetMedSelection: PropTypes.func,
  patient: PropTypes.object,
  pharmaConfig: PropTypes.object,
  pharmaProgram: PropTypes.object,
  setPharmaProgram: PropTypes.func,
  t: PropTypes.func,
};

export default compose(
  withTranslation(['medSelection', 'common']),
  container
)(MedSummary);
