import React from 'react';
import { compose } from 'redux';
import { withTranslation, Trans } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import withSession from '../../hocs/withSession';

import shoppingCartIcon from '../../assets/images/icons/icon_cart_download.svg';
import container from './container';
import { displayPhone, isWebview } from '../../lib/utilities';
import { createWalgreensCart } from '../../api/walgreens';
import './styles.scss';

export class PrepareCart extends React.Component {
  transferCartUrl = undefined;
  transferCartStillLoading = false;
  errorThreshold = 0;
  maxErrorThreshold = 3;
  displaySupportPage = false;

  state = { time: 5 };

  constructor(props) {
    super(props);
    this.countDown = this.countDown.bind(this);
    this.getCartTransferUrl = this.getCartTransferUrl.bind(this);
  }

  componentDidMount() {
    const { session } = this.props;
    if (session) {
      this.initializeCart();
    }
  }

  componentDidUpdate(prevProps) {
    const { session: prevSession } = prevProps;
    const { session } = this.props;
    if (!prevSession && session && session.id) {
      this.initializeCart();
    }
  }

  initializeCart() {
    this.getCartTransferUrl();
    this.timer = setInterval(this.countDown, 1000);
  }

  redirectPage() {
    //window.open(this.transferCartUrl, "_blank");
    document.location.href = this.transferCartUrl;
  }

  countDown() {
    let seconds = this.state.time - 1;

    if (seconds === 0) {
      if (this.transferCartUrl) {
        this.redirectPage();
      } else {
        this.transferCartStillLoading = true;
      }

      clearInterval(this.timer);
    }

    this.setState({
      time: seconds,
    });
  }

  sendErrorNotification = () => {
    const { sendToastError, t } = this.props;
    sendToastError({
      title: t('common:ERROR'),
      message: t('WAG_CART_ERROR'),
      position: 'tc',
      autoDismiss: 0,
      action: {
        label: t('common:TRY_AGAIN'),
        callback: this.getCartTransferUrl,
      },
    });
  };

  getWebviewUrl(url) {
    if (url === 'https://www.walgreens.com')
      return 'https://n-www.walgreens.com';
    if (url === 'https://m-qa2.walgreens.com')
      return 'https://n-qa2.walgreens.com';
    return url;
  }

  getCartTransferUrl() {
    const { walgreensProducts, session } = this.props;
    const body = {
      products: walgreensProducts,
      uid: session.id,
    };

    createWalgreensCart(body)
      .then((req) => {
        this.transferCartUrl = isWebview(navigator.userAgent)
          ? this.getWebviewUrl(req.transferURL)
          : req.transferURL;
        if (this.state.time === 0 && this.transferCartStillLoading) {
          this.redirectPage();
        }
      })
      .catch((err) => {
        this.errorThreshold = this.errorThreshold + 1;
        if (this.errorThreshold !== this.maxErrorThreshold) {
          this.sendErrorNotification();
        }
        this.displaySupportPage = true;
      });
  }

  render() {
    const { supportPhone, t } = this.props;
    const { time: number } = this.state;
    const phoneNumber = displayPhone(supportPhone);

    return (
      <Container as='main' className='container-cart'>
        <Row>
          <Col xs={12} md={{ span: 10, offset:1 }}>
            <Row>
              <Col xs={6} md={4} className='cart-text'>
                <h1>{t('SETTING_UP_CART')}</h1>
              </Col>
            </Row>
            <Row>
              {!this.transferCartStillLoading && !this.displaySupportPage && (
                <Col xs={6} md={4} className='cart-text'>
                  <Trans i18nKey='CART_LOADED_NUM_SECONDS' values={{ number }}>
                    Your cart will be loaded in <strong>{{ number }}</strong>{' '}
                    seconds...
                  </Trans>
                </Col>
              )}
              {this.transferCartStillLoading && !this.displaySupportPage && (
                <Col xs={6} md={4} className='cart-text'>
                  <div>
                    <p>{t('CART_TAKING_LONGER')}</p>
                    <p>{t('CART_REDIRECT_WHEN_READY')}</p>
                  </div>
                </Col>
              )}
              {this.displaySupportPage && (
                <div>
                  <p>{t('NEED_HELP')}</p>
                  <p>
                    <Trans
                      i18nKey='CALL_OR_EMAIL_SUPPORT'
                      phoneNumber={phoneNumber}
                    >
                      Call us at {{ phoneNumber }} or email support at{' '}
                      <a href='mailto:help@propellerhealth.com\'>
                        help@propellerhealth.com
                      </a>
                      .
                    </Trans>
                  </p>
                </div>
              )}
            </Row>
            <Row className='cart-img-container'>
              <img
                className='cart-img'
                alt='Shopping Cart'
                src={shoppingCartIcon}
              />
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default compose(
  withTranslation(['wag', 'common']),
  container,
  withSession
)(PrepareCart);
