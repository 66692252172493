import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push as pushHistory } from 'connected-react-router';
import { withTranslation } from 'react-i18next';
import PageHeading from '../../../components/PageHeading/PageHeading';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GreenButton from '../../../components/Button/GreenButton';
import Support from '../../../components/Support';
import { publicImg } from '../../../lib/utilities';
import { logPage } from '../../../lib/analytics';
import { getNextPage } from '../enrollment-utils';

const success = publicImg('success1.png');
const sorry = publicImg('sorry1.svg');

const mapStateToProps = (state) => {
  const { device, enrollmentData, enrollmentMeds, groupConfig, groupConfig4x } = state;

  return {
    device: device,
    enrollmentData: enrollmentData,
    enrollmentMeds: enrollmentMeds,
    groupConfig: groupConfig,
    groupConfig4x: groupConfig4x,
  };
};
const mapDispatchToProps = {
  pushHistory,
};
class CoppaSuccess extends Component {
  componentDidMount() {
    logPage('Coppa-Results-Success');
  }

  handleNext = () => {
    const next = getNextPage({
      currentPage: 'coppa',
      device: this.props.device,
      enrollmentData: this.props.enrollmentData,
      enrollmentMeds: this.props.enrollmentMeds,
      groupConfig: this.props.groupConfig,
      groupConfig4x: this.props.groupConfig4x,
    });
    this.props.pushHistory(next);
  };

  render() {
    const { t } = this.props;
    return (
      <Container>
        <PageHeading pageTitle={t('coppa:PAGE_TITLE_COPPA_RESULT')}>
          <Row>
            <Col xs={12}>
              <h1 className='sm-left-lg-center-text'>
                {t('coppa:THANK_YOU_IDENTITY_VERIFIED')}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className='text-center'>
              <img src={success} alt={t('coppa:COPPA_SUCCESS')} height='180' />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className='text-center'>
              <GreenButton
                block
                className='back-next-buttons'
                onClick={this.handleNext}
              >
                {t('common:CONTINUE')}
              </GreenButton>
            </Col>
          </Row>
        </PageHeading>
      </Container>
    );
  }
}

class CoppaFailure extends Component {
  componentDidMount() {
    logPage('Coppa-Results-Failure');
  }

  render() {
    const { t } = this.props;
    return (
      <Container>
        <PageHeading pageTitle={t('PAGE_TITLE_COPPA_RESULT')} t={t}>
          <Row>
            <Col xs={12}>
              <h1 className='sm-left-lg-center-text'>
                {t('coppa:UNABLE_TO_VERIFY_IDENTITY')}
              </h1>
            </Col>
          </Row>
          <Support img={{ src: sorry, alt: t('coppa:SORRY'), height: 180 }} />
        </PageHeading>
      </Container>
    );
  }
}

class CoppaResult extends React.Component {
  render() {
    const {
      enrollmentData,
      groupConfig,
      groupConfig4x,
      pushHistory,
      t,
      verified,
    } = this.props;
    return verified ? (
      <CoppaSuccess
        t={t}
        pushHistory={pushHistory}
        groupConfig={groupConfig}
        groupConfig4x={groupConfig4x}
        enrollmentData={enrollmentData}
      />
    ) : (
      <CoppaFailure groupConfig={groupConfig} t={t} />
    );
  }
}

export default compose(
  withTranslation('coppa'),
  connect(mapStateToProps, mapDispatchToProps)
)(CoppaResult);
