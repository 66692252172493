import { makeActionCreator } from '../utilities';

export const SET_PHARMA_PROGRAM = 'SET_PHARMA_PROGRAM';
export const SET_CONFIRMED_PHARMA_PROGRAM = 'SET_CONFIRMED_PHARMA_PROGRAM';

export const setPharmaProgram = makeActionCreator(
  SET_PHARMA_PROGRAM,
  'payload'
);
export const setConfirmedPharmaProgram = makeActionCreator(
  SET_CONFIRMED_PHARMA_PROGRAM,
  'payload'
);
