import React from 'react';
import { publicImg } from '../../lib/utilities';

const getISOCode = (country) => {
  if (country) {
    return country.toUpperCase();
  }
  return 'US';
};

const Flag = (props) => {
  const { country } = props;
  const isoCountry = getISOCode(country);
  const path = `iso_flags/${isoCountry}.png`;
  const flagImg = publicImg(path);

  return <img className='iso-flag' src={flagImg} alt={isoCountry} />;
};

export default Flag;
