import React from 'react';
import { withTranslation } from 'react-i18next';
import './styles.scss';

const ScoreLabels = ({ min, max, count, ranges, t }) => {
  let interval = min - 1;

  const labels = ranges.map((r) => {
    let style = {
      width: `${(100 * (r.value - interval)) / count}%`,
    };
    interval = +r.value;
    return (
      <div key={r.class} className={r.class} style={style}>
        {t(r.keyText)}
      </div>
    );
  });

  return <div className='act-labels clearfix break-words'>{labels}</div>;
};

const ScoreBar = ({ data, score, t }) => {
  const min = data.min,
    max = data.max,
    ranges = data.ranges,
    interval = data.interval,
    count = max - min + 1,
    baseDelay = 1250 / count,
    bar = new Array(count),
    style = {
      width: `${100 / count}%`,
    };

  let category;

  for (var i = 0; i < count; i++) {
    let value = i + min,
      inRange = value <= score ? 'act-sc' : '',
      showVal = [min, max].includes(value) ? '' : 'hide-sm',
      delay = (i + 5) * baseDelay,
      displayValue = interval ? (value % interval === 0 ? value : null) : value;

    // we don't want to run a filter on every iteration
    category =
      category === undefined || value > category.value
        ? ranges.find((r) => r.value > value)
        : category;

    bar[i] = (
      <div key={`score-point-${i}`} className='act-pt' style={style}>
        <div className={`act-num ${showVal}`}>{displayValue}</div>
        <div
          className={`act-point ${inRange} ${category && category.class}`}
          style={{ animationDelay: `${delay}ms` }}
        />
        <div className={`act-guide ${category.class}`} />
      </div>
    );
  }

  const scoreLeft = (100 * (score - min + 1)) / count - 100 / count / 2;

  return (
    <div className='act-score-wrapper'>
      <div
        className='act-score'
        style={{
          left: `${scoreLeft}%`,
          animationDelay: `${(score - min + 10) * baseDelay}ms`,
        }}
      >
        {score}
        <div className='pointer-wrapper'>
          <div className='pointer' />
        </div>
      </div>
      <div className='act-score-main'>{bar}</div>
      <ScoreLabels
        min={min}
        max={max}
        count={count}
        ranges={data.ranges}
        t={t}
      />
    </div>
  );
};

export default withTranslation('quiz')(ScoreBar);
