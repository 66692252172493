import React from 'react';
import { withRouter } from 'react-router-dom';
import PageHeading from '../../../components/PageHeading/PageHeading';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SelectedMedication from '../../../components/SelectedMedication';
import GaLink from '../../../components/GaLink';
import GreenButton from '../../../components/Button/GreenButton';
import GreyButton from '../../../components/Button/GreyButton';
import Title from '../../../components/Title/Title';
import analytics from '../../../lib/analytics';
import { SUBDOMAIN, isNonNebPillSprayRescue } from '../../../lib/utilities';
import Modal from 'react-bootstrap/Modal';
import warning from '../../../assets/images/icons/warning.svg';
import './HasSelectedMeds.scss';

import {
  isSponsorshipGroup,
  hasSensor,
  isPharmaProgramEligible,
  hashMed,
} from '../../../lib/utilities';
import {
  determinePharmaProgramEligibility,
  programMedFilter,
} from '../../../lib/pharmaProgram';

// make sure we have some meds with sensors
const pathForMeds = (meds, pharmaProgram) => {
  const medsForProgram = programMedFilter(pharmaProgram);
  const medsWithSensors = meds.filter((m) => medsForProgram(m) && hasSensor(m));
  const nSensoredMeds = medsWithSensors.length;

  if (nSensoredMeds > 0 && isSponsorshipGroup()) {
    if (pharmaProgram.selectedProgram !== 'propeller') {
      return {
        pathname: '/med-selection/your-sensors',
        search: meds.map((med) => `m=${hashMed(med.id)}`).join('&'),
      };
    } else {
      return {
        pathname: '/med-selection/no-sponsor',
      };
    }
  }

  if (nSensoredMeds === 0) {
    return {
      pathname: '/none-compatible',
    };
  } else {
    return {
      pathname: '/med-selection/your-sensors',
      search: meds.map((med) => `m=${hashMed(med.id)}`).join('&'),
    };
  }
};

const ErrorModal = ({ handleClose, show, t }) => {
  return (
    <Modal show={show} className='has-selected-meds'>
      <Modal.Body>
        <button
          aria-label={t('CLOSE')}
          type='button'
          className='close-modal'
          onClick={handleClose}
        />
        <div className='med-limit'>
          <img src={warning} alt='' width='20%' height='20%' />
          <h1 className='font-medium'>
            {t('MED_SUMMARY_TOO_MANY_MEDS_ERROR_TITLE')}
          </h1>
          <p className='font-book'>
            {t('MED_SUMMARY_TOO_MANY_MEDS_ERROR_BODY')}
          </p>
          <GreenButton onClick={handleClose}>{t('OK')}</GreenButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

class HasSelectedMeds extends React.Component {
  errorLimit = 8;
  warningLimit = 3;

  VALIDATION_STATE = {
    RESCUE_WARNING: 'rescue-warning',
    TOTAL_WARNING: 'total-warning',
    ERROR: 'error',
    NULL: '',
  };

  state = {
    disabled: false,
    showModal: false,
    sortByType: false,
    valid: this.VALIDATION_STATE.NULL,
  };

  componentDidMount() {
    const { medications = [] } = this.props;
    this.updatePatientProgram();
    this.validateMedications(medications);

    if (SUBDOMAIN === 'myasthma') {
      analytics.fbqMyasthma({
        name: 'AddToWishlist',
        properties: {
          content_name: 'Form Reaching - 25%',
        },
      });
    }
  }

  componentDidUpdate({ medications: prevMedications }) {
    const { medications } = this.props;
    const nPrevMeds = prevMedications.length;
    const nMeds = medications.length;
    this.validateMedications(medications);

    // we can't respond directly to removing a med, so we do it
    // indirectly by looking at a simple change in quantity
    if (nPrevMeds !== nMeds) {
      this.updatePatientProgram();
    }
  }

  sortMedsByType = (medications) => {
    return medications.sort((a, b) => {
      if (isNonNebPillSprayRescue(a) > isNonNebPillSprayRescue(b)) {
        return -1;
      }
      return 1;
    });
  };

  validateMedications = (medications) => {
    const sensoredMeds = medications.filter(hasSensor);
    const rescues = sensoredMeds.filter((med) => {
      return isNonNebPillSprayRescue(med);
    });

    const validation =
      medications.length > this.errorLimit
        ? 'ERROR'
        : rescues.length >= 2
        ? 'RESCUE_WARNING'
        : medications.length > this.warningLimit
        ? 'TOTAL_WARNING'
        : 'NULL';

    // don't change state if validation
    if (this.state.valid !== this.VALIDATION_STATE[validation]) {
      this.setState(() => {
        return { valid: this.VALIDATION_STATE[validation], disabled: false };
      });
      // sorting should never be turned off once it's turned on
      if (validation === 'RESCUE_WARNING') {
        this.setState(() => {
          return { sortByType: true };
        });
      }
    }
  };

  showErrorModal = () => {
    this.setState(() => {
      return { showModal: true, disabled: false };
    });
  };

  handleClose = () => {
    this.setState(() => {
      return { showModal: false };
    });
  };

  handleClick = () => {
    const { history, medications, pharmaProgram } = this.props;
    this.setState(() => {
      return { disabled: true };
    });
    // action = 'postMedsSelection-viewResults';
    // redirect to pathForMeds(medications, pharmaProgram)
    if (medications.length > 8) {
      this.showErrorModal();
    } else {
      history.push(pathForMeds(medications, pharmaProgram));
    }
  };

  updatePatientProgram() {
    // checks if sd is one of PHARMA_PROGRAM_SUBDOMAINS (i.e. MARKETING_SUBDOMAINS or WALGREENS_SUBDOMAINS)
    if (isPharmaProgramEligible()) {
      const {
        patient,
        medications,
        pharmaConfig,
        pharmaProgram,
        setPharmaProgram,
      } = this.props;

      const newProgram = determinePharmaProgramEligibility({
        patient,
        meds: medications,
        pharmaConfig,
        subdomain: SUBDOMAIN,
      });

      if (newProgram !== pharmaProgram.selectedProgram) {
        setPharmaProgram(newProgram);
      }
    }
  }

  render() {
    const { medications, country = 'US', removeMed, t } = this.props;
    const { disabled } = this.state;
    let sortedMeds = medications;
    if (this.state.sortByType) {
      sortedMeds = this.sortMedsByType(medications);
    }

    return (
      <div className='med-summary'>
        <PageHeading pageTitle={t('PAGE_TITLE_MED_SUMMARY')} t={t}>
          <Row className='selected-medications'>
            <Col xs={12}>
              <Title
                className='sm-left-lg-center-text'
                text={t('medSelection:GOT_IT')}
                level={1}
              />
            </Col>
            <Col xs={12}>
              <Title
                className='sm-left-lg-center-text'
                text={t('medSelection:WELL_FIND_BEST_KIT')}
                level={2}
              />
            </Col>

          {this.state.valid === 'total-warning' ? (
            <Col xs={12} className='warning' role='alert' aria-live='assertive'>
              <img
                className='warning-img'
                src={warning}
                alt='warning'
                width='26'
                height='26'
              />
              <p className='font-medium'>
                {t('MED_SUMMARY_TOO_MANY_MEDS_WARNING')}
              </p>
            </Col>
          ) : this.state.valid === 'rescue-warning' ? (
            <Col xs={12} className='warning' role='alert' aria-live='assertive'>
              <img
                className='warning-img'
                src={warning}
                alt='warning'
                width='24'
                height='24'
              />
              <p className='font-medium'>
                {t('MED_SUMMARY_TOO_MANY_RESCUES_WARNING')}
                <br />
                <span className='font-book subtext'>
                  {t('MED_SUMMARY_TOO_MANY_RESCUES_WARNING_SUBTEXT')}
                </span>
              </p>
            </Col>
          ) : (
            ''
          )}
          </Row>

          <div className='selected-medications'>
            {sortedMeds.map((med) => {
              return (
                <SelectedMedication
                  medication={med}
                  country={country}
                  key={med.id}
                  warning={this.state.valid}
                  remove={() => removeMed(med.id)}
                />
              );
            })}
          </div>
          <div className='act-buttons'>
            <div className='back-next-buttons text-center'>
              {disabled ? (
                <GreenButton disabled className='main-cta auto-center'>
                  {t('medSelection:FIND_SENSORS')}
                </GreenButton>
              ) : (
                <GreenButton
                  onClick={this.handleClick}
                  className='main-cta auto-center'
                >
                  {t('medSelection:FIND_SENSORS')}
                </GreenButton>
              )}
              <GreyButton
                className='main-cta auto-center'
                as={GaLink}
                action='medSummary-addAnother'
                to={{
                  pathname: '/med-selection/form-factors',
                }}
              >
                {t('common:ADD_ANOTHER')}
              </GreyButton>
              <div>
                <GaLink
                  className='btn-link'
                  action='medSummary-getSupport'
                  to={{
                    pathname: '/med-selection/support',
                  }}
                >
                  {t('common:NEED_HELP')}
                </GaLink>
              </div>
            </div>
          </div>
          <ErrorModal
            handleClose={this.handleClose}
            show={this.state.showModal}
            t={t}
          />
        </PageHeading>
      </div>
    );
  }
}

HasSelectedMeds.propTypes = {
  country: PropTypes.string,
  medications: PropTypes.array.isRequired,
  notify: PropTypes.func.isRequired,
  patient: PropTypes.object,
  pharmaConfig: PropTypes.object,
  pharmaProgram: PropTypes.object.isRequired,
  removeMed: PropTypes.func.isRequired,
  removeNotification: PropTypes.func.isRequired,
  setPharmaProgram: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withRouter(HasSelectedMeds);
