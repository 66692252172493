import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import GreenButton from '../Button/GreenButton';
import container from './container';
import { isTouchScreen } from '../../lib/utilities';
import { signIn } from '../../api/user';

function FieldGroup({ id, label, help, ...props }) {
  return (
    <Form.Group controlId={id}>
      <Form.Label>{label}</Form.Label>
      <Form.Control {...props} />
      {help && <Form.Text>{help}</Form.Text>}
    </Form.Group>
  );
}

export class SignInForm extends React.Component {
  state = {
    email: '',
    password: '',
  };

  onChange = ({ target }) => {
    const attribute = target.getAttribute('data-attr');
    this.setState({
      [attribute]: target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: this.state.email,
      password: this.state.password,
    };

    signIn(data)
      .then((res) => {
        this.props.setSession(res);
      })
      .catch((err) => {
        // do something in the ui
        this.props.setError(err);
      });
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <h2>{t('YOUR_SESSION_HAS_EXPIRED_SIGN_IN')}</h2>
        <form onSubmit={this.onSubmit}>
          <FieldGroup
            id='formControlsEmail'
            type='email'
            label={t('EMAIL_ADDRESS')}
            placeholder={t('EMAIL_ADDRESS_PLACEHOLDER')}
            autoFocus={!isTouchScreen()}
            onChange={this.onChange}
            data-attr='email'
            autoComplete='email'
          />
          <FieldGroup
            id='formControlsPassword'
            label={t('PASSWORD')}
            type='password'
            onChange={this.onChange}
            data-attr='password'
            autoComplete='password'
          />
          <GreenButton type='submit'>{t('SIGN_IN')}</GreenButton>
        </form>
      </React.Fragment>
    );
  }
}

export const SignIn = ({ show, ...rest }) => {
  return (
    <Modal show={show}>
      <Modal.Body>
        <SignInForm {...rest} />
      </Modal.Body>
    </Modal>
  );
};

export default compose(withTranslation(['common']), container)(SignIn);
