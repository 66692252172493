import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import Carousel from 'nuka-carousel';

import Caret from '../../../assets/images/carat.svg';
import './sensor-carousel.scss';
import { TLD } from '../../../lib/utilities';
import { logEvent } from '../../../lib/analytics';

const SensorCarousel = ({ t }) => {
  const lang = i18n.language || 'en-US';
  const [slideIndex, setSlide] = useState(0);
  const env = TLD === 'com' ? 'com' : TLD === 'io' ? 'io' : 'biz';

  // be sure to change alt text if carousel images are changed, updated, reordered, etc.
  const carouselAltText = [
    'CAROUSEL_SENSOR_HFA',
    'CAROUSEL_SENSOR_ATTACHES_TO_INHALER',
    'CAROUSEL_SENSOR_AUTOMATICALLY_RECORDS_EACH_DOSE',
    'CAROUSEL_SENSOR_SENDS_INFO_SMARTPHONE',
    'CAROUSEL_LEARN_CAUSE_OF_FLAREUPS',
    'CAROUSEL_START_DAY_WITH_CONFIDENCE',
    'CAROUSEL_FIND_INHALER_IF_LOST',
  ];

  useEffect(() => {
    if (slideIndex > 0) {
      logEvent('click', {
        action: `click-carousel-slide-${slideIndex + 1}`,
        label: 'YourSensors-SensorCarousel',
      });
    }
  }, [slideIndex]);

  const slides = [1, 2, 3, 4, 5, 6, 7].map((slide, i) => {
    return (
      <div key={`slide-${i}`} className='sensor-slide' aria-live='polite'>
        <img
          srcSet={
            (`https://images.propellerhealth.${env}/images/iasof/carousel/${lang}/${
              i + 1
            }.png?width=450 450w`,
            `https://images.propellerhealth.${env}/images/iasof/carousel/${lang}/${
              i + 1
            }.png?width=550, 550w`)
          }
          sizes='(min-width: 769px and max-width: 1199px) 450px, (min-width: 1200px) 550px, 450px'
          src={`https://images.propellerhealth.${env}/images/iasof/carousel/${lang}/${
            i + 1
          }.png?width=450`}
          alt={t(`medSelection:${carouselAltText[i]}`)}
        />
      </div>
    );
  });

  return (
    <div className='sensor-carousel' aria-label={t('common:HOW_IT_WORKS')}>
      <Carousel
        renderAnnounceSlideMessage={({ currentSlide, slideCount }) =>
          `Slide ${currentSlide + 1} of ${slideCount}`
        }
        afterSlide={(slideIndex) => setSlide(slideIndex)}
        autoplay={false}
        dragging={true}
        swiping={true}
        renderCenterLeftControls={({ previousSlide }) =>
          slideIndex !== 0 ? (
            <button className='caret-wrapper prev' onClick={previousSlide}>
              <img src={Caret} alt={t('common:PREVIOUS')} width='16' />
            </button>
          ) : null
        }
        renderCenterRightControls={({ nextSlide }) =>
          slideIndex < slides.length - 1 ? (
            <button className='caret-wrapper next' onClick={nextSlide}>
              <img src={Caret} alt={t('common:NEXT')} width='16' />
            </button>
          ) : null
        }
        renderCenterBottomControls={({ currentSlide }) => {
          return <div>{currentSlide}</div>;
        }}
        slideIndex={slideIndex}
        width={'100%'}
        heightMode={'max'}
      >
        {slides}
      </Carousel>
    </div>
  );
};

export default withTranslation(['medSelection, common'])(SensorCarousel);
