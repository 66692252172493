import { TLD } from './environment';

export const ANDROID_LINK =
  'https://play.google.com/store/apps/details?id=com.asthmapolis.mobile';
export const IOS_LINK =
  'https://itunes.apple.com/us/app/propeller-health/id520741533?mt=8';
export const PATIENT_PORTAL_URL = `https://patient.propellerhealth.${TLD}`;
export const GENERIC_DOWNLOAD_LINK = 'https://www.propellerhealth.com/app/';
export const TERMS_ROOT = 'https://s3-us-west-2.amazonaws.com/ph-legal';
export const DEFAULT_TERMS_DIR = 'termsAndConditions/default';

export const DISPLAY_DATE_FORMAT = 'MM/DD/YYYY';
export const API_DATE_FORMAT = 'YYYY-MM-DD';

export const TERMS_ACCEPTED_MESSAGE = 'termsAccepted';
export const TERMS_CANCELED_MESSAGE = 'termsCanceled';

export const PROPELLER_SUPPORT_EMAIL = 'help@propellerhealth.com';

export const X_PH_API_VERSION = '3.93.0';
